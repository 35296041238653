import { asIterableExpression } from '../compilation'
import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isArray, isMethodCall, isStringReference } from '../util'

type Type = { ':every': JsonValue }

/**
 * Check if every item in an array or an iterator is truthy.
 *
 * @usage: ```{ ':every': expr|expr[] }```
 * where:
 * - expr: any: the expression to evaluate
 *
 * @example
 * ```{ ':every': [true, false] }``` => false
 * ```{ ':every': [true, true] }``` => true
 *
 * @real world example
 * Create an and condition with multiple expressions in a if statement.
 * ```
 * {
 * ":if": {":every": [{":cond" : {"data.count": {"$gt": 4}}}, {":cond" : {"data.count": {"$lt": 10}}}]},
 * ":then": {"ok": true},
 * ":else": {"ok": false}
 * }
 * ```
 *
 * The above example will return true if data.count is greater than 4 and less than 10.
 */
export default createMethod<Type>({
  name: ':every',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':every') continue
      return false
    }

    const { ':every': everyExpr } = expr

    switch (typeof everyExpr) {
      case 'string':
        return isStringReference(everyExpr)

      case 'object':
        if (everyExpr === null) return false
        return isArray(everyExpr) || isMethodCall(everyExpr)

      default:
        return false
    }
  },

  evaluate(expr): undefined | boolean {
    // Optimization: eval only one item at a time
    if (isArray(expr[':every'])) {
      for (const item of expr[':every']) {
        const itemVal = this.evaluate(item)
        if (!itemVal) return false
      }
      return true
    }

    const result = this.evaluate(expr[':every'])
    if (Array.isArray(result)) return result.every(Boolean)

    return undefined
  },

  compile(expr) {
    const everyCompiled = this.createContext(':every').compile(expr[':every'])

    // Optimization: prefer iterator
    const iterableGetter = asIterableExpression(everyCompiled)

    return (scope: Scope) => {
      const iterable = iterableGetter(scope)
      if (!iterable) return undefined
      for (const item of iterable) if (!item) return false
      return true
    }
  },
})
