import { asIterableExpression } from '../compilation'
import { subScope } from '../scope'
import { JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isArray, isPureExpression } from '../util'

type Type = { ':map': JsonValue; ':to': JsonValue }

/**
 * Maps each element of an array to a new value.
 * @usage: ```{":map": array, ":to": mapper}```
 * where:
 * - `array` is an array
 * - `mapper` a json expression that will be evaluated for each element of the array
 *
 * @sub-scope variables
 * - `@item` the current item being processed
 * - `@index` the index of the current item
 * - `@position` the position of the current item (index + 1)
 * - `@first` true if the current item is the first item
 * - `@last` true if the current item is the last item
 * - `@array` the array being processed
 * - `@length` the length of the array
 *
 * @example
 * - ```{":map": [1, 2, 3], ":to": {":sum": ["{@item}", 1]}}``` returns `[2, 3, 4]`
 */
export default createMethod<Type>({
  name: ':map',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':map' || key === ':to') continue
      return false
    }

    if (!isArray(expr[':map']) && !isPureExpression(expr[':map'])) return false
    if (typeof expr[':to'] === 'undefined') return false

    return true
  },

  evaluate(expr) {
    const array = this.evaluate(expr[':map'])
    if (!isArray(array)) return undefined

    const { length } = array
    const result = Array(array.length)

    // Optimization: Create a re-usable child context

    // for (let i = 0; i < array.length; i++) {
    //   const ctx = this.subScope({ ... })
    //   result[i] = ctx.evaluate(expr[':to'])
    // }
    // return result

    const ctx = this.subScope({
      // Constant variables
      '@array': array,
      '@length': length,
      // Dynamic variables
      '@item': null as Value,
      '@first': true as boolean,
      '@index': -1,
      '@position': 0,
      '@last': true as boolean,
    })

    // Optimization: keep reference to scope object at hand
    const childScope = ctx.scope

    for (let i = 0; i < array.length; i++) {
      childScope['@item'] = array[i]
      childScope['@index'] = i
      childScope['@position'] = i + 1
      childScope['@first'] = i === 0
      childScope['@last'] = i === length - 1

      result[i] = ctx.evaluate(expr[':to'])
    }

    return result
  },

  compile(expr) {
    const inputCompiled = this.createContext(':map').compile(expr[':map'])
    const mapperCompiled = this.createContext(':to').compile(expr[':to'])

    const iterableGetter = asIterableExpression(inputCompiled)

    return (scope: Scope): Value => {
      const input = iterableGetter(scope)
      if (!input) return undefined

      const array = isArray(input) ? input : Array.from(input)

      const { length } = array
      const result = Array(array.length)

      // Optimization: Create a re-usable child context
      // for (let i = 0; i < array.length; i++) {
      //   const childScope = subScope(scope, { ... })
      //   result[i] = mapperCompiled(childScope)
      // }
      // return result

      const childScope = subScope(scope, {
        // Constant variables
        '@array': array,
        '@length': length,
        // Dynamic variables
        '@item': null as Value,
        '@first': true as boolean,
        '@index': -1,
        '@position': 0,
        '@last': true as boolean,
      })

      for (let i = 0; i < length; i++) {
        childScope['@item'] = array[i]
        childScope['@index'] = i
        childScope['@position'] = i + 1
        childScope['@first'] = i === 0
        childScope['@last'] = i === length - 1

        result[i] = mapperCompiled(childScope)
      }

      return result
    }
  },
})
