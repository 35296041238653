import { noop, returnNull, returnTrue, Type } from './util'

export type Awaitable<T> = PromiseLike<T> | T

/**
 * TS didn't have a built-in Awaited<T> type until 3.8
 * @deprecated use built-in Awaited<T> instead
 */
type AwaitedLegacy<T> = Awaited<T>
export { AwaitedLegacy as Awaited }

interface CatchIf {
  <E, R = null>(checker?: (v: any) => boolean, handler?: (v: E) => R): <F>(
    err: F
  ) => F extends E ? R : never
  <E, R = null>(checker?: (v: any) => v is E, handler?: (v: E) => R): <F>(
    err: F
  ) => F extends E ? R : never
}

export const catchIf: CatchIf =
  (checker: (v: any) => boolean = returnTrue, handler: (v: any) => any = returnNull) =>
  (err: any): any => {
    if (checker(err)) return handler(err)
    throw err
  }

interface Catcher {
  <E>(klass: Type<E>): (v: any) => null
  <E, R>(klass: Type<E>, handler: (v: E) => R): (v: any) => R
}

export const catcher: Catcher = <E>(klass: Type<E>, handler: (v: any) => any = returnNull) =>
  catchIf((err: any): err is Type<E> => err instanceof klass, handler)

export class Deferred<T> {
  public readonly promise: Promise<T>
  public resolve: (v: T | PromiseLike<T>) => void = noop
  public reject: (reason: Error) => void = noop

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}

type Lock = <T>(fn: () => T | PromiseLike<T>) => Promise<T>

export const createLock = (): Lock => {
  let promise = Promise.resolve()
  return async (fn) => {
    const result = promise.then(fn)
    promise = result.then(noop, noop)
    // Await is required here to make sure that a new promise is returned,
    // requiring the calling function to explicitely catch the error

    // eslint-disable-next-line @typescript-eslint/return-await
    return await result
  }
}

type AsAsyncFn<T extends (this: any, ...args: any[]) => any> = (
  this: ThisParameterType<T>,
  ...args: Parameters<T>
) => Promise<ReturnType<T>>

export const wrapWithLock = <T extends (this: any, ...args: any[]) => any>(
  lock: Lock,
  fn: T
): AsAsyncFn<T> => {
  return async function (...args: any[]) {
    return lock(fn.bind(this, ...args))
  }
}

/**
 * @example
 * // Assuming an implementation of a lock:
 * declare lock: <R>(runSafely: () => R | Promise<R>) => Promise<R>
 *
 * // That allows to run async tasks sequentially:
 * lock(async () => {
 *   console.log('this will run first')
 *   await someAsynctask()
 *   console.log('this will run second')
 * })
 *
 * lock(async () => {
 *   console.log('this will run third')
 *   await someOtherAsynctask()
 *   console.log('this will run fourth')
 * })
 *
 * // That lock can be "deferred" as follows:
 * (async () => {
 *   const release = await lockDeferrer(lock)
 *
 *   console.log('this will run fifth')
 *
 *   release()
 * })()
 */
export const lockDeferrer = async (lock: Lock): Promise<() => void> => {
  const { resolve } = await asyncDeferrer<void>(lock)
  return resolve as () => void
}

export async function runAsync<T extends (this: any, ...args: any[]) => any>(
  this: ThisParameterType<T>,
  f: T,
  ...args: Parameters<T>
): Promise<ReturnType<T> extends Awaitable<infer R> ? R : ReturnType<T>> {
  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        resolve(f.call(this, ...args))
      } catch (err) {
        reject(err)
      }
    })
  })
}

/**
 * @example
 * // Assuming an implementation of a lock:
 * declare lock: <R>(runSafely: () => R | Promise<R>) => Promise<R>
 *
 * // That allows to run async tasks sequentially:
 * lock(async () => {
 *   console.log('this will run first')
 *   await someAsynctask()
 *   console.log('this will run second')
 * })
 *
 * lock(async () => {
 *   console.log('this will run third')
 *   await someOtherAsynctask()
 *   console.log('this will run fourth')
 * })
 *
 * // That lock can be "deferred" as follows:
 * (async () => {
 *   const { resolve, reject } = await asyncDeferrer(lock)
 *
 *   console.log('this will run fifth')
 *
 *   resolve()
 * })()
 */
export const asyncDeferrer = async <R>(
  run: (cb: () => R | Promise<R>) => void
): Promise<{
  resolve: (value: R) => void
  reject: (err: Error) => void
}> => {
  const started = new Deferred<void>()
  const ended = new Deferred<R>()

  // Prevent 'unhandledRejection'
  ended.promise.catch(noop)

  runAsync(run, () => {
    started.resolve(undefined)
    return ended.promise
  }).catch(started.reject)

  await started.promise

  return ended
}

/**
 * @returns `Promise<true>` if aborted
 */
export function sleep(delay: number, signal?: AbortSignal) {
  return new Promise<boolean>((resolve, reject) => {
    if (signal?.aborted) resolve(true)
    else {
      const cleanup = (event?: { type: 'abort' }) => {
        resolve(event != null)
        clearTimeout(timeout)
        // @ts-expect-error (AbortSignal poorly typed)
        signal?.removeEventListener('abort', cleanup)
      }
      const timeout = setTimeout(cleanup, delay)
      // @ts-expect-error (AbortSignal poorly typed)
      signal?.addEventListener('abort', cleanup)
    }
  })
}

export async function sleepForSince(
  delay: number,
  since: number,
  signal?: AbortSignal
): Promise<boolean> {
  const sleepFor = delay - (Date.now() - since)
  if (sleepFor > 0 && !signal?.aborted) {
    await sleep(sleepFor, signal)
    return true
  }
  return false
}

export async function allFulfilled<T extends readonly unknown[] | []>(
  promises: T
): Promise<{ -readonly [P in keyof T]: PromiseFulfilledResult<Awaited<T[P]>> }>

export async function allFulfilled<T>(
  promises: Iterable<T | PromiseLike<T>>
): Promise<Array<PromiseFulfilledResult<Awaited<T>>>>

export async function allFulfilled<T>(
  promises: Iterable<T | PromiseLike<T>>
): Promise<Array<Awaited<T>>> {
  const results = await Promise.allSettled(promises)

  const errors = []
  const values: Array<Awaited<T>> = []

  for (const result of results) {
    if (result.status === 'rejected') errors.push(result.reason)
    else values.push(result.value)
  }

  if (errors.length) throw errors.length === 1 ? errors[0] : new AggregateError(errors)

  return values
}
