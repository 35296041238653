import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { StringTransformer } from './'

/**
 * Shortcut to evaluate an expression using the ":eval" method.
 *
 * @usage: ```> expression```
 */
class EvalStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const firstCharCode = expr.charCodeAt(0)

    return firstCharCode === 62 /* > */
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    return this.evaluateMethodCall({ ':eval': `{${expr.slice(1).trimStart()}}` })
  }
}

export default new EvalStringTransformer()
