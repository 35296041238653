import LRU from 'lru-cache'
import { createCompiler, createEvaluator, CompilationError } from '@penbox-io/json-expression'
import {
  convertLegacyForm,
  methods,
  populateElement,
  populateStep,
  normalizeElements,
  normalizeSteps,
} from '@penbox-io/smart-form'

import { getPath, getFlowSteps, identity } from './util.js'

export * from './normalize.js'
export * from './accessors.js'

/**
 * @typedef {import('@penbox-io/json-expression').ContextDetails} ContextDetails
 */

/**
 * @param {{ legacy?: boolean; prefix?: string[] }} [options]
 */
export const createStepsEvaluator = (options = undefined) => {
  const legacy = options?.legacy !== false
  const evaluator = createEvaluator({ legacy, methods })
  const transform = legacy ? convertLegacyForm : identity

  return (flow) => {
    if (!flow?.attributes) return undefined

    const expr = transform(getFlowSteps(flow))
    if (!expr) return undefined

    const { variables = null } = flow.attributes
    const exprWithVars = variables ? { ':define': variables, ':in': expr } : expr

    return (scope) => {
      const builtSteps = evaluator.evaluate(exprWithVars, scope)
      return normalizeSteps(builtSteps)?.map(populateStep, getPath(scope, options?.prefix))
    }
  }
}

/**
 * @param {(flow: any) => any} getExpr
 * @param {{ legacy?: boolean; prefix?: string[] }} [options]
 */
export const createElementsEvaluator = (getElements, options = undefined) => {
  const legacy = options?.legacy !== false
  const evaluator = createEvaluator({ legacy, methods })
  const transform = legacy ? convertLegacyForm : identity

  return (flow) => {
    if (!flow?.attributes) return undefined

    const expr = transform(getElements(flow))
    if (!expr) return undefined

    const { variables = null } = flow.attributes
    const exprWithVars = variables ? { ':define': variables, ':in': expr } : expr

    return (scope) => {
      const builtElements = evaluator.evaluate(exprWithVars, scope)
      return normalizeElements(builtElements)?.map(populateElement, getPath(scope, options?.prefix))
    }
  }
}

/**
 * @param {(flow: any) => any} getExpr
 * @param {{ legacy?: boolean; context?: ContextDetails }} [options]
 * @returns
 */
export const createExpressionCompiler = (getExpr, options = undefined) => {
  const legacy = options?.legacy !== false
  const context = options?.context

  const compiler = createCompiler({ legacy, methods })

  /**
   * @param {Parameters<typeof getExpr>} args
   */
  return (flow, ...args) => {
    if (!flow?.attributes) return undefined

    const expr = getExpr(flow, ...args)
    if (expr == null) return undefined

    const { variables = null } = flow.attributes
    const exprWithVars = variables ? { ':define': variables, ':in': expr } : expr

    try {
      return compiler.compile(exprWithVars, context)
    } catch (err) {
      // Because we wrapped the expr in a define/in, we need to fix the debugging error path
      if (variables && err instanceof CompilationError) {
        const i = context?.path?.length ?? 0

        if (err.path[i] === ':define') err.path.splice(0, i + 1, 'attributes', 'variables')
        else if (err.path[i] === ':in') err.path.splice(i, 1)
        // else should not happen
      }

      throw err
    }
  }
}

/** @deprrecated Use {@link createExpressionCompiler} */
export const createFlowExpressionCompiler = createExpressionCompiler

/**
 * @param {string[]} path
 * @param {boolean} [legacy]
 */
const createFlowCompiler = (path, legacy = true) => {
  if (!Array.isArray(path) || path.length === 0) {
    throw new TypeError('"path" must be an array of strings')
  }

  const getExpr = legacy
    ? (flow) => convertLegacyForm(getPath(flow, path))
    : (flow) => getPath(flow, path)

  return createFlowExpressionCompiler(getExpr, { legacy, context: { path } })
}

const compilerResultWrapper = (compiler, resultTransform) => {
  return (flow, ...args) => {
    const compiled = compiler(flow, ...args)
    if (!compiled) return () => undefined

    return (scope) => {
      const result = compiled(scope)
      return resultTransform(result, scope)
    }
  }
}

/**
 * @param {{ path?: string[]; prefix?: string[]; legacy?: boolean }} [options]
 */
export const createStepsCompiler = (options = undefined) =>
  compilerResultWrapper(
    createFlowCompiler(options?.path ?? ['attributes', 'steps'], options?.legacy),
    (result, scope) => normalizeSteps(result)?.map(populateStep, getPath(scope, options?.prefix))
  )

/**
 * @param {{ path: string[]; prefix?: string[]; legacy?: boolean }} options
 */
export const createElementsCompiler = ({ path, prefix = undefined, legacy = true }) =>
  compilerResultWrapper(createFlowCompiler(path, legacy), (result, scope) =>
    normalizeElements(result)?.map(populateElement, getPath(scope, prefix))
  )

export const cachedFlowCompiler = (compile, { max = 50 } = {}) => {
  const cache = new LRU({ max })

  return (flow, ...args) => {
    if (!flow?.attributes) return undefined

    const ts = flow.attributes.$updated_at

    const key = flow.id + (args.length > 0 ? `:${JSON.stringify(args)}` : '')

    if (cache.has(key)) {
      const cached = cache.get(key)
      if (cached.ts === ts) return cached.val
      else cache.del(key)
    }

    const val = compile(flow, ...args)

    cache.set(key, { ts, val })

    return val
  }
}
