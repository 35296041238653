import { MethodCall, MethodSignature } from '../types'
import { createMethod, isNumber, isPureExpression, isString, toNumber } from '../util'

type Type = {
  ':to-fixed': number | string | MethodCall
  ':digits': number | string | MethodCall
}

/**
 * Converts a number to a string with a fixed number of digits.
 *
 * @usage: ```{":to-fixed": number, ":digits"?: number}```
 *
 * where:
 * - `number` is a number
 * - `digits` is the number of digits to appear after the decimal point - default is 2
 *
 * @example
 * - ```{":to-fixed": 123.456}``` returns `"123.46"`
 * - ```{":to-fixed": 123.456, ":digits": 1}``` returns `"123.5"`
 */
export default createMethod<Type>({
  name: ':to-fixed',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':to-fixed') continue
      if (key === ':digits') continue
      return false
    }

    const toFixed = expr[':to-fixed']
    const digits = expr[':digits']
    if (!isNumber(toFixed) && !isPureExpression(toFixed) && !isString(toFixed)) return false
    if (digits != undefined && !isNumber(digits) && !isPureExpression(digits) && !isString(digits))
      return false

    return true
  },

  evaluate(expr) {
    const value = toNumber(this.evaluate(expr[':to-fixed']))
    const digits = expr[':digits'] !== undefined ? toNumber(this.evaluate(expr[':digits'])) ?? 0 : 2

    if (!Number.isFinite(value)) return undefined
    if (typeof digits !== 'number') return undefined

    if (digits < 0) return undefined
    if (digits >= 100) return undefined

    return value.toFixed(digits)
  },
})
