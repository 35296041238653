import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isArray, isDate, isJsonifiable, isPlainObject } from '../util'

const NAME = ':cmp' as const

type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>
}[keyof T]

type Type = {
  [_ in typeof NAME]: JsonValue
} & RequireAtLeastOne<{
  ':eq'?: JsonValue
  ':gt'?: JsonValue
  ':ge'?: JsonValue
  ':gte'?: JsonValue
  ':lt'?: JsonValue
  ':le'?: JsonValue
  ':lte'?: JsonValue
  ':neq'?: JsonValue
}>

/**
 * Compares a value with a set of conditions.
 * @usage: ```{ ':cmp': value, ':eq'?: value, ':gt'?: value, ':ge'?: value, ':lt'?: value, ':le'?: value }```
 * where:
 * - value: any: the value to compare
 * - eq: any?: the value to compare with for equality
 * - gt: any?: the value to compare with for greater than
 * - ge: any?: the value to compare with for greater than or equal
 * - lt: any?: the value to compare with for less than
 * - le: any?: the value to compare with for less than or equal
 *
 * @example
 * ```{ ':cmp': 1, ':eq': 1 }``` => true
 * ```{ ':cmp': 1, ':eq': 2 }``` => false
 * ```{ ':cmp': 1, ':gt': 0 }``` => true
 * ```{ ':cmp': 1, ':gt': 1 }``` => false
 * ```{ ':cmp': 1, ':ge': 1 }``` => true
 * ```{ ':cmp': 1, ':ge': 2 }``` => false
 * ```{ ':cmp': 1, ':gte': 1 }``` => true
 * ```{ ':cmp': 1, ':gte': 2 }``` => false
 * ```{ ':cmp': 1, ':lt': 2 }``` => true
 * ```{ ':cmp': 1, ':lt': 1 }``` => false
 * ```{ ':cmp': 1, ':le': 1 }``` => true
 * ```{ ':cmp': 1, ':le': 0 }``` => false
 * ```{ ':cmp': 1, ':lte': 1 }``` => true
 * ```{ ':cmp': 1, ':lte': 0 }``` => false
 *
 * @real world example
 *
 * You need to print a message based on the value of a variable.
 * scope: ```{ count: 3 }```
 * ```{ ":if": { ":cmp": "{count}", ":eq": 0 }, ":then": "No items", ":else": "Items: {count}" }```
 */
export default createMethod<Type>({
  name: NAME,

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === NAME) continue
      if (key === ':gt' || key === ':ge' || key === ':gte') continue
      if (key === ':lt' || key === ':le' || key === ':lte') continue
      if (key === ':eq') continue
      if (key === ':neq') continue
      return false
    }

    if (expr[NAME] === undefined) return false

    if (
      expr[':gt'] === undefined &&
      expr[':ge'] === undefined &&
      expr[':gte'] === undefined &&
      expr[':lt'] === undefined &&
      expr[':le'] === undefined &&
      expr[':lte'] === undefined &&
      expr[':eq'] === undefined &&
      expr[':neq'] === undefined
    ) {
      return false
    }

    return true
  },

  evaluate(expr) {
    const sub = this.evaluate(expr[NAME])
    const subNumber = Number(sub)

    if (
      expr[':gt'] !== undefined ||
      expr[':ge'] !== undefined ||
      expr[':gte'] !== undefined ||
      expr[':lt'] !== undefined ||
      expr[':le'] !== undefined ||
      expr[':lte'] !== undefined
    ) {
      if (Number.isNaN(subNumber)) return undefined
    }

    if (expr[':gt'] !== undefined) {
      const val = Number(this.evaluate(expr[':gt']))
      if (Number.isNaN(val)) return undefined
      if (!(subNumber > val)) return false
    }
    if (expr[':ge'] !== undefined || expr[':gte'] !== undefined) {
      const val = Number(this.evaluate(expr[':ge'] ?? expr[':gte']))
      if (Number.isNaN(val)) return undefined
      if (!(subNumber >= val)) return false
    }
    if (expr[':lt'] !== undefined) {
      const val = Number(this.evaluate(expr[':lt']))
      if (Number.isNaN(val)) return undefined
      if (!(subNumber < val)) return false
    }

    if (expr[':le'] !== undefined || expr[':lte'] !== undefined) {
      const val = Number(this.evaluate(expr[':le'] ?? expr[':lte']))
      if (Number.isNaN(val)) return undefined
      if (!(subNumber <= val)) return false
    }

    // Perf: eq() being more expensive than simple comparisons, do is last
    if (expr[':eq'] !== undefined) {
      const val = this.evaluate(expr[':eq'])
      if (!eq(sub, val)) return false
    }

    if (expr[':neq'] !== undefined) {
      const val = this.evaluate(expr[':neq'])
      if (eq(sub, val)) return false
    }

    return true
  },

  compile(expr) {
    const sub$ = this.createContext(NAME).compile(expr[NAME])

    const eq$ = expr[':eq'] === undefined ? null : this.createContext(':eq').compile(expr[':eq'])
    const neq$ =
      expr[':neq'] === undefined ? null : this.createContext(':neq').compile(expr[':neq'])
    const gt$ = expr[':gt'] === undefined ? null : this.createContext(':gt').compile(expr[':gt'])
    const ge$ =
      expr[':ge'] === undefined && expr[':gte'] === undefined
        ? null
        : this.createContext(':ge').compile(expr[':ge'] ?? expr[':gte'])
    const lt$ = expr[':lt'] === undefined ? null : this.createContext(':lt').compile(expr[':lt'])
    const le$ =
      expr[':le'] === undefined && expr[':lte'] === undefined
        ? null
        : this.createContext(':le').compile(expr[':le'] ?? expr[':lte'])

    return (scope: Scope) => {
      const sub = sub$(scope)
      const subNumber = Number(sub)

      if (gt$ !== null || ge$ !== null || lt$ !== null || le$ !== null) {
        if (Number.isNaN(subNumber)) return undefined
      }

      if (gt$ !== null) {
        const val = Number(gt$(scope))
        if (Number.isNaN(val)) return undefined
        if (!(subNumber > val)) return false
      }
      if (ge$ !== null) {
        const val = Number(ge$(scope))
        if (Number.isNaN(val)) return undefined
        if (!(subNumber >= val)) return false
      }
      if (lt$ !== null) {
        const val = Number(lt$(scope))
        if (Number.isNaN(val)) return undefined
        if (!(subNumber < val)) return false
      }
      if (le$ !== null) {
        const val = Number(le$(scope))
        if (Number.isNaN(val)) return undefined
        if (!(subNumber <= val)) return false
      }

      // Perf: eq() being more expensive than simple comparisons, do is last
      if (eq$ !== null) {
        const val = eq$(scope)
        if (!eq(sub, val)) return false
      }

      if (neq$ !== null) {
        const val = neq$(scope)
        if (eq(sub, val)) return false
      }

      return true
    }
  },
})

const isJsonifyableType = (
  type: string
): type is 'number' | 'boolean' | 'string' | 'object' | 'undefined' => {
  switch (type) {
    case 'number':
    case 'boolean':
    case 'string':
    case 'object':
    case 'undefined':
      return true
    default:
      return false
  }
}

const isComparableObject = (value: any): boolean => {
  if (isArray(value)) return true
  if (isDate(value)) return true
  if (isJsonifiable(value)) return true
  if (isPlainObject(value)) return true
  return false
}

export const eq = (a: any, b: any): undefined | boolean => {
  const aType = typeof a
  const bType = typeof b

  switch (aType) {
    case 'number':
      if (Number.isNaN(a) || Number.isNaN(b)) return undefined
    // falls through
    case 'boolean':
    case 'string':
    case 'undefined':
      if (!isJsonifyableType(bType)) return undefined
      return a === b
    case 'object':
      if (!isJsonifyableType(bType)) return undefined
      if (aType !== bType) return false
      // b is an object
      break

    default:
      // We don't know how to compare invalid types together (e.g. BigInt(1) === Number(1) ?)
      return undefined
  }

  if (!isComparableObject(b)) return undefined // isComparableObject(a) is performed in ifs below
  if (a === b) return true
  if (a === null || b === null) return a === b

  // Both a and b are non-null objects at this point

  if (isArray(a)) {
    if (!isArray(b)) return false

    const { length } = a
    if (length !== b.length) return false
    for (let i = length; i-- !== 0; ) if (!eq(a[i] ?? null, b[i] ?? null)) return false
    return true
  }

  if (isDate(a)) {
    if (!isDate(b)) return false

    const aTime = a.getTime()
    const bTime = b.getTime()
    if (Number.isNaN(aTime) || Number.isNaN(bTime)) return undefined

    return aTime === bTime
  }

  if (isJsonifiable(a) || isJsonifiable(b)) {
    const aJson = isJsonifiable(a) ? a.toJSON() : a
    const bJson = isJsonifiable(b) ? b.toJSON() : b

    // Heuristic attempting to prevent infinite loops
    if (isJsonifiable(aJson) || isJsonifiable(bJson)) return undefined

    return eq(aJson, bJson)
  }

  if (isPlainObject(a)) {
    if (!isPlainObject(b)) return false

    // Only compare keys with defined values (as JSON.stringify would omit them)
    const aKeys = Object.keys(a).filter(isDefinedInThis, a)
    const bKeys = Object.keys(b).filter(isDefinedInThis, b)

    const { length } = aKeys
    if (length !== bKeys.length) return false

    for (let i = length; i-- !== 0; ) {
      const key = aKeys[i]

      if (!bKeys.includes(key)) return false
    }

    for (let i = length; i-- !== 0; ) {
      const key = aKeys[i]

      const equal = eq(a[key], b[key])
      if (equal !== true) return equal // false or undefined
    }

    return true
  }

  // Unknown object
  return undefined
}

function isDefinedInThis<T extends Record<string, any>>(this: T, key: keyof T) {
  return this[key] !== undefined
}
