import { MethodCall, MethodSignature } from './types'

export type TestContext = { strict: boolean }

export interface MethodDefinition<T = any> {
  name: Extract<keyof T, string>
  test: (this: TestContext, expr: MethodCall) => expr is MethodSignature<T>
}

type ConfigOptions = {
  strict: boolean
  ignoreInlineExpression?: boolean
  ignoreCompileErrors?: boolean
  methods: Iterable<MethodDefinition>
}

const stripCompile = <T extends MethodDefinition>({ compile, ...def }: T): Omit<T, 'compile'> => def

export class Config {
  public readonly strict: boolean
  public readonly ignoreInlineExpression: boolean
  public readonly ignoreCompileErrors: boolean
  public readonly methods: MethodContainer

  constructor(options: ConfigOptions) {
    this.strict = options.strict
    this.ignoreInlineExpression = options.ignoreInlineExpression ?? false
    this.ignoreCompileErrors = options.ignoreCompileErrors ?? !options.strict
    this.methods =
      options.methods instanceof MethodContainer
        ? options.methods
        : new MethodContainer(options.methods)
  }

  extend(options?: Partial<ConfigOptions> & { stripCompile?: boolean }): Config {
    const methods = options?.methods ? [...this.methods, ...options.methods] : this.methods

    return new Config({
      strict: options?.strict ?? this.strict,
      ignoreInlineExpression: options?.ignoreInlineExpression ?? this.ignoreInlineExpression,
      ignoreCompileErrors: options?.ignoreCompileErrors ?? this.ignoreCompileErrors,
      methods: options?.stripCompile ? Array.from(methods, stripCompile) : methods,
    })
  }

  findMethod(expr: MethodCall) {
    return this.methods.find(expr, this)
  }
}

class MethodContainer implements Iterable<MethodDefinition> {
  private readonly index: Map<string, Set<MethodDefinition>>

  constructor(methods: Iterable<MethodDefinition>) {
    this.index = buildMethodContainerIndex(methods)
  }

  *[Symbol.iterator]() {
    for (const methods of this.index.values()) {
      // For some strange reason, yield* does not work properly when transpiled for IE11
      // @see https://github.com/babel/babel/issues/14495
      // yield* methods
      for (const method of methods) yield method
    }
  }

  find(expr: MethodCall, ctx: TestContext): MethodDefinition | undefined {
    for (const key in expr) {
      if (key === '$schema') continue

      const methods = this.index.get(key)
      if (methods) for (const method of methods) if (method.test.call(ctx, expr)) return method
    }

    return undefined
  }
}

function buildMethodContainerIndex(methods: Iterable<MethodDefinition>) {
  const index = new Map<string, Set<MethodDefinition>>()
  for (const method of methods) {
    const methods = index.get(method.name)
    if (methods) methods.add(method)
    else index.set(method.name, new Set([method]))
  }
  return index
}
