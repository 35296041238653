import { createMethodLegacySimple } from '../legacy'

/**
 * Check if the value is false.
 *
 * @usage: ```{ ':false': value }```
 * where:
 * - value: any: the value to check if it is false
 *
 * @example
 * ```{ ':false': false }``` => true
 * ```{ ':false': true }``` => false
 * ```{ ':false': 0 }``` => false
 *
 * @real world example
 *
 * Check if the value is false, if the value is false, then return "Missing value", otherwise return "Found it".
 * With scope ```{a:[1,2,3]}```
 *
 * ```
 * {
 * ":define": {
 *    "value": {
 *      ":find": "{a}",
 *      ":where": { ":cond": { "@item": { "$eq": 3 } } }
 *    },
 *    "hasValue": {":defined": "{value}"}
 *  },
 *  ":in": {
 *    ":if": {":false": "{hasValue}"},
 *    ":then": "Missing value",
 *    ":else": "Found it"
 *  }
 * }
 * ```
 */
export default createMethodLegacySimple(':false', function (args): boolean {
  return this.evaluate(args) === false
})
