const elementKeyReducer = (obj, key) => {
  if (!obj) return undefined
  if (typeof obj !== 'object') return undefined
  if (key === '__proto__') return undefined
  if (key === 'constructor' && typeof obj[key] === 'function') return undefined

  const value = obj[key]
  if (value == null) return value

  if (value === Object.prototype) return undefined
  if (typeof value === 'function') return undefined

  // Allow objects to return another value than themselves
  if (typeof value.valueOf === 'function') return value.valueOf()

  return value
}

/**
 * @todo Allow deep path? (using path.split('.').reduce(elementKeyReducer, this))
 * @param {object} object
 * @param {string[]} [path]
 */
export const getPath = (object, path = undefined) => {
  if (!path?.length) return object

  return path.reduce(elementKeyReducer, object)
}

export const getFlowSteps = (flow) => flow.attributes.steps

export const identity = (i) => i
