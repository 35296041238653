import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { StringTransformer } from './'
import { findLast, findQuotes } from './utils'
import { isDate, isNumber, toDateOrValue } from '../util'

/**
 * Handle the "-" operator.
 *
 * If numbers: substract them
 * If date and number: add the number of days to the date
 * If both are dates: return the diff in days between the dates
 *
 * @usage: ```expr - expr2```.
 */
class MinusStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const quotes = findQuotes(expr)
    const operator = findLast(expr, '-', quotes)

    return operator !== -1
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    const quotes = findQuotes(expr)
    const operator = findLast(expr, '-', quotes)

    const left = expr.slice(0, operator).trim()
    const right = expr.slice(operator + 1).trim()

    const evaledLeft = toDateOrValue(this.evalStringExpression(left))
    const evaledRight = toDateOrValue(this.evalStringExpression(right))

    // add 1 day to a date
    if (isDate(evaledLeft) && isNumber(evaledRight)) {
      return this.evaluateMethodCall({ ':substract': [evaledLeft, `${evaledRight}d`] })
    }

    if (isDate(evaledLeft) && isDate(evaledRight)) {
      return this.evaluateMethodCall({ ':diff': [evaledLeft, evaledRight] })
    }

    return this.evaluateMethodCall({ ':substract': [evaledLeft, evaledRight] })
  }
}

export default new MinusStringTransformer()
