import { toDate } from './date'

export type Comparator<T> = (a: T, b: T) => number

export function combineComparators<T>(...fns: Array<Comparator<T>>): Comparator<T> {
  if (fns.length === 0) return () => 0
  if (fns.length === 1) return fns[0]

  return (a: T, b: T) => {
    for (let i = 0; i < fns.length; i++) {
      const result = fns[i].call(null, a, b)
      if (result > 0 || result < 0) return result
    }
    return 0
  }
}

export function invertComparator<T>(comparator: Comparator<T>): Comparator<T> {
  return (a: T, b: T) => {
    const result = comparator(a, b)
    return result > 0 ? -1 : result < 0 ? 1 : 0
  }
}

export function compareBy<V>(tranform: (a: V) => number): Comparator<V>
export function compareBy<V, T>(tranform: (a: V) => T, c: Comparator<T>): Comparator<V>
export function compareBy<V, T = number>(
  tranform: (a: V) => T,
  compare: Comparator<T> = nativeCompare
): Comparator<V> {
  return (a, b) => compare(tranform(a), tranform(b))
}

export const nativeCompare: Comparator<unknown> = (a, b) => {
  // Comparator operator is runtime-safe
  if ((a as any) < (b as any)) return -1
  if ((a as any) > (b as any)) return 1

  return 0
}

export const dateCompare: Comparator<Date | null | undefined> = (a, b) => {
  const aTime = a?.getTime() ?? NaN
  const bTime = b?.getTime() ?? NaN

  if (Number.isNaN(a)) return Number.isNaN(b) ? 0 : -1
  if (Number.isNaN(b)) return 1

  if (aTime < bTime) return -1
  if (aTime > bTime) return 1

  return 0
}

export const localeCompare: Comparator<unknown> = (a, b) => {
  if (typeof a !== 'string') return -1
  if (typeof b !== 'string') return 1

  return a.localeCompare(b)
}

/**
 * Compare anything that can be converted into a Date using toDate()
 *
 * @see {@link toDate}
 */
export const toDateCompare = compareBy(toDate, dateCompare)
