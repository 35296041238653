import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isNumber, isPureExpression, isString } from '../util'

type Type = { ':substr': JsonValue; ':start'?: JsonValue; ':end'?: JsonValue }

/**
 * Extracts a substring from a string.
 * @usage: ```{":substr": string, ":start"?: number, ":end"?: number}```
 *
 * where:
 * - `string` is a string to extract the substring from
 * - `start` is the index to start the substring from (default: 0)
 * - `end` is the index to end the substring at (default: string.length)
 *
 * @example
 * - ```{":substr": "Hello World", ":start": 6, ":end": 11}``` returns `"World"`
 * - ```{"substr": "Hello World", ":start": 6}``` returns `"World"`
 * - ```{"substr": "Hello World", ":end": 5}``` returns `"Hello"`
 */
export default createMethod<Type>({
  name: ':substr',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':substr' || key === ':start' || key === ':end') continue
      return false
    }

    if (!isString(expr[':substr']) && !isPureExpression(expr[':substr'])) return false
    if (!isNumber(expr[':start'] ?? 0) && !isPureExpression(expr[':start'])) return false
    if (!isNumber(expr[':end'] ?? 0) && !isPureExpression(expr[':end'])) return false

    return true
  },

  evaluate(expr) {
    const string = this.evaluate(expr[':substr'])
    if (!isString(string)) return undefined

    const start = typeof expr[':start'] !== 'undefined' ? this.evaluate(expr[':start']) : 0
    if (!isNumber(start)) return undefined

    const end = typeof expr[':end'] !== 'undefined' ? this.evaluate(expr[':end']) : string.length
    if (!isNumber(end)) return undefined

    return string.slice(start, end)
  },

  compile(expr) {
    const substrCompiled = this.createContext(':substr').compile(expr[':substr'])

    const startCompiled =
      expr[':start'] === undefined ? null : this.createContext(':start').compile(expr[':start'])

    const endCompiled =
      expr[':end'] === undefined ? null : this.createContext(':end').compile(expr[':end'])

    return (scope: Scope) => {
      const string = substrCompiled(scope)
      if (!isString(string)) return undefined

      const start = startCompiled ? startCompiled(scope) : 0
      if (!isNumber(start)) return undefined

      const end = endCompiled ? endCompiled(scope) : string.length
      if (!isNumber(end)) return undefined

      return string.slice(start, end)
    }
  },
})
