import { JsonValue, MethodSignature } from '../types'
import { createMethod, isArray, isDate } from '../util'

type Type = {
  ':diff': JsonValue
  ':comparator': 'day' | 'days' | 'hour' | 'hours' | 'minute' | 'minutes' | 'second' | 'seconds'
}

/**
 * Returns the sum of all numbers in an array.
 * @usage: ```{":diff": array, ":comparator": 'day' | 'hour' | 'minute' | 'second'}```
 * where:
 * - `array` is an array with 2 dates
 * - `comparator` is the unit to compare the dates. Default is 'day'. Can be 'day', 'hour', 'minute' or 'second'
 *
 * @example
 * - ```{":diff": ['{$yesterday}', '{$today}']}``` returns `1`
 * - ```{":diff": ['{$yesterday}', '{$today}'], ":comparator": 'hour'}``` returns `24`
 */
export default createMethod<Type>({
  name: ':diff',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':diff') continue
      if (key === ':comparator') continue
      return false
    }

    return true
  },

  evaluate(expr) {
    const array = this.evaluate(expr[':diff'])

    if (!isArray(array) || array.length !== 2) return undefined

    const [date1, date2] = array

    if (!isDate(date1) || !isDate(date2)) return undefined

    const diff = date1.getTime() - date2.getTime()

    switch (expr[':comparator']) {
      case 'hour':
      case 'hours':
        return Math.floor(diff / (1000 * 60 * 60))
      case 'minute':
      case 'minutes':
        return Math.floor(diff / (1000 * 60))
      case 'second':
      case 'seconds':
        return Math.floor(diff / 1000)
      default: // default days
        return Math.floor(diff / (1000 * 60 * 60 * 24))
    }
  },
})
