export const date = 'Невалидна дата.'
export const email = 'Невалиден имейл адрес.'
export const iban = 'Невалиден IBAN номер.'
export const invalid = 'Невалидна стойност.'
export const invalidFileType = 'Невалиден тип файл.'
export const maxCount = 'Това поле трябва да съдържа най-много {0} елемента.'
export const maxDate = 'Въведете дата преди {0}.'
export const maxDecimals =
  'Тази стойност трябва да съдържа най-много {0} десетични знака. Внимавайте да не използвате разделител за хилядни.'
export const maxFileSize = 'Изберете файл, който е по-малък от {0}.'
export const maxFilesSize = 'Всички файлове трябва да са по-малки от {0}.'
export const maxLength = 'Тази стойност трябва да бъде по-малка от {0} знака.'
export const maxValue = 'Тази стойност трябва да бъде по-малка или равна на {0}.'
export const minCount = 'Това поле трябва да съдържа най-малко {0} елемента.'
export const minDate = 'Въведете дата след {0}.'
export const minFileSize = 'Изберете файл, който е по-голям от {0}.'
export const minFilesSize = 'Всички файлове трябва да са по-големи от {0}.'
export const minLength = 'Тази стойност трябва да бъде повече от {0} знака.'
export const minValue =
  'Тази стойност трябва да бъде по-голяма или равна на {0}. Внимавайте да не използвате разделител за хилядни.'
export const pattern = 'Тази стойност не съответства на правилния формат.'
export const phone = 'Невалиден телефонен номер.'
export const phoneCountry = 'Невалиден {0} телефонен номер.'
export const phoneFixedLine = 'Невалиден стационарен телефонен номер.'
export const phoneIntl = 'Уверете се, че започвате с вашия +xx код на страната.'
export const phoneMobile = 'Невалиден мобилен номер.'
export const required = 'Необходима е стойност.'
export const requiredButton = 'Моля, натиснете този бутон.'
export const requiredCard = 'Моля, кликнете върху {0}.'
export const requiredChoice = 'Моля, изберете една.'
export const requiredChoices = 'Изберете една или повече.'
export const requiredDate = 'Необходима е валидна дата.'
export const requiredDatetime = 'Необходима е валидна дата и час.'
export const requiredDownload =
  'Всички файлове трябва да бъдат изтеглени, за да валидирате тази стъпка.'
export const requiredFile = 'Необходим е файл.'
export const requiredFileMultiple = 'Моля, предоставете поне един файл.'
export const requiredSignature = 'Моля, подпишете се.'
export const requiredRating = 'Изберете стойност между 1 и 5.'
