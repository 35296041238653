export type Undefined = null | undefined

export type Nested<T> = T | Array<Nested<T>> | { [_ in string]?: Nested<T> }
export type Dict<T> = { [_ in string]?: T }

// eslint-disable-next-line @typescript-eslint/ban-types
export type NonJson = Function | symbol | bigint | undefined

export type JsonScalar = null | string | number | boolean
export type JsonValue = Nested<JsonScalar>
export type JsonDict = Dict<JsonValue>
export type JsonList = JsonValue[]

export type IfJsonKey<T, K extends keyof T> = K extends string
  ? T[K] extends NonJson
    ? never
    : K
  : never

export type AsJson<T, Fallback = never> = T extends { toJSON: () => infer U }
  ? AsJson<U, Fallback>
  : T extends NonJson
  ? Fallback
  : T extends JsonValue // JsonScalar
  ? T
  : T extends [infer U, ...infer R]
  ? [AsJson<U, null>, ...AsJson<R, null>]
  : T extends []
  ? []
  : T extends Array<infer U>
  ? Array<AsJson<U, null>>
  : T extends object
  ? { [K in keyof T as IfJsonKey<T, K>]: AsJson<T[K], undefined> }
  : Fallback

export type JsonArray<T = JsonValue> = Array<AsJson<T, null>>
export type JsonObject<T = JsonDict> = AsJson<T>

export type TypedArray =
  | Int8Array
  | Uint8Array
  | Uint8ClampedArray
  | Int16Array
  | Uint16Array
  | Int32Array
  | Uint32Array
  | Float32Array
  | Float64Array
  | BigInt64Array
  | BigUint64Array

export type SyncOrAsyncIterable<T = any> = AsyncIterable<T> | Iterable<T>

export type Type<T> = new (...args: any[]) => T

export const noop: (...args: any[]) => void = () => {}
export const identity = <T>(x: T): T => x
export const isDefined = <T>(v: T): v is NonNullable<T> => v != null
export const isUndefined = (v: unknown = null): v is Undefined => v === null
export const isFunction = (v: unknown): v is Function => typeof v === 'function' // eslint-disable-line @typescript-eslint/ban-types
export const isString = (v: unknown): v is string => typeof v === 'string'
export const isDataUri = (v: unknown): v is `data:${string}` =>
  typeof v === 'string' && v.startsWith('data:')
export const isNumber = (v: unknown): v is number => typeof v === 'number'
export const isBoolean = (v: unknown): v is boolean => typeof v === 'boolean'
export const isScalar = (v: unknown): v is undefined | JsonScalar =>
  v === undefined || isJsonScalar(v)
export const isJsonScalar = (input: unknown): input is JsonScalar => {
  switch (typeof input) {
    case 'string':
    case 'number':
    case 'boolean':
      return true
    case 'object':
      return input == null
    default:
      return false
  }
}

export const isDataView = (v: unknown): v is DataView =>
  typeof DataView !== 'undefined' && v instanceof DataView
export const isInt8Array = (v: unknown): v is Int8Array =>
  typeof Int8Array !== 'undefined' && v instanceof Int8Array
export const isUint8Array = (v: unknown): v is Uint8Array =>
  typeof Uint8Array !== 'undefined' && v instanceof Uint8Array
export const isUint8ClampedArray = (v: unknown): v is Uint8ClampedArray =>
  typeof Uint8ClampedArray !== 'undefined' && v instanceof Uint8ClampedArray
export const isInt16Array = (v: unknown): v is Int16Array =>
  typeof Int16Array !== 'undefined' && v instanceof Int16Array
export const isUint16Array = (v: unknown): v is Uint16Array =>
  typeof Uint16Array !== 'undefined' && v instanceof Uint16Array
export const isInt32Array = (v: unknown): v is Int32Array =>
  typeof Int32Array !== 'undefined' && v instanceof Int32Array
export const isUint32Array = (v: unknown): v is Uint32Array =>
  typeof Uint32Array !== 'undefined' && v instanceof Uint32Array
export const isFloat32Array = (v: unknown): v is Float32Array =>
  typeof Float32Array !== 'undefined' && v instanceof Float32Array
export const isFloat64Array = (v: unknown): v is Float64Array =>
  typeof Float64Array !== 'undefined' && v instanceof Float64Array
export const isBigInt64Array = (v: unknown): v is BigInt64Array =>
  typeof BigInt64Array !== 'undefined' && v instanceof BigInt64Array
export const isBigUint64Array = (v: unknown): v is BigUint64Array =>
  typeof BigUint64Array !== 'undefined' && v instanceof BigUint64Array

export const isTypedArray = (v: unknown): v is TypedArray =>
  isInt8Array(v) ||
  isUint8Array(v) ||
  isUint8ClampedArray(v) ||
  isInt16Array(v) ||
  isUint16Array(v) ||
  isInt32Array(v) ||
  isUint32Array(v) ||
  isFloat32Array(v) ||
  isFloat64Array(v) ||
  isBigInt64Array(v) ||
  isBigUint64Array(v)

export const isView =
  typeof ArrayBuffer !== 'undefined'
    ? (v: unknown): v is DataView | TypedArray => ArrayBuffer.isView(v)
    : (v: unknown): v is DataView | TypedArray => isTypedArray(v) || isDataView(v)

export function ifArray<T>(
  v: unknown,
  itemChecker: (v: any) => v is T
): (typeof v & T[]) | undefined
export function ifArray<A>(
  v: A,
  itemChecker?: (v: any) => boolean
): Array<NonNullable<A> extends ReadonlyArray<infer T> ? T : unknown> | undefined

export function ifArray(v: unknown, itemChecker?: (v: any) => boolean) {
  return Array.isArray(v) && (!itemChecker || v.every(itemChecker)) ? v : undefined
}
export const ifObject = (v: unknown) => (typeof v === 'object' && v !== null ? v : undefined)
export const ifBoolean = (v: unknown) => (typeof v === 'boolean' ? v : undefined)
export const ifNumber = (v: unknown) => (typeof v === 'number' ? v : undefined)
export const ifString = (v: unknown) => (typeof v === 'string' ? v : undefined)
export const ifSymbol = (v: unknown) => (typeof v === 'symbol' ? v : undefined)
export const ifFunction = (v: unknown) => (typeof v === 'function' ? v : undefined)
export const ifScalar = (v: unknown) => (isJsonScalar(v) ? v : undefined)
export const ifMatch = (v: unknown, regExp: RegExp) =>
  typeof v === 'string' && regExp.test(v) ? v : undefined

export function ifInstance<T extends abstract new (...args: any) => any>(
  v: unknown,
  ...classes: T[]
): InstanceType<T> | undefined {
  if (v != null && typeof v === 'object') {
    for (let i = 1; i < arguments.length; i++) {
      // eslint-disable-next-line prefer-rest-params
      if (v instanceof arguments[i]) return v as InstanceType<T>
    }
  }
  return undefined
}

export const returnTrue: (...args: any[]) => true = () => true
export const returnFalse: (...args: any[]) => false = () => false
export const returnNull: (...args: any[]) => null = () => null

export function iife<T, F extends (a: T) => any>(a: T, f: F): ReturnType<F> {
  return f(a)
}

export function using<F>(value: F, init: (value: F) => Promise<void>): Promise<F>
export function using<F>(value: F, init: (value: F) => void): F
export function using<F>(value: F, init: (value: F) => void | Promise<void>): F | Promise<F> {
  const r = init(value)
  if (r === undefined) return value
  return r.then(() => value)
}

export function jsonClone<T>(
  value: T,
  options?: {
    reviver?: Parameters<typeof JSON.parse>[1]
    replacer?: Parameters<typeof JSON.stringify>[1]
  }
): AsJson<T, null> {
  switch (typeof value) {
    case 'string':
    case 'number':
    case 'boolean':
      return value as AsJson<T, null>
    case 'function': // In case value.toJSON is defined...
    case 'object':
      if (value !== null) {
        return JSON.parse(JSON.stringify(value, options?.replacer), options?.reviver)
      }
    // falls through
    default:
      return null as AsJson<T, null>
  }
}
