import { asIterableExpression } from '../compilation'
import { JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isArray, isPureExpression, toString } from '../util'

const isEntry = (v: unknown): v is [Value, Value] =>
  isArray(v) && v.length === 2 && v[0] !== undefined && v[1] !== undefined

type Type = {
  ':object-create': JsonValue
}

/**
 * Creates an object from an array of entries.
 * @usage: ```{":object-create": array}```
 * where:
 * - `array` is an array of entries where each entry is an array of two elements: [key, value]
 *
 * @example
 * - ```{":object-create": [["a", 1], ["b", 2]]}``` returns `{a: 1, b: 2}`
 */
export default createMethod<Type>({
  name: ':object-create',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':object-create') continue
      return false
    }

    if (isArray(expr[':object-create'])) {
      // TODO: Only validate this in strict mode ?
      // for (const item of expr[':object-create']) {
      //   if (!isEntry(item) && !isPureExpression(item)) return false
      // }
      return true
    }

    return isPureExpression(expr[':object-create'])
  },

  evaluate(expr) {
    const array = this.evaluate(expr[':object-create'])
    if (!isArray(array)) return undefined

    return createObject(array)
  },

  compile(expr) {
    const getArray = this.createContext(':object-create').compile(expr[':object-create'])
    const entryGenerator = asIterableExpression(getArray)

    return (scope: Scope) => {
      const iterable = entryGenerator(scope)
      if (!iterable) return undefined

      return createObject(iterable)
    }
  },
})

function createObject(iterable: Iterable<Value>) {
  const object: Record<string, Value> = {}

  for (const item of iterable) {
    if (!isEntry(item)) continue

    const key = toString(item[0])
    if (key === undefined) continue

    object[key] = item[1]
  }

  return object
}
