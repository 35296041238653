import { ifString } from '@penbox-io/stdlib'

import { type Dict } from '../../common'
import { type Definition } from '../../core'

import {
  type Value,
  type Options as ChoiceOptions,
  options as choiceOptions,
  requirable as choiceRequirable,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} from '../partials/choice.js'

type Options<V extends Value = Value> = ChoiceOptions<V> & {
  hint?: string
  placeholder?: string
  readonly?: boolean
}

export default {
  parse,
  options,
  requirable,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  // Optimization: spread operator is slow
  const options = choiceOptions(input, locale)

  return {
    label: options.label,
    choices: options.choices,
    allowCustom: options.allowCustom,
    submitOnChange: options.submitOnChange,
    hint: ifString(input.hint),
    placeholder: ifString(input.placeholder),
    readonly: input.readonly === true,
  }
}

function requirable(options: Options): boolean {
  return options.allowCustom || choiceRequirable(options)
}
