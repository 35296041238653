import { toAscii, toLatin1 } from './string'
import { Dict } from './util'

type HeaderValue = number | string | string[]
type HeaderName = Lowercase<string>

export function findHeader<T extends HeaderName, H extends Dict<HeaderValue>>(
  headerName: T,
  headers: H
): HeaderValue | undefined {
  if (headers) for (const key in headers) if (key.toLowerCase() === headerName) return headers[key]
  return undefined
}

export function firstHeader<T extends HeaderName, H extends Dict<HeaderValue>>(
  headerName: T,
  headers: H
): Exclude<HeaderValue, any[]> | undefined {
  const header = findHeader(headerName, headers)
  const value = Array.isArray(header) ? header[0] : header
  if (value === '') return undefined
  return value
}

export function contentDispositionFilename(header: unknown): string | undefined {
  if (Array.isArray(header)) {
    for (const h of header) {
      const value = contentDispositionFilename(h)
      if (value) return value
    }
    return undefined
  }

  if (typeof header === 'string') {
    const utf8Match = header.match(/filename\*=utf-8''([^\s;]*)/i)
    if (utf8Match) return decodeURIComponent(utf8Match[1])

    const latinMatch = header.match(/filename\*=iso-8859-1'[a-z]*'([^\s;]*)/i)
    if (latinMatch) {
      return toLatin1(
        latinMatch[1].replaceAll(/%([0-9A-Fa-f]{2})/g, (str, hex) =>
          String.fromCharCode(parseInt(hex, 16))
        )
      )
    }

    const legacyMatch = header.match(/filename="([^"]*)"/i) || header.match(/filename=([^;"]*)/i)
    if (legacyMatch) return decodeURIComponent(legacyMatch[1])
  }

  if (typeof header === 'object') {
    return contentDispositionFilename(findHeader('content-disposition', header as any))
  }

  return undefined
}

export function contentDispositionEncode(
  filename: string,
  disposition: 'attachment' | 'inline' | `form-data; name="${string}"` = 'attachment'
) {
  const stripped = toAscii(filename).replaceAll(/"+/g, '')
  const uriEncoded = encodeURIComponent(filename)
    .replaceAll("'", '%27')
    .replaceAll('(', '%28')
    .replaceAll(')', '%29')
    .replaceAll('*', '%2A')

  return `${disposition}; filename="${stripped}"; filename*=UTF-8''${uriEncoded}`
}
