<template lang="pug">
v-container(@click="clickCount++" fill-height).d-flex.flex-column.align-center.justify-center
  template(v-if="status === 400")
    h1.mb-6(v-text="$t('invalid_link')").text-h2.primary--text
  template(v-else-if="status === 404")
    h1.mb-6(v-text="$t('not_found')").text-h2.primary--text
  template(v-else)
    h1.mb-6(v-text="title").text-h2.primary--text
    p(v-if="description && description !== title" v-text="description").text-h4

  v-expand-transition
    p(v-if="showDetails").text-h6
      code.mr-2(v-text="code").warning
      code.mr-2(v-if="status >= 0" v-text="status").accent
      span(v-text="message")
</template>

<i18n lang="yaml">
en:
  error: 'Error'
  http: 'HTTP error {status}'
  details: 'Details'
  invalid_link: 'The link you opened is invalid'
  not_found: 'In order to ensure the security of your private data, this link has been deactivated.'

fr:
  error: 'Erreur'
  http: 'Erreur HTTP {status}'
  details: 'Détails'
  invalid_link: "Le lien que vous avez ouvert n'est pas valide"
  not_found: "Afin d'assurer la sécurité de vos données, ce lien a été désactivé."

nl:
  error: 'Fout'
  http: 'HTTP-fout {status}'
  details: 'Fout details'
  invalid_link: 'De link die je hebt geopend is ongeldig'
  not_found: 'Om de veiligheid van uw gegevens te waarborgen, is deze link gedeactiveerd.'
de:
  error: 'Fehler'
  http: 'HTTP-Fehler {status}'
  details: 'Einzelheiten'
  invalid_link: 'Der von Ihnen geöffnete Link ist ungültig'
  not_found: 'Um die Sicherheit Ihrer privaten Daten zu gewährleisten, wurde dieser Link deaktiviert.'
</i18n>

<style lang="sass" scoped>
.container *
  user-select: none
</style>

<script>
import { normalizeError } from '@penbox-io/error-parser'
import { stringifyError } from '@penbox-io/vuetify-lib'

export default {
  props: { error: { type: Object, required: true } },

  data() {
    return {
      clickCount: 0,
    }
  },

  computed: {
    normalized() {
      return normalizeError(this.error)
    },
    title() {
      const { code, status } = this
      return code === 'http-error' || status >= 0 ? this.$t('http', { status }) : this.$t('error')
    },
    description() {
      return stringifyError.call(this, this.normalized)
    },
    status() {
      return this.normalized.status
    },
    code() {
      return this.normalized.code
    },
    message() {
      return this.normalized.message
    },
    showDetails() {
      return ((this.clickCount / 5) | 0) % 2 === 1
    },
  },

  watch: {
    error: {
      immediate: true,
      handler(err) {
        if (err.isAxiosError) return // Should be handled by interceptor

        this.$sentry?.withScope((scope) => {
          const statusCode = err.statusCode ?? err.status
          if (statusCode >= 400 && statusCode < 500) scope.setLevel('warning')

          if (err instanceof Error) this.$sentry.captureException(err)
          else this.$sentry.captureEvent({ message: err.message, extra: err, level: 'fatal' })
        })
      },
    },
  },

  mounted() {
    this.$store.dispatch('hostname/load', window.location)

    const { locale } = this.$route.query
    if (locale) {
      try {
        this.$root.$i18n.setLocale(locale)
      } catch (err) {
        // This should not generate an error
        console.error('Unable to set locale', err)
      }
    }
  },
}
</script>
