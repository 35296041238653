import { asIterableExpression } from '../compilation'
import { JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isArray, isPureExpression } from '../util'

type Type = { ':assign': JsonValue[] }

/**
 * Copy the values of all of the enumerable own properties from one or more source objects to a target object. Returns the target object.
 * @usage: `{ ':assign': [object1, object2, ...] }`
 * where:
 * - object1, object2, ...: object: the objects to assign
 *
 * @example
 * ```{ ':assign': [{ 'a': 1 }, { 'b': 2 }] }``` => { 'a': 1, 'b': 2 }
 * ```{ ':assign': [{ 'a': 1 }, { 'a': 2 }] }``` => { 'a': 2 }
 *
 * @real world example
 * You need to initialize an object with default values and override some of them.
 * args: { 'option2': 100 } // here args has option3 value but not option1
 * ```{options: { ':assign': [{ 'option1': 1, option2: 2 }, "{args}"] } }``` => { options: { 'option1': 1, 'option2': 100 } }
 *
 * options has the default value for option1 and the value from args for option2.
 */
export default createMethod<Type>({
  name: ':assign',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':assign') continue
      return false
    }

    if (!isArray(expr[':assign']) && !isPureExpression(expr[':assign'])) return false

    return true
  },

  evaluate(expr) {
    const values = this.evaluate(expr[':assign'])
    if (!isArray(values)) return undefined
    return Object.assign({}, ...values)
  },

  compile(expr) {
    const assignCompiled = this.createContext(':assign').compile(expr[':assign'])
    const iterableGetter = asIterableExpression(assignCompiled)

    return (scope: Scope) => {
      const iterable = iterableGetter(scope)
      if (!iterable) return undefined

      const result: Record<string, Value> = {}
      for (const values of iterable) Object.assign(result, values)
      return result
    }
  },
})
