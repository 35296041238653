const maxIndex = (a: number, b: number) => (a === -1 ? b : b === -1 ? a : Math.max(a, b))

export const parseFilename = (name: string): [string, string] | [string, string, string] => {
  const hashIndex = name.indexOf('#')
  const queryIndex = name.indexOf('?')
  const endIndex = maxIndex(hashIndex, queryIndex)

  const nameWithoutEnd = endIndex === -1 ? name : name.substring(0, endIndex)

  const lastSlashIndex = nameWithoutEnd.lastIndexOf('/')
  const lastColumnIndex = nameWithoutEnd.lastIndexOf(':')

  const basenameIndex = maxIndex(lastSlashIndex, lastColumnIndex) + 1

  const path = nameWithoutEnd.substring(0, basenameIndex)
  const basenameWithExt = nameWithoutEnd.substring(basenameIndex)

  const dotIndex = basenameWithExt.lastIndexOf('.')
  if (dotIndex !== -1) {
    const ext = basenameWithExt.substring(dotIndex)
    if (/^\.[a-z][a-z_-]*[a-z]$/i.test(ext)) {
      // replace the '.' with '_' in the filename to avoid issues when downloading file
      return [path, basenameWithExt.substring(0, dotIndex).replace(/\./gi, '_'), ext]
    }
  }

  return [path, basenameWithExt]
}

export function renameBasename(name: string, newName?: string) {
  if (!newName) return name

  const [, newBasename] = parseFilename(newName)
  if (!newBasename) return name

  const [path, , ext = ''] = parseFilename(name)

  return `${path}${newBasename}${ext}`
}

export function renameExt(name: string, newExt?: string) {
  if (!newExt) return name

  const [path, basename] = parseFilename(name)

  return `${path}${basename}${newExt}`
}
