import { CompiledExpression } from '../compilation'
import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isArray } from '../util'
import { eq } from './cmp'

type Type = {
  ':case': JsonValue
  ':when': Array<[JsonValue, JsonValue]>
  ':else'?: JsonValue
}

/**
 * A switch statement that evaluates the first matching case.
 * @usage: ```{ ':case': value, ':when': [[condition1, then1], [condition2, then2], ...], ':else': elseValue }```
 * where:
 * - value: any: the value to compare
 * - condition1, condition2, ...: any: the conditions to compare with value
 * - then1, then2, ...: any: the value to return if the condition matches
 * - elseValue: any?: the value to return if no condition matches (default)
 *
 * @example
 * ```{ ':case': 1, ':when': [[1, 'one'], [2, 'two']], ':else': 'other' }``` => 'one'
 *
 * @real world example
 * You need to print a different message based on the status of a task.
 * scope: ```{ task: { status: 'done' } }```
 * ```{ ":case": "{task.status}", ":when": [["done", "Task is done"], ["failed", "Task failed"], ["pending", "Task is pending"]], ":else": "Task is in progress" }```
 */
export default createMethod<Type>({
  name: ':case',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':case' || key === ':when' || key === ':else') continue
      return false
    }

    if (expr[':case'] === undefined) return false

    if (!isArray(expr[':when'])) return false
    for (const c of expr[':when']) {
      if (isArray(c)) {
        if (c.length !== 2) return false
        if (c[0] === undefined) return false
        if (c[1] === undefined) return false
      } else {
        return false
      }
    }

    return true
  },

  evaluate(expr) {
    const subject = this.evaluate(expr[':case'])
    if (subject === undefined) return undefined

    for (const [is, then] of expr[':when']) {
      if (eq(subject, this.evaluate(is))) return this.evaluate(then)
    }

    return expr[':else'] == null ? expr[':else'] : this.evaluate(expr[':else'])
  },

  compile(expr) {
    const switchCompiled = this.createContext(':case').compile(expr[':case'])

    const whenRaw = expr[':when']
    const whenCompiled: Array<[CompiledExpression, CompiledExpression]> = Array(whenRaw.length)
    for (let i = 0; i < whenRaw.length; i++) {
      const is = this.createContext(i, 0).compile(whenRaw[i][0])
      const then = this.createContext(i, 1).compile(whenRaw[i][1])

      whenCompiled[i] = [is, then]
    }

    const elseCompiled =
      expr[':else'] == null ? null : this.createContext(':else').compile(expr[':else'])

    return (scope: Scope) => {
      const subject = switchCompiled(scope)
      if (subject === undefined) return undefined

      for (const [is, then] of whenCompiled) {
        if (eq(subject, is(scope))) return then(scope)
      }

      return elseCompiled?.(scope)
    }
  },
})
