import { formatDate, ifString, isValidDate } from '@penbox-io/stdlib'

import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { Options as DateOptions, parseDate, parseDateOptions } from '../utils/date.js'
import { type FormError, buildError } from '../utils/error.js'

type Value = string
type Options = DateOptions & {
  label?: FormHelpable
  hint?: string
  placeholder?: string
  allowPicker: boolean
  weekDays?: boolean
}
type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
  localize,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  const dateOptions = parseDateOptions(input, locale)

  return {
    label: ifFormHelpable(input.label),
    hint: ifString(input.hint),
    placeholder: ifString(input.placeholder),

    allowPicker: input.picker !== false, // true by default

    datePattern: dateOptions.datePattern,
    min: dateOptions.min,
    max: dateOptions.max,
    weekDays: dateOptions.weekDays,
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  if (value == null) return null
  switch (typeof value) {
    case 'string':
      return value.trim() || null
    case 'object':
      if (value instanceof Date) return normalizeDate(value) || null
      return null
    default:
      return null
  }
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  const date = parseDate(value)
  if (!date) {
    if (required) return buildError('requiredDate', locale)
    return null
  }

  const { min } = options
  if (min && date < min) {
    return buildError('minDate', locale, [formatDate(min, options.datePattern)])
  }

  const { max } = options
  if (max && date > max) {
    return buildError('maxDate', locale, [formatDate(max, options.datePattern)])
  }

  return null
}

function normalizeDate(date: Date): undefined | string {
  if (!isValidDate(date)) return undefined

  const year = String(date.getFullYear())
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

function normalize(element: Element): undefined | Value {
  const date = parseDate(element.value)
  if (!date) return undefined

  return normalizeDate(date)
}

function stringify(element: Element): undefined | string {
  const date = normalize(element)
  if (!date) return undefined

  const formatted = formatDate(date, element.options.datePattern)
  if (formatted) return formatted

  return undefined
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}

function localize(element: Element, locale: string): string | undefined {
  const date = parseDate(element.value)
  if (!date) return undefined

  return new Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
    timeStyle: undefined,
  }).format(date)
}
