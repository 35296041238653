export const date = 'Nederīgs datums.'
export const email = 'Nederīga e-pasta adrese.'
export const iban = 'Nederīgs IBAN numurs.'
export const invalid = 'Nederīga vērtība.'
export const invalidFileType = 'Nederīgs faila tips.'
export const maxCount = 'Šim laukam jāietver ne vairāk kā {0} vienības.'
export const maxDate = 'Ievadiet datumu pirms {0}.'
export const maxDecimals =
  'Šai vērtībai jāietver ne vairāk kā {0} decimāldaļas. Pārliecinieties, ka neizmantojat tūkstošu atdalītāju.'
export const maxFileSize = 'Izvēlieties failu, kas ir mazāks par {0}.'
export const maxFilesSize = 'Visiem failiem jābūt mazākiem par {0}.'
export const maxLength = 'Šai vērtībai jābūt mazākai par {0} rakstzīmēm.'
export const maxValue = 'Šai vērtībai jābūt mazākai vai vienādai ar {0}.'
export const minCount = 'Šim laukam jāietver vismaz {0} vienības.'
export const minDate = 'Ievadiet datumu pēc {0}.'
export const minFileSize = 'Izvēlieties failu, kas ir lielāks par {0}.'
export const minFilesSize = 'Visiem failiem jābūt lielākiem par {0}.'
export const minLength = 'Šai vērtībai jābūt lielākai par {0} rakstzīmēm.'
export const minValue =
  'Šai vērtībai jābūt lielākai vai vienādai ar {0}. Pārliecinieties, ka neizmantojat tūkstošu atdalītāju.'
export const pattern = 'Šī vērtība neatbilst pareizajam formātam.'
export const phone = 'Nederīgs telefona numurs.'
export const phoneCountry = 'Nederīgs {0} telefona numurs.'
export const phoneFixedLine = 'Nederīgs fiksētais numurs.'
export const phoneIntl = 'Pārliecinieties, ka sākat ar savu +xx valsts kodu.'
export const phoneMobile = 'Nederīgs mobilā tālruņa numurs.'
export const required = 'Nepieciešama vērtība.'
export const requiredButton = 'Lūdzu, noklikšķiniet uz šīs pogas.'
export const requiredCard = 'Lūdzu, noklikšķiniet uz {0}.'
export const requiredChoice = 'Lūdzu, izvēlieties vienu.'
export const requiredChoices = 'Izvēlieties vienu vai vairākus.'
export const requiredDate = 'Nepieciešams derīgs datums.'
export const requiredDatetime = 'Nepieciešams derīgs datums un laiks.'
export const requiredDownload = 'Visiem failiem jābūt lejupielādētiem, lai apstiprinātu šo soli.'
export const requiredFile = 'Nepieciešams fails.'
export const requiredFileMultiple = 'Lūdzu, norādiet vismaz vienu failu.'
export const requiredSignature = 'Lūdzu, parakstieties.'
export const requiredRating = 'Izvēlieties vērtību no 1 līdz 5.'
