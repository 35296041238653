// https://github.com/blackswanny/performance-polyfill/blob/master/src/index.js
const performance = window.performance || {}

if (!performance.now) {
  const startOffset = Date.now ? Date.now() : +new Date()

  performance.now =
    performance.webkitNow ||
    performance.mozNow ||
    performance.msNow ||
    (() => (Date.now ? Date.now() : +new Date()) - startOffset)
}

if (!window.performance) {
  window.performance = performance
}
