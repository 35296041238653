export default {
  UPDATE(state, data) {
    state.title = data.attributes.name
    state.colors = data.attributes.colors
    state.logo = data.attributes.logo ?? data.attributes.icon ?? data.attributes.favicon
    state.favicon = data.attributes.favicon
  },
  SET_LOCALE(state, { locale }) {
    state.userLocale = locale
  },
}
