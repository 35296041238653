import { asIterableExpression, CompilationContext, CompiledExpression } from '../compilation'
import { EvaluationContext } from '../evaluation'
import { JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isArray } from '../util'

type Type = { ':in': [JsonValue, JsonValue] }

/**
 * Check if a value is in a list of values.
 *
 * @usage: ```{ ':in': [value, array] }```
 * where:
 * - value: any: the value to search for
 * - array: array | string: the list of values to search in
 *
 * @example
 * ```{ ':in': [1, [1, 2, 3]] }``` => true
 * ```{ ':in': [4, [1, 2, 3]] }``` => false
 */
export default createMethod<Type>({
  name: ':in',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':in') continue
      return false
    }

    if (!isArray(expr[':in'])) return false
    if (expr[':in'].length !== 2) return false

    return true
  },

  evaluate(expr): undefined | boolean {
    return computeIn.call(this, expr[':in'][0], expr[':in'][1])
  },

  compile(expr) {
    const needleGetter = this.createContext(':in', 0).compile(expr[':in'][0])
    const haystackGetter = this.createContext(':in', 1).compile(expr[':in'][1])

    return compileIn.call(this, needleGetter, haystackGetter)
  },
})

export function computeIn(
  this: EvaluationContext,
  needle: JsonValue,
  haystack: JsonValue
): undefined | boolean {
  const search = this.evaluate(needle)
  if (search === undefined && this.config.strict) return undefined

  // Optimization: Eval one element at a time:
  if (isArray(haystack)) {
    for (let i = 0; i < haystack.length; i++) {
      const candidate = this.evaluate(haystack[i])
      if (search === candidate) return true
    }
    return false
  }

  const candidates = this.evaluate(haystack)
  if (!isArray(candidates)) {
    // Make it work with scalar values as well
    return candidates === search
  }

  return candidates.includes(search)
}

export function compileIn(
  this: CompilationContext,
  needleGetter: CompiledExpression,
  haystackGetter:
    | CompiledExpression
    | (CompiledExpression<Value[]> & {
        iterator: (scope: Scope) => Generator<Value, undefined, void>
      })
) {
  // Optimization: prefer iterator
  const iterableGetter = asIterableExpression(haystackGetter)

  return (scope: Scope): undefined | boolean => {
    const search = needleGetter(scope)
    if (search === undefined && this.config.strict) return undefined

    const iterable = iterableGetter(scope)
    if (!iterable) return undefined
    // Make it work with scalar values as well
    if (iterable === search) return true

    for (const item of iterable) if (item === search) return true
    return false
  }
}
