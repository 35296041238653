export function parseNumber(value: unknown, min?: number, max?: number): number | undefined {
  if (value == null) return undefined
  switch (typeof value) {
    case 'object':
    case 'string':
      value = parseFinite(value)
      if (value === undefined) return undefined
    // falls through
    case 'number': {
      if (!Number.isFinite(value)) return undefined
      const number = value as number | 0
      if (min != null && number < min) return undefined
      if (max != null && number > max) return undefined
      return number
    }
    // falls through
    default:
      return undefined
  }
}

export const parseFinite = (v: unknown): number | undefined => {
  if (v == null) return undefined
  const val =
    typeof v === 'object' && 'valueOf' in v && typeof v.valueOf === 'function' ? v.valueOf() : v

  if (typeof val === 'number') return Number.isFinite(val) ? val : undefined
  if (typeof val !== 'string') return undefined
  if (val === '') return undefined

  // If already in the right format, return the parsed number
  if (val.match(/^-?(?:\d+\.?\d*|\d*\.?\d+)(?:e-?\d+)?$/)) return Number(val)

  const lastSepIdx = Math.max(val.lastIndexOf('.'), val.lastIndexOf(','))

  const int = (lastSepIdx === -1 ? val : val.slice(0, lastSepIdx)).replace(/[\s,._]+/g, '')
  if (!/^-?\d*$/.test(int)) return undefined

  const fra = (lastSepIdx === -1 ? '' : val.slice(lastSepIdx + 1)).replace(/[\s_]+/g, '')
  if (!/^\d*(?:e-?\d+)?$/.test(fra)) return undefined

  const parsed = Number(`${int}.${fra}`)

  if (!Number.isFinite(parsed)) return undefined // Overflow

  return parsed
}

export const extractDecimals = (v: any, radix = 10) => {
  if (typeof v !== 'number' && typeof v !== 'string') return undefined
  if (v === '') return undefined

  const num = parseFinite(v)
  if (num === undefined) return undefined

  const [, decimals] = num.toString(radix).split('.', 2)
  return decimals || null
}

type NumberFormatConfig = {
  digitGroupSeparator: string
  decimalMarker: string
}

export const NUMBER_FORMAT_CONFIGS: Map<string, NumberFormatConfig> = new Map([
  ['si', { digitGroupSeparator: ' ', decimalMarker: '.' }], // SI
  ['js', { digitGroupSeparator: '_', decimalMarker: '.' }], // Javascript

  ['fr', { digitGroupSeparator: ' ', decimalMarker: ',' }],
  ['nl', { digitGroupSeparator: ' ', decimalMarker: ',' }],
  ['en', { digitGroupSeparator: ',', decimalMarker: '.' }],
])

export const formatNumber = (
  val: any,
  options: {
    locale?: string
    minDecimals?: number
    maxDecimals?: number
  } & Partial<NumberFormatConfig> = {}
): string => {
  const number = parseFinite(val)
  if (number === undefined) return ''

  const [integer, fractional] = String(number).split('.', 2)
  const lang = options.locale ?? 'si'
  const config = NUMBER_FORMAT_CONFIGS.get(lang)

  const s = options.digitGroupSeparator ?? config?.digitGroupSeparator ?? ' '
  const d = options.decimalMarker ?? config?.decimalMarker ?? '.'
  const truncatedFractionals = options.maxDecimals
    ? fractional?.slice(0, options.maxDecimals)
    : fractional
  const paddedFractionals = options.minDecimals
    ? (truncatedFractionals ?? '').padEnd(options.minDecimals, '0')
    : truncatedFractionals

  return (
    integer.replace(/\B(?=(\d{3})+(?!\d))/g, s) +
    (paddedFractionals ? `${d}${paddedFractionals}` : '')
  )
}

/**
 *
 * @param value Cast the value to a number if possible
 * @returns parsed number or the original value
 */
export function toNumber<T>(value: T): number | T {
  if (value === null) return value
  if (typeof value !== 'string') return value
  // Try to parse the value as a number
  let parsedValue = Number(value)

  if (isNaN(parsedValue)) return value
  return parsedValue
}
