export default {
  load,
  setLocale,
}

/** @this {import('vuex').Store} */
async function load({ commit }, { hostname }) {
  if (!hostname) return

  const { data } = await this.$axios.$get(`/core/v1/companies`, {
    params: {
      'filter': {
        attributes: { hostname: { $eq: hostname } },
        // TODO (?) : Add this only in production
        // meta: { $extends: { 'pnbx:fill:domain': { active: true } } },
      },
      'page[limit]': 1,
    },
  })

  if (data.length >= 1) {
    commit('UPDATE', data[0])
  }
}

function setLocale({ commit }, { locale }) {
  commit('SET_LOCALE', { locale })
}
