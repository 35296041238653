import { MethodCall, MethodSignature } from '../types'
import { createMethod, isPureExpression, isString, toString } from '../util'

type Type = {
  ':lowercase': string | MethodCall
}

/**
 * Converts a string to lowercase.
 * @usage: ```{":lowercase": string}```
 * where:
 * - `string` is a string
 *
 * @example
 * - ```{":lowercase": "Hello World"}``` returns `"hello world"`
 */
export default createMethod<Type>({
  name: ':lowercase',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':lowercase') continue
      return false
    }

    const lowercase = expr[':lowercase']
    if (!isPureExpression(lowercase) && !isString(lowercase)) return false

    return true
  },

  evaluate(expr) {
    return toString(this.evaluate(expr[':lowercase']))?.toLowerCase()
  },
})
