import Vue from 'vue'

const KEY = 'RxitOC0XIBXSWqMl4GMHWtdASJdGNvkz'

const MUTATION_MAPPING = {
  request: {
    *LOAD_SUCCESS({ responseId }) {
      if (!process.client) return
      if (!this.request?.request) return
      if (responseId) return
      yield { event: 'Response Started' }
    },
    *ATTACHMENT_UPLOAD_SUCCESS({ duration }) {
      yield {
        event: 'File Uploaded',
        properties: { upload_duration: duration },
      }
    },
    *CONFIRMED_STEP({ stepId }) {
      yield { event: 'Step Validated', properties: { stepId } }
    },
    *PATCH_REQUEST({ data }) {
      const current = this.request.response

      if (data.attributes?.completed_at != null && current?.attributes.completed_at === null) {
        yield { event: 'Response Completed' }
      }

      const rating = data.meta?.['pnbx:feedback:rating']
      if (rating !== undefined) {
        yield { event: 'Response Rating Added', properties: { rating } }
      }

      const feedback = data.meta?.['pnbx:feedback:message']
      if (feedback !== undefined) {
        yield { event: 'Response Feedback Added', properties: { feedback } }
      }

      if (data?.attributes?.declined_at != null && current?.attributes?.declined_at == null) {
        yield { event: 'Response Declined' }
      }
    },
  },
}

export default async function trackingPlugin(ctx, inject) {
  const errorHandler = /** @type {(err: Error) => void} */ (
    Vue.config.errorHandler || ctx.error || console.error
  )

  const axios = ctx.$axios.create({
    baseURL: `https://api.segment.io/v1/`,
    headers: {
      'Authorization': `Basic ${Buffer.from(`${KEY}:`, 'utf-8').toString('base64')}`,
      'Content-Type': 'application/json',
    },
  })

  const segment = (type, data) => axios.$post(type, data)

  ctx.store.subscribe(async ({ type, payload }, state) => {
    try {
      if (!state.request) return // store not initialized yet

      const [module, mutation] = type.split('/', 2)
      const method = MUTATION_MAPPING[module]?.[mutation]

      if (!method) return

      const { request } = state
      const { flow } = request
      const ownerEmail = request?.request?.attributes?.$owner_email
      const commonProperties = {
        requestId: request.requestId,
        workspace: request?.company?.attributes?.slug,
        flow_slug: flow?.attributes?.slug,
        flow_version: flow?.attributes?.version,
        owner_email: ownerEmail,
        source: 'pen-fill',
      }

      for (const { event, properties = {} } of method.call(state, payload)) {
        segment('track', {
          event,
          properties: { ...properties, ...commonProperties },
          context: getContext(),
          // We identify using owner email even though this is a different user,
          // but it is to follow the completion journey of the request
          userId: ownerEmail || 'anonymous',
        }).catch(errorHandler)
      }
    } catch (err) {
      errorHandler(/** @type {Error} */ (err))
    }
  })
}

const getContext = () => ({
  page: {
    path: window.location.pathname,
    referrer: document.referrer,
    search: location.search,
    title: document.title,
    url: window.location.href,
  },
  library: {
    name: 'pnbx:apps:fill',
  },
  userAgent: navigator.userAgent,
})
