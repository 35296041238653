import { createMethodLegacySimple } from '../legacy'

/**
 * Check if the value is true.
 *
 * @usage: ```{ ':true': value }```
 * where:
 * - value: any: the value to check if it is true
 *
 * @example
 * ```{ ':true': true }``` => true
 * ```{ ':true': false }``` => false
 * ```{ ':true': 0 }``` => false
}
 */
export default createMethodLegacySimple(':true', function (args): boolean {
  return this.evaluate(args) === true
})
