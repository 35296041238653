import { extractDecimals, ifString } from '@penbox-io/stdlib'

import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'

import { parseNumber } from '../utils/number.js'

type Value = number

type Options = {
  label?: FormHelpable
  hint?: string
  placeholder?: string
  prefix?: string
  suffix?: string
  clearable: boolean
  min?: number
  max?: number
}
type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  const min = parseNumber(input.min, 0)
  const max = parseNumber(input.max, min || 0)

  return {
    label: ifFormHelpable(input.label),
    hint: ifString(input.hint),
    placeholder: ifString(input.placeholder),
    prefix: ifString(input.prefix),
    suffix: ifString(input.suffix),
    min,
    max,
    clearable: input.clearable !== false,
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  if (typeof value === 'number') {
    return value
  } else {
    return null
  }
}

function validate<O extends Options>(
  options: O,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (value === null) {
    if (required) return buildError('required', locale)
    return null
  }
  const { min } = options
  if (min != null && value < min) {
    return buildError('minValue', locale, [renderNumber(min)])
  }

  const { max } = options
  if (max != null && value > max) {
    return buildError('maxValue', locale, [renderNumber(max)])
  }

  const valueDecimals = extractDecimals(value)
  if (valueDecimals && valueDecimals.length > 2) {
    return buildError('maxDecimals', locale, [2])
  }

  return null
}

function renderNumber(value: number): string {
  return Number(value)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

function normalize(element: Element): undefined | Value {
  return element.value ?? undefined
}

function stringify(element: Element): string | undefined {
  if (element.value == null) return undefined

  const text = renderNumber(element.value)

  return textWrap(text, element.options)
}

function textWrap(text: string, { prefix, suffix }: Options) {
  const prefixStr = prefix && typeof prefix === 'string' ? `${prefix} ` : ''
  const suffixStr = suffix && typeof suffix === 'string' ? ` ${suffix}` : ''

  return `${prefixStr}${text}${suffixStr}`
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}
