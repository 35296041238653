import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isArray, isPureExpression, isString, jsonify } from '../util'
import { findPath, parsePath } from '../string'

type Type = { ':ref': JsonValue }

/**
 * Returns the value of a reference.
 *
 * @usage: ```{":ref": path}```
 * where:
 * - `path` is a string to parse as a path
 *
 * @example
 * scope: {a: {b: 42}, array: ["a", "b", "c"]}
 * - ```{":ref": "a"}``` returns the value of `{b: 42}`
 * - ```{":ref": "a.b"}``` returns `42`
 * - ```{":ref": "array.1"}``` returns `"b"`
 */
export default createMethod<Type>({
  name: ':ref',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':ref') continue
      return false
    }

    if (!isString(expr[':ref']) && !isPureExpression(expr[':ref'])) return false

    return true
  },

  evaluate(expr) {
    const ref = this.evaluate(expr[':ref'])

    const path = parsePathOrArray(ref)
    if (!path) return undefined

    const value = findPath.call(this.scope, path[0] === '$' ? path.slice(1) : path)
    return jsonify(value)
  },

  compile(expr) {
    const refCompiled = this.createContext(':ref').compile(expr[':ref'])

    return (scope: Scope) => {
      const ref = refCompiled(scope)

      const path = parsePathOrArray(ref)
      if (!path) return undefined

      const value = findPath.call(scope, path[0] === '$' ? path.slice(1) : path)
      return jsonify(value)
    }
  },
})

function parsePathOrArray(input: unknown): undefined | Array<string | number> {
  if (isString(input)) {
    return parsePath(input)
  } else if (isArray(input)) {
    for (let i = 0; i < input.length; i++) {
      const partType = typeof input[i]
      if (partType !== 'string' && partType !== 'number') return undefined
    }
    return input as string[]
  } else {
    return undefined
  }
}
