import { JsonValue, MethodSignature, Scope, Value } from '../types'

import { createMethod, isArray, isMethodCall, isPlainObject, isString, toString } from '../util'

type Type = {
  ':url': string | Record<string, JsonValue>
  ':search'?: JsonValue
}

/**
 * Creates a URL from a string and a search object.
 *
 * @usage: ```{':url': string, ':search'?: object}```
 * where:
 * - `string` is a string representing the URL
 * - `object` is an object representing the search parameters
 *
 * @example
 * - ```{':url': 'https://example.com'}``` returns `'https://example.com'`
 * - ```{':url': 'https://example.com', ':search': {a: 1, b: 2}}``` returns `'https://example.com?a=1&b=2'`
 */
export default createMethod<Type>({
  name: ':url',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':url' || key === ':search') continue
      return false
    }

    if (expr[':url'] == null) return false
    if (!isString(expr[':url']) && !isMethodCall(expr[':ur'])) return false
    if (expr[':search'] !== undefined && !isPlainObject(expr[':search'])) return false

    return true
  },

  evaluate(expr): Value {
    const uri = this.evaluate(expr[':url'])
    if (typeof uri !== 'string') return undefined

    const search = this.evaluate(expr[':search'])

    try {
      return formatUri(uri, search).toString()
    } catch {
      return undefined
    }
  },

  compile(expr) {
    const urlCompiled = this.createContext(':url').compile(expr[':url'])

    if ('iterator' in urlCompiled) return undefined
    if (urlCompiled.static && typeof urlCompiled.staticValue !== 'string') return undefined

    const searchCompiled = this.createContext(':search').compile(expr[':search'])

    return (scope: Scope) => {
      const uri = urlCompiled(scope)
      if (typeof uri !== 'string') return undefined

      const search = searchCompiled(scope)

      try {
        return formatUri(uri, search).toString()
      } catch {
        return undefined
      }
    }
  },
})

const formatUri = (uri: string, search?: any): InstanceType<typeof URL> => {
  const url = new URL(uri)
  if (isPlainObject(search)) {
    for (const key in search) {
      const value = search[key]
      if (value === undefined) continue

      if (value === null) {
        url.searchParams.delete(key)
      } else if (isArray(value)) {
        for (const val of value) {
          if (val == null) continue

          const v = toString(val)
          if (v !== undefined) url.searchParams.append(key, v)
        }
      } else {
        const v = toString(value)
        if (v !== undefined) url.searchParams.set(key, v)
      }
    }
  }
  return url
}
