// http://paulirish.com/2011/requestanimationframe-for-smart-animating/
// http://my.opera.com/emoller/blog/2011/12/20/requestanimationframe-for-smart-er-animating

if (!window.requestAnimationFrame) {
  let lastTime = 0

  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function requestAnimationFramePolyfill(
      /**  @type {(n: number) => any} */ callback,
      /**  @type {DOMElement} */ _element
    ) {
      const currTime = new Date().getTime()
      const timeToCall = Math.max(0, 16 - (currTime - lastTime))
      lastTime = currTime + timeToCall
      const id = window.setTimeout(callback, timeToCall, currTime + timeToCall)
      return id
    }

  window.cancelAnimationFrame =
    window.cancelAnimationFrame ||
    window.webkitCancelRequestAnimationFrame ||
    window.mozCancelRequestAnimationFrame ||
    window.oCancelRequestAnimationFrame ||
    window.msCancelRequestAnimationFrame ||
    function cancelAnimationFramePolyfill(id) {
      window.clearTimeout(id)
    }
}
