import { subScope } from '../scope'
import { JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isNumber, isPureExpression } from '../util'

type Type = {
  ':array': JsonValue
  ':fill'?: JsonValue
}

const parseLength = (value: Value): number | undefined => {
  const length = Number(value)

  if (!(length >= 0)) return undefined
  if (length !== (length | 0)) return undefined

  return length
}

const mapToIndex = (_: any, i: number) => i

/**
 * Creates an array of a specified length with the option to fill it with a value.
 * @usage: ```{ ':array': length, ':fill': value }```
 * where:
 * - length: number: the length of the array
 * - value: any?: the value to fill the array with - can be a static value or an expression
 *
 * @sub-scope variables
 * - @index: number: the current index of the array
 * - @position: number: the current position of the array (index + 1)
 * - @first: boolean: true if the current index is 0
 * - @last: boolean: true if the current index is the last index
 * - @length: number: the length of the array
 *
 * @example
 * ```{ ':array': 3 }``` => [0, 1, 2]
 * ```{ ':array': 3, ':fill': 0 }``` => [0, 0, 0]
 * ```{ ':array': 3, ':fill': { 'type': 'text', 'key': 'data.item_{@index}'} }``` => ['data.item_0', 'data.item_1', 'data.item_2']
 *
 */
export default createMethod<Type>({
  name: ':array',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':array') continue
      if (key === ':fill') continue
      return false
    }

    if (!isNumber(expr[':array']) && !isPureExpression(expr[':array'])) return false

    return true
  },

  evaluate(expr): undefined | number[] {
    const length = parseLength(this.evaluate(expr[':array']))
    if (length === undefined) return undefined

    const array = Array(length)

    if (expr[':fill'] === undefined) {
      return Array.from(array, mapToIndex)
    }

    // Optimization: Create a re-usable child context

    // for (let i = 0; i < length; i++) {
    //   const ctx = this.subScope({ '@index': i, '@position': i + 1 })
    //   array[i] = ctx.evaluate(expr[':fill'])
    // }

    const ctx = this.subScope({
      '@first': true as boolean,
      '@index': -1,
      '@position': 0,
      '@last': true as boolean,
      '@length': length,
    })

    // Optimization: keep reference to scope object at hand
    const childScope = ctx.scope

    for (let i = 0; i < length; i++) {
      childScope['@index'] = i
      childScope['@position'] = i + 1
      childScope['@first'] = i === 0
      childScope['@last'] = i === length - 1

      array[i] = ctx.evaluate(expr[':fill'])
    }

    return array
  },

  compile(expr) {
    const length$ = this.createContext(':array').compile(expr[':array'])
    const fill$ =
      expr[':fill'] === undefined ? null : this.createContext(':fill').compile(expr[':fill'])

    return (scope: Scope): Value => {
      const length = parseLength(length$(scope))
      if (length === undefined) return undefined

      const array = Array(length)

      if (!fill$) {
        return Array.from(array, mapToIndex)
      }

      const childScope = subScope(scope, {
        '@first': true as boolean,
        '@index': -1,
        '@position': 0,
        '@last': true as boolean,
        '@length': length,
      })

      for (let i = 0; i < length; i++) {
        childScope['@index'] = i
        childScope['@position'] = i + 1
        childScope['@first'] = i === 0
        childScope['@last'] = i === length - 1

        array[i] = fill$(childScope)
      }

      return array
    }
  },
})
