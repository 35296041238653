import { ifString } from '@penbox-io/stdlib'

import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'
import { parseNumber, formatNumber, extractDecimals } from '../utils/number.js'
import { textWrap } from '../utils/text.js'

type Value = number

type Options = {
  autocomplete?: string
  clearable: boolean
  decimals?: number
  hint?: string
  label?: FormHelpable
  max?: number
  min?: number
  placeholder?: string
  prefix?: string
  suffix?: string
  readonly?: boolean
}

type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  const min = parseNumber(input.min, undefined)
  const max = parseNumber(input.max, min)

  return {
    autocomplete: ifString(input.autocomplete),
    clearable: input.clearable !== false,
    decimals: parseNumber(input.decimals, 0, 100),
    hint: ifString(input.hint),
    label: ifFormHelpable(input.label),
    max,
    min,
    readonly: input.readonly === true,
    placeholder: ifString(input.placeholder),
    prefix: ifString(input.prefix),
    suffix: ifString(input.suffix),
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  const number = parseNumber(value)
  if (number == null) return null

  return number
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (value === null) {
    if (required) return buildError('required', locale)
    return null
  }

  const { min } = options
  if (min != null && value < min) {
    return buildError('minValue', locale, [renderNumber(min, options, locale)])
  }

  const { max } = options
  if (max != null && value > max) {
    return buildError('maxValue', locale, [renderNumber(max, options, locale)])
  }

  const { decimals } = options
  if (decimals != null) {
    const valueDecimals = extractDecimals(value)
    if (valueDecimals && valueDecimals.length > decimals) {
      return buildError('maxDecimals', locale, [decimals])
    }
  }

  return null
}

function renderNumber(value: number, options: Options, locale: string): string {
  const text = formatNumber(value, { locale, minDecimals: options.decimals ?? 1 })
  return textWrap(text, options)
}

function normalize(element: Element): undefined | Value {
  return element.value ?? undefined
}

function stringify(element: Element): string | undefined {
  if (element.value == null) return undefined

  return renderNumber(element.value, element.options, element.locale)
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}
