import { asIterableExpression } from '../compilation'
import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isArray, isPureExpression } from '../util'

type Type = { ':coalesce': JsonValue }

/**
 * Returns the first non-null value from a list of expressions.
 * @usage: ```{ ':coalesce': [expr1, expr2, ...] }```
 * where:
 * - expr1, expr2, ...: any: the expressions to evaluate
 * @example
 * ``` ":coalesce": [null, 1, 2] }``` => 1
 * ``` ":coalesce": [null, null, 2] }``` => 2
 * ``` ":coalesce": [null, null, null] }``` => null
 * ``` ":coalesce": [null, null, { "a": 1 }] }``` => { "a": 1 }
 *
 * @real world example
 * You need to get the first non-null value from a list of variables.
 * scope: ```{ 'a': null, 'b': null, 'c': 3 }```
 * ```{ ":coalesce": ["{a}", "{b}", "{c}"] }``` => 3
 */
export default createMethod<Type>({
  name: ':coalesce',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':coalesce') continue
      return false
    }

    if (!isArray(expr[':coalesce']) && !isPureExpression(expr[':coalesce'])) return false

    return true
  },

  evaluate(expr) {
    const { ':coalesce': coalesceExpr } = expr

    // Optimization: eval only required items
    if (isArray(coalesceExpr)) {
      for (const item of coalesceExpr) {
        const result = this.evaluate(item)
        if (result != null) return result
      }

      return null
    }

    const evaled = this.evaluate(coalesceExpr)
    if (isArray(evaled)) {
      for (const item of evaled) {
        if (item != null) return item
      }

      return null
    }

    // Invalid evaled value
    return undefined
  },

  compile(expr) {
    const compiledExpr = this.createContext(':coalesce').compile(expr[':coalesce'])

    // Optimization: prefer iterator
    const iterableGetter = asIterableExpression(compiledExpr)

    return (scope: Scope) => {
      const iterable = iterableGetter(scope)
      if (!iterable) return undefined

      for (const item of iterable) if (item != null) return item
      return null
    }
  },
})
