import { asIterableExpression } from '../compilation'
import { createMethodLegacySimple } from '../legacy'
import { Scope } from '../types'
import { isArray } from '../util'

/**
 * Check if all elements in the array are equal.
 *
 * @usage: ```{ ':eq': array }```
 * where:
 * - array: array: the array to check if all elements are equal
 *
 * @example
 * ```{ ':eq': [1, 1, 1] }``` => true
 * ```{ ':eq': [1, 2, 1] }``` => false
 */
export default createMethodLegacySimple(
  ':eq',
  function (args): undefined | boolean {
    if (!isArray(args)) return undefined

    let prev
    for (let i = 0; i < args.length; i++) {
      const current = this.evaluate(args[i])
      if (i !== 0 && current !== prev) return false
      prev = current
    }

    return true
  },
  function (compiledExpr) {
    const iterableGetter = asIterableExpression(compiledExpr)

    return (scope: Scope) => {
      const iterable = iterableGetter(scope)
      if (!iterable) return undefined

      let prev
      let checkWithPrev = false
      for (const current of iterable) {
        if (checkWithPrev && current !== prev) return false
        prev = current
        checkWithPrev = true
      }

      return true
    }
  }
)
