import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isArray } from '../util'

type Type = { ':if': JsonValue; ':then'?: JsonValue; ':else'?: JsonValue }

/**
 * Conditionally return a value.
 *
 * @usage: ```{ ':if': condition, ':then': then, ':else?': else }```
 *
 * where:
 * - condition: any: the condition to evaluate
 * - then: any: the value to return if the condition is true
 * - else?: any: the value to return if the condition is false
 *
 * @example
 * ```{ ':if': true, ':then': 'yes', ':else': 'no' }``` => 'yes'
 * ```{ ':if': true, ':then': 'yes' }``` => 'yes'
 * ```{ ':if': false, ':then': 'yes', ':else': 'no' }``` => 'no'
 *
 * @real world example
 *
 * You need to print a message based on the value of a variable.
 * scope: ```{ count: 3 }```
 * ```{ ":if": { ":cmp": "{count}", ":eq": 0 }, ":then": "No items", ":else": "Items: {count}" }```
 *
 */
export default createMethod<Type>({
  name: ':if',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':if' || key === ':then' || key === ':else') continue
      return false
    }

    if (expr[':if'] === undefined) return false
    if (expr[':then'] === undefined && expr[':else'] === undefined) return false
    if (isArray(expr[':if'])) return false // Legacy

    return true
  },

  evaluate(expr) {
    // Optimization
    if (expr[':then'] === expr[':else']) return this.evaluate(expr[':then'])

    return this.evaluate(expr[':if']) ? this.evaluate(expr[':then']) : this.evaluate(expr[':else'])
  },

  compile(expr) {
    const condCompiled = this.createContext(':if').compile(expr[':if'])

    const thenCompiled =
      expr[':then'] === undefined
        ? () => undefined
        : this.createContext(':then').compile(expr[':then'])

    const elseCompiled =
      expr[':else'] === undefined
        ? () => undefined
        : this.createContext(':else').compile(expr[':else'])

    // Optimization: No need to compute "if"
    // TODO: use deep equality
    if (expr[':then'] === expr[':else']) return thenCompiled

    // Optimization
    if (condCompiled.static) return condCompiled.staticValue ? thenCompiled : elseCompiled

    return (scope: Scope) => {
      return condCompiled(scope) ? thenCompiled(scope) : elseCompiled(scope)
    }
  },
})
