export function parseEnum<T>(type: T, value: unknown): undefined | T[keyof T] {
  for (const key in type) {
    if (Object.prototype.hasOwnProperty.call(type, key)) {
      const item = type[key as keyof T]
      if (item === value) return item
    }
  }

  return undefined
}
