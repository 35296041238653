import { ifString, isValidDate } from '@penbox-io/stdlib'

import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'
import {
  Options as DateOptions,
  parseDate,
  parseDateOptions,
  stringifyDate,
} from '../utils/date.js'

export type Value = string
export type Options = DateOptions & {
  label?: FormHelpable
  hint?: string
  placeholder?: string
  weekDays?: boolean
}
export type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

export function options(input: Dict, locale: string): Options {
  const dateOptions = parseDateOptions(input, locale)

  return {
    label: ifFormHelpable(input.label),
    hint: ifString(input.hint),
    placeholder: ifString(input.placeholder),

    datePattern: dateOptions.datePattern,
    min: dateOptions.min,
    max: dateOptions.max,
    weekDays: dateOptions.weekDays,
  }
}

export function parse(options: Options, locale: string, value: unknown): null | Value {
  if (value == null) return null
  switch (typeof value) {
    case 'string':
      return value.trim() || null
    case 'object':
      if (value instanceof Date && isValidDate(value)) {
        return value.toISOString()
      }
      return null
    default:
      return null
  }
}

export function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  const date = parseDate(value)
  if (!date) {
    if (required) return buildError('requiredDatetime', locale)
    return null
  }

  const min = options.min
  if (min && date < min) {
    return buildError('maxDate', locale, [min.toLocaleString(locale)])
  }

  const max = options.max
  if (max && date > max) {
    return buildError('maxDate', locale, [max.toLocaleString(locale)])
  }

  return null
}

export function normalize(element: Element): undefined | Value {
  return parseDate(element.value)?.toISOString()
}

export function stringify(element: Element): undefined | string {
  const date = parseDate(element.value)
  if (!date) return undefined

  return stringifyDate(date, element.locale, element.options.datePattern)
}

export function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}
