import { flatten, ifNumber, ifString, isArray, isDefined, JsonScalar } from '@penbox-io/stdlib'

import {
  type Dict,
  type FormHelpable,
  ifFormHelpable,
  stringifyHelpable,
  isDict,
} from '../../common'
import { type Definition, type GenericElement } from '../../core'
import { type FormError, buildError } from '../utils/error.js'
import { parseDate } from '../utils/date.js'
import { ifUri } from '../utils/url.js'

type Item = {
  id?: string
  uri: string
  key: string // Will default to uri
  name?: string
  type?: string // TODO? strongly type this ?
  size?: number
  fill?: Dict<JsonScalar>
}

type Value = { [_ in string]?: null | Date }
type Options = {
  submitOnChange: boolean
  items: Item[]
  label?: FormHelpable
  customName?: string
}
type Element = GenericElement<Options, Value>

export default {
  options,
  requirable,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
  localize,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    label: ifFormHelpable(input.label),
    items: parseItems(input.items),
    customName: ifString(input.custom_name),
    submitOnChange: input.submit_on_change === true,
  }
}

function requirable({ items }: Options): boolean {
  return items.length > 0
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  const parsed: Value = {}

  // Hot path, avoid iterable
  for (let i = 0; i < options.items.length; i++) {
    const item = options.items[i]
    const itemValue = (value as any)?.[item.key]

    parsed[item.key] = itemValue ? parseDate(itemValue) || null : null
  }

  return parsed
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  // Hot path, avoid iterable
  if (required && options.items) {
    for (let i = 0; i < options.items.length; i++) {
      const item = options.items[i]
      const itemValue = (value as any)?.[item.key]

      if (!itemValue) {
        return buildError('requiredDownload', locale, [item.name])
      }
    }
  }

  return null
}

function normalize(element: Element): undefined | Value {
  return element.value || undefined
}

function stringify(element: Element): undefined | string {
  return element.options.items.map(stringifyItem, element).join('\n')
}

function stringifyItem(this: Element, item: Item): string {
  const value = this.value?.[item.key]
  const prefix = item.name || item.uri
  const suffix = value ? ` (${value.toLocaleString(this.locale)})` : '' // "not downloaded"
  return `${prefix}${suffix}`
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.title || element.options.label)
}

function parseItems(input: unknown): Item[] {
  if (!isArray(input)) return []
  return Array.from(flatten(input), parseItem).filter(isDefined)
}

function parseItem(input: unknown): undefined | Item {
  if (!isDict(input)) return undefined

  const uri = ifUri(input.uri)
  if (!uri) return undefined

  const key = ifString(input.key) || uri
  const name = ifString(input.name) || undefined
  const type = ifString(input.type) || undefined
  const size = ifNumber(input.size)
  const fill = input.fill
  const id = ifString(input.id)

  return { uri, key, name, type, size, fill, id }
}

function localize(element: Element, locale: string): string | undefined {
  return element.options.items.map((x) => x.name).join(', ')
}
