import { createMethodLegacySimple } from '../legacy'
import { isArray } from '../util'

/**
 * Deprecated: should not be used
 *
 * Check if the array is increasing
 *
 * @usage: ```{ ':increasing': [array] }```
 *
 * @example
 * ```{ ':increasing': [1, 2, 3] }``` => true
 * ```{ ':increasing': [1, 2, 2] }``` => false
 */
export default createMethodLegacySimple(':increasing', function (args): undefined | boolean {
  // Optimization: Try to avoid unnecessary computation of entire array
  const argsEvald = !isArray(args)
  const array = argsEvald ? this.evaluate(args) : args
  if (!isArray(array)) return undefined

  const isbool = typeof array[0] === 'boolean'
  const strictly = isbool ? array[0] : false
  const init = isbool ? 1 : 0

  // At least 2 items are required for this to have any meaning
  if (array.length < init + 2) return false

  let prev = NaN
  for (let i = init; i < array.length; i++) {
    const item = argsEvald ? array[i] : this.evaluate(array[i])
    const next = Number(item)
    if (Number.isNaN(next)) return undefined
    if (i !== init) {
      const isIncreasing = strictly ? next > prev : next >= prev
      if (!isIncreasing) return false
    }
    prev = next
  }

  return true
})
