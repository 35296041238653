import { ifArray, ifBoolean, ifString, isDefined } from '@penbox-io/stdlib'
import { Icon, ifIcon } from '@penbox-io/json-form-icons'

import {
  type Dict,
  type FormHelpable,
  ifFormHelpable,
  isDict,
  stringifyHelpable,
} from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'
import { parseDate } from '../utils/date.js'
import { asURL } from '../utils/url.js'

type Action = {
  title: FormHelpable
  icon?: string
} & (
  | {
      url: URL
      key?: string
    }
  | {
      url?: URL
      key: string
    }
)

type Value = Record<string, null | Date>
type Options = {
  label?: FormHelpable
  content?: string
  actions?: Action[]
  dark?: boolean
  color?: string
  icon?: Icon
}
type Element = GenericElement<Options, Value>

export default {
  options,
  requirable,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    label: ifFormHelpable(input.label),
    content: ifString(input.content),
    actions: ifArray(input.actions)?.map(parseAction).filter(isDefined),
    dark: ifBoolean(input.dark),
    color: ifString(input.color),
    icon: ifIcon(input.icon),
  }
}

function requirable({ actions }: Options): boolean {
  return actions?.some(hasKey) === true
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  if (!options.actions?.some(hasKey)) return null

  const parsed: Value = {}

  // Hot path, avoid iterable
  for (let i = 0; i < options.actions.length; i++) {
    const action = options.actions[i]
    if (action.key == null) continue

    parsed[action.key] = parseDate((value as any)?.[action.key]) || null
  }

  return parsed
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  // Hot path, avoid iterable
  if (required && options.actions) {
    for (let i = 0; i < options.actions.length; i++) {
      const action = options.actions[i]
      if (action.key == null) continue

      if (!value?.[action.key]) {
        return buildError('requiredCard', locale, [stringifyHelpable(action.title)])
      }
    }
  }

  return null
}

function normalize(element: Element): undefined | Value {
  const { actions } = element.options
  if (!actions?.some(hasKey)) return undefined

  const value: Value = {}

  for (let i = 0; i < actions.length; i++) {
    const action = actions[i]
    if (action.key == null) continue

    value[action.key] = getValue(element, action.key) || null
  }

  return value
}

function stringify(element: Element): undefined | string {
  return element.options.actions?.map(stringifyAction, element)?.join('\n')
}

function stringifyAction(this: Element, action: Action): string {
  const label = stringifyHelpable(action.title)
  const value = getValue(this, action.key)
  return `${label}${value ? ` (${value.toLocaleString(this.locale)})` : ''}`
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.title || element.options.label)
}

function parseAction(input: unknown): undefined | Action {
  if (!isDict(input)) return undefined

  const title = ifString(input.title)
  if (!title) return undefined

  const url = input.href ? asURL(input.href) : undefined
  const key = ifString(input.key)

  if (url === undefined && key === undefined) return undefined

  const icon = ifIcon(input.icon)
  return { title, url, key, icon } as Action
}

function hasKey(action: Action): action is Action & { key: string } {
  return action.key != null
}

function getValue(element: Element, key?: string): Date | null | undefined {
  return key != null ? parseDate((element.value as any)?.[key]) || null : undefined
}
