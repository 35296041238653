export const date = 'Ogiltigt datum.'
export const email = 'Ogiltig e-postadress.'
export const iban = 'Ogiltigt IBAN-nummer.'
export const invalid = 'Ogiltigt värde.'
export const invalidFileType = 'Ogiltig filtyp.'
export const maxCount = 'Detta fält bör innehålla högst {0} objekt.'
export const maxDate = 'Ange ett datum före {0}.'
export const maxDecimals =
  'Detta värde får innehålla högst {0} decimaler. Var noga med att inte använda tusentalsavgränsare.'
export const maxFileSize = 'Välj en fil som är mindre än {0}.'
export const maxFilesSize = 'Alla filer måste vara mindre än {0}.'
export const maxLength = 'Detta värde får vara högst {0} tecken långt.'
export const maxValue = 'Detta värde måste vara mindre än eller lika med {0}.'
export const minCount = 'Detta fält bör innehålla minst {0} objekt.'
export const minDate = 'Ange ett datum efter {0}.'
export const minFileSize = 'Välj en fil som är större än {0}.'
export const minFilesSize = 'Alla filer måste vara större än {0}.'
export const minLength = 'Detta värde måste vara längre än {0} tecken.'
export const minValue =
  'Detta värde måste vara större än eller lika med {0}. Var noga med att inte använda tusentalsavgränsare.'
export const pattern = 'Detta värde följer inte rätt format.'
export const phone = 'Ogiltigt telefonnummer.'
export const phoneCountry = 'Ogiltigt {0} telefonnummer.'
export const phoneFixedLine = 'Ogiltigt fast telefonnummer.'
export const phoneIntl = 'Var noga med att börja med ditt +xx landskod.'
export const phoneMobile = 'Ogiltigt mobilnummer.'
export const required = 'Ett värde krävs.'
export const requiredButton = 'Klicka på denna knapp.'
export const requiredCard = 'Klicka på {0}.'
export const requiredChoice = 'Välj ett alternativ.'
export const requiredChoices = 'Välj ett eller flera alternativ.'
export const requiredDate = 'Ett giltigt datum krävs.'
export const requiredDatetime = 'Ett giltigt datum och tid krävs.'
export const requiredDownload = 'Alla filer måste laddas ner för att validera detta steg.'
export const requiredFile = 'En fil krävs.'
export const requiredFileMultiple = 'Ange minst en fil.'
export const requiredSignature = 'Var god signera.'
export const requiredRating = 'Välj ett värde mellan 1 och 5.'
