import { createMethodLegacySimple } from '../legacy'
import { Value } from '../types'
import { isArray } from '../util'

/**
 * Filter items in an array by a condition.
 *
 * @usage: ```{ ':filter': [array, condition] }```
 * where:
 * - array: any[]: the array to filter
 * - condition: any: the condition to filter by
 *
 * @sub-scope variables
 * - @index: number: the current index of the array
 * - @item: any: the current item in the array
 * - @array: any[]: the array being filtered
 *
 * @example
 * ```{ ":filter": [[1,2,3,4], {":cond": {"@item": {"$gt": 1}}}] }``` => [2, 3, 4]
 * ```{ ":filter": [[1,2,3,4], {}] }``` => [1,2, 3, 4]
 */
export default createMethodLegacySimple(':filter', function (args): undefined | Value[] {
  if (!isArray(args) || args.length < 1 || args.length > 2) return undefined

  const array = this.evaluate(args[0])
  if (!isArray(array)) return undefined

  // Filter falsy items by default
  if (args.length === 1) return array.filter(Boolean)

  return array.filter((item, index, array) => {
    const ctx = this.subScope({ '@item': item, '@index': index, '@array': array })
    const result = ctx.evaluate(args[1])
    return (
      result !== false &&
      result !== '' &&
      result !== undefined &&
      result !== null &&
      result === result // eslint-disable-line no-self-compare
    )
  })
})
