import { flatten, isArray, isIterable } from '@penbox-io/stdlib'

import { Dict } from '../common'

import { FormStep, DefinitionMap } from './definition.js'
import { buildElements, ValueGetter } from './element.js'

export interface StepDefinition extends Dict {
  id?: string | number
  label?: null | string
  meta?: null | Dict
  visible?: true
}

export function isStepDefinition(item: any): item is StepDefinition {
  if (!item || typeof item !== 'object') {
    return false
  }

  if (item.id !== undefined && typeof item.id !== 'string' && typeof item.id !== 'number') {
    return false
  }

  if (item.label != null && typeof item.label !== 'string') {
    return false
  }

  if (item.visible != null && !item.visible) {
    return false
  }

  if (item.meta != null) {
    if (typeof item.meta !== 'object') return false
    if (Array.isArray(item.meta)) return false
  }

  return true
}

export function buildSteps<D extends DefinitionMap>(
  definitions: D,
  input: unknown,
  locale: string,
  ensureSubmittable: boolean,
  getValue?: ValueGetter,
  elementTransform?: (element: unknown) => unknown
): Array<FormStep<D>> {
  let position = -1
  const steps: Array<FormStep<D>> = []

  const iterable = isArray(input) ? flatten(input) : isIterable(input) ? input : null
  if (iterable) {
    for (const item of iterable) {
      // Counting position, even if item is not a step definitifion (typically
      // "null") as this allows ids to be more stable.
      position++

      if (!item) continue
      if (!isStepDefinition(item)) continue

      steps.push({
        id: position,
        label: item.label || null, // null if empty string
        meta: item.meta ?? undefined,
        enabled: Boolean(item.enabled ?? true),
        elements: buildElements(
          definitions,
          item.elements,
          locale,
          ensureSubmittable,
          getValue,
          elementTransform
        ),
      })
    }
  }

  return steps
}
