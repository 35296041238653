import { type Definition } from '../../core'

import {
  type Options,
  type Value,
  options,
  requirable,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} from '../partials/choice.js'

export default {
  options,
  requirable,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>
