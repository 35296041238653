import { createLock, deepEqual, objectMap } from '@penbox-io/stdlib'

/**
 * @template R
 * @typedef {import('vuex').Store<R>} Store
 */

/**
 * @template S
 * @template R
 * @typedef {import('vuex').ActionHandler<S, R>} ActionHandler
 */

/**
 * @template S
 * @template R
 * @typedef {import('vuex').ActionContext<S, R>} ActionContext
 */

/**
 * @template S
 * @template R
 * @typedef {Record<string, ActionHandler<S, R>>|ActionHandler<S, R>} ActionOpt
 */

export class AbortError extends Error {
  constructor(action, type, payload) {
    super('This action was aborted')
    this.code = 'aborted'
    this.action = action
    this.type = type
    this.payload = payload
  }
}

export const dispatchPayloadComparator = (allowSkipping = true) => {
  let prev
  return (context, payload = null) => {
    if (allowSkipping && payload?.skipCache === true) return false
    const changed = prev === undefined || !deepEqual(prev, payload)
    prev = payload
    return !changed
  }
}

/**
 * @template S
 * @template R
 * @param {ActionHandler<S, R>} fn
 * @return {ActionHandler<S, R>}
 */
export const withCache = (fn, stillValid = dispatchPayloadComparator()) => {
  let prev = null
  const generate = takeLatest(fn)

  if (typeof generate !== 'function') {
    throw new TypeError(`withCache() can only be used with a function`)
  }

  return /** @this {Store<R>} */ function (context, payload) {
    if (prev === null || !stillValid.call(this, context, payload)) {
      prev = Promise.resolve(generate.call(this, context, payload))
    } else {
      prev = prev.catch(generate.bind(this, context, payload))
    }
    return prev
  }
}

/**
 * @template S
 * @template R
 * @template {ActionOpt<S, R>} T
 * @param {T} obj
 * @return {T}
 */
export const withLock = (obj) => {
  const lock = createLock()

  // @ts-ignore
  return typeof obj === 'function' ? wrapFn(obj) : objectMap(obj, wrapFn)

  /**
   * @param {ActionHandler<S, R>} fn
   * @return {ActionHandler<S, R>}
   */
  function wrapFn(fn) {
    return /** @this {Store<R>} */ function (context, payload) {
      return lock(fn.bind(this, context, payload))
    }
  }
}

export const usingUniqueExecutionContext = () => {
  let currentExecutionId = 0
  return (context) => {
    const id = ++currentExecutionId
    return (payload) => id === currentExecutionId
  }
}

/**
 * @template S
 * @template R
 * @template {any} T
 * @param {{ (this: Store<R>, context: ActionContext<S, R>): T }} getter
 * @param {{ deep?: boolean }} options
 * @return {{ (this: Store<R>, context: ActionContext<S, R>): (payload: any) => boolean }}
 */
export const usingStable = (getter, { deep = false } = {}) =>
  deep
    ? /** @this {Store<R>} */
      function (context) {
        const value = getter.call(this, context)
        return (payload) => deepEqual(value, getter.call(this, context))
      }
    : /** @this {Store<R>} */
      function (context) {
        const value = getter.call(this, context)
        return (payload) => value === getter.call(this, context)
      }

export const takeLatest = (obj) => withGuard(obj, usingUniqueExecutionContext())

/**
 * @template S
 * @template R
 * @template {ActionOpt<S, R>} T
 * @param {T} obj
 * @return {T}
 */
export const withGuard = (obj, createGuard) => {
  // @ts-ignore
  return typeof obj === 'function' ? wrapFn(obj) : objectMap(obj, wrapFn)

  /**
   * @param {ActionHandler<S, R>} fn
   * @param {string} [type]
   * @return {ActionHandler<S, R>}
   */
  function wrapFn(fn, type = fn.name) {
    return /** @this {Store<R>} */ function (origContext, payload) {
      const guard = createGuard.call(this, origContext)

      const wrappedContext = createWrappedContext(guard, origContext, type)
      return fn.call(this, wrappedContext, payload)
    }
  }

  function createWrappedContext(guard, origContext, type) {
    return Object.create(origContext, {
      commit: { enumerable: true, value: wrapContextFn(guard, origContext, 'commit', type) },
      dispatch: { enumerable: true, value: wrapContextFn(guard, origContext, 'dispatch', type) },
    })
  }

  function wrapContextFn(guard, origContext, action, type) {
    return /** @this {Store<R>} */ function (wrappedContext, payload) {
      if (guard(payload) !== true) throw new AbortError(action, type, payload)
      return origContext[action].call(this, wrappedContext, payload)
    }
  }
}

let currentExecutionId = 0

/**
 * @template S
 * @template R
 * @param {ActionOpt<S, R>} parentActions
 * @param {ActionOpt<S, R>} childActions
 * @return {ActionOpt<S, R>}
 */
export const withDependent = (parentActions, childActions) => {
  const execIdKey = `__dependentActionGroup:${++currentExecutionId}`
  const stableExecIdGuard = usingStable(
    /** @this {Store<R>} */ function (context) {
      return this[execIdKey]
    }
  )
  return {
    ...withGuard(
      parentActions,
      /** @this {Store<R>} */ function (context) {
        this[execIdKey] = (this[execIdKey] || 0) + 1
        return stableExecIdGuard.call(this, context)
      }
    ),
    ...withGuard(childActions, stableExecIdGuard),
  }
}
