import { JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isArray } from '../util'

type Type = { ':if': [JsonValue, JsonValue, JsonValue?] }

/**
 * Legacy method to conditionally return a value. You should use :if instead.
 *
 * @usage: ```{ ':if': [condition, then, else] }```
 * where:
 * - condition: any: the condition to evaluate
 * - then: any: the value to return if the condition is true
 * - else: any: the value to return if the condition is false
 *
 * @example
 * ```{ ':if': [true, 'yes', 'no'] }``` => 'yes'
 */
export default createMethod<Type>({
  name: ':if',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':if') continue
      return false
    }

    if (!isArray(expr[':if'])) return false
    if (expr[':if'].length < 2 || expr[':if'].length > 3) return false // Error: Invalid usage

    return true
  },

  evaluate(expr): Value {
    const [condExpr, thenExpr, elseExpr] = expr[':if']

    // Optimization: No need to compute "if"
    // TODO: use deep equality
    if (thenExpr === elseExpr) return this.evaluate(thenExpr)

    return this.evaluate(condExpr) ? this.evaluate(thenExpr) : this.evaluate(elseExpr)
  },

  compile(expr) {
    const condCompiled = this.createContext(':if', 0).compile(expr[':if'][0])
    const thenCompiled = this.createContext(':if', 1).compile(expr[':if'][1])

    const elseCompiled =
      expr[':if'][2] === undefined
        ? () => undefined
        : this.createContext(':if', 2).compile(expr[':if'][2])

    // Optimization: No need to compute "if"
    // TODO: use deep equality
    if (expr[':if'][1] === expr[':if'][2]) return thenCompiled

    // Optimization
    if (condCompiled.static) return condCompiled.staticValue ? thenCompiled : elseCompiled

    return (scope: Scope) => {
      return condCompiled(scope) ? thenCompiled(scope) : elseCompiled(scope)
    }
  },
})
