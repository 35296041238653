import Vue from 'vue'

const loadStrings = (locale) =>
  import(
    /* webpackInclude: /\.js$/ */
    /* webpackChunkName: "vuetify-[request]" */
    `vuetify/lib/locale/${locale}`
  ).then(
    (module) => module.default,
    // eslint-disable-next-line no-console
    (err) => console.warn(`Unable to load "${locale}" Vuetify locale:`, err)
  )

const storeModule = {
  namespaced: true,
  state: () => ({
    locales: {},
  }),
  mutations: {
    setStrings(state, { locale, strings }) {
      if (strings) Vue.set(state.locales, locale, strings)
    },
  },
  actions: {
    async loadStrings({ commit, state }, locale) {
      if (state.locales[locale]) return
      const strings = await loadStrings(locale)
      if (strings) commit('setStrings', { locale, strings })
    },
  },
}

export default async function i18nVuetifyPlugin({ app: { i18n }, store = null, $vuetify }) {
  if (!i18n || !$vuetify) {
    throw new Error('Nuxt i18n-vuetify module require nuxt-i18n and vuetify to be loaded.')
  }

  const setCurrent = (locale) => void Vue.set($vuetify.lang, 'current', locale)
  const setStrings = (locale, strings) =>
    void Vue.set($vuetify.lang.locales, locale, { ...$vuetify.lang.locales[locale], ...strings })

  if (store) {
    // Using the store allows sending the strings loaded during SSR to the
    // client.
    const ns = 'vuetify-i18n'

    store.registerModule(ns, storeModule, {
      preserveState: store.state[ns] !== undefined,
    })

    // Merge any preserved strings into vuetify (strings loaded during SSR)
    for (const locale in store.state[ns].locales) {
      setStrings(locale, store.state[ns].locales[locale])
    }

    // Merge future loaded strings into vuetify
    store.subscribe(async ({ type, payload }, state) => {
      if (type === `${ns}/setStrings`) {
        const { locale, strings } = payload
        setStrings(locale, strings)
      }
    })

    const setLocale = async (locale) => {
      await store.dispatch(`${ns}/loadStrings`, locale)
      setCurrent(locale)
    }

    await setLocale('en')
    await setLocale(i18n.locale)
    i18n.vm.$watch('locale', setLocale)
  } else {
    const setLocale = async (locale) => {
      const strings = await loadStrings(locale)
      if (strings) setStrings(locale, strings)
      else setStrings(locale, await loadStrings('en'))
      setCurrent(locale)
    }

    // Not needed since they won't be sync from server to client:
    // await setLocale('en')
    await setLocale(i18n.locale)
    i18n.vm.$watch('locale', setLocale)
  }
}
