import { flatten, formatValue } from '@penbox-io/stdlib'

import { type Dict, type Helpable, stringifyHelpable, isDict, isHelpableDict } from '../../common'

export type Value = string | number | boolean | null

export interface ChoiceDefinition<V extends Value = Value> extends Readonly<Dict> {
  value: V
  label?: unknown
}

export type Choice<V extends Value = Value> = {
  value: V
  label: string | Helpable
}

export type ChoiceValue<C extends Choice> = C['value']
export type ChoicesValue<C extends Choice[]> = ChoiceValue<C[number]>

function isValue(value: unknown): value is Value {
  switch (typeof value) {
    case 'number':
      return Number.isFinite(value)
    case 'string':
    case 'boolean':
      return true
    case 'object':
      return value === null
    default:
      return false
  }
}

function isChoiceDefinition(choice: Dict): choice is ChoiceDefinition {
  return isValue(choice.value)
}

function normalizeChoice<V extends Value = Value>(
  choiceDef: ChoiceDefinition<V>,
  locale: string
): Choice<V> {
  const { label } = choiceDef

  if (label !== '' && label !== undefined) {
    switch (typeof label) {
      case 'string':
        if (label) return choiceDef as Choice<V>
        break
      case 'object':
        if (label !== null && isHelpableDict(label)) {
          return choiceDef as Choice<V>
        }
      // falls through
      default:
        if (label === null || isValue(label)) {
          return {
            value: choiceDef.value,
            label: formatValue(label, { locale }),
          }
        }
    }
  }

  return {
    value: choiceDef.value,
    label: formatValue(choiceDef.value, { locale }),
  }
}

export function normalizeChoices(input: unknown, locale: string, element: Dict): Choice[] {
  const choices: Choice[] = []

  if (Array.isArray(input)) {
    for (const item of flatten<unknown>(input)) {
      switch (typeof item) {
        case 'object':
          if (item === null) {
            // Add null value only if element is not required
            if (element?.required !== true) {
              appendChoice(choices, { value: item, label: formatValue(item, { locale }) })
            }
          } else if (isChoiceDefinition(item)) {
            appendChoice(choices, normalizeChoice(item, locale))
          }
          continue
        case 'string':
          appendChoice(choices, { value: item, label: item })
          continue
        case 'boolean':
        case 'number':
          appendChoice(choices, { value: item, label: formatValue(item, { locale }) })
          continue
      }
    }
  } else if (isDict(input)) {
    // No need to filter duplicates as objects cannot contain the same key twice
    const keys = Object.keys(input).sort()

    for (let i = 0; i < keys.length; i++) {
      const value: string = keys[i]
      const label: unknown = input[value]
      switch (typeof label) {
        case 'string':
          choices.push({ value, label })
          continue
        case 'object':
          if (label === null) {
            choices.push({ value, label: formatValue(label, { locale }) })
          }
          continue
        case 'boolean':
        case 'number':
          choices.push({ value, label: formatValue(label, { locale }) })
          continue
      }
    }
  }

  return choices
}

/**
 * @note This function is not pure but it does not prevent normalizeChoices to be pure
 */
function appendChoice<V extends Value = Value>(choices: Array<Choice<V>>, choice: Choice<V>): void {
  // Optimization: using regular for loop because this is a hot path
  for (let i = 0; i < choices.length; i++) if (choices[i].value === choice.value) return
  choices.push(choice)
}

export function stringifyChoice(choice: Choice): string {
  return stringifyHelpable(choice.label) ?? String(choice.value)
}

export function findChoice<T extends Choice[]>(value: unknown, choices: T): undefined | T[number] {
  for (let i = 0; i < choices.length; i++) {
    // Because we check for strict equality, only scalar types are allowed (null, string, number, boolean)
    if (choices[i].value === value) return choices[i]
  }
  return undefined
}
