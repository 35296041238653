import { ifOwnProperty } from '@penbox-io/stdlib'

import { type FormError } from '../../common'

import { LOCALIZED_MESSAGES, fallbackMessages, Messages } from './error-messages'

export { FormError }
export function buildError(
  code: string,
  locale: string,
  vars?: any,
  additionalMessages?: Messages
): FormError {
  const lang = locale.length > 2 ? locale.slice(0, 2) : locale
  const strings: Messages = LOCALIZED_MESSAGES[lang] || fallbackMessages
  const message =
    ifOwnProperty(additionalMessages, code) ||
    ifOwnProperty(strings, code) ||
    fallbackMessages.invalid
  return {
    code: `errors.validation.${code}`,
    message: message.includes('{')
      ? message.replace(/\{([^}]+)\}/, (_: string, k: string) => vars?.[k.trim()] ?? '')
      : message,
  }
}
