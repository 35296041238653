import { createMethodLegacySimple } from '../legacy'

/**
 * Returns the logical negation of the result of the expression.
 *
 * @usage: ```{':not': expression}```
 * where:
 * - `expression` is any valid expression
 *
 * @example
 * - ```{':not': true}``` returns `false`
 * - ```{':not': false}``` returns `true`
 *
 * This method is equivalent to the `!` operator in JavaScript.
 */
export default createMethodLegacySimple(':not', function (args): undefined | boolean {
  const result = this.evaluate(args)
  return result === undefined ? undefined : !result
})
