import { normalizeError, normalizeErrors } from '@penbox-io/error-parser'

/** @typedef {import('@penbox-io/error-parser').NormalizedError} NormalizedError */

/**
 * @todo: Move this in its own module
 */

export function getString(vm, key, params = undefined) {
  if (typeof vm?.$te !== 'function') return undefined

  do if (vm.$te(key)) return vm.$t(key, typeof params === 'object' ? params : undefined)
  while ((vm = vm.$parent))

  return undefined
}

/**
 * @this {Vue}
 * @param {unknown} err
 */
export function stringifyErrors(err) {
  if (!err) return []

  return Array.from(normalizeErrors(err), generateMessage, this).filter(Boolean)
}

/**
 * @this {Vue}
 * @param {unknown} err
 * @param {string | number} [fallbackCode] Allow to be a number when used as an array mapper.
 */
export function stringifyError(err, fallbackCode = undefined) {
  if (!err) return undefined

  const normalized = normalizeError(err)
  return generateMessage.call(this, normalized, fallbackCode)
}

/**
 * @this {Vue}
 * @param {NormalizedError} err
 * @param {string | number} [fallbackCode] Allow to be a number when used as an array mapper.
 */
export function generateMessage(
  { status, code, message, meta = undefined },
  fallbackCode = undefined
) {
  if (code !== 'http-error' && code) {
    const string = getString(this, `errors.${code}`, meta)
    if (string) return string
  }

  // backwards compatibility
  if (code === 'js-error') {
    const string = getString(this, `errors.undefined`)
    if (string) return string
  }

  if (code === 'http-error' || status >= 0) {
    const string =
      (status >= 400 && getString(this, `errors.http-${status}`)) ||
      (status >= 500 && getString(this, `errors.http-5xx`)) ||
      (status >= 400 && getString(this, `errors.http-4xx`)) ||
      (status === 0 && getString(this, `errors.http-network`)) ||
      getString(this, `errors.http-error`)
    if (string) return string
  }

  // Optimization: avoid trying getString with a value (of "code") that was already checked.
  if (code !== fallbackCode && typeof fallbackCode === 'string') {
    const string = getString(this, `errors.${fallbackCode}`, meta)
    if (string) return string
  }

  if (code == null) {
    const string = getString(this, `errors.undefined`)
    if (string) return string
  }

  console.debug(`Unable to find error message for "${code}" and "${fallbackCode}"`)
  return message
}
