import { formatValue } from '@penbox-io/stdlib'

import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'

export type Value = boolean
export type Options = {
  label?: FormHelpable
  submitOnChange?: boolean
}
export type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

export function options(input: Dict, locale: string): Options {
  return {
    label: ifFormHelpable(input.label),
    submitOnChange: input.submit_on_change === true,
  }
}

export function parse(options: Options, locale: string, input: unknown): null | Value {
  if (input == null) return false // Otherwise we have to toggle twice to get to false
  if (input === true || input === false) return input

  return (
    input === 'true' ||
    input === 'True' ||
    input === 'TRUE' ||
    input === '1' ||
    input === 'yes' ||
    input === 'on' ||
    input === 1
  )
}

export function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (required && value !== true) {
    return buildError('requiredChecked', locale)
  }
  return null
}

export function normalize(element: Element): undefined | Value {
  if (element.value === true) return true
  if (element.value === false) return false

  return undefined
}

export function stringify(element: Element): undefined | string {
  const value = normalize(element)
  if (value == null) return undefined

  return formatValue(element.value, element)
}

export function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.title || element.options.label)
}
