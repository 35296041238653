import { createMethodLegacySimple } from '../legacy'

/**
 * Check if the value is null.
 *
 * @usage: ```{ ':undefined': value }```
 * where:
 * - value: any: the value to check if it is undefined
 *
 * @example
 * ```{ ':undefined': null }``` => true
 * ```{ ':undefined': 0 }``` => false
 */
export default createMethodLegacySimple(':undefined', function (args): undefined | boolean {
  const value = this.evaluate(args)
  return value == null
})
