import { stripLatinAccents } from './string'

const isDone = <T, TReturn = any>(result: IteratorResult<T, TReturn>): boolean =>
  result.done === true
const getValue = <T, TReturn = any>(result: IteratorResult<T, TReturn>): T | TReturn => result.value
const getIterator = <T>(value: Iterable<T>): Iterator<T> => value[Symbol.iterator]()
const getNext = <T, TReturn = any, TNext = undefined>(
  it: Iterator<T, TReturn, TNext>
): IteratorResult<T, TReturn> => it.next()

export function zip<A, B>(...iterables: [Iterable<A>, Iterable<B>]): Iterable<[A, B]>
export function zip<A, B, C>(
  ...iterables: [Iterable<A>, Iterable<B>, Iterable<C>]
): Iterable<[A, B, C]>
export function zip<A, B, C, D>(
  ...iterables: [Iterable<A>, Iterable<B>, Iterable<C>, Iterable<D>]
): Iterable<[A, B, C, D]>
export function zip<A, B, C, D, E>(
  ...iterables: [Iterable<A>, Iterable<B>, Iterable<C>, Iterable<D>, Iterable<E>]
): Iterable<[A, B, C, D, E]>

export function* zip<T>(...iterables: Array<Iterable<T>>): Iterable<T[]> {
  if (iterables.length === 0) return
  const iterators = Array.from(iterables, getIterator)
  while (true) {
    const results = iterators.map(getNext)
    if (results.some(isDone)) return
    yield results.map(getValue)
  }
}

type UnzipResult<T extends Iterable<readonly unknown[]>> = T extends Iterable<[]>
  ? []
  : T extends Iterable<[infer A, ...infer R]>
  ? [A[], ...UnzipResult<Iterable<R>>]
  : T extends Iterable<never>
  ? []
  : T extends Iterable<infer I>
  ? I[][]
  : unknown[][]

export function unzip<T extends Iterable<readonly unknown[]>>(iterable: T): UnzipResult<T>

export function unzip<T extends Iterable<unknown[]>>(iterable: T): unknown[][] {
  const iterator = iterable[Symbol.iterator]()
  const items: unknown[][] = []
  let i = 0
  do {
    const results = iterator.next()
    if (results.done) break
    if (!Array.isArray(results.value)) throw new TypeError(`Expected an array`)

    for (let j = 0; j < results.value.length; j++) {
      const item = (items[j] ??= [])
      item[i] = results.value[j]
    }

    i++
  } while (true)

  return items
}

export function* concat<T = any>(
  ...iterables: Array<undefined | Iterable<T>>
): Generator<T, void, undefined> {
  for (const it of iterables) if (it) yield* it
}

export const first = <T>(iterable: Iterable<T>): T | undefined => {
  for (const item of iterable) return item
  return undefined
}

export const join = (sep: string) => {
  return <T = any>(iterable: Iterable<T>) => {
    // Let's assume that Array.join is better than this implementation
    if (Array.isArray(iterable)) return iterable.join(sep)
    let str = ''
    let gotOne = false
    for (const item of iterable) {
      str += gotOne ? sep + String(item) : String(item)
      gotOne = true
    }
    return str
  }
}

export type FilterFn<T = any> = (this: any, value: T, index: number, it: Iterable<T>) => boolean

export const filter = <F extends FilterFn = FilterFn>(fn: F) => {
  return (
    iterable: Iterable<Parameters<F>[0]>,
    thisArg?: ThisParameterType<F>
  ): Iterable<Parameters<F>[0]> => ({
    *[Symbol.iterator]() {
      let i = 0
      for (const item of iterable) if (fn.call(thisArg, item, i++, iterable)) yield item
    },
  })
}

export const createFilter = <F extends FilterFn>(fn: F) =>
  function* (
    this: ThisParameterType<F>,
    subject: Iterable<Parameters<F>[0]>
  ): Generator<Parameters<F>[0], void, undefined> {
    let i = 0
    for (const item of subject) if (fn.call(this, item, i++, subject)) yield item
  }

export type MapperFn<T = any, R = any> = (this: any, value: T, index: number, it: Iterable<T>) => R

export const createMap = <F extends MapperFn = MapperFn>(fn: F) =>
  function* (
    this: ThisParameterType<F>,
    subject: Iterable<Parameters<F>[0]>
  ): Generator<ReturnType<F>, void, undefined> {
    let i = 0
    for (const item of subject) yield fn.call(this, item, i++, subject)
  }

export const createPartition =
  <T, P>(getter: (v: T) => P) =>
  (iterable: Iterable<T>): Iterable<[P, Iterable<T>]> => ({
    *[Symbol.iterator]() {
      const partitions = new Map<P, T[]>()
      for (const item of iterable) {
        const key = getter(item)
        const list = partitions.get(key) ?? []
        list.push(item)
        partitions.set(key, list)
      }

      yield* partitions
    },
  })

export const partition = <T, F extends FilterFn<T>>(
  iterable: Iterable<T>,
  predicate: F,
  thisArg?: ThisParameterType<F>
): [T[], T[]] => {
  const trueArray: T[] = []
  const falseArray: T[] = []
  let i = 0

  for (const item of iterable) {
    const result = predicate.call(thisArg, item, i++, iterable)

    if (result) trueArray.push(item)
    else falseArray.push(item)
  }

  return [trueArray, falseArray]
}

export type NonStringIterable<T> = T extends string ? never : Iterable<T>

export const isIterable = <T>(subject?: any): subject is Iterable<T> =>
  subject != null && typeof subject[Symbol.iterator] === 'function'

export const isIterableObject = <T>(subject?: any): subject is NonStringIterable<T> =>
  typeof subject === 'object' && isIterable(subject)

function* neverYield(): Generator<never> {}

export const asIterable = <T>(
  subject: T
): T extends null | undefined
  ? Iterable<never>
  : T extends Promise<any> | AsyncIterable<any>
  ? never
  : T extends NonStringIterable<infer I>
  ? Iterable<I>
  : Iterable<T> => {
  if (subject === null || subject === undefined) {
    return { [Symbol.iterator]: neverYield } as any
  }

  if ((subject as any)[Symbol.asyncIterator]) {
    throw new TypeError('Unable to convert asyncIterator to iterable')
  }

  if (typeof (subject as any).then === 'function') {
    throw new TypeError('Unable to convert promise to iterable')
  }

  if (isIterableObject(subject)) return subject as any

  return {
    *[Symbol.iterator]() {
      yield subject
    },
  } as any
}

const normalize = (term?: string | null) =>
  term ? stripLatinAccents(term).trim().toLowerCase() : ''

export const tsFilter = <F extends (i: any) => Iterable<string>>(termGenerator: F) =>
  function* (
    this: ThisParameterType<F>,
    iterable?: Iterable<Parameters<F>[0]>,
    search?: string | null
  ): Generator<Parameters<F>[0], void, undefined> {
    if (!iterable) return
    if (!search) return yield* iterable

    const normalized = normalize(search).split(/\s+/g)

    /* eslint-disable no-labels */
    nextItem: for (const item of iterable) {
      nextTerm: for (const needle of normalized) {
        for (const term of termGenerator.call(this, item)) {
          if (term && normalize(term).includes(needle)) continue nextTerm
        }
        // needle not found in any term
        continue nextItem
      }

      yield item
    }
    /* eslint-enable no-labels */
  }

export const digFor = <T>(predicate: (k: any) => k is T, followVein = false) => {
  return function* digger(subject: any): Generator<T, void, undefined> {
    if (predicate(subject)) {
      yield subject
      if (!followVein) return
    }

    if (subject === null || typeof subject !== 'object') return

    if (isIterable(subject)) {
      for (const item of subject) yield* digger(item)
    } else {
      for (const key in subject) yield* digger(subject[key])
    }
  }
}
