export const date = 'Fecha no válida.'
export const email = 'Dirección de correo electrónico no válida.'
export const iban = 'Número IBAN no válido.'
export const invalid = 'Valor no válido.'
export const invalidFileType = 'Tipo de archivo no válido.'
export const maxCount = 'Este campo debe contener como máximo {0} elementos.'
export const maxDate = 'Introduce una fecha anterior a {0}.'
export const maxDecimals =
  'Este valor debe contener como máximo {0} decimales. Asegúrate de no usar un separador de miles.'
export const maxFileSize = 'Selecciona un archivo menor que {0}.'
export const maxFilesSize = 'Todos los archivos deben ser menores que {0}.'
export const maxLength = 'Este valor debe tener menos de {0} caracteres.'
export const maxValue = 'Este valor debe ser menor o igual a {0}.'
export const minCount = 'Este campo debe contener al menos {0} elementos.'
export const minDate = 'Introduce una fecha posterior a {0}.'
export const minFileSize = 'Selecciona un archivo mayor que {0}.'
export const minFilesSize = 'Todos los archivos deben ser mayores que {0}.'
export const minLength = 'Este valor debe tener más de {0} caracteres.'
export const minValue =
  'Este valor debe ser mayor o igual a {0}. Asegúrate de no usar un separador de miles.'
export const pattern = 'Este valor no respeta el formato correcto.'
export const phone = 'Número de teléfono no válido.'
export const phoneCountry = 'Número de teléfono {0} no válido.'
export const phoneFixedLine = 'Número de teléfono fijo no válido.'
export const phoneIntl = 'Asegúrate de empezar con el código de país +xx.'
export const phoneMobile = 'Número de móvil no válido.'
export const required = 'Se requiere un valor.'
export const requiredButton = 'Haz clic en este botón.'
export const requiredCard = 'Haz clic en {0}.'
export const requiredChoice = 'Elige uno.'
export const requiredChoices = 'Elige uno o más.'
export const requiredDate = 'Se requiere una fecha válida.'
export const requiredDatetime = 'Se requiere una fecha y hora válidas.'
export const requiredDownload = 'Todos los archivos deben ser descargados para validar este paso.'
export const requiredFile = 'Se requiere un archivo.'
export const requiredFileMultiple = 'Proporcione al menos un archivo.'
export const requiredSignature = 'Firme, por favor.'
export const requiredRating = 'Elige un valor entre 1 y 5.'
