import { isObjectLike, objectGet } from '@penbox-io/stdlib'

import type { FlowScope, RequestScope } from '../types/index.js'
import type { ValueGetter } from './engine.js'

const noopGetter: ValueGetter = () => undefined

/**
 * The return value of this function is logically equivalent to the following
 * example but performs the following additional tasks:
 * - Namespace validation (all form keys must start with either "data.", "user.", etc.)
 * - Legacy support ("data." used to be optional)
 *
 * @example Logically equivalent to:
 * ```js
 * return (key) => objectGet(scope, key)
 * ```
 */
export function createValueGetter<S extends FlowScope | RequestScope>(
  scope: S,
  allowedNamespaces: ReadonlyArray<Extract<keyof S, string>>,
  defaultNamespace?: Extract<keyof S, string>
): ValueGetter {
  if (!scope) return noopGetter

  // Optimization: no readable namespace in scope
  const scopeNamespaces = allowedNamespaces.filter((ns) => isObjectLike(scope[ns]))
  const { length } = scopeNamespaces
  if (length === 0) return noopGetter

  const defaultScope =
    defaultNamespace != null && scopeNamespaces.includes(defaultNamespace)
      ? scope[defaultNamespace]
      : null

  const hasDefaultScope = defaultScope != null

  const isAllowedNs = (ns: unknown): ns is keyof S =>
    (scopeNamespaces as readonly unknown[]).includes(ns)

  // Optimization: only scan the key prefix for the namespace
  if (length <= 3) {
    const ns0 = scopeNamespaces[0]!
    const ns0len = ns0.length
    const ns0obj = scope[ns0]

    const ns1 = scopeNamespaces[1]
    const ns1present = ns1 !== undefined
    const ns1len = ns1 ? ns1.length : null
    const ns1obj = ns1 ? scope[ns1] : null

    const ns2 = scopeNamespaces[2]
    const ns2present = ns2 !== undefined
    const ns2len = ns2 ? ns2.length : null
    const ns2obj = ns2 ? scope[ns2] : null

    return (key: string) => {
      // ns0 is always present
      if (key.charCodeAt(ns0len) === 46 && key.startsWith(ns0)) {
        return objectGet(ns0obj, key.slice(ns0len + 1))
      }

      // if (const) should be optimized away by jit-compiler
      if (ns1present) {
        if (key.charCodeAt(ns1len!) === 46 && key.startsWith(ns1)) {
          return objectGet(ns1obj!, key.slice(ns1len! + 1))
        }
      }

      // if (const) should be optimized away by jit-compiler
      if (ns2present) {
        if (key.charCodeAt(ns2len!) === 46 && key.startsWith(ns2)) {
          return objectGet(ns2obj!, key.slice(ns2len! + 1))
        }
      }

      // if (const) should be optimized away by jit-compiler
      if (hasDefaultScope) {
        if (!key.includes('.')) {
          // Not using data[key] because objectGet:
          // - protects against __proto__ and other prototype pollution
          // - will not return functions
          return objectGet(defaultScope, key)
        }
      }

      return undefined
    }
  }

  // Hot path.
  return (key: string) => {
    const dot = key.indexOf('.')
    if (dot !== -1) {
      const ns = key.slice(0, dot)

      if (isAllowedNs(ns)) {
        // Optimization: because we already assessed that the namespace exists in
        // the scope, we can skip the first loop in objectGet.

        // return objectGet(scope, key)
        return objectGet(scope[ns], key.slice(dot + 1))
      } else {
        return undefined
      }
    } else if (hasDefaultScope) {
      // if (const) should be optimized away by jit-compiler

      // Not using data[key] because objectGet:
      // - protects against __proto__ and other prototype pollution
      // - will not return functions
      return objectGet(defaultScope, key)
    } else {
      return undefined
    }
  }
}
