import { createMethodLegacySimple } from '../legacy'
import { computeMultiply } from './multiply'

/**
 * ALIAS for `multiply`
 * Returns the product of all numbers in the input array.
 * @usage: ```{":product": array}```
 * where:
 * - `array` is an array of numbers
 *
 * @example
 * - ```{":product": [1, 2, 3]}``` returns `6`
 */
export default createMethodLegacySimple(':product', computeMultiply)
