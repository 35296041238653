import Vue from 'vue'
import VueI18n from 'vue-i18n'

import fallbackMessage from '~/locales/en.js'

Vue.use(VueI18n)

const supportedLocales = JSON.parse('["fr","en","nl","de","bg","cz","dk","ee","fi","hu","lv","lt","pl","ro","sk","es","se","ua"]')
const fallbackLocale = 'en'

// Fool proof
if (!supportedLocales.includes(fallbackLocale)) supportedLocales.push(fallbackLocale)

const negotiate = (preferredLocales) => {
  for (const locale of preferredLocales) {
    if (typeof locale !== 'string') continue

    if (supportedLocales.includes(locale)) {
      return locale
    }

    const lang = locale.split('-', 1)[0]
    if (lang !== locale && supportedLocales.includes(lang)) {
      return lang
    }
  }
  return null
}

const storeModule = {
  namespaced: true,
  state: () => ({
    locale: null,
    browserLocale: null,
    messages: {
      /**
       * Since "fallbackMessage" will be bundled with the JS, let's avoid adding
       * these strings to the state, as this would result in higher memory use
       * when SSR pre-populates the state with fallback message.
       */
      // [fallbackLocale]: fallbackMessage,
    },
  }),
  getters: {
    locale: (state, getters) => state.locale || state.browserLocale || fallbackLocale,
    messages: (state, getters) => ({ ...state.messages, [fallbackLocale]: fallbackMessage }),
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale ? negotiate([locale]) : null
    },
    setBrowserLocales(state, locales) {
      state.browserLocale = negotiate(locales)
    },
    setLocaleMessage(state, { locale, message }) {
      Vue.set(state.messages, locale, message)
    },
  },
  actions: {
    async setLocale({ commit, dispatch, state }, locale = null) {
      if (locale === state.locale) return
      try {
        if (locale) await dispatch('loadMessage', locale)
      } finally {
        commit('setLocale', locale)
      }
    },
    async loadMessage({ commit, state }, locale = null) {
      if (!locale) return
      if (locale === fallbackLocale) return
      if (state.messages[locale]) return

      try {
        const { default: message } = await import(
          /* webpackChunkName: "locales-[request]" */
          `~/locales/${locale}.js`
        )
        commit('setLocaleMessage', { locale, message })
      } catch (err) {
        if (supportedLocales.includes(locale)) throw err
      }
    },
  },
}

export default async function i18nPlugin({ app, store }, inject) {
  const ns = 'i18n'

  store.registerModule(ns, storeModule, {
    preserveState: store.state[ns] !== undefined,
  })

  if (!store.state[ns].browserLocale) {
    if (process.server) {
      console.warn('Server side locale negotiation not implemented')

      // store.commit(`${ns}/setBrowserLocales`, locale)
    }

    if (process.client) {
      const browserLocales = navigator.languages || [navigator.userLanguage, navigator.language]
      store.commit(`${ns}/setBrowserLocales`, browserLocales)
    }
  }

  const locale = store.getters[`${ns}/locale`]

  await store.dispatch(`${ns}/loadMessage`, locale)

  const messages = store.getters[`${ns}/messages`]

  // Create i18n with locale & messages from store
  const i18n = new VueI18n({ locale, fallbackLocale, messages })

  Object.defineProperty(i18n, 'supportedLocales', {
    value: supportedLocales,
    enumerable: false,
    configurable: true,
    writable: false,
  })

  // Keep i18n in sync with locale & messages from store
  store.watch(
    (state, getters) => getters[`${ns}/locale`],
    (locale) => {
      i18n.locale = locale
    }
  )

  store.subscribe(({ type, payload }, state) => {
    if (type === `${ns}/setLocaleMessage`) {
      const { locale, message } = payload
      i18n.setLocaleMessage(locale, message)
    }
  })

  // Keep store in sync with i18n. Note that changing i18n.locale
  // might cause a "x => fallback => y" transition when switching
  // to an unloaded locale y. Use i18n.setLocale() to avoid this
  // effect by loading the locale before updating i18n.locale.
  i18n.vm.$watch('locale', (locale) => {
    store.dispatch(`${ns}/setLocale`, locale)
  })

  app.i18n = i18n
  app.i18n.setLocale = async (locale) => {
    await store.dispatch(`${ns}/setLocale`, locale)
  }
}
