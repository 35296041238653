import { createMethod } from '@penbox-io/json-expression'
import { parseLocale } from '@penbox-io/stdlib'

/** @typedef {import('@penbox-io/json-expression').Scope} Scope */
/** @typedef {import('@penbox-io/json-expression').MethodCall} MethodCall */

const FALLBACK_LOCALE = 'en'

const asString = (val) => {
  switch (typeof val) {
    case 'number':
      return String(val)
    case 'string':
      return val
    default:
      return ''
  }
}

const PLACEHOLDER_REGEXP = /\$(\$|\d+)/g

/** @typedef {{ ":i18n": string | { [iso: string]: string }; ":else"?: string; ':values'?: string[]; override?: any }} Type */

export default createMethod({
  name: ':i18n',
  /**
   * @param {MethodCall} expr
   * @return {expr is Type}
   */
  test(expr) {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':i18n' || key === ':else' || key === ':values' || key === ':override') continue
      return false
    }

    if (typeof expr[':i18n'] === 'string' && expr[':i18n'] === '') return false
    if (typeof expr[':i18n'] === 'object') {
      if (Array.isArray(expr[':i18n'])) {
        return false
      }
      if (Object.keys(expr[':i18n']).length === 0) {
        return false
      }
    }

    if (typeof expr[':else'] !== 'undefined' && typeof expr[':else'] !== 'string') return false
    if (typeof expr[':values'] !== 'undefined' && !Array.isArray(expr[':values'])) return false

    return true
  },
  /**
   * @param {Type} expr
   * @return {string | null}
   */
  evaluate(expr) {
    const { scope } = this
    if (!scope) return null

    const isV2 = typeof expr[':i18n'] === 'object'

    if (scope.options?.['pnbx:i18n:debug'] === true) return expr[':i18n']

    let message

    if (!isV2) {
      message =
        this.evaluate(expr[':override']) ??
        stringPathGetter(scope.$strings, scope.$locale, expr[':i18n']) ??
        expr[':else']
    } else {
      const parsedLocal = parseLocale(scope.$locale)
      if (parsedLocal) {
        message = expr[':i18n'][parsedLocal[0]] ?? expr[':else']
      }
    }

    if (typeof message !== 'string') return null

    const values = expr[':values']
    if (!isV2 && values && message.includes('$')) {
      const length = values.length

      let replacementsCacheLazy

      message = message.replace(PLACEHOLDER_REGEXP, (_, pos) => {
        if (pos === '$') return '$'

        const index = Number(pos)

        // Optimization
        if (!(index < length)) return ''

        return ((replacementsCacheLazy ??= [])[index] ??= asString(this.evaluate(values[index])))
      })
    }

    if (!this.config.strict && message.includes('{')) {
      const result = this.evaluateString(message)
      if (result == null) return null // Fool-proof

      message = asString(result)
    }

    return message
  },
  /**
   * @param {Type} expr
   * @todo Test this
   */
  compile(expr) {
    const isV2 = typeof expr[':i18n'] === 'object'
    const i18nKey = expr[':i18n']
    const arrayPath = !isV2 ? i18nKey.split('.') : []
    const fallbackMessage = expr[':else'] ?? null
    const overrideMessage =
      expr[':override'] != null ? this.createContext(':override').compile(expr[':override']) : null

    /** @type {(scope: Scope) => string | null} */
    let messageGetter = (scope) => {
      if (!scope) return null
      let message = null

      if (isV2) {
        const parsedLocal = parseLocale(scope.$locale)

        if (parsedLocal) {
          message = expr[':i18n'][parsedLocal[0]] ?? expr[':i18n'][scope.$defaultLocale]
        }
      } else {
        message =
          overrideMessage?.(scope) ??
          arrayPathGetter(scope.$strings, scope.$locale, arrayPath) ??
          fallbackMessage
      }

      if (typeof message !== 'string') return null

      return message
    }

    if (!isV2 && expr[':values']?.length) {
      const length = expr[':values'].length
      const values = expr[':values'].map((value, i) =>
        this.createContext(':values', i).compile(value)
      )

      const orig = messageGetter
      messageGetter = (scope) => {
        const message = orig(scope)
        if (!message) return message

        // Optimization
        if (!message.includes('$')) return message

        let replacementsCacheLazy

        return message.replace(PLACEHOLDER_REGEXP, (_, pos) => {
          if (pos === '$') return '$'

          const index = Number(pos)

          // Optimization
          if (!(index < length)) return ''

          return ((replacementsCacheLazy ??= [])[index] ??= asString(values[index]?.(scope)))
        })
      }
    }

    if (!this.config.strict) {
      const { evaluator } = this
      const orig = messageGetter
      messageGetter = (scope) => {
        const message = orig(scope)
        if (!message) return message

        // Optimization
        if (!message.includes('{')) return message

        const result = evaluator.evaluateString(message, scope)
        if (result == null) return null

        return asString(result)
      }
    }

    // Re-define as const to allow compilier optimizations
    const orig = messageGetter
    return (scope) => {
      if (scope.options?.['pnbx:i18n:debug'] === true) return i18nKey
      return orig(scope)
    }
  },
})

/** @type {(strings: object, locale: unknown, string: string, fallbackLocale?: unknown) => (string | null)} */
export const translateString = (strings, locale, string, fallbackLocale) => {
  if (typeof string === 'string') {
    if (string.startsWith('__I18N_') && string.endsWith('__')) {
      const key = string.slice(7, -2)
      return stringPathGetter(strings, locale, key, fallbackLocale) ?? ''
    }
  }

  return string
}

/**
 * @template {(strings: object) => string | null} T
 * @param {T} keyFinder
 */
const createGetter = (keyFinder) => {
  /**
   * @param {unknown} strings
   * @param {unknown} locale
   * @param {ThisParameterType<T>} strKey
   * @param {unknown} [fallbackLocale]
   * @return {string | null}
   */
  return (strings, locale, strKey, fallbackLocale = FALLBACK_LOCALE) => {
    if (typeof strings !== 'object' || strings === null) return null

    const parsedLocale = parseLocale(locale)
    if (parsedLocale) {
      const [lang, country] = parsedLocale
      const normalizedLocale = country ? `${lang}_${country}` : undefined

      if (normalizedLocale && strings[normalizedLocale]) {
        const message = keyFinder.call(strKey, strings[normalizedLocale])
        if (message !== null) return message
      }

      if (lang && strings[lang]) {
        const message = keyFinder.call(strKey, strings[lang])
        if (message !== null) return message
      }
    }

    if (locale !== fallbackLocale && typeof fallbackLocale === 'string') {
      if (strings[fallbackLocale]) {
        const message = keyFinder.call(strKey, strings[fallbackLocale])
        if (message !== null) return message
      }
    }

    return null
  }
}

const stringPathGetter = createGetter(
  /**
   * @this {string}
   * @type {(this: string, obj: object) => string | null}
   */
  function stringPathGetter(obj) {
    const { length } = this

    let pos = 0
    let prop
    let char
    let type

    do {
      prop = ''
      while (pos < length) {
        char = this[pos++]
        if (char === '.') break
        prop += char
      }

      obj = obj[prop]
      type = typeof obj

      if (pos === length) {
        if (type === 'string') return /** @type {string} */ (obj)

        // For i18n with count ?
        // if (Array.isArray(obj)) return obj

        return null
      }

      if (obj === null || type !== 'object') {
        return null
      }

      // For i18n with count ?
      // if (Array.isArray(obj)) return null
    } while (true)
  }
)

const arrayPathGetter = createGetter(
  /**
   * @this {string[]}
   * @type {(this: string[], obj: object) => string | null}
   */
  function (obj) {
    const { length } = this
    if (length === 0) return null

    let last = obj
    let i = 0
    let value

    while (i < length) {
      value = last[this[i]]
      if (value == null) return null

      last = value
      i++
    }

    if (typeof last === 'string') return last

    return null
  }
)
