import { createMethod, isArray, isArrayExpression } from '../util'
import { subScope } from '../scope'
import { JsonValue, MethodSignature, Scope, Value } from '../types'

type Type = {
  ':reduce': JsonValue
  ':using': JsonValue
  ':initial': JsonValue
}

/**
 * Reduces an array to a single value.
 * @usage: ```{ ':reduce': array, ':using': expression, ':initial': value }```
 * where:
 * - array: any: the array to reduce
 * - using: expression: the expression to evaluate for each item
 * - initial: any: the initial value
 *
 * @sub-scope variables
 * - `@item` the current item being processed
 * - `@index` the current index
 * - `@position` the current position
 * - `@acc` the current accumulator
 *
 * @example
 * - ```{":reduce": [1, 2, 3], ":using": {":sum": ["{@acc}", "{@item}"]}, ":initial": 0}``` returns `6`
 */
export default createMethod<Type>({
  name: ':reduce',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue

      if (key === ':initial') continue
      if (key === ':using') continue
      if (key === ':reduce') {
        if (isArrayExpression(expr[':reduce'])) continue
        return false
      }

      return false
    }

    if (expr[':initial'] === undefined) return false
    if (expr[':using'] === undefined) return false
    if (expr[':reduce'] === undefined) return false

    return true
  },

  evaluate(expr): undefined | Value {
    const initial = this.evaluate(expr[':initial'])
    const array = this.evaluate(expr[':reduce'])
    if (!isArray(array)) return undefined
    if (array.length === 0) return initial

    // Optimization: Create a re-usable child context
    const ctx = this.subScope({
      '@item': undefined as Value,
      '@index': -1,
      '@position': 0,
      '@acc': undefined as Value,
    })

    let acc = initial

    for (let i = 0; i < array.length; i++) {
      ctx.scope['@item'] = array[i]
      ctx.scope['@index'] = i
      ctx.scope['@position'] = i + 1
      ctx.scope['@acc'] = acc

      acc = ctx.evaluate(expr[':using'])
    }

    return acc
  },

  compile(expr) {
    const reduce$ = this.createContext(':reduce').compile(expr[':reduce'])
    const initial$ = this.createContext(':initial').compile(expr[':initial'])
    const using$ = this.createContext(':using').compile(expr[':using'])

    return (scope: Scope) => {
      const array = reduce$(scope)
      if (!isArray(array)) return undefined

      let acc = initial$(scope)

      // Optimization: Create a re-usable child scope instead of one per iteration
      const childScope = subScope(scope, {
        '@item': undefined as Value,
        '@index': -1,
        '@position': 0,
        '@acc': acc,
      })

      for (let i = 0; i < array.length; i++) {
        childScope['@item'] = array[i]
        childScope['@index'] = i
        childScope['@position'] = i + 1
        childScope['@acc'] = acc

        acc = using$(childScope)
      }

      return acc
    }
  },
})
