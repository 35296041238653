import { ifString } from '@penbox-io/stdlib'

import { FormError, ifFormHelpable, type Dict } from '../../common'
import { GenericElement, type Definition } from '../../core'
import { buildError } from '../utils/error'
import * as countries from 'i18n-iso-countries'

import {
  type Value,
  type Options as ChoiceOptions,
  parse,
  normalize,
  stringify,
  stringifyTitle,
} from '../partials/choice.js'

type Options<V extends Value = Value> = ChoiceOptions<V> & {
  hint?: string
  placeholder?: string
  readonly?: boolean
}
type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
  localize,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  countries.registerLocale(require(`i18n-iso-countries/langs/${locale}.json`))
  const c = countries.getNames(locale)

  return {
    label: ifFormHelpable(input.label),
    submitOnChange: input.submit_on_change === true,
    hint: ifString(input.hint),
    placeholder: ifString(input.placeholder),
    readonly: input.readonly === true,
    choices: Object.keys(c)
      .map((key) => ({ value: key, label: c[key] }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  }
}

function localize(element: Element, locale: string): string | undefined {
  if (!element.value) return undefined
  if (typeof element.value !== 'string') return undefined

  return countries.getName(element.value, locale)
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (value === null) {
    if (required) return buildError('requiredChoices', locale)
    return null
  }

  if (typeof value !== 'string') {
    return buildError('invalid', locale)
  }

  if (value?.length !== 2) {
    return buildError('invalid', locale)
  }

  if (!countries.isValid(value)) {
    return buildError('invalid', locale)
  }

  return null
}
