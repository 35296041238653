import { asError, Error } from './error.js'

export async function invokeAsync<F extends (...args: any[]) => any>(
  this: ThisType<F>,
  f: F,
  ...args: Parameters<F>
): Promise<ReturnType<F>> {
  return f.call(this, ...args)
}

export const negate = <T extends (this: any, ...args: any[]) => boolean>(f: T) =>
  function (this: ThisParameterType<T>, ...args: Parameters<T>): boolean {
    return !f.call(this, ...args)
  }

export const createOnceGetter = <T>(value: T, { debug = false } = {}) => {
  let state: { done: true; trace?: Error } | { done: false; value: T } = { done: false, value }

  const getter = (): T => {
    if (state.done) {
      throw new Error('getter called more than once', { cause: state.trace })
    }

    const { value } = state

    const trace = debug ? new Error('getter initially called here') : undefined
    if (trace) Error.captureStackTrace?.(trace, getter)

    state = { done: true, trace }
    return value
  }

  // Object.defineProperty(getter, 'done', { get: () => state.done })

  return getter
}

export const once = <F extends (this: any, ...args: any[]) => any>(initializer: F) => {
  if (typeof initializer !== 'function') throw new TypeError('Invalid use of "once"')

  let init: F | null = initializer
  let val: ReturnType<F>

  const getter = function (this: ThisParameterType<F>, ...args: Parameters<F>): ReturnType<F> {
    if (init) {
      val = init.call(this, ...args)
      init = null
    }
    return val
  }

  Object.defineProperty(getter, 'loaded', {
    get: () => init === null,
  })

  return getter as F & { loaded: boolean }
}

/** @deprecated use {@link once} instead */
export const lazy = once

export function safeWrap<F extends (this: any, ...args: any[]) => any>(
  fn: F
): (this: ThisParameterType<F>, ...args: Parameters<F>) => ReturnType<F> | undefined

export function safeWrap<
  F extends (this: any, ...args: any[]) => any,
  H extends (this: ThisParameterType<F>, err: Error, args: Parameters<F>) => any
>(
  fn: F,
  handler: H
): (this: ThisParameterType<F>, ...args: Parameters<F>) => ReturnType<F> | ReturnType<H>

export function safeWrap<F extends (this: any, ...args: any[]) => any>(
  fn: F,
  handler?: (this: ThisParameterType<F>, err: Error, args: Parameters<F>) => any
): (
  this: ThisParameterType<F>,
  ...args: Parameters<F>
) => ReturnType<F> | ReturnType<NonNullable<typeof handler>> | undefined {
  return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    try {
      return fn.call(this, ...args)
    } catch (err) {
      if (handler) return handler.call(this, asError(err), args)

      return undefined
    }
  }
}

type Cancel = () => boolean
type Flush<F extends (...args: any[]) => any> = () => undefined | ReturnType<F>

export type Debounced<F extends (...args: any[]) => any> = ((
  this: ThisParameterType<F>,
  ...args: Parameters<F>
) => void) & {
  force: F
  cancel: Cancel
  flush: Flush<F>
}

export const debounce = <F extends (...args: any[]) => any>(
  fn: F,
  delay: number,
  immediate = false
): Debounced<F> => {
  let current: {
    callback: null | (() => ReturnType<F>)
    tid: ReturnType<typeof setTimeout>
  } | null = null

  const flush: Flush<F> = () => {
    if (current) {
      const { callback, tid } = current
      current = null
      clearTimeout(tid)
      if (callback) return callback()
    }

    return undefined
  }

  const cancel: Cancel = () => {
    if (current) {
      clearTimeout(current.tid)
      current = null
      return true
    }

    return false
  }

  const force: F = function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    cancel()
    return fn.call(this, ...args)
  } as F

  const debounced = function (this: ThisParameterType<F>, ...args: Parameters<F>) {
    if (!current && immediate) {
      // Setting up a "fake" current object to cause the next call (within delay) to be debounced
      current = { callback: null, tid: setTimeout(cancel, delay) }

      fn.call(this, ...args)

      return
    }

    cancel()
    current = { callback: fn.bind(this, ...args), tid: setTimeout(flush, delay) }
  }

  return Object.assign(debounced, { cancel, force, flush })
}

export const trivialMemCache = <T, R>(gen: (k: T) => R): ((k: T) => R) => {
  const cache = new Map<T, R>()
  return (key: T): R => {
    if (!cache.has(key)) cache.set(key, gen(key))
    return cache.get(key) as R
  }
}

export function createTrickle(options: { frequency: number } | { delay: number }) {
  const delay = 'delay' in options ? options?.delay : 1e3 / options.frequency
  if (!(delay > 0)) throw new TypeError(`delay/frequency must be positive`)

  let next = 0

  const trickle = () =>
    new Promise<void>((resolve) => {
      const now = Date.now()
      if (next >= now) {
        setTimeout(resolve, next - now)
        next += delay
      } else {
        resolve()
        next = now + delay
      }
    })

  trickle.postpone = (amount: Date | number) => {
    next = amount instanceof Date ? amount.getTime() : Date.now() + amount
  }

  return trickle
}
