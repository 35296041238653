import { compileTransforms, ifString, isArray, parseRegexp, Transform } from '@penbox-io/stdlib'

import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'
import { parseNumber } from '../utils/number.js'
import { EMAIL_RE, textWrap } from '../utils/text.js'
import { validate as validatePhone } from '../partials/phone.js'

type Value = string

type Options = {
  label?: FormHelpable
  autocomplete?: string
  clearable: boolean
  format: 'text' | 'tel' | 'email'
  hint?: string
  mask?: string
  max?: number
  min?: number
  multiline: boolean
  pattern?: RegExp
  placeholder?: string
  prefix?: string
  suffix?: string
  transform?: Transform
  readonly?: boolean
}

type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  const min = parseNumber(input.min, 0)
  const max = parseNumber(input.max, min || 0)

  const format = parseFormat(input.format)

  return {
    autocomplete: ifString(input.autocomplete),
    clearable: input.clearable !== false,
    format,
    hint: ifString(input.hint),
    label: ifFormHelpable(input.label),
    mask: format === 'text' ? ifString(input?.mask) : undefined,
    max,
    min,
    readonly: input.readonly === true,
    multiline: format === 'text' && input.multiline === true,
    pattern: parseRegexp(input.pattern) || undefined,
    placeholder: ifString(input.placeholder),
    prefix: ifString(input.prefix),
    suffix: ifString(input.suffix),
    transform: !isArray(input.transforms) ? undefined : compileTransforms(...input.transforms),
  }
}

function parseFormat(input: unknown): Options['format'] {
  switch (input) {
    case 'tel':
    case 'email':
      return input
    // case 'text':
    default:
      return 'text'
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  switch (typeof value) {
    case 'number':
      return Number.isFinite(value) ? value.toString() : null
    case 'string':
      return value || null
    default:
      return null
  }
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (value === null) {
    if (required) return buildError('required', locale)
    return null
  }

  const { min } = options
  if (min != null && value.length < min) {
    return buildError('minLength', locale, [min])
  }

  const { max } = options
  if (max != null && value.length > max) {
    return buildError('maxLength', locale, [max])
  }

  if (options.pattern && !options.pattern.test(value)) {
    return buildError('pattern', locale, [options.pattern])
  }

  // TODO: validate mask pattern ?
  // if (options.mask) {}

  if (options.format === 'tel') {
    const error = validatePhone({}, locale, value, false)
    if (error) return error
  }

  if (options.format === 'email' && !EMAIL_RE.test(value)) {
    return buildError('email', locale)
  }

  return null
}

function normalize(element: Element): undefined | Value {
  if (element.value == null) return undefined
  return element.value || undefined
}

function stringify(element: Element): string | undefined {
  if (element.value == null) return undefined

  const text = element.value

  return textWrap(text, element.options)
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}
