import { createMethodLegacySimple } from '../legacy'
import { Value } from '../types'
import { isArray } from '../util'

/**
 * Flatten an array.
 *
 * @usage: ```{ ':flatten': array }```
 * where:
 * - array: any[]: the array to flatten
 *
 * @example
 * ```{ ":flatten": [[1, [2, [3, 4], 5], 6]] }``` => [1, 2, 3, 4, 5, 6]
 *
 */
export default createMethodLegacySimple(':flatten', function (args): undefined | Value[] {
  const result = this.evaluate(args)
  if (!isArray(result)) return undefined
  // @ts-expect-error https://github.com/microsoft/TypeScript/issues/49280
  return result.flat(Infinity)
})
