export default {
  en: {
    title: 'Help & Support',
    contact_title: 'Contact',
    contact_text:
      'If you have any questions regarding the use of this form, you can contact us using the contact details below:',
    decline_text:
      'You do not wish to answer this form and cancel any future reminders? Click the button below to let us know.',
    decline_btn: 'Decline',
    warning_title: 'Are you sure?',
    warning_subtitle: "You can finish it later if you don't have the time right now.",
    warning_confirm: "Yes I'm sure.",
    warning_cancel: 'No',
  },
  fr: {
    title: 'Aide & Support',
    contact_title: 'Contact',
    contact_text:
      "Si vous avez des questions quant à l'utilisation de ce formulaire, vous pouvez nous contacter en utilisant les informations de contact ci-dessous:",
    decline_text:
      'Vous ne désirez pas répondre à ce formulaire et annuler tout rappel futur? Cliquez sur le bouton ci-dessous afin de nous le notifier.',
    decline_btn: 'Refuser',
    warning_title: 'Êtes-vous certain?',
    warning_subtitle: "Vous pouvez le terminer plus tard si vous n'avez pas le temps maintenant.",
    warning_confirm: "Oui, l'annuler",
    warning_cancel: 'Non, continuer plus tard',
  },
  nl: {
    title: 'Hulp & Ondersteuning',
    contact_title: 'Contact',
    contact_text:
      'Als u vragen heeft over het gebruik van dit formulier, kunt u contact met ons opnemen via onderstaande gegevens:',
    decline_text:
      'Wilt u dit formulier niet beantwoorden en geen toekomstige herinneringen ontvangen? Klik op de knop hieronder om ons dit te laten weten.',
    decline_btn: 'Weiger',
    warning_title: 'Weet je zeker dat?',
    warning_subtitle: 'Je kunt het later afmaken als je nu geen tijd hebt.',
    warning_confirm: 'Ja ik weet het zeker.',
    warning_cancel: 'Nee',
  },
  de: {
    title: 'Hilfe & Unterstützung',
    contact_title: 'Contact',
    contact_text:
      'Wenn Sie Fragen zur Verwendung dieses Formulars haben, können Sie uns über die untenstehenden Informationen kontaktieren:',
    decline_text:
      'Möchten Sie dieses Formular nicht beantworten und alle zukünftigen Erinnerungen abbrechen? Klicken Sie auf die Schaltfläche unten, um uns zu mitteilen.',
    decline_btn: 'Ablehnen',
    warning_title: 'Bist du sicher?',
    warning_subtitle: 'Sie können es später beenden, wenn Sie gerade keine Zeit haben.',
    warning_confirm: 'Ja, ich bin mir sicher.',
    warning_cancel: 'Nein',
  },

  bg: {
    title: 'Помощ и подкрепа',
    contact_title: 'Контакт',
    contact_text:
      'Ако имате въпроси относно използването на този формуляр, можете да се свържете с нас, използвайки контактните данни по-долу:',
    decline_text:
      'Не желаете да отговорите на този формуляр и да анулирате бъдещи напомняния? Щракнете върху бутона по-долу, за да ни уведомите.',
    decline_btn: 'Отказ',
    warning_title: 'Сигурни ли сте?',
    warning_subtitle: 'Можете да го завършите по-късно, ако нямате време в момента.',
    warning_confirm: 'Да, сигурен съм.',
    warning_cancel: 'Не',
  },
  fi: {
    title: 'Ohje ja tuki',
    contact_title: 'Ota yhteyttä',
    contact_text:
      'Jos sinulla on kysyttävää tämän lomakkeen käytöstä, voit ottaa meihin yhteyttä alla olevien yhteystietojen kautta:',
    decline_text:
      'Etkö halua vastata tähän lomakkeeseen ja peruuttaa tulevat muistutukset? Klikkaa alla olevaa painiketta ilmoittaaksesi meille.',
    decline_btn: 'Kieltäytyä',
    warning_title: 'Oletko varma?',
    warning_subtitle: 'Voit suorittaa sen myöhemmin, jos sinulla ei ole aikaa juuri nyt.',
    warning_confirm: 'Kyllä, olen varma.',
    warning_cancel: 'Ei',
  },
  cz: {
    title: 'Nápověda a podpora',
    contact_title: 'Kontakt',
    contact_text:
      'Máte-li jakékoliv otázky týkající se použití tohoto formuláře, můžete nás kontaktovat pomocí níže uvedených kontaktních údajů:',
    decline_text:
      'Nechcete na tento formulář odpovědět a zrušit budoucí připomínky? Klikněte na tlačítko níže, abyste nám to oznámili.',
    decline_btn: 'Odmítnout',
    warning_title: 'Jste si jisti?',
    warning_subtitle: 'Pokud nyní nemáte čas, můžete to dokončit později.',
    warning_confirm: 'Ano, jsem si jistý.',
    warning_cancel: 'Ne',
  },
  dk: {
    title: 'Hjælp og support',
    contact_title: 'Kontakt',
    contact_text:
      'Hvis du har spørgsmål vedrørende brugen af denne formular, kan du kontakte os via nedenstående kontaktinformation:',
    decline_text:
      'Ønsker du ikke at besvare denne formular og annullere fremtidige påmindelser? Klik på knappen nedenfor for at give os besked.',
    decline_btn: 'Afslå',
    warning_title: 'Er du sikker?',
    warning_subtitle: 'Du kan færdiggøre det senere, hvis du ikke har tid lige nu.',
    warning_confirm: 'Ja, jeg er sikker.',
    warning_cancel: 'Nej',
  },
  ee: {
    title: 'Abi ja tugi',
    contact_title: 'Kontakt',
    contact_text:
      'Kui teil on küsimusi selle vormi kasutamise kohta, võite meiega ühendust võtta alltoodud kontaktandmete kaudu:',
    decline_text:
      'Te ei soovi seda vormi vastata ja tulevasi meeldetuletusi tühistada? Klõpsake allpool olevat nuppu, et meile teada anda.',
    decline_btn: 'Keeldu',
    warning_title: 'Oled sa kindel?',
    warning_subtitle: 'Kui sul pole praegu aega, võid selle hiljem lõpetada.',
    warning_confirm: 'Jah, ma olen kindel.',
    warning_cancel: 'Ei',
  },
  hu: {
    title: 'Segítség és támogatás',
    contact_title: 'Kapcsolat',
    contact_text:
      'Ha kérdése van a nyomtatvány használatával kapcsolatban, a lenti elérhetőségeken léphet velünk kapcsolatba:',
    decline_text:
      'Nem kíván válaszolni erre az űrlapra és lemondani a jövőbeli emlékeztetőkről? Kattintson az alábbi gombra, hogy értesítsen minket.',
    decline_btn: 'Elutasít',
    warning_title: 'Biztos benne?',
    warning_subtitle: 'Ha most nincs időd, később is befejezheted.',
    warning_confirm: 'Igen, biztos vagyok benne.',
    warning_cancel: 'Nem',
  },
  lv: {
    title: 'Palīdzība un atbalsts',
    contact_title: 'Kontakti',
    contact_text:
      'Ja jums ir jautājumi par šīs veidlapas izmantošanu, varat sazināties ar mums, izmantojot zemāk norādītos kontaktus:',
    decline_text:
      'Vai nevēlaties atbildēt uz šo veidlapu un atcelt turpmākos atgādinājumus? Noklikšķiniet uz pogas zemāk, lai mums paziņotu.',
    decline_btn: 'Noraidīt',
    warning_title: 'Vai esat pārliecināts?',
    warning_subtitle: 'Ja jums tagad nav laika, varat to pabeigt vēlāk.',
    warning_confirm: 'Jā, es esmu pārliecināts.',
    warning_cancel: 'Nē',
  },
  lt: {
    title: 'Pagalba ir palaikymas',
    contact_title: 'Kontaktai',
    contact_text:
      'Jei turite klausimų dėl šios formos naudojimo, galite susisiekti su mumis naudodami žemiau pateiktus kontaktinius duomenis:',
    decline_text:
      'Nenorite atsakyti į šią formą ir atšaukti būsimus priminimus? Paspauskite mygtuką žemiau, kad praneštumėte mums.',
    decline_btn: 'Atsisakyti',
    warning_title: 'Ar jūs tikras?',
    warning_subtitle: 'Jeigu dabar neturite laiko, galite tai padaryti vėliau.',
    warning_confirm: 'Taip, aš tikras.',
    warning_cancel: 'Ne',
  },
  pl: {
    title: 'Pomoc i wsparcie',
    contact_title: 'Kontakt',
    contact_text:
      'Jeżeli masz pytania dotyczące korzystania z tego formularza, możesz się z nami skontaktować, korzystając z poniższych danych kontaktowych:',
    decline_text:
      'Nie chcesz odpowiedzieć na ten formularz i anulować przyszłe przypomnienia? Kliknij poniższy przycisk, aby nas o tym poinformować.',
    decline_btn: 'Odrzuć',
    warning_title: 'Jesteś pewny?',
    warning_subtitle: 'Jeśli teraz nie masz czasu, możesz to dokończyć później.',
    warning_confirm: 'Tak, jestem pewny.',
    warning_cancel: 'Nie',
  },
  ro: {
    title: 'Ajutor și asistență',
    contact_title: 'Contact',
    contact_text:
      'Dacă aveți întrebări referitoare la utilizarea acestui formular, ne puteți contacta folosind detaliile de contact de mai jos:',
    decline_text:
      'Nu doriți să răspundeți la acest formular și să anulați orice reamintiri viitoare? Faceți clic pe butonul de mai jos pentru a ne anunța.',
    decline_btn: 'Refuză',
    warning_title: 'Ești sigur?',
    warning_subtitle: 'Îl poți finaliza mai târziu dacă nu ai timp acum.',
    warning_confirm: 'Da, sunt sigur.',
    warning_cancel: 'Nu',
  },
  sk: {
    title: 'Pomoc a podpora',
    contact_title: 'Kontakt',
    contact_text:
      'Ak máte otázky týkajúce sa použitia tohto formulára, môžete nás kontaktovať prostredníctvom nižšie uvedených kontaktných údajov:',
    decline_text:
      'Neželáte si odpovedať na tento formulár a zrušiť budúce pripomenutia? Kliknite na tlačidlo nižšie, aby ste nás informovali.',
    decline_btn: 'Odmietnuť',
    warning_title: 'Ste si istý?',
    warning_subtitle: 'Ak teraz nemáte čas, môžete to dokončiť neskôr.',
    warning_confirm: 'Áno, som si istý.',
    warning_cancel: 'Nie',
  },
  es: {
    title: 'Ayuda y soporte',
    contact_title: 'Contacto',
    contact_text:
      'Si tiene alguna pregunta sobre el uso de este formulario, puede ponerse en contacto con nosotros utilizando los datos de contacto que aparecen a continuación:',
    decline_text:
      '¿No desea responder a este formulario y cancelar futuros recordatorios? Haga clic en el botón de abajo para informarnos.',
    decline_btn: 'Rechazar',
    warning_title: '¿Estás seguro?',
    warning_subtitle: 'Puedes terminarlo más tarde si no tienes tiempo ahora mismo.',
    warning_confirm: 'Sí, estoy seguro.',
    warning_cancel: 'No',
  },
  se: {
    title: 'Hjälp och support',
    contact_title: 'Kontakt',
    contact_text:
      'Om du har några frågor angående användningen av detta formulär kan du kontakta oss med kontaktinformationen nedan:',
    decline_text:
      'Vill du inte svara på detta formulär och avbryta framtida påminnelser? Klicka på knappen nedan för att meddela oss.',
    decline_btn: 'Avböja',
    warning_title: 'Är du säker?',
    warning_subtitle: 'Du kan slutföra det senare om du inte har tid just nu.',
    warning_confirm: 'Ja, jag är säker.',
    warning_cancel: 'Nej',
  },
  ua: {
    title: 'Допомога та підтримка',
    contact_title: 'Контакт',
    contact_text:
      "Якщо у вас є питання щодо використання цієї форми, ви можете зв'язатися з нами, використовуючи контактні дані нижче:",
    decline_text:
      'Ви не бажаєте відповідати на цю форму та скасувати майбутні нагадування? Натисніть кнопку нижче, щоб повідомити нас.',
    decline_btn: 'Відхилити',
    warning_title: 'Ви впевнені?',
    warning_subtitle: 'Якщо у вас зараз немає часу, ви можете завершити це пізніше.',
    warning_confirm: 'Так, я впевнений.',
    warning_cancel: 'Ні',
  },
}
