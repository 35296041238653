import { formatValue } from '@penbox-io/stdlib'

import { FormElement, DefinitionMap, FormStep, DefinitionValue } from './definition.js'
import { ValueGetter, buildElement, buildElements, isElementWithKey } from './element.js'
import { buildSteps } from './step.js'

export type BuildOptions = {
  locale?: string
  ensureSubmittable?: boolean
  getValue?: ValueGetter
}

export function createElementBuilder<D extends DefinitionMap>(
  definitions: D,
  elementTransform?: (element: unknown) => unknown
) {
  return (input: unknown, options?: BuildOptions) => {
    return buildElement(
      definitions,
      input,
      options?.locale || 'en',
      options?.getValue,
      elementTransform
    )
  }
}

export function createElementsBuilder<D extends DefinitionMap>(
  definitions: D,
  elementTransform?: (element: unknown) => unknown
) {
  return (input: unknown, options?: BuildOptions) => {
    return buildElements(
      definitions,
      input,
      options?.locale || 'en',
      options?.ensureSubmittable === true,
      options?.getValue,
      elementTransform
    )
  }
}

export function createElementsValuesExtractor<D extends DefinitionMap>(definitions: D) {
  return function* extractElementsValues(
    elements: Array<FormElement<D>>
  ): Generator<[string, DefinitionValue<D> | undefined, FormElement<D> & { key: string }], void> {
    for (const element of elements) {
      if (isElementWithKey(element)) {
        const definition = definitions[element.type]
        const value = definition.normalize(element)
        yield [element.key, value, element as FormElement<D> & { key: string }]
      }
    }
  }
}

export function createStepsBuilder<D extends DefinitionMap>(
  definitions: D,
  elementTransform?: (element: unknown) => unknown
) {
  return (input: unknown, options?: BuildOptions) => {
    return buildSteps(
      definitions,
      input,
      options?.locale || 'en',
      options?.ensureSubmittable === true,
      options?.getValue,
      elementTransform
    )
  }
}

export function createStepsValuesExtractor<D extends DefinitionMap>(definitions: D) {
  return function* extractStepsValues(
    steps: Array<FormStep<D>>
  ): Generator<
    [
      string,
      DefinitionValue<D> | undefined,
      FormElement<D> & { key: NonNullable<FormElement<D>['key']> }
    ],
    void
  > {
    for (const step of steps) {
      for (const element of step.elements) {
        if (isElementWithKey(element)) {
          const definition = definitions[element.type]
          const value = definition.normalize(element)
          yield [element.key, value, element]
        }
      }
    }
  }
}

export function createValueNormalizer<D extends DefinitionMap>(definitions: D) {
  return normalizeValue

  function normalizeValue<E extends FormElement<D>>(element: E, strict?: boolean) {
    if (strict && element.error) return undefined

    const definition = definitions[element.type]

    const value: unknown = definition.normalize(element)
    if (value !== undefined) return value

    if (!strict) {
      // The value is not valid, but we are in loose mode, so invalid primitives are allowed.
      return toPrimitiveValue(element.value)
    }

    return undefined
  }
}

export function createValueStringifier<D extends DefinitionMap>(definitions: D) {
  return stringifyValue

  function stringifyValue<E extends FormElement<D>>(element: E, strict?: false): string
  function stringifyValue<E extends FormElement<D>>(element: E, strict: true): string | undefined
  function stringifyValue<E extends FormElement<D>>(
    element: E,
    strict?: boolean
  ): string | undefined {
    if (strict && element.error) return undefined

    const value = definitions[element.type].stringify(element)
    if (value !== undefined) return value

    if (!strict) {
      // The value is not valid. Try to stringify it anyways in loose mode.
      return formatValue(toPrimitiveValue(element.value), element)
    }

    return undefined
  }
}

export function createTitleStringifier<D extends DefinitionMap>(definitions: D) {
  return <E extends FormElement<D>>(element: E) => {
    return definitions[element.type].stringifyTitle(element)
  }
}

/**
 * In loose mode, only primitive types are allowed. This function allows to
 * return a primitive value, or undefined.
 */
function toPrimitiveValue(value: unknown): string | number | boolean | undefined | null {
  if (value == null) return null
  switch (typeof value) {
    case 'number':
      if (!Number.isFinite(value)) return undefined
    // falls through
    case 'string':
    case 'boolean':
      return value
  }
  return undefined
}
