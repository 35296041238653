import { type Dict, type FormHelpable, stringifyHelpable, isDict } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'
import { parseDate, stringifyDate } from '../utils/date.js'

type Value = {
  date: Date
  data: unknown
}
type Options = {
  label?: FormHelpable
  mode: 'redirect' | 'popup'
  href: URL
  setup?: {
    payload?: Dict<unknown>
  }
  submitOnChange?: boolean
}
type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    href: new URL(input.href),
    mode: input.mode === 'popup' ? 'popup' : 'redirect',
    setup: input.setup ? { payload: input.setup?.payload } : undefined,
    submitOnChange: input.submit_on_change === true,
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  if (!isDict(value)) return null
  if (!('date' in value) || !('data' in value)) return null

  const date = parseDate(value.date)
  if (!date) return null

  return { date, data: value.data }
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (value === null) {
    if (required) return buildError('requiredButton', locale)
    return null
  }

  return null
}

function normalize(element: Element): undefined | Value {
  return element.value || undefined
}

function stringify(element: Element): undefined | string {
  const value = normalize(element)
  if (!value) return undefined

  return stringifyDate(value.date, element.locale)
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}
