import { MethodCall, MethodSignature } from '../types'
import { createMethod, isPureExpression, isString, toString } from '../util'

type Type = {
  ':uppercase': string | MethodCall
}

/**
 * Converts a string to uppercase.
 * @usage: ```{":uppercase": string}```
 * where:
 * - `string` is a string
 *
 * @example
 * - ```{":uppercase": "Hello World"}``` returns `"HELLO WORLD"`
 */
export default createMethod<Type>({
  name: ':uppercase',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':uppercase') continue
      return false
    }

    const uppercase = expr[':uppercase']
    if (!isPureExpression(uppercase) && !isString(uppercase)) return false

    return true
  },

  evaluate(expr) {
    return toString(this.evaluate(expr[':uppercase']))?.toUpperCase()
  },
})
