import { CompilationContext, CompilationResult, CompiledExpression } from './compilation'
import { EvaluationContext } from './evaluation'
import { JsonValue, MethodSignature, Value } from './types'
import { createMethod } from './util'

export const createMethodLegacySimple = <Name extends string>(
  name: Extract<Name, string>,
  evaluate: (this: EvaluationContext, arg: JsonValue) => undefined | Value,
  compile?: (this: CompilationContext, arg: CompiledExpression) => CompilationResult
) =>
  createMethod<{ [k in Name]: JsonValue }>({
    name,
    test(expr): expr is MethodSignature<{ [k in Name]: JsonValue }> {
      if (typeof expr[name] === 'undefined') return false
      for (const key in expr) {
        if (key === '$schema') continue
        if (key === name) continue
        return false
      }
      return true
    },
    evaluate(expr) {
      return evaluate.call(this, expr[name])
    },
    ...(compile && {
      compile(expr) {
        const compiledExpr = this.createContext(name).compile(expr[name])
        return compile.call(this, compiledExpr)
      },
    }),
  })

export const createMethodLegacyMultiple = <Name extends string, Args extends string>(
  name: Extract<Name, string>,
  args: Args[],
  evaluate: (
    this: EvaluationContext,
    expr: MethodSignature<{ [k in Name]: JsonValue } & { [k in Args]?: JsonValue }>
  ) => undefined | Value
) =>
  createMethod<{ [k in Name]: JsonValue } & { [k in Args]?: JsonValue }>({
    name,
    evaluate,
    test: (
      expr
    ): expr is MethodSignature<{ [k in Name]: JsonValue } & { [k in Args]?: JsonValue }> => {
      if (typeof expr[name] === 'undefined') return false
      for (const key in expr) {
        if (key === '$schema') continue
        if (key === name) continue
        if (args.includes(key as any)) continue
        return false
      }
      return true
    },
  })
