export default {
  vuetify: {
    rules: {
      checked: 'This must be checked.',
      complexity: 'This field requires numbers, special characters, lower and upper case letters.',
      date: 'Invalid date.',
      email: 'Invalid email address.',
      exactLength: 'This value must contain {0} characters.',
      iban: 'Invalid IBAN number.',
      invalid: 'Invalid value.',
      maxCount: 'This field should contain at most {0} items.',
      maxDate: 'Enter a date before {0}.',
      maxDecimals:
        'This value must contain at most {0} decimal places. Be careful not to use a thousands separator.',
      maxFileSize: 'Select a file smaller than {0}.',
      maxFilesSize: 'All files must be smaller than {0}.',
      maxLength: 'This value must be less than {0} characters long.',
      maxValue: 'This value must be smaller or equal than {0}.',
      minCount: 'This field should contain at least {0} items.',
      minDate: 'Enter a date after {0}.',
      minFileSize: 'Select a file larger than {0}.',
      minFilesSize: 'All files must be larger than {0}.',
      minLength: 'This value must be more than {0} characters long.',
      minValue:
        'This value must be greater or equal than {0}. Be careful not to use a thousands separator.',
      oneOf: 'Must be one of {0}.',
      phone: 'Invalid phone number.',
      phoneIntl: 'Make sure to start with your +xx country code.',
      regexp: 'This value does not respect the right format.',
      required: 'A value is required.',
      url: 'Invalid URL.',
      urlPort: 'This URL contains a forbidden port number.',
      urlProtocol: 'This URL must start with {0}',
      urlTld: 'This URL must contain one of the following top level domain: {0}.',
    },
  },
}
