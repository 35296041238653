import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'
import { parseDate, stringifyDate } from '../utils/date.js'

type Value = Date
type Options = {
  label?: FormHelpable
  return: unknown
}
type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    label: ifFormHelpable(input.label),
    return: input.return ?? null,
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  return parseDate(value) || null
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (value == null) {
    // Override "required" error message
    if (required) return buildError('requiredButton', locale)
    return null
  }

  return null
}

function normalize(element: Element): undefined | Value {
  return element.value || undefined
}

function stringify(element: Element): undefined | string {
  const value = normalize(element)
  if (!value) return undefined

  return stringifyDate(value, element.locale)
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}
