import { createMethodLegacyMultiple } from '../legacy'

/**
 * Check if the value is defined, if the value is not null or undefined.
 *
 * @usage: ```{ ':defined': variable }```
 *
 * where:
 * - variable: any: the variable to check if it is defined
 *
 * @example
 * ```{ ':defined': 'a' }``` => true
 * ```{ ':defined': null }``` => false
 * ```{ ':defined': 0 }``` => true
 *
 */
export default createMethodLegacyMultiple(':defined', [], function (expr): undefined | boolean {
  const value = this.evaluate(expr[':defined'])
  return value != null
})
