import { omit } from '@penbox-io/stdlib'
import { getMemorized, setMemorized } from './request/util'

const localStorageIntegration = (store) => {
  store.subscribe(async ({ type, payload }, state) => {
    if (type === 'request/SET_PENDING_VALUE') {
      const { key, value, options = undefined } = payload
      if (options?.meta?.memorize) setMemorized(state.request.flow, { [key]: value })
    }
  })

  store.watch(
    (state) => state.request.flow?.id,
    (id) => {
      if (id) {
        const { flow, response } = store.state.request
        const memorized = flow && response ? getMemorized(flow) : null
        if (memorized) {
          const data = omit(memorized.data, Object.keys(response.attributes.data || {}))
          const user = omit(memorized.user, Object.keys(response.attributes.user || {}))
          store.commit('request/UPDATE_PENDING', { data, user })
        }
      }
    },
    { immediate: true }
  )
}

const i18nIntegration = (store) => {
  // When plugin is initialized, app.i18n does not exist yet
  // Need to watch for app.i18n in order to be able to setLocale
  store.watch(
    (state, getters) => store.app?.i18n && getters['branding/locale'],
    (locale) => {
      if (locale) {
        store.app.i18n.setLocale(locale)
      }
    },
    {
      immediate: true,
    }
  )
}

export const plugins = [i18nIntegration, localStorageIntegration]
