import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { findMatchingPair } from '../util'
import { StringTransformer } from './'
import { findNext, findQuotes } from './utils'

/**
 * Evaluate an expression enclosed in parenthesis.
 *
 * @usage: ```( expression ) + ( expression2 )```
 */
class ParenthesisStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const quotes = findQuotes(expr)

    const parenthesis = findNext(expr, 0, '(', quotes)

    return parenthesis !== -1
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    const quotes = findQuotes(expr)
    const parenthesis = findNext(expr, 0, '(', quotes)

    const closing = findMatchingPair(expr, '(', ')', parenthesis)

    if (closing === -1) {
      return undefined // Unclosed parenthesis
    }

    // Replace the content of the parenthesis by its evaluation
    const content = expr.slice(parenthesis + 1, closing)
    const evalContent = this.evalStringExpression(content)

    // Replace the parenthesis by the evaluation
    expr = expr.slice(0, parenthesis) + evalContent + expr.slice(closing + 1)

    return this.evalStringExpression(expr)
  }
}

export default new ParenthesisStringTransformer()
