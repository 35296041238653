import { EvaluationContext } from '../evaluation'
import { createMethodLegacySimple } from '../legacy'
import { findPath } from '../string'
import { JsonValue, Value } from '../types'
import { isArray, isNumber, isPlainObject, isString } from '../util'

function computeConditionCheck(
  this: EvaluationContext,
  op: string,
  value: Value,
  reference: Value
): boolean {
  switch (op) {
    case '$eq':
      return value === reference
    case '$ne':
    case '$neq':
      return value !== reference
    case '$gt':
      return Number(value) > Number(reference)
    case '$ge':
    case '$gte':
      return Number(value) >= Number(reference)
    case '$lt':
      return Number(value) < Number(reference)
    case '$le':
    case '$lte':
      return Number(value) <= Number(reference)
    case '$null': {
      const isNull = value === undefined || value === null
      return isNull === reference
    }
    case '$empty': {
      const isEmpty = value === undefined || value === null || (value as any).length === 0
      return isEmpty === reference
    }
    case '$includes': {
      if (isArray(reference)) {
        return isArray(value) && reference.every((c) => value.includes(c))
      }

      if (isNumber(reference)) {
        return isArray(value) && value.includes(reference)
      }

      if (isString(reference)) {
        if (isArray(value)) {
          return value.includes(reference)
        }

        return isString(value) && value.includes(reference)
      }

      return false
    }
    case '$intersects': {
      if (isArray(reference)) {
        return isArray(value) && reference.some((c) => value.includes(c))
      }

      if (isNumber(reference)) {
        return isArray(value) && value.includes(reference)
      }

      if (isString(reference)) {
        if (isArray(value)) {
          return value.includes(reference)
        }

        return isString(value) && value.includes(reference)
      }
      return false
    }
    case '$in': {
      if (isArray(reference)) {
        return !isArray(value) && reference.includes(value)
      }
      if (isString(reference)) {
        return isString(value) && reference.includes(value)
      }
      return false
    }
    case '$nin': {
      if (isArray(reference)) {
        return !isArray(value) && !reference.includes(value)
      }
      if (isString(reference)) {
        return !isString(value) || !reference.includes(value)
      }
      return false
    }
    default:
      return false // unknown operator
  }
}

/**
 * Conditional operator.
 * @usage: ```{ ':cond': { path: { op: value } } }```
 * where:
 * - path: string: the path to the value to compare
 * - op: string: the operator to use for comparison (one of $eq, $ne, $gt, $ge, $lt, $le, $null, $empty, $includes, $intersects, $in, $nin)
 * - value: any: the value to compare to
 *
 * operators:
 * - $eq: value === reference. eg: ```{ ':cond': { 'data.count': { '$eq': 5 } } }``` // true if data.count === 5
 * - $ne (or $neq): value !== reference. eg: ```{ ':cond': { 'data.count': { '$ne': 5 } } }``` // true if data.count !== 5
 * - $gt: value > reference. eg: ```{ ':cond': { 'data.count': { '$gt': 5 } } }``` // true if data.count > 5
 * - $ge (or $gte): value >= reference. eg: ```{ ':cond': { 'data.count': { '$ge': 5 } } }``` // true if data.count >= 5
 * - $lt: value < reference. eg: ```{ ':cond': { 'data.count': { '$lt': 5 } } }``` // true if data.count < 5
 * - $le (or $lte): value <= reference. eg: ```{ ':cond': { 'data.count': { '$le': 5 } } }``` // true if data.count <= 5
 * - $null: value is null. eg: ```{ ':cond': { 'data.count': { '$null': true } } }``` // true if data.count is null or undefined
 * - $empty: value is empty. eg: ```{ ':cond': { 'data.count': { '$empty': true } } }``` // true if data.count is null, undefined or empty array or string
 * - $includes: value includes reference. eg: ```{ ':cond': { 'data.array': { '$includes': 1 } } }``` // where data.array is an array, true if data.count includes 1
 *    Reference can be:
 *    - a number: true if data.array includes the number. eg: ```{ ':cond': { 'data.array': { '$includes': 1 } } }``` // true if data.array includes 1
 *    - a string: true if data.array includes the string. eg: ```{ ':cond': { 'data.array': { '$includes': 'hello' } } }``` // true if data.array includes 'hello'
 *    - a string: true if data.string includes the string. eg: ```{ ':cond': { 'data.string': { '$includes': 'hello' } } }``` // true if data.string (hello world for example) includes 'hello'
 *    - an array: true if data.array includes all the numbers in the array. eg: ```{ ':cond': { 'data.array': { '$includes': [1, 2] } } }``` // true if data.array includes 1 and 2
 *
 * - $intersects: value intersects reference. eg: ```{ ':cond': { 'data.array': { '$intersects': 1 } } }``` // where data.array is an array, true if data.array includes 1
 *   Reference can be:
 *   - a number: true if data.array includes the number. eg: ```{ ':cond': { 'data.array': { '$intersects': 1 } } }``` // true if data.array includes 1
 *   - a string: true if data.array includes the string. eg: ```{ ':cond': { 'data.array': { '$intersects': 'hello' } } }``` // true if data.array includes 'hello'
 *   - a string: true if data.string includes the string. eg: ```{ ':cond': { 'data.string': { '$intersects': 'hello' } } }``` // true if data.string (hello world for example) includes 'hello'
 *   - an array: true if data.array includes any of the numbers in the array. eg: ```{ ':cond': { 'data.array': { '$intersects': [1, 2] } } }``` // true if data.array includes 1 or 2
 *
 * - $in: value in reference. eg: ```{ ':cond': { 'data.count': { '$in': [1, 2, 3] } } }``` // true if data.count is 1, 2 or 3
 * - $nin: value not in reference. eg: ```{ ':cond': { 'data.count': { '$nin': [1, 2, 3] } } }``` // true if data.count is not 1, 2 or 3
 *
 * Operators can be combinable. eg: ```{ ':cond': { 'data.count': { '$gt': 5, '$lt': 10 } } }``` // true if data.count > 5 and data.count < 10
 * Or even more:
 * ```{ ':cond': { 'data.count': { '$gt': 5, '$lt': 10 }, 'data.name': { '$eq': 'John' } } }``` // true if data.count > 5 and data.count < 10 and data.name === 'John'
 *
 */
export default createMethodLegacySimple(':cond', function (args): undefined | boolean {
  if (!isPlainObject(args) || !Object.values(args).every(isPlainObject)) return undefined

  // Cast possible thanks to if() here before
  for (const [key, checks] of Object.entries(args) as Array<[string, Record<string, JsonValue>]>) {
    const value = findPath.call(this.scope, key.split('.'))

    for (const [op, expr] of Object.entries(checks)) {
      const reference = this.evaluate(expr)

      if (!computeConditionCheck.call(this, op, value, reference)) {
        return false
      }
    }
  }

  return true
})
