import { subScopeDefine } from '../scope'
import { Define, JsonValue, MethodSignature, Scope } from '../types'
import { createMethod } from '../util'
import { compileDefine, evalDefine } from './define'

type Type = { ':eval': JsonValue; ':using'?: Define }

/**
 * Evaluate an raw expression with an optional sub-scope.
 *
 * @usage: ```{ ':eval': JsonValue, ':using'?: Define }```
 * where:
 * - JsonValue: any: the expression to evaluate
 * - Define: Define: the variables to define in the sub-scope
 *
 * @example
 * ```
 * {
 * ":using": {
 *   "duplicateInArray":{ ":raw": ["{a}", "{a}"]}
 * },
 * ":eval": "{duplicateInArray}"
 * }
 * ```
 */
export default createMethod<Type>({
  name: ':eval',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':eval' || key === ':using') continue
      return false // Error: Unknown key
    }

    if (typeof expr[':eval'] === 'undefined') return false

    return true
  },

  evaluate(expr) {
    const evaled = this.evaluate(expr[':eval'])
    return expr[':using'] ? evalDefine.call(this, expr[':using'], evaled) : this.evaluate(evaled)
  },

  compile(expr) {
    const evalCompiled = this.createContext(':eval').compile(expr[':eval'])

    const newVarsGetters =
      expr[':using'] === undefined
        ? []
        : compileDefine.call(this.createContext(':using'), expr[':using'])
    if (newVarsGetters === undefined) return undefined

    // Optimization
    if (evalCompiled.static) return evalCompiled

    const { evaluator } = this
    return (scope: Scope) => {
      const result = evalCompiled(scope)

      const childScope = newVarsGetters.length ? subScopeDefine(scope, newVarsGetters) : scope
      return evaluator.evaluate(result, childScope)
    }
  },
})
