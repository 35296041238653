import { subScope } from '../scope'
import { JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isArray, isPlainObject, isPureExpression } from '../util'

type Type = {
  ':object-entries': JsonValue
  ':as'?: JsonValue
  ':order-by'?: false | 'key' | 'value'
}

/**
 * Returns an array of entries from an object.
 * @usage: ```{ ':object-entries': object, ':as'?: expression, ':order-by'?: false | 'key' | 'value' }```
 *
 * where:
 * - object: any: the object to extract the entries from
 * - as: expression: an optional expression to evaluate for each entry
 * - order-by: false | 'key' | 'value': the order to sort the entries
 *
 * @sub-scope variables
 * - `@length` the number of entries
 * - `@index` the current index
 * - `@position` the current position
 * - `@first` true if the current entry is the first
 * - `@last` true if the current entry is the last
 * - `@entry` the current entry
 * - `@key` the current key
 * - `@value` the current value
 *
 * @example
 * - ```{':object-entries': {a: 1, b: 2}}``` returns `[["a", 1], ["b", 2]]`
 * - ```{':object-entries': {a: 1, b: 2}```, ':as': '{@key}: {@value}'}` returns `["a: 1", "b: 2"]`
 * - ```{':object-entries': {a: 2, b: 1}```, ':order-by': 'value'}` returns `[["b", 1], ["a", 2]]`
 * - ```{':object-entries': {a: 2, b: 1}```, ':order-by': false}` returns `[["a", 2], ["b", 1]]`
 * - ```{':object-entries': {a: 2, b: 1}```, ':order-by': 'key'}` returns `[["a", 2], ["b", 1]]`
 */
export default createMethod<Type>({
  name: ':object-entries',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':object-entries') continue
      if (key === ':as') continue
      if (key === ':order-by') continue
      return false
    }

    if (
      expr[':order-by'] !== undefined &&
      ![false, 'key', 'value'].includes(expr[':order-by'] as any)
    ) {
      return false
    }

    return isPlainObject(expr[':object-entries']) || isPureExpression(expr[':object-entries'])
  },

  evaluate(expr) {
    const sortFn = getSortFn(expr[':order-by'])
    if (sortFn === undefined) return undefined

    const object = this.evaluate(expr[':object-entries'])
    if (!isPlainObject(object)) return undefined

    const entries = Object.entries(object)

    if (sortFn) entries.sort(sortFn)

    if (expr[':as'] === undefined) return entries

    const { length } = entries
    const result: Value[] = Array(length)

    // Optimization: create one re-usable sub-scope
    const ctx = this.subScope({
      // Static:
      '@length': length,
      // Dynamic:
      '@index': -1,
      '@position': 0,
      '@first': true as boolean,
      '@last': true as boolean,
      '@entry': undefined as [string, Value] | undefined,
      '@key': undefined as string | undefined,
      '@value': undefined as Value,
    })

    for (let i = 0, childScope = ctx.scope; i < length; i++) {
      const entry = entries[i]

      childScope['@index'] = i
      childScope['@position'] = i + 1
      childScope['@first'] = i === 0
      childScope['@last'] = i === length - 1
      childScope['@entry'] = entry
      childScope['@key'] = entry[0]
      childScope['@value'] = entry[1]

      result[i] = ctx.evaluate(expr[':as']) ?? null
    }

    return result
  },

  compile(expr) {
    const getObject = this.createContext(':object-entries').compile(expr[':object-entries'])
    const getAs = expr[':as'] === undefined ? null : this.createContext(':as').compile(expr[':as'])

    const sortFn = getSortFn(expr[':order-by'])
    if (sortFn === undefined) return undefined

    return (scope: Scope) => {
      const object = getObject(scope)
      if (!isPlainObject(object)) return undefined

      const entries = Object.entries(object)

      if (sortFn) entries.sort(sortFn)

      if (!getAs) return entries

      const { length } = entries
      const result: Value[] = Array(length)

      const childScope = subScope(scope, {
        // Static:
        '@length': length,
        // Dynamic:
        '@index': -1,
        '@position': 0,
        '@first': true as boolean,
        '@last': true as boolean,
        '@entry': undefined as [string, Value] | undefined,
        '@key': undefined as string | undefined,
        '@value': undefined as Value,
      })

      for (let i = 0; i < length; i++) {
        const entry = entries[i]

        childScope['@index'] = i
        childScope['@position'] = i + 1
        childScope['@first'] = i === 0
        childScope['@last'] = i === length - 1
        childScope['@entry'] = entry
        childScope['@key'] = entry[0]
        childScope['@value'] = entry[1]

        result[i] = getAs(childScope) ?? null
      }

      return result
    }
  },
})

const getSortFn = (sortBy: false | 'key' | 'value' = 'key') => {
  switch (sortBy) {
    case false:
      return null
    case 'key':
      return entryKeyComparator
    case 'value':
      return entryValueComparator
    default:
      return undefined // Fool-proof
  }
}

function entryKeyComparator([ka]: [string, Value], [kb]: [string, Value]): number {
  return stringComparator(ka, kb)
}

function entryValueComparator(a: [string, Value], b: [string, Value]): number {
  return valueComparator(a[1], b[1]) || entryKeyComparator(a, b)
}

function valueComparator(a: Value, b: Value): number {
  const ta = typeof a
  const tb = typeof b

  if (ta !== tb) return 0
  if (ta === 'string') return stringComparator(a as string, b as string)
  if (ta === 'number') return (a as number) - (b as number)
  if (ta === 'bigint') return (a as number) - (b as number)
  if (ta === 'boolean') return a === b ? 0 : a ? 1 : -1

  // Should not happen
  if (ta === 'undefined') return 0
  if (ta === 'function') return 0
  if (ta === 'symbol') return 0

  if (ta === 'object') {
    if (isArray(a) && isArray(b)) {
      const { length: la } = a
      const { length: lb } = b

      const lc = la < lb ? la : lb

      for (let i = 0; i < lc; i++) {
        const c = valueComparator(a[i], b[i])
        if (c !== 0) return c
      }

      return la - lb
    }

    return 0
  }

  return 0
}

function stringComparator(a: string, b: string): number {
  return a.localeCompare(b)
}
