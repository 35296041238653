import { EvaluationContext } from '../evaluation'
import { createMethodLegacySimple } from '../legacy'
import { JsonValue } from '../types'
import { isArray, toNumber } from '../util'

const multiply = (a: number, b: number): number => a * b

/**
 * Returns the product of all numbers in the input array.
 * @usage: ```{":multiply": array}```
 * where:
 * - `array` is an array of numbers
 *
 * @example
 * - ```{":multiply": [1, 2, 3]}``` returns `6`
 */
export default createMethodLegacySimple(':multiply', computeMultiply)

export function computeMultiply(this: EvaluationContext, args: JsonValue): undefined | number {
  const input = this.evaluate(args)
  if (!isArray(input)) return undefined

  const values = input.map(toNumber)
  if (values.some(isNaN)) return undefined

  return values.reduce(multiply, 1)
}
