import { Scope } from './types'
import { findMatchingPair, isArray, isString, toString } from './util'

export function findPath(this: null | undefined | Scope, path: Array<string | number>): any {
  let current = this as unknown

  for (let i = 0; i < path.length; i++) {
    const p = path[i]

    if (typeof current !== 'object' || current === null) {
      return undefined
    } else if (isArray(current)) {
      if (p === 'length') {
        current = current.length
        continue
      }

      const index = Number(p) % current.length
      if (Number.isNaN(index)) return undefined
      current = current[index < 0 ? current.length + index : index]
    } else {
      const k = toString(p)
      if (k === undefined) return undefined
      if (k === '__proto__') return undefined
      if (k === 'constructor' && typeof current[k] === 'function') return undefined

      current = (current as any)[k]
    }
  }

  return current
}

export function parsePath(
  str: unknown,
  partEvaluator?: (part: string) => undefined | string
): undefined | string[] {
  if (!isString(str)) return undefined

  const parts: string[] = []

  let i = 0

  while (i < str.length) {
    const dot = str.indexOf('.', i)
    const bracket = str.indexOf('[', i)

    // we've reached the end
    if (dot === -1 && bracket === -1) {
      parts.push(str.slice(i, str.length))
      break
    }
    // dots
    else if (bracket === -1 || (dot !== -1 && dot < bracket)) {
      parts.push(str.slice(i, dot))
      i = dot + 1
    }
    // brackets
    else {
      if (bracket > i) {
        parts.push(str.slice(i, bracket))
        i = bracket
      }

      const closing = findMatchingPair(str, '[', ']', i)
      if (closing === -1) return undefined

      const inner = str.slice(i + 1, closing).trim()

      const part = partEvaluator ? partEvaluator(inner) : inner
      if (part === undefined) return undefined

      parts.push(part)

      const next = str.charAt(closing + 1)
      if (next === '') {
        break
      } else if (next === '[') {
        i = closing + 1
      } else if (next === '.') {
        i = closing + 2
      } else {
        return undefined
      }
    }
  }

  return parts
}
