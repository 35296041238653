import { createMethodLegacySimple } from '../legacy'
import { isArray, toNumber } from '../util'

const divide = (a: number, b: number): number => a / b

/**
 * Returns the division of all numbers in the input array.
 * @usage: ```{":divide": array}```
 * where:
 * - `array` is an array of numbers
 *
 * @example
 * - ```{":divide": [1, 2, 3]}``` returns `0.16666666666666666`
 * - ```{":divide": [1, 2, 0]}``` returns `Infinity`
 */
export default createMethodLegacySimple(':divide', function (args): undefined | number {
  const input = this.evaluate(args)
  if (!isArray(input)) return undefined
  const values = input.map(toNumber)
  if (values.length === 0) return undefined
  if (values.some(isNaN)) return undefined

  return values.reduce(divide)
})
