declare const Event: undefined | (new () => { type: string; target: unknown })
declare const EventSource: undefined | (new () => { url: string; withCredentials: boolean })

export type NormalizedError = {
  message: string
  status?: number
  code?: string
  meta?: any
}

type BoomOutput = { statusCode: number; payload: any }
type ResponseError = BoomOutput | { status?: number; statusCode?: number; data?: any }
type JsonapiError = {
  meta?: any
  code?: string
  status?: number
  detail?: string
  title?: string
}
type JsonapiPayload = {
  errors?: JsonapiError[]
}
type JsonapiResponse = {
  status?: number
  data: JsonapiPayload
}

type NormalizedResponse = { status: number; data: any }

const ifString = (v: unknown) => (typeof v === 'string' ? v : undefined)
const ifNumber = (v: unknown) => (typeof v === 'number' ? v : undefined)

const isObjectLike = (v: unknown): v is Record<string, unknown> =>
  v != null && typeof v === 'object'

const hasResponse = (v: any): v is { response: any } => isObjectLike(v) && isObjectLike(v.response)

const isBoom = (v: any): v is { output: BoomOutput } =>
  v instanceof Error && Boolean((v as any).isBoom)

const isAggegateError =
  typeof AggregateError !== 'undefined'
    ? (v: any): v is AggregateError => v instanceof AggregateError
    : (v: any): v is AggregateError => v instanceof Error && Array.isArray((v as any).errors)

const normalizeResponse = (response: any): NormalizedResponse => ({
  status: response.status || response.statusCode,
  data: response.data || response.body || response.payload,
})

const withNormalizedResponse =
  <F extends (response: NormalizedResponse) => any>(fn: F) =>
  (response: ResponseError): ReturnType<F> =>
    fn(normalizeResponse(response))

const isJsonapiResponse = withNormalizedResponse(
  ({ data }) => data?.jsonapi?.version === '1.0'
) as (v: any) => v is JsonapiResponse

function normalizeJsonapiError(error: JsonapiError): NormalizedError {
  // Expose internal cause error
  if (error.meta?.isResponseError === true) {
    return responseError({
      status: error.meta.status,
      data: error.meta.payload,
    })
  }

  return {
    code: error.code || 'http-error',
    status: error.status || 500,
    message: error.detail || error.title || 'Unknown error',
    meta: error.meta ?? undefined,
  }
}

const jsonapiResponseError = withNormalizedResponse(({ data, status }): NormalizedError => {
  const error = data.errors?.[0]
  if (error) return normalizeJsonapiError(error)

  // fallback
  return genericResponseError({ data, status })
})

const genericResponseError = withNormalizedResponse(
  ({ status, data }): NormalizedError => ({
    // Basic heuristice to extract error details { code: string, message: string }
    code: ifString(data?.code) || ifString(data?.error) || 'http-error',
    status,
    message: ifString(data?.message) || ifString(data?.description) || 'Unknown error',
    meta: data?.meta ?? undefined,
  })
)

export const responseError = (response: any): NormalizedError => {
  if (isJsonapiResponse(response)) {
    return jsonapiResponseError(response)
  }

  return genericResponseError(response)
}

const isEventSourceError =
  typeof Event !== 'undefined' && typeof EventSource !== 'undefined'
    ? (v: any): boolean => v instanceof Event && v.target instanceof EventSource
    : (v: any): boolean => false

export function normalizeErrorInternal(v: unknown = null): NormalizedError {
  if (v === null) {
    return {
      code: undefined,
      message: 'Invalid error',
    }
  }

  if (isBoom(v)) {
    return responseError(v.output)
  }

  if (hasResponse(v)) {
    return responseError(v.response)
  }

  if (!isObjectLike(v)) {
    const str = typeof v === 'string' ? v : undefined
    const isCode = str != null && /^[a-z0-9][a-z0-9._-]*$/i.test(str)
    return {
      code: isCode ? str : undefined,
      message: (isCode ? undefined : str) || 'Unknown error',
    }
  }

  if (v.isAxiosError) {
    return {
      code: `http-network`,
      status: 0,
      message: 'Network Error',
    }
  }

  if (isEventSourceError(v)) {
    return {
      code: `http-network`,
      status: 0,
      message: 'Network Error',
    }
  }

  const status = ifNumber(v.status) || ifNumber(v.statusCode) || undefined
  const code =
    ifNumber(v.code) ??
    ifString(v.code) ??
    (status != null && status >= 0 ? 'http-error' : undefined) ??
    (ifString(v.name) ? 'js-error' : undefined) ??
    undefined
  const message = ifString(v.description) || ifString(v.message) || 'Unknown error'

  return {
    code: code == null ? undefined : String(code),
    status,
    message,
  }
}

const normalizeJsonapiErrors = withNormalizedResponse(function* ({
  data,
  status,
}): Generator<NormalizedError, void, unknown> {
  if (data.errors) for (const error of data.errors) yield normalizeJsonapiError(error)
  else yield genericResponseError({ status, data })
})

export function normalizeError(v: unknown): NormalizedError {
  if (v) for (const i of normalizeErrors(v)) return i
  return normalizeErrorInternal(v)
}

export function* normalizeErrors(...errs: unknown[]): Generator<NormalizedError, void, unknown> {
  for (const err of errs) {
    // Filter
    if (!err) continue

    // Flatten
    if (Array.isArray(err)) {
      yield* normalizeErrors(...err)
      continue
    }

    // Known error wrappers

    if (isAggegateError(err)) {
      if (err.errors.length) yield* normalizeErrors(...err.errors)
      else normalizeErrorInternal(err)
      continue
    }

    if (hasResponse(err) && isJsonapiResponse(err.response)) {
      yield* normalizeJsonapiErrors(err.response)
      continue
    }

    if (isJsonapiResponse(err)) {
      yield* normalizeJsonapiErrors(err)
      continue
    }

    // Handle common errors
    yield normalizeErrorInternal(err)
  }
}
