import { createMethodLegacyMultiple } from '../legacy'
import { Value } from '../types'
import { isArray, isMethodCall, isObjectLike, isPlainObject, toString } from '../util'

/**
 * Maps the entries of an object.
 * @usage: ```{":map-entries": object, :key: string, :value: mapper }```
 *
 * where:
 * - `object` is an object
 * - `key` an optional new key for the entries - default to the current key
 * - `mapper` is a string expression that will be evaluated for each key
 *
 * @sub-scope variables
 * - `@key` the current key being processed
 * - `@value` the current value being processed
 * - `@self` the object being processed
 *
 * @example
 * - ```{":map-entries": {"a": 1, "b": 2}, ":value": "{@value}"}``` returns `{a: 1, b: 1}`
 * - ```{":map-entries": {"a": 1, "b": 2},  ":value": {":sum": ["{@value}", 1]}}``` returns `{a: 2, b: 3}`
 * - ```{":map-entries": {"a": 1, "b": 2}, ":value": "{@key}", ":key": "{@value}"}``` returns `{1: "a", 2: "b"}`
 */
export default createMethodLegacyMultiple(
  ':map-entries',
  [':key', ':value'],
  function ({
    ':map-entries': inputExpr,
    ':key': keyExpr,
    ':value': valueExpr,
  }): undefined | Record<string, Value> {
    // Validation
    if (inputExpr == null) return undefined
    if (isArray(keyExpr)) return undefined
    if (isObjectLike(keyExpr) && !isMethodCall(keyExpr)) return undefined

    const input = this.evaluate(inputExpr)

    if (!isPlainObject(input)) return undefined

    const output: Record<string, Value> = {}

    // Optimization
    if (keyExpr === null) return output

    for (const key in input) {
      const value = input[key]
      if (value === undefined) continue

      const ctx = this.subScope({ '@key': key, '@value': value, '@self': input })

      const newKey = toString(keyExpr === undefined ? key : ctx.evaluate(keyExpr))
      if (newKey === undefined) continue

      const newValue = valueExpr === undefined ? value : ctx.evaluate(valueExpr)
      if (newValue === undefined) continue

      output[newKey] = newValue
    }

    return output
  }
)
