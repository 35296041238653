export type ElementInfo = {
  id: string
  type: string
  [key: string]: any
}

export type Form = Array<{
  id: string
  elements: Array<{ id: string; type: string; [key: string]: any }>
  [key: string]: any
}>

/*
 * Get all elements from a form
 * @param {Form} The form with the steps and the elements - not evaluated - not compiled
 * @param {string} An optional type to filter the elements based on the type
 */
export function getAllElements(form: Form, type?: string): ElementInfo[] {
  if (!form) {
    return []
  }

  if (!Array.isArray(form)) {
    return []
  }

  const elements: ElementInfo[] = []
  extractForJSONElement(form, elements)

  if (type) {
    return elements.filter((x) => x.type === type)
  }

  return elements
}

function extractForJSONElement(jsonElement: any, elements: ElementInfo[]) {
  if (!jsonElement) return

  // array or object
  if (typeof jsonElement !== 'object') {
    return
  }

  if (Array.isArray(jsonElement)) {
    jsonElement.forEach((element, index) => {
      extractForJSONElement(element, elements)
    })

    return
  }

  if (jsonElement.id !== undefined && jsonElement.type !== undefined) {
    elements.push(jsonElement)
  }

  for (const property in jsonElement) {
    extractForJSONElement(jsonElement[property], elements)
  }
}

export function findElementById(form: Form, id: string): ElementInfo | undefined {
  if (!form) {
    return undefined
  }

  if (!Array.isArray(form)) {
    return undefined
  }

  const elements: ElementInfo[] = []
  extractForJSONElement(form, elements)

  return elements.find((x) => x.id === id)
}
