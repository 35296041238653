import { stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import type { Value, Options } from '../partials/phone.js'
import { options, validate, parsePhoneNumber } from '../partials/phone.js'

type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function parse(options: Options, locale: string, value: unknown): null | Value {
  if (value && typeof value === 'string') {
    return value.trim() || null
  }

  return null
}

function normalize(element: Element): undefined | Value {
  return parsePhoneNumber(element.value, element.options)?.number
}

function stringify(element: Element): undefined | string {
  const pn = parsePhoneNumber(element.value, element.options)
  if (!pn) return undefined

  if (pn.country === element.options.country) {
    return pn.formatNational()
  }

  return pn.formatInternational()
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}
