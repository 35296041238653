import { Scope } from './types'

export type SubScope<Parent extends Scope, Child extends Scope> = Parent extends null
  ? Child extends null
    ? null
    : Child
  : Child extends null
  ? Parent
  : Omit<Parent, keyof Child> & Child

export function subScope<S extends Scope, T extends Scope>(parent: S, child: T): SubScope<S, T>
export function subScope(parent: Scope, child: Scope): Scope {
  // The following code is the logical equivalent to this, but allows to keep
  // non-enumerable properties of the parent accessible.

  const parentObject = typeof parent === 'object' ? parent : null
  const childObject = typeof child === 'object' ? child : null

  if (!parentObject || !childObject) return parentObject || childObject

  // Optimization: avoid creating a new object if vars contains not enumerable prop
  let scope: Scope | undefined

  for (const k in childObject) {
    const value = childObject[k]

    const current = scope || (scope = Object.create(parentObject))

    // Make sure to avoid overriding a property that has a setter
    if (k in parentObject) {
      Object.defineProperty(current, k, {
        writable: true,
        configurable: true,
        enumerable: true,
        value,
      })
      continue
    }

    current[k] = value
  }

  return scope || parentObject
}

// TODO: Find a way to return a type that is the combination of all returned scopes
export function subScopeDefine<S extends Scope, T extends Scope>(
  parent: S,
  varsGetters: ReadonlyArray<null | ((scope: Scope) => Partial<T>)>
): SubScope<S, T> {
  let scope: SubScope<S, T> | undefined
  const parentObject = typeof parent === 'object' ? parent : null

  for (let i = 0; i < varsGetters.length; i++) {
    const varsGetter = varsGetters[i]
    if (!varsGetter) continue

    const vars = varsGetter(scope || parent)
    for (const k in vars) {
      const current = scope || (scope = Object.create(parentObject))

      const value = vars[k]

      // Make sure to avoid overriding a property that has a setter
      if (parentObject && k in parentObject) {
        Object.defineProperty(current, k, {
          writable: true,
          configurable: true,
          enumerable: true,
          value,
        })
        continue
      }

      current[k] = value
    }
  }

  return (scope || parentObject) as SubScope<S, T>
}
