import { createMethod, JsonValue, MethodSignature, Scope } from '@penbox-io/json-expression'
import { parseLocale } from '@penbox-io/stdlib'

const FALLBACK_LOCALE = 'en'

const asString = (val: unknown) => {
  switch (typeof val) {
    case 'number':
      return String(val)
    case 'string':
      return val
    default:
      return ''
  }
}

const PLACEHOLDER_REGEXP = /\$(\$|\d+)/g

type Type = {
  ':i18n': string | { [iso: string]: string }
  ':else'?: string
  ':values'?: string[]
  ':override'?: any
}

const isDebugEnabled = (scope: Scope) => (scope as any)?.options?.['pnbx:i18n:debug'] === true

/**
 * @todo Test this
 */
export default createMethod<Type>({
  name: ':i18n',
  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':i18n' || key === ':else' || key === ':values' || key === ':override') continue
      return false
    }

    if (typeof expr[':i18n'] === 'string' && expr[':i18n'] === '') return false
    if (typeof expr[':i18n'] === 'object') {
      if (Array.isArray(expr[':i18n'])) {
        return false
      }
      if (Object.keys(expr[':i18n'] as object).length === 0) {
        return false
      }
    }

    if (typeof expr[':else'] !== 'undefined' && typeof expr[':else'] !== 'string') return false
    if (typeof expr[':values'] !== 'undefined' && !Array.isArray(expr[':values'])) return false

    return true
  },
  evaluate(expr) {
    const { scope } = this
    if (!scope) return null

    const isV2 = typeof expr[':i18n'] === 'object'

    if (isDebugEnabled(scope)) return expr[':i18n']

    let message

    if (!isV2) {
      message =
        this.evaluate(expr[':override']) ??
        stringPathGetter(scope.$strings, scope.$locale, expr[':i18n'] as string) ??
        expr[':else']
    } else {
      const parsedLocal = parseLocale(scope.$locale)
      const parsedDefaultLocale = parseLocale(scope.$defaultLocale) ?? ['en']

      if (parsedLocal) {
        message =
          (expr[':i18n'] as { [iso: string]: string })[parsedLocal[0]] ??
          (expr[':i18n'] as { [iso: string]: string })[parsedDefaultLocale[0]]
      }
    }

    if (typeof message !== 'string') return null

    const values = expr[':values']
    if (!isV2 && values && message.includes('$')) {
      const length = values.length

      let replacementsCacheLazy

      message = message.replace(PLACEHOLDER_REGEXP, (_, pos) => {
        if (pos === '$') return '$'

        const index = Number(pos)

        // Optimization
        if (!(index < length)) return ''

        return ((replacementsCacheLazy ??= [])[index] ??= asString(this.evaluate(values[index])))
      })
    }

    if (!this.config.strict && message.includes('{')) {
      const result = this.evaluateString(message)
      if (result == null) return null // Fool-proof

      message = asString(result)
    }

    return message
  },
  compile(expr) {
    const isV2 = typeof expr[':i18n'] === 'object'

    const i18nKey = expr[':i18n'] as string
    const arrayPath = !isV2 ? i18nKey.split('.') : []
    const fallbackMessage = expr[':else'] ?? null
    const overrideMessage =
      expr[':override'] != null ? this.createContext(':override').compile(expr[':override']) : null

    let messageGetter = (scope: Scope): string | null => {
      if (!scope) return null
      let message: string | null = null

      if (isV2) {
        const parsedLocal = parseLocale(scope.$locale)
        const parsedDefaultLocale = parseLocale(scope.$defaultLocale) ?? ['en']

        if (parsedLocal) {
          message =
            (expr[':i18n'] as { [iso: string]: string })[parsedLocal[0]] ??
            (expr[':i18n'] as { [iso: string]: string })[parsedDefaultLocale[0]]
        }
      } else {
        message =
          overrideMessage?.(scope) ??
          arrayPathGetter(scope.$strings, scope.$locale, arrayPath) ??
          fallbackMessage
      }

      if (typeof message !== 'string') return null

      return message
    }

    if (!isV2 && expr[':values']?.length) {
      const length = expr[':values'].length
      const values = expr[':values'].map((value, i) =>
        this.createContext(':values', i).compile(value)
      )

      const orig = messageGetter
      messageGetter = (scope) => {
        const message = orig(scope)
        if (!message) return message

        // Optimization
        if (!message.includes('$')) return message

        let replacementsCacheLazy

        return message.replace(PLACEHOLDER_REGEXP, (_, pos) => {
          if (pos === '$') return '$'

          const index = Number(pos)

          // Optimization
          if (!(index < length)) return ''

          return ((replacementsCacheLazy ??= [])[index] ??= asString(values[index]?.(scope)))
        })
      }
    }

    if (!this.config.strict) {
      const { evaluator } = this
      const orig = messageGetter
      messageGetter = (scope) => {
        const message = orig(scope)
        if (!message) return message

        // Optimization
        if (!message.includes('{')) return message

        const result = evaluator.evaluateString(message, scope)
        if (result == null) return null

        return asString(result)
      }
    }

    // Re-define as const to allow compilier optimizations
    const orig = messageGetter
    return (scope: Scope) => {
      if (isDebugEnabled(scope)) return i18nKey
      return orig(scope)
    }
  },
})

export const translateString = (
  strings: object,
  locale: unknown,
  string: string,
  fallbackLocale?: unknown
): string | null => {
  if (typeof string === 'string') {
    if (string.startsWith('__I18N_') && string.endsWith('__')) {
      const key = string.slice(7, -2)
      return stringPathGetter(strings, locale, key, fallbackLocale) ?? ''
    }
  }

  return string
}

const createGetter = <T extends (this: any, strings: unknown) => string | null>(keyFinder: T) => {
  return (
    strings: unknown,
    locale: unknown,
    strKey: ThisParameterType<T>,
    fallbackLocale: unknown = FALLBACK_LOCALE
  ) => {
    if (strings == null || typeof strings !== 'object') return null
    const stringsMap = strings as Record<string, JsonValue>

    const parsedLocale = parseLocale(locale)
    if (parsedLocale) {
      const [lang, country] = parsedLocale
      const normalizedLocale = country ? `${lang}_${country}` : undefined

      if (normalizedLocale && stringsMap[normalizedLocale]) {
        const message = keyFinder.call(strKey, stringsMap[normalizedLocale])
        if (message !== null) return message
      }

      if (lang && stringsMap[lang]) {
        const message = keyFinder.call(strKey, stringsMap[lang])
        if (message !== null) return message
      }
    }

    if (locale !== fallbackLocale && typeof fallbackLocale === 'string') {
      if (stringsMap[fallbackLocale]) {
        const message = keyFinder.call(strKey, stringsMap[fallbackLocale])
        if (message !== null) return message
      }
    }

    return null
  }
}

const stringPathGetter = createGetter(function stringPathGetter(
  this: string,
  obj: unknown
): string | null {
  const { length } = this

  let cur: unknown = obj
  let pos = 0
  let prop: string
  let char: string

  do {
    prop = ''
    while (pos < length) {
      char = this[pos++]
      if (char === '.') break
      prop += char
    }

    cur = cur ? (cur as any)[prop] : undefined
    const type = typeof cur

    if (pos === length) {
      if (type === 'string') return cur as string

      // For i18n with count ?
      // if (Array.isArray(cur)) return cur

      return null
    }

    if (cur === null || type !== 'object') {
      return null
    }

    // For i18n with count ?
    // if (Array.isArray(cur)) return null
  } while (true)
})

const arrayPathGetter = createGetter(function (this: string[], obj: unknown): string | null {
  const { length } = this
  if (length === 0) return null

  let last: unknown = obj
  let i = 0
  let value

  while (i < length) {
    value = last ? (last as any)[this[i]] : undefined
    if (value == null) return null

    last = value
    i++
  }

  if (typeof last === 'string') return last

  return null
})
