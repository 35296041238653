if (!window.IntersectionObserver) {
  class FakeIntersectionObserver {
    constructor(callback) {
      this.callback = callback
    }

    observe(el) {
      const els = el.length >= 0 ? Array.prototype.slice.call(el) : [el]
      const entries = els.map((target) => ({ target, isIntersecting: true }))
      this.callback(entries, this)
    }

    unobserve(el) {}
  }

  window.IntersectionObserver = FakeIntersectionObserver
}
