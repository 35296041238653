import { formatValue } from '@penbox-io/stdlib'

import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'

type Value = unknown
type Options = {
  label?: FormHelpable
}
type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    label: ifFormHelpable(input.label),
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  return value ?? null
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (required && !isNonEmpty(value)) {
    return buildError('required', locale)
  }

  return null
}

function normalize(element: Element): undefined | Value {
  return element.value
}

function stringify(element: Element): undefined | string {
  return formatValue(element.value, element)
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}

function isNonEmpty(v: unknown): boolean {
  if (v == null) return false
  if (typeof v === 'string') return v.length > 0
  if (Array.isArray(v)) return v.length > 0
  if (Number.isNaN(v)) return false

  return true
}
