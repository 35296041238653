import { Scope } from '../types'
import { createMethodLegacySimple } from '../legacy'

/**
 * Returns true if the result of the expression is `null`.
 *
 * @usage: ```{':null': expression}```
 * where:
 * - `expression` is any valid expression
 *
 * @example
 * - ```{':null': null}``` returns `true`
 * - ```{':null': 1}``` returns `false`
 */
export default createMethodLegacySimple(
  ':null',
  function (args): boolean {
    return this.evaluate(args) === null
  },
  function (compiledExpr) {
    return (scope: Scope) => compiledExpr(scope) === null
  }
)
