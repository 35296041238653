import { JsonValue, MethodSignature } from '../types'
import { createMethod, isArray } from '../util'

import { compileIn, computeIn } from './in'

type Type = { ':includes': [JsonValue, JsonValue] }

/**
 * Check if a value is in a list of values.
 *
 * @usage: ```{ ':includes': [array, value] }```
 * where:
 * - array: array: the list of values to search in
 * - value: any: the value to search for
 *
 * @example
 * ```{ ':includes': [[1, 2, 3], 1] }``` => true
 * ```{ ':includes': [[1, 2, 3], 4] }``` => false
 */
export default createMethod<Type>({
  name: ':includes',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':includes') continue
      return false
    }

    if (!isArray(expr[':includes'])) return false
    if (expr[':includes'].length !== 2) return false

    return true
  },

  evaluate(expr): undefined | boolean {
    return computeIn.call(this, expr[':includes'][1], expr[':includes'][0])
  },

  compile(expr) {
    const needleGetter = this.createContext(':includes', 1).compile(expr[':includes'][1])
    const haystackGetter = this.createContext(':includes', 0).compile(expr[':includes'][0])

    return compileIn.call(this, needleGetter, haystackGetter)
  },
})
