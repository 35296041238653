import { isIBAN, matchesIBAN, normalizeIBAN } from '@penbox-io/iban-validator'
import { ifString } from '@penbox-io/stdlib'

import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'
import { spaceOutIBAN } from '../utils/bank-account.js'

type Value = string
type Options = {
  hint?: string
  placeholder?: string
  label?: FormHelpable
  format: 'iban'
}
type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    label: ifFormHelpable(input.label),
    hint: ifString(input.hint),
    placeholder: ifString(input.placeholder),
    format: 'iban',
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  if (value && typeof value === 'string') {
    return value.trim() || null
  }

  return null
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (value == null) {
    if (required) return buildError('required', locale)
    return null
  }

  if (isIBAN(value)) return null

  return buildError('iban', locale)
}

function normalize(element: Element): undefined | Value {
  if (element.value) {
    const normalized = normalizeIBAN(element.value)
    if (matchesIBAN(normalized)) return normalized
  }

  return undefined
}

function stringify(element: Element): undefined | string {
  const normalized = normalize(element)
  if (!normalized) return undefined

  return spaceOutIBAN(normalized)
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}
