import { CompilationContext } from '../compilation'
import { subScope } from '../scope'
import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isArray } from '../util'

type Type = { ':pipe': JsonValue[]; ':init'?: JsonValue }

/**
 * Pipes a value through a series of expressions.
 * @usage: ```{":pipe": [expression1, expression2, ...], ":init": expression}```
 *
 * where:
 * - `expression1`, `expression2`, ... are expressions that will be evaluated in sequence
 * - `init` is an optional initial value - returned if the pipe is empty
 *
 * @sub-scope variables
 * - `@` the current value being processed
 *
 * @example
 * - ```{":pipe": [{":split": "Hello world", ":separator": " "}, {":map": ["{@}", "{@item}_edit"]}]}``` returns `["Hello_edit", "world_edit"]`
 *
 * @real world example
 * Split the content of a file by lines and then split each line by tabs.
 *
 * ```
 * {
 * "normalized": {
 *   ":pipe": [
 *     {
 *       ":split": "{input_file_content}",
 *       ":separator": "\n"
 *     },
 *     {
 *       ":map": [
 *         "{@}",
 *         {
 *           ":split": "{@item}",
 *           ":separator": "\t"
 *         }
 *       ]
 *     }
 *   ]
 * }
 * ```
}

 */
export default createMethod<Type>({
  name: ':pipe',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':pipe') continue
      if (key === ':init') continue
      return false
    }

    if (!isArray(expr[':pipe'])) return false

    return true
  },

  evaluate(expr) {
    let result = this.evaluate(expr[':init'])

    for (let i = 0; i < expr[':pipe'].length; i++) {
      const ctx = this.subScope({ '@': result })
      result = ctx.evaluate(expr[':pipe'][i])
    }

    return result
  },

  compile(expr) {
    const init = this.createContext(':init').compile(expr[':init'])
    const pipe = expr[':pipe'].map(compilePipeItem, this.createContext(':pipe'))

    const { length } = pipe

    // Optimisation
    if (length === 0) return init
    if (length === 1) {
      const [compiled] = pipe
      return (scope: Scope) => compiled(subScope(scope, { '@': init(scope) }))
    }

    return (scope: Scope) => {
      let result = init(scope)

      // Optimisation: create a re-usable scope object
      const childScope = Object.create(scope ?? null, {
        '@': { configurable: true, enumerable: true, get: () => result },
      })

      for (let i = 0; i < length; i++) {
        // const childScope = subScope(scope, { '@': result })
        result = pipe[i](childScope)
      }

      return result
    }
  },
})

function compilePipeItem(this: CompilationContext, item: JsonValue, index: number) {
  const ctx = this.createContext(index)
  return ctx.compile(item)
}
