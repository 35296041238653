import { CompilationContext, CompilationResult, CompiledExpression } from '../compilation'
import { TestContext } from '../config'
import { EvaluationContext } from '../evaluation'
import { subScopeDefine } from '../scope'
import { Define, JsonValue, MethodCall, MethodSignature, Scope, Value } from '../types'
import { createMethod, isArray, isDefine, isPlainObject, isStatic, jsonify } from '../util'

type LegacyType = { ':define': JsonValue; ':in': JsonValue }
type StrictType = { ':define': Define; ':in': JsonValue }
type Type = LegacyType | StrictType

/**
 * Define variables in a new scope and evaluate an expression in that scope.
 * @usage: ```{ ':define': { var1: expr1, var2: expr2, ... }, ':in': expr }```
 * where:
 * - :define: an object with variable definitions for the new scope
 * - :in: any: the expression to evaluate in the new scope
 *
 * @example
 * ```{ ':define': { x: 1 }, ':in': { x: 2 } }``` => 2
 * ```{ ':define': { x: 1 }, ':in': { x: { ':sum': ['{x}', 1] } } }``` => 2
 *
 * @real world example
 * You need to calculate the total price of a shopping cart.
 * scope: ```{ items: [{ price: 10 }, { price: 20 }] }```
 * ```{ ':define': { total: { ':sum': ['{items.price}'] } }, ':in': '{total}' }``` => 30
 *
 * Extra: How to define a reusable function?
 * ```
 * {
 * ":define": [{
 * "duplicateInArray": {
 *    ":raw": ["{a}", "{a}"]
 *  }
 * }],
 * ":in": {
 *   "duplicatedArray": "{> duplicateInArray}"
 *  }
 * }
 * ```
 * Here we define a function `duplicateInArray` that duplicates an item in an array. We use the ":raw" method to define the function as a raw expression (not evaluated).
 * Then we use the function in the ":in" expression by referencing it with the "{> duplicateInArray}" syntax. the ">" character is used to evaluate a raw expression. It is a shortcut for `{":eval": "{duplicateInArray}"}`.
 */
export default createMethod<Type>({
  name: ':define',

  test<C extends TestContext>(
    this: C,
    expr: MethodCall
  ): expr is true extends C['strict'] ? MethodSignature<StrictType> : MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':define' || key === ':in') continue
      return false // Error: Unknown key
    }

    if (typeof expr[':in'] === 'undefined') return false
    if (this.strict && !isDefine(expr[':define'])) return false

    return true
  },

  evaluate(expr): Value {
    return evalDefine.call(this, expr[':define'], expr[':in'])
  },

  compile(expr) {
    const inCompiled = this.createContext(':in').compile(expr[':in'])

    const varsGetters = compileDefine.call(this.createContext(':define'), expr[':define'])
    if (!varsGetters) return undefined

    // Optimization
    if (inCompiled.static) return inCompiled

    // Optimization
    if (varsGetters.length === 0) return inCompiled

    return (parent: Scope): Value => {
      const childScope = subScopeDefine(parent, varsGetters)
      return inCompiled(childScope)
    }
  },
})

export function evalDefine(
  this: EvaluationContext,
  defineExpr: Define | JsonValue,
  inExpr: unknown
): Value {
  // Optimization
  if (isStatic(inExpr)) return jsonify(inExpr) // TODO: clone ?

  const ctx = this.subScopeEval(defineExpr)
  return ctx.evaluate(inExpr)
}

export function compileDefine(this: CompilationContext, item: Define | JsonValue) {
  const defineCompiled = isArray(item)
    ? item.flatMap(compileArrayItem, this)
    : [compileDefinitionRecord.call(this, item)]

  // Validate
  if (this.config.strict && !defineCompiled.every(Boolean)) return undefined

  return defineCompiled.filter(Boolean) as Array<CompiledExpression<Record<string, Value>>>
}

function compileArrayItem(
  this: CompilationContext,
  item: Define | JsonValue,
  index: number,
  _array: ReadonlyArray<Define | JsonValue>
): Array<CompilationResult<Record<string, Value>>> {
  const context = this.createContext(index)
  if (isArray(item)) return item.flatMap(compileArrayItem, context)
  else return [compileDefinitionRecord.call(context, item)]
}

function compileDefinitionRecord(
  this: CompilationContext,
  define: Define | JsonValue
): CompilationResult<Record<string, Value>> {
  // Legacy (1)
  if (!isPlainObject(define)) return undefined

  const newVarsCompiled: Record<keyof typeof define, CompiledExpression> = {}

  for (const k in define) {
    if (k === '$schema') continue // Reserve for future use

    newVarsCompiled[k] = k.startsWith(':')
      ? // Defining methods is not supported (yet). Prevent accessing method
        // defined in parent scopes to support future use of :keyed properties.
        () => undefined
      : this.createContext(k).compile(define[k])
  }

  // Optimization
  if (Object.keys(newVarsCompiled).length === 0) return () => ({})

  return (scope: Scope): Record<keyof typeof define, Value> => {
    const newVars: Record<string, Value> = {}
    for (const k in newVarsCompiled) {
      const compiled = newVarsCompiled[k]
      newVars[k] = compiled(scope)
    }
    return newVars
  }
}
