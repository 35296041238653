import { createMethodLegacySimple } from '../legacy'
import { isArray, isDate, isNumber } from '../util'

/**
 * Returns the minimum value of an array of numbers or dates.
 *
 * @usage: ```{':min' array}```
 * where:
 * - `array` is an array of numbers or dates
 *
 * @example
 * - ```{':min': [1, 2, 3]}``` returns `1`
 * - ```{':min': [{ ':date': '2022-01-12' }, { ':date': '2021-01-12' }, { ':date': '2024-01-12' }]}``` returns `new Date('2021-01-12T00:00:00.000Z')`
 */
export default createMethodLegacySimple(':min', function (args): undefined | Date | number {
  const evaled = !isArray(args)
  const array = isArray(args) ? args : this.evaluate(args)

  if (!isArray(array)) return undefined

  let current: Date | number | undefined

  for (let i = 0; i < array.length; i++) {
    const item = evaled ? array[i] : this.evaluate(array[i])
    if (isNumber(item) || isDate(item)) {
      if (current === undefined || item.valueOf() < current.valueOf()) current = item
    }
  }

  return current
})
