import { mdiAlertCircle } from '@mdi/js'
import { colors } from '@penbox-io/branding'

export default {
  icons: {
    iconfont: 'mdiSvg',
    values: {
      warning: mdiAlertCircle,
    },
  },
  theme: {
    options: { variations: true },
    themes: {
      light: { ...colors, 'deep-purple': colors.deepPurple },
    },
  },
}
