import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import type { Choice, Value, ChoicesValue } from '../utils/choice.js'
import { findChoice, normalizeChoices, stringifyChoice } from '../utils/choice.js'
import { type FormError, buildError } from '../utils/error.js'

export { Value }
export type Options<V extends Value = Value> = {
  label?: FormHelpable
  choices: Array<Choice<V>>
  allowCustom?: boolean
  submitOnChange?: boolean
}

type OptionsValue<O extends Options> = O extends { allowCustom: true }
  ? ChoicesValue<O['choices']> | string
  : ChoicesValue<O['choices']>

export type Element<O extends Options = Options> = GenericElement<O, OptionsValue<O>>

export default {
  options,
  requirable,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

export function options(input: Dict, locale: string): Options {
  return {
    label: ifFormHelpable(input.label),
    choices: input.choices ? normalizeChoices(input.choices, locale, input) : [],
    allowCustom: input.allow_custom === true,
    submitOnChange: input.submit_on_change === true,
  }
}

export function requirable({ choices }: Options): boolean {
  return choices.length > 0
}

export function parse<X, O extends Options>(
  options: O,
  locale: string,
  v: X
): X extends OptionsValue<O> ? X : null
export function parse(options: Options, locale: string, value: unknown): null | Value {
  // Optimization: no need to search for the "null" choice is we sill return
  // "null" anyway.
  if (value == null) return null

  if (options.allowCustom && value && typeof value === 'string') {
    return value
  }

  const choice = findChoice(value, options.choices)
  if (choice) return choice.value

  return null
}

export function validate<O extends Options = Options>(
  options: O,
  locale: string,
  value: null | OptionsValue<O>,
  required: boolean
): null | FormError {
  if (required && value === null) {
    // ignore "required" when there are no choices
    if (!options.allowCustom && options.choices.length === 0) return null
    // If there is a "null" value choice, we're good
    if (findChoice(null, options.choices)) return null

    return buildError('requiredChoice', locale)
  }

  return null
}

export function normalize(element: Element): undefined | Value {
  // If there is a "null" value choice, we're good
  if (element.value === null) {
    return findChoice(null, element.options.choices) ? null : undefined
  }

  return element.value
}

export function stringify(element: Element): undefined | string {
  if (element.value === null || !element.error) {
    const choice = findChoice(element.value, element.options.choices)
    if (choice) return stringifyChoice(choice)

    if (element.options.allowCustom && typeof element.value === 'string') {
      return element.value
    }
  }

  return undefined
}

export function stringifyTitle<E extends Element>(element: E): undefined | string {
  return stringifyHelpable(element.title || element.options.label)
}
