import { isIterable } from './iterator'

export type MaybeArray<T> = T | T[]
export type NestedArray<T> = Array<T | NestedArray<T>>
export type ArrayItem<T> = T extends ReadonlyArray<infer U> ? U : never

const isArrayNative = Array.isArray

export function isArray<T>(value: unknown, predicate: (v: unknown) => v is T): value is T[]
export function isArray(value: unknown): value is any[]

export function isArray(value: unknown, predicate?: (v: unknown) => boolean): value is any[] {
  return isArrayNative(value) && (predicate ? value.every(predicate) : true)
}

export const indexTransformer = <T, R>(
  shouldTransform: (index: number) => boolean,
  fn: (item: T, index: number) => R
): ((iterable: Iterable<T>, thisArg?: ThisParameterType<typeof fn>) => Array<T | R>) => {
  return (iterable: Iterable<T>, thisArg?: ThisParameterType<typeof fn>) =>
    Array.from(iterable, mapFn, thisArg)
  function mapFn(this: ThisParameterType<typeof fn>, item: T, i: number): T | R {
    return shouldTransform(i) ? fn.call(this, item, i) : item
  }
}

export const uniqueFilter = (value: any, index: number, array: any[]): boolean =>
  array.indexOf(value) === index

export const sortedUniqueFilter = (value: any, index: number, array: any[]): boolean =>
  (index === 0 || value !== array[index - 1]) &&
  (index === array.length - 1 || value !== array[index + 1])

export const intersect = <T = void>(a?: T[], b?: T[]): T[] =>
  !isArrayNative(a) || a.length === 0 || !isArrayNative(b) || b.length === 0
    ? []
    : a === b
    ? Array.from(a)
    : a.filter(Set.prototype.has, new Set(b))

type AsItem<T> = T extends null | undefined
  ? never
  : T extends string
  ? T
  : T extends ReadonlyArray<infer U>
  ? U
  : T extends Iterable<infer U>
  ? U
  : T extends AsyncIterable<infer U>
  ? U
  : T

export function asArray<T, F extends (this: any, v: AsItem<T>, k: number) => any>(
  v: T,
  mapFn: F,
  thisArg: ThisParameterType<F>
): Array<ReturnType<F>>
export function asArray<T, F extends (v: AsItem<T>, k: number) => any>(
  v: T,
  mapFn: F
): Array<ReturnType<F>>
export function asArray<T>(v: T): Array<AsItem<T>>

export function asArray<T>(
  v: T,
  mapFn?: (v: AsItem<T>, k: number) => any,
  thisArg?: ThisParameterType<typeof mapFn>
): typeof mapFn extends (...args: any[]) => infer R ? R[] : Array<AsItem<T>> {
  if (v == null) {
    return []
  }

  if (isArrayNative(v)) {
    return typeof mapFn === 'function' ? v.map(mapFn, thisArg) : v
  }

  if (typeof v !== 'string' && isIterable<AsItem<T>>(v)) {
    return typeof mapFn === 'function' ? Array.from(v, mapFn, thisArg) : Array.from(v)
  }

  return [typeof mapFn === 'function' ? mapFn.call(thisArg, v as AsItem<T>, 0) : v]
}

export const isArrayLike = <T>(subject: any): subject is ArrayLike<T> =>
  isArrayNative(subject) ||
  (subject !== null &&
    typeof subject === 'object' &&
    typeof subject.length === 'number' &&
    (subject.length === 0 || (subject.length > 0 && subject.length - 1 in subject)))

/**
 * @note This function has been highly optimized to use a stack instead of recursive calls
 * @example
 *  // Equivalent to:
 *  export function* flatten(input: any[]): Generator<any> {
 *    for (const item of input) {
 *      if (isArray(item)) yield* flatten(item)
 *      else yield item
 *    }
 *  }
 */
export function* flatten<T>(input: NestedArray<T>): Generator<T, void, unknown> {
  // Optimization: nothing to flatten
  if (input.length === 0) return

  let head: undefined | { array: NestedArray<T>; index: number } = { array: input, index: 0 }
  let item

  // Optimization: use a stack instead of recursive calls
  const stack: Array<NonNullable<typeof head>> = []

  do {
    while (head.index < head.array.length) {
      item = head.array[head.index++]

      if (isArrayNative(item)) {
        // Optimization: skip flattening empty array
        if (item.length === 0) continue

        stack.push(head)

        head = { array: item, index: 0 }
      } else {
        yield item
      }
    }
  } while ((head = stack.pop()))
}

// Move an element in array from position a to position b
export function moveElement<T>(array: T[], from: number, to: number): T[] {
  if (!Number.isInteger(from)) throw new TypeError('From index should be an integer')
  if (!Number.isInteger(to)) throw new TypeError('To index should be an integer')
  if (from < 0 || from > array.length - 1) throw new TypeError('Invalid from index')
  if (to < 0 || to > array.length - 1) throw new TypeError('Invalid to index')
  if (from === to) return array

  const element = array[from]
  const result = array.filter((_, i) => i !== from)
  result.splice(to, 0, element)
  return result
}

export function numberArrayStartsWith(a: ArrayLike<number>, header: ArrayLike<undefined | number>) {
  const { length } = header

  // Foolproof against non array-like inputs
  if (!(a?.length >= length)) {
    return false
  }

  for (let i = 0; i < length; i++) {
    if (header[i] === undefined) continue
    if (header[i] !== a[i]) return false
  }

  return true
}

export function toFlatArray(input: any): any[] {
  const array = JSON.parse(JSON.stringify(input))

  if (Array.isArray(array)) {
    return array?.flat(Infinity)?.map((child) => {
      const newChild = child
      if (typeof newChild !== 'object') {
        return newChild
      } else {
        for (const k in child) {
          if (Array.isArray(child[k])) {
            newChild[k] = toFlatArray(child[k])
          } else {
            newChild[k] = child[k]
          }
        }
        return newChild
      }
    })
  } else {
    return array
  }
}

export function arrayEquals(arr1: any[], arr2: any[]) {
  if (!isArray(arr1) || !isArray(arr2)) return false
  if (arr1 === arr2) return true // Same reference
  if (arr1.length !== arr2.length) return false // Different lengths

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false // Different elements
  }

  return true // Arrays are equal
}
