import { asArray, formatValue, ifArray, parseFilename, toDate } from '@penbox-io/stdlib'
import { getHelpableText } from './util.js'
import * as countries from 'i18n-iso-countries'

const extract = (prop) => (obj) => obj[prop]

export const formatElementTitle = (el) => getHelpableText(el.title ?? el.label)

export const stringifyElementValue = (el, { locale = 'en', loose = true } = {}) => {
  const value = formatElementValue(el, { loose })
  return stringifyFormattedValue(el, value, { locale, loose })
}

export const stringifyFormattedValue = (el, value, { locale = 'en', loose = true } = {}) => {
  if (value === undefined && !loose) return undefined
  if (value == null) return formatValue(value, { locale })

  switch (el.type) {
    case 'rating':
      if (!(value >= 0 && value <= 5)) break
      return Array.from(Array(5), (_, i) => (i < value ? '★' : '☆')).join('')

    case 'date': {
      const date = toDate(value)
      if (!(date instanceof Date)) break
      return new Intl.DateTimeFormat(locale ?? 'en', {
        dateStyle: 'short',
        timeStyle: undefined,
      }).format(date)
    }

    case 'datetime': {
      const date = toDate(value)
      if (!(date instanceof Date)) break
      return new Intl.DateTimeFormat(locale ?? 'en', {
        dateStyle: 'short',
        timeStyle: 'short',
      }).format(date)
    }

    case 'card':
      if (!Array.isArray(value)) break
      return value.map((action) => `${action.label} (${action.href})`).join('\n')

    case 'checkboxes':
    case 'toggles':
    case 'radio':
    case 'choices':
      if (Array.isArray(value)) {
        return el.choices
          .filter((choice) => value.includes(choice?.value))
          .map(stringifyChoice)
          .join(', ')
      } else {
        return (
          el.choices.filter((choice) => choice?.value === value).map(stringifyChoice)[0] ||
          undefined
        )
      }

    case 'file':
      if (typeof value !== 'object') break
      return asArray(value, extract('name')).join(', ')

    case 'phone':
      if (typeof value !== 'string') break
      return value

    case 'autocomplete':

    case 'hidden':
    case 'text':
    case 'toggle':
    case 'checkbox':
      return formatValue(value, { locale })

    case 'country':
      countries.registerLocale(require(`i18n-iso-countries/langs/${locale}.json`))
      return countries.getName(value, locale)
  }

  // Invalid element type

  if (!loose) return undefined

  return formatValue(value, { locale })
}

function stringifyChoice(choice) {
  return getHelpableText(choice.label) ?? String(choice.value)
}

/**
 *
 * @param {{ key?: string; type: string; value: any; options: { custom_name?: string; choices?: { label: string | { text: string }; value: any }[]; actions?: { title?: string; href?: string; key?: string }[] } }} el
 * @param {{ loose?: boolean }} [options]
 */
export const formatElementValue = (el, { loose = true } = {}) => {
  const { value } = el
  const valueType = typeof value
  switch (el.type) {
    case 'rating': {
      if (value == null) return value
      if (!(value >= 0 && value <= 5)) break

      return value
    }

    case 'card': {
      if (valueType !== 'object' && valueType !== 'undefined') break

      return ifArray(el.options?.actions)?.map((a) => ({
        title: a.title,
        value: a.key ? value?.[a.key] ?? false : null,
        href: a.href,
        icon: a.icon,
      }))
    }

    case 'submit':
    case 'datetime':
    case 'date': {
      if (value == null) return value
      const date = new Date(value)
      if (isNaN(date.valueOf())) break

      return date
    }

    case 'phone': {
      if (value == null) return value
      if (valueType !== 'string') break

      return value
    }

    case 'hidden': {
      return value
    }

    case 'bank-account': {
      if (value == null) return value
      if (valueType !== 'string') break

      return value
    }

    case 'text': {
      if (value == null) return value
      if (valueType === 'number') return value
      if (valueType !== 'string') break

      const { prefix = undefined, suffix = undefined } = el.options ?? {}
      return `${prefix ?? ''}${value}${suffix ?? ''}`
    }

    case 'toggle':
    case 'checkbox': {
      if (value == null) return value
      if (valueType !== 'boolean') break

      return value
    }

    case 'autocomplete': {
      if (value == null) return value
      if (!Array.isArray(el.options?.choices)) break // Invalid flow options

      const choice = el.options.choices.find((c) => c.value === value)
      if (!choice) return value // Custom user input

      return getHelpableText(choice.label)
    }

    case 'checkboxes':
    case 'toggles':
    case 'radio': {
      if (value == null) return value
      if (!Array.isArray(el.options?.choices)) break // Invalid flow options

      return el.options.choices.map((c) => ({
        label: getHelpableText(c.label),
        value: Array.isArray(value) ? value.includes(c.value) : value === c.value,
      }))
    }

    case 'file': {
      if (value != null && valueType !== 'object' && valueType !== 'string') break

      const files = asArray(value, normalizeFile, el).filter(Boolean)
      return files.length === 0 ? null : files
    }
  }

  // In loose mode, only primitive types are allowed

  if (loose) {
    if (valueType === 'string') return value
    if (valueType === 'number') return value
    if (valueType === 'boolean') return value
  }

  // The value is not valid...

  return undefined
}

/** @this {{ options: { custom_name?: string } }} */
function normalizeFile(value, index = undefined) {
  if (!value) return null
  const norm = typeof value === 'string' ? parseLegacyAttachment(value) : value

  const filename = getFilename(this, norm, index)
  if (!filename) return null

  return { ...norm, filename, type: norm.type || extToType(norm.name) }
}

const parseLegacyAttachment = (v) => ({
  id: v.match(/^attachments:\/\/\/([^/]+)(?:\?.*)$/)?.[1],
  name: decodeURIComponent(v.match(/[?&]name=([^&]*)(?:&.*)?$/)?.[1] || ''),
})

const getFilename = (element, { name, type }, index = undefined) => {
  const [, , ext = typeToExt(type) ?? ''] = name ? parseFilename(name) : []

  // TODO: How do we handle this ?
  // if (!ext) return null

  const customName = element.options.custom_name
  if (customName) return `${customName}${index >= 0 ? ` - ${index + 1}` : ''}${ext}`

  return name
}

// Only supports images for now.
// TODO: use external lib ?
const typeToExt = (type) => {
  if (!type) return null

  const match = String(type).match(/^image\/(bmp|gif|png|apng|tiff|webp|jpg)$/)
  if (match) return `.${match[1]}`

  if (type === 'image/svg+xml') return '.svg'

  return undefined
}

// Only supports images for now.
// TODO: use external lib ?
const extToType = (name) => {
  if (!name) return undefined

  const match = String(name).match(/\.(bmp|gif|png|apng|tiff|webp|jpg)$/i)
  if (match) return `image/${match[1].toLowerCase()}`
  if (String(name).endsWith('.svg')) return 'image/svg+xml'
  if (String(name).endsWith('.jpeg')) return 'image/jpg'

  return undefined
}
