/* Error "cause" polyfill */

// eslint-disable-next-line @typescript-eslint/ban-types
type CaptureStackTrace = (targetObject: object, constructorOpt?: Function) => void

interface ErrorPolyfillConstructor extends ErrorConstructor {
  new (message?: string, options?: { cause?: unknown }): Error & { cause?: unknown }
  (message?: string, options?: { cause?: unknown }): Error & { cause?: unknown }

  captureStackTrace: CaptureStackTrace
}

// eslint-disable-next-line @typescript-eslint/ban-types
const captureStackTrace: undefined | CaptureStackTrace = (Error as any).captureStackTrace

const ErrorPolyfill: ErrorPolyfillConstructor =
  'cause' in new (Error as any)('', { cause: '' })
    ? (Error as ErrorPolyfillConstructor)
    : ((Super) => {
        function Error(this: Error, message?: string, options?: { cause?: unknown }) {
          // @ts-expect-error
          if (!(this instanceof Error)) return new Error(message, options)
          Super.call(this, message)
          captureStackTrace?.(this, Error)
          setCause(this, options?.cause)
        }
        Error.prototype = Object.create(Super.prototype)
        Error.prototype.constructor = Error
        Error.prototype.name = 'Error'
        Error.captureStackTrace = captureStackTrace
        return Error as ErrorPolyfillConstructor
      })(Error)

export { ErrorPolyfill as Error }

/* AggregateError (+ "cause") polyfill */

interface AggregateErrorPolyfillConstructor extends AggregateErrorConstructor {
  new (errors: Iterable<any>, message?: string, options?: { cause?: unknown }): AggregateError
  (errors: Iterable<any>, message?: string, options?: { cause?: unknown }): AggregateError

  captureStackTrace: CaptureStackTrace
}

export const AggregateErrorNative =
  typeof AggregateError !== 'undefined' ? AggregateError : undefined

const AggregateErrorPolyfill =
  AggregateErrorNative && 'cause' in new (AggregateErrorNative as any)([], '', { cause: '' })
    ? (AggregateErrorNative as AggregateErrorPolyfillConstructor)
    : ((Super) => {
        function AggregateError(
          this: AggregateError,
          errors: Iterable<any>,
          message = '',
          options?: { cause?: unknown }
        ) {
          // @ts-expect-error
          if (!(this instanceof AggregateError)) return new AggregateError(errors, message, options)
          Super.call(this, message)
          captureStackTrace?.(this, AggregateError)
          setErrors(this, errors)
          setCause(this, options?.cause)
        }
        AggregateError.prototype = Object.create(Super.prototype)
        AggregateError.prototype.constructor = AggregateError
        AggregateError.prototype.name = 'AggregateError'
        AggregateError.captureStackTrace = captureStackTrace
        return AggregateError as AggregateErrorPolyfillConstructor
      })(AggregateErrorNative ?? ErrorPolyfill)

export { AggregateErrorPolyfill as AggregateError }

/* Utilities */

export type AsErrorOptions = {
  cause?: unknown
  message?: string
  ctor?: (...args: any[]) => any
}

export function asError(err: unknown, options?: AsErrorOptions): Error & { cause?: unknown } {
  if (err instanceof Error) return setCause(err, options?.cause)
  if (Array.isArray(err) && err.length <= 1) return asError(err[0], options)

  const message = extractMessage(err) || options?.message || 'Unexpected error'
  const error = Array.isArray(err)
    ? new AggregateErrorPolyfill(err, message, options)
    : Object.assign(new ErrorPolyfill(message, options), err)
  captureStackTrace?.(error, options?.ctor ?? asError)
  return error
}

export function thrower(err?: unknown, options?: AsErrorOptions): never {
  throw asError(err, options?.ctor ? options : { ...options, ctor: options?.ctor ?? thrower })
}

/* Internal utilities */

function extractMessage(err: unknown) {
  if (err) {
    switch (typeof err) {
      case 'string': {
        return err
      }
      case 'object': {
        const { message } = err as any
        if (typeof message === 'string') return message
        break
      }
    }
  }
  return undefined
}

function setCause<E extends Error, C = unknown>(err: E, cause?: C): E & { cause?: C } {
  if (cause !== undefined && !Object.prototype.hasOwnProperty.call(err, 'cause')) {
    Object.defineProperty(err, 'cause', {
      value: cause,
      writable: true,
      enumerable: false,
      configurable: true,
    })
  }
  return err
}

function setErrors<E extends Error, T = unknown>(err: E, errors: Iterable<T>): E & { errors: T[] } {
  if (!Object.prototype.hasOwnProperty.call(err, 'errors')) {
    Object.defineProperty(err, 'errors', {
      value: Array.from(errors),
      writable: true,
      enumerable: false,
      configurable: true,
    })
  }
  return err as E & { errors: T[] }
}
