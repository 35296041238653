import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod } from '../util'

import { parseDate } from './date'

type Type = { ':format-date': JsonValue; ':pattern': JsonValue; ':locale': JsonValue }

/**
 * Formats a date using a pattern.
 * @usage: ```{ ':format-date': date, ':pattern': string, ':locale': string }```
 *
 * where:
 * - :format-date: string: the date string to format
 * - :pattern?: string: the pattern to use for formatting the date string
 * - :locale?: string: the locale to use for formatting the date string
 *
 * @example
 * ```{ ':format-date': '2021-01-01', ':pattern': 'yyyy-MM-dd' }``` => '2021-01-01'
 * ```{ ':format-date': '2021-01-01', ':pattern': 'yyyy/MM/dd' }``` => '2021/01/01'
 * ```{ ':format-date': '2021-01-01', ':locale': 'fr' }``` => '01/01/2021'
 */
export default createMethod<Type>({
  name: ':format-date',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':format-date' || key === ':pattern' || key === ':locale') continue
      return false
    }

    if (expr[':format-date'] === undefined) return false

    return true
  },

  evaluate(expr) {
    const pattern = this.evaluate(expr[':pattern'])
    const locale = this.evaluate(expr[':locale'])

    if (pattern && typeof pattern !== 'string') return undefined
    if (locale && typeof locale !== 'string') return undefined

    const date = parseDate(this.evaluate(expr[':format-date']))
    if (date === undefined) return undefined

    if (pattern) {
      return formatDate(date, pattern as string)
    }

    return new Intl.DateTimeFormat(locale as string, {
      dateStyle: 'short',
      timeStyle: undefined,
    }).format(date)
  },

  compile(expr) {
    const date = this.createContext(':format-date').compile(expr[':format-date'])
    const pattern = this.createContext(':pattern').compile(expr[':pattern'])
    const locale = this.createContext(':locale').compile(expr[':locale'])

    return (scope: Scope) => {
      const patternValue = pattern(scope)
      if (patternValue && typeof patternValue !== 'string') return undefined

      const localeValue = locale(scope)
      if (localeValue && typeof localeValue !== 'string') return undefined

      const dateValue = parseDate(date(scope))
      if (dateValue === undefined) return undefined

      if (patternValue) {
        return formatDate(dateValue, patternValue as string)
      }

      return new Intl.DateTimeFormat(localeValue as string, {
        dateStyle: 'short',
        timeStyle: undefined,
      }).format(dateValue)
    }
  },
})

export function formatDate(date: Date, pattern: string): undefined | string {
  let result = ''
  for (let i = 0; i < pattern.length; i++) {
    const char = pattern[i]
    switch (char) {
      case '\\':
        // Escape character, insert next character as-is
        i++
        result += i < pattern.length ? pattern[i] : ''
        break
      case 'd':
        result += String(date.getDate())
        break
      case 'D':
        result += String(date.getDate()).padStart(2, '0')
        break
      case 'm':
        result += String(date.getMonth() + 1)
        break
      case 'M':
        result += String(date.getMonth() + 1).padStart(2, '0')
        break
      case 'y':
        result += String(date.getFullYear()).slice(-2)
        break
      case 'Y':
        result += String(date.getFullYear())
        break
      case ' ':
      case '-':
      case '/':
      case ':':
      case '.':
        result += char
        break
      default:
        // Ignore "unrecognized" pattern characters
        break
    }
  }
  return result
}
