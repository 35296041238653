import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { StringTransformer } from './'
import { findLast, findQuotes } from './utils'

/**
 * divide two values.
 *
 * @usage: ```expr / expr2```.
 */
class DivideStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const quotes = findQuotes(expr)

    const operator = findLast(expr, '/', quotes)

    return operator !== -1
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    const quotes = findQuotes(expr)
    const operator = findLast(expr, '/', quotes)

    const left = expr.slice(0, operator).trim()
    const right = expr.slice(operator + 1).trim()

    return this.evaluateMethodCall({ ':divide': [`{${left}}`, `{${right}}`] })
  }
}

export default new DivideStringTransformer()
