// TODO: move to stdlib ?

export type Dict<T = any> = { [_ in string]?: T }

export function ifDict(value: unknown): Dict | undefined {
  return isDict(value) ? value : undefined
}

export function isDict(value: unknown): value is Dict {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export function asDict<T>(
  input: unknown,
  predicate: (v: unknown) => v is T,
  ignoreUndefined = true
): undefined | Dict<T> {
  if (input == null) return undefined
  if (!isDict(input)) return undefined

  const copy: Dict<T> = {}
  let valid = true

  for (const key in input) {
    if (ignoreUndefined && input[key] === undefined) continue
    if (predicate(input[key])) {
      copy[key] = input[key]
    } else {
      valid = false
    }
  }

  if (valid) return input as Dict<T>

  return copy
}
