import { JsonValue, MethodSignature } from '../types'
import { createMethod, isArray, toNumber } from '../util'

type Type = { ':power': [JsonValue, JsonValue] }

/**
 * Returns the result of raising the first argument to the power of the second argument.
 * @usage: ```{":power": [x, y]}```
 * where:
 * - `x` is a number
 * - `y` is a number
 *
 * @example
 * - ```{":power": [2, 3]}``` returns `8`
 * - ```{":power": [2, 0.5]}``` returns `1.4142135623730951`
 */
export default createMethod<Type>({
  name: ':power',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':power') continue
      return false
    }

    if (!isArray(expr[':power'])) return false
    if (expr[':power'].length !== 2) return false

    return true
  },

  evaluate(expr): undefined | number {
    const x = toNumber(this.evaluate(expr[':power'][0]))
    if (isNaN(x)) return undefined

    const y = toNumber(this.evaluate(expr[':power'][1]))
    if (isNaN(y)) return undefined

    return Math.pow(x, y)
  },
})
