import { JsonValue, MethodSignature } from '../types'
import { createMethod, isArray } from '../util'

type Type = {
  ':range': [JsonValue, JsonValue] | [JsonValue, JsonValue, JsonValue]
}

/**
 * Creates an array of numbers in a specified range.
 * @usage: ```{ ':range': [start, end, inc] }```
 * where:
 * - start: number: the start of the range, inclusive,
 * - end: number: the end of the range, exclusive
 * - inc: number: the increment between each number in the range, default is 1
 * @example
 * ```{ ':range': [0, 2] }``` => [0, 1]
 * ```{ ':range': [2, 4] }``` => [2, 3]
 * ```{ ':range': [0, 10, 2] }``` => [0, 2, 4, 6, 8]
 * ```{ ':range': [0, 10, 3] }``` => [0, 3, 6, 9]
 *
 * @real world example
 * You need to print an element x times and x is defined in your scope.
 *
 * 1. You define a scope with count value: `{x: 3}`
 * 2. You use the range method to create an array of 3 elements and you map over it to print the element.
 *
 * ```{ ":map": [{ ":range": [0, "{x}"] }, { "type":"text", "key": "data.item_{@item}"}] }```
 */
export default createMethod<Type>({
  name: ':range',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':range') continue
      return false
    }

    if (isArray(expr[':range'])) return expr[':range'].length === 2 || expr[':range'].length === 3

    return false
  },

  evaluate(expr): undefined | number[] {
    const start = Number(this.evaluate(expr[':range'][0]))
    const end = Number(this.evaluate(expr[':range'][1]))
    const inc = Number(this.evaluate(expr[':range'][2] ?? 1))

    if (end < start) return undefined

    const result = []

    for (let i = start; i < end; i += inc) {
      result.push(i)
    }

    return result
  },
})
