import {
  mdiAlert,
  mdiArrowUp,
  mdiCached,
  mdiCheck,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiCheckCircle,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCircle,
  mdiClose,
  mdiCloseCircle,
  mdiExclamation,
  mdiInformation,
  mdiMenu,
  mdiMenuDown,
  mdiMinus,
  mdiMinusBox,
  mdiPageFirst,
  mdiPageLast,
  mdiPaperclip,
  mdiPencil,
  mdiPlus,
  mdiRadioboxBlank,
  mdiRadioboxMarked,
  mdiStar,
  mdiStarHalf,
  mdiStarOutline,
  mdiUnfoldMoreHorizontal,
} from '@mdi/js'

export default {
  complete: mdiCheck,
  cancel: mdiCloseCircle,
  close: mdiClose,
  delete: mdiCloseCircle,
  clear: mdiClose,
  success: mdiCheckCircle,
  info: mdiInformation,
  warning: mdiExclamation,
  error: mdiAlert,
  prev: mdiChevronLeft,
  next: mdiChevronRight,
  checkboxOn: mdiCheckboxMarked,
  checkboxOff: mdiCheckboxBlankOutline,
  checkboxIndeterminate: mdiMinusBox,
  delimiter: mdiCircle,
  sort: mdiArrowUp,
  expand: mdiChevronDown,
  menu: mdiMenu,
  subgroup: mdiMenuDown,
  dropdown: mdiMenuDown,
  radioOn: mdiRadioboxMarked,
  radioOff: mdiRadioboxBlank,
  edit: mdiPencil,
  ratingEmpty: mdiStarOutline,
  ratingFull: mdiStar,
  ratingHalf: mdiStarHalf,
  loading: mdiCached,
  first: mdiPageFirst,
  last: mdiPageLast,
  unfold: mdiUnfoldMoreHorizontal,
  file: mdiPaperclip,
  plus: mdiPlus,
  minus: mdiMinus,
}
