export const convertLegacyForm = (json) => {
  if (json === null || typeof json !== 'object') {
    return json
  } else if (Array.isArray(json)) {
    const converted = Array(json.length)
    let result = json
    for (let i = 0; i < json.length; i++) {
      converted[i] = convertLegacyForm(json[i])
      if (converted[i] !== json[i]) result = converted
    }
    return result
  } else {
    const converted = {}
    let result = json
    for (const key in json) {
      switch (key) {
        case ':visible':
          if (!('visible' in converted)) {
            result = converted
            converted.visible = convertLegacyConditional(json[key])
          }
          break
        case ':required':
          if (!('required' in converted)) {
            result = converted
            converted.required = convertLegacyConditional(json[key])
          }
          break
        default:
          converted[key] = convertLegacyForm(json[key])
          if (converted[key] !== json[key]) result = converted
          break
      }
    }
    return result
  }
}

const convertLegacyConditional = (cond) => {
  if (!isExtendedCondition(cond)) return cond

  // Optimization
  if (Object.keys(cond).length === 0) return true

  const clauses = []
  const { $not = undefined, $every = undefined, $either = undefined, ...rest } = cond

  if ($not !== undefined) clauses.push({ ':not': convertLegacyConditional($not) })
  if (Array.isArray($every)) clauses.push({ ':every': $every.map(convertLegacyConditional) })
  if (Array.isArray($either)) clauses.push({ ':either': $either.map(convertLegacyConditional) })
  if (Object.keys(rest).length > 0) clauses.push({ ':cond': rest })

  if (clauses.length === 0) return true
  if (clauses.length === 1) return clauses[0]
  return { ':every': clauses }
}

const isPlainObject = (k) =>
  k !== null && typeof k === 'object' && Object.prototype.toString.call(k) === '[object Object]'
const isConditionalKey = (k) => k.startsWith('$')
const isConditionalCheck = (v) => isPlainObject(v) && Object.keys(v).every(isConditionalKey)
const isExtendedCondition = (v) =>
  isPlainObject(v) &&
  Object.entries(v).every(([key, value]) => isConditionalKey(key) || isConditionalCheck(value))
