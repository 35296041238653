import { JsonValue, MethodSignature, Value } from '../types'
import { createMethod } from '../util'

type Type = { ':raw': JsonValue }

/**
 * Returns the raw value. If the input is a JSON-expression, it will not be evaluated.
 *
 * @usage: ```{":raw": value}```
 * where:
 * - `value` is any value
 *
 * @example
 * - ```{":raw": 42}``` returns `42`
 */
export default createMethod<Type>({
  name: ':raw',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':raw') continue
      return false
    }

    if (typeof expr[':raw'] === 'undefined') return false

    return true
  },

  evaluate(expr): Value {
    return expr[':raw']
  },

  compile(expr) {
    const value = expr[':raw']
    return () => value
  },
})
