export const date = 'Nieprawidłowa data.'
export const email = 'Nieprawidłowy adres e-mail.'
export const iban = 'Nieprawidłowy numer IBAN.'
export const invalid = 'Nieprawidłowa wartość.'
export const invalidFileType = 'Nieprawidłowy typ pliku.'
export const maxCount = 'To pole powinno zawierać maksymalnie {0} elementów.'
export const maxDate = 'Wprowadź datę przed {0}.'
export const maxDecimals =
  'Ta wartość musi zawierać maksymalnie {0} miejsc dziesiętnych. Uważaj, aby nie używać separatora tysięcy.'
export const maxFileSize = 'Wybierz plik mniejszy niż {0}.'
export const maxFilesSize = 'Wszystkie pliki muszą być mniejsze niż {0}.'
export const maxLength = 'Ta wartość musi być krótsza niż {0} znaków.'
export const maxValue = 'Ta wartość musi być mniejsza lub równa {0}.'
export const minCount = 'To pole powinno zawierać co najmniej {0} elementów.'
export const minDate = 'Wprowadź datę po {0}.'
export const minFileSize = 'Wybierz plik większy niż {0}.'
export const minFilesSize = 'Wszystkie pliki muszą być większe niż {0}.'
export const minLength = 'Ta wartość musi być dłuższa niż {0} znaków.'
export const minValue =
  'Ta wartość musi być większa lub równa {0}. Uważaj, aby nie używać separatora tysięcy.'
export const pattern = 'Ta wartość nie odpowiada właściwemu formatowi.'
export const phone = 'Nieprawidłowy numer telefonu.'
export const phoneCountry = 'Nieprawidłowy {0} numer telefonu.'
export const phoneFixedLine = 'Nieprawidłowy numer stacjonarny.'
export const phoneIntl = 'Upewnij się, że zaczynasz od kodu kraju +xx.'
export const phoneMobile = 'Nieprawidłowy numer komórkowy.'
export const required = 'Wartość jest wymagana.'
export const requiredButton = 'Kliknij ten przycisk.'
export const requiredCard = 'Kliknij {0}.'
export const requiredChoice = 'Proszę wybierz jedną opcję.'
export const requiredChoices = 'Wybierz jedną lub więcej opcji.'
export const requiredDate = 'Wymagana jest prawidłowa data.'
export const requiredDatetime = 'Wymagana jest prawidłowa data i czas.'
export const requiredDownload = 'Wszystkie pliki muszą być pobrane, aby zatwierdzić ten krok.'
export const requiredFile = 'Wymagany jest plik.'
export const requiredFileMultiple = 'Podaj co najmniej jeden plik.'
export const requiredSignature = 'Proszę podpisać.'
export const requiredRating = 'Wybierz wartość od 1 do 5.'
