import { formatValue } from '@penbox-io/stdlib'

import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'

type Value = NonNullable<unknown>
type Options = {
  label?: FormHelpable
  return: unknown
  outlined: boolean
  submitOnChange: boolean
}
type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    label: ifFormHelpable(input.label),
    outlined: input.outlined === true,
    submitOnChange: input.submit_on_change === true,
    return: input.return ?? null,
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  if (value == null || value === '') return null

  return value
}

function validate(
  options: Options,
  locale: string,
  value: unknown,
  required: boolean
): null | FormError {
  if (required && value == null) {
    return buildError('requiredButton', locale)
  }

  return null
}

function normalize(element: Element): undefined | Value {
  return element.value ?? undefined
}

function stringify(element: Element): undefined | string {
  const value = normalize(element)
  if (value === undefined) return undefined

  return formatValue(value, element)
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}
