import { createMethodLegacySimple } from '../legacy'
import { isArray, isMethodCall, isPlainObject, toString } from '../util'

/**
 * Switch statement.
 *
 * @usage: ```{":switch": [search, cases, default]}```
 * where:
 * - `search` is the value to search for
 * - `cases` is an object with keys as search values and values as results
 * - `default` is the default value if no match is found
 *
 * @example
 * - ```{":switch": ["1", {"1": "one", "2": "two"}, "other"]}``` returns `"one"`
 * - ```{":switch": ['{ user.gender }', { male: 'Mr.', female: 'Mrs.' }, 'Mx.'] }``` returns `'Mr.'` or `'Mrs.'` or `'Mx.'`
 */
export default createMethodLegacySimple(':switch', function (args) {
  if (!isArray(args) || args.length < 2 || args.length > 3) return undefined
  const needsEval = !isPlainObject(args[1]) || isMethodCall(args[1])

  const cases = needsEval ? this.evaluate(args[1]) : args[1]
  if (!isPlainObject(cases)) return undefined

  const search = toString(this.evaluate(args[0]))
  if (search === undefined) return undefined

  if (needsEval) {
    // "cases" was already eval'd
    if (cases[search]) return cases[search]
  } else {
    // "cases" was not eval'd. We can eval only the keys and matching case.
    for (const key in cases) {
      if (search === toString(this.evaluate(key))) {
        return this.evaluate(cases[key])
      }
    }
  }

  // Default
  return this.evaluate(args[2])
})
