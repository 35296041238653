import { normalizeErrors } from '@penbox-io/error-parser'
const state = () => ({
  loading: false,
  errors: null,
  contact: null,
  requests: null,
  flows: null,
  customizations: null,
  company: null,
})

/**
 * @type {import('vuex').Module<ReturnType<typeof state>>}
 */
export default {
  actions: {
    async getContact({ commit, getters }) {
      commit('LOAD')
      try {
        const contact = await this.$axios.$get('/core/v1/contacts', {
          headers: {
            authorization: `Bearer ${getters.token}`,
          },
          params: {
            'page[size]': 1,
            'include': 'company',
          },
        })
        commit('LOAD_COMPLETE', contact)
      } catch (err) {
        commit('LOAD_COMPLETE', { errors: Array.from(normalizeErrors(err)) })
      }
    },
    async getRequests({ commit, getters }, { options }) {
      commit('LOAD')
      commit('LOAD_REQUESTS')
      try {
        let filter
        if (options.search) {
          filter = {
            either: [
              { attributes: { $flow_name: { $ilike: `%${options.search}%` } } },
              { attributes: { $request_status: { $ilike: `%${options.search}%` } } },
              { attributes: { $description: { $ilike: `%${options.search}%` } } },
            ],
          }
        }

        const headers = {
          authorization: `Bearer ${getters.token}`,
        }
        if (options.force) headers['cache-control'] = 'no-cache'
        const requests = await this.$axios.$get('/core/v1/contact_requests', {
          headers,
          params: {
            filter,
            'page[number]': options.number,
            'page[size]': options.size,
            'include': 'contact',
            'order': options.order,
          },
        })

        commit('LOAD_COMPLETE', requests)
        return requests.data
      } catch (err) {
        commit('LOAD_COMPLETE', { errors: Array.from(normalizeErrors(err)) })
      }
    },
    async getCustomizations({ commit, getters }) {
      commit('LOAD')
      try {
        const customizations = await this.$axios.$get('/core/v1/flow_customizations', {
          params: {
            include: 'flow',
          },
          headers: {
            authorization: `Bearer ${getters.token}`,
          },
        })
        commit('LOAD_COMPLETE', customizations)
      } catch (err) {
        commit('LOAD_COMPLETE', { errors: Array.from(normalizeErrors(err)) })
      }
    },
    async createRequest({ commit, getters }, { contact, customization }) {
      commit('LOAD')
      try {
        const request = await this.$axios.$post(
          '/core/v1/requests',
          {
            data: {
              attributes: {
                user: contact.attributes.user,
                created_from: 'portal',
              },
              relationships: {
                flow_customization: {
                  data: {
                    id: customization.id,
                    type: 'flow_customizations',
                  },
                },
                flow: {
                  data: {
                    id: customization.relationships.flow.data.id,
                    type: 'flows',
                  },
                },
              },
              meta: {
                'pnbx:created_by': { pen: 'fill' },
              },
            },
          },
          {
            headers: {
              authorization: `Bearer ${getters.token}`,
            },
          }
        )
        // POST a contact_requests to link current contact to request
        await this.$axios.$post(
          '/core/v1/contact_requests',
          {
            data: {
              attributes: {
                created_by_contact: true,
              },
              relationships: {
                contact: {
                  data: {
                    id: contact.id,
                    type: 'contacts',
                  },
                },
                request: {
                  data: {
                    id: request.data.id,
                    type: 'requests',
                  },
                },
              },
            },
          },
          {
            headers: {
              authorization: `Bearer ${getters.token}`,
            },
          }
        )

        commit('LOAD_COMPLETE', request)
        return request.data
      } catch (err) {
        commit('LOAD_COMPLETE', { errors: Array.from(normalizeErrors(err)) })
      }
    },

    async cancelRequest({ commit, getters }, { id }) {
      try {
        const args = {
          id,
          action: 'cancel',
        }
        await this.$axios.$post(
          `/core/v1/contact_requests/${id}/exec/cancel`,
          {},
          {
            headers: {
              authorization: `Bearer ${getters.token}`,
            },
          }
        )
        commit('RELOAD_REQUESTS', { args, result: id })
      } catch (err) {
        commit('LOAD_COMPLETE', { errors: Array.from(normalizeErrors(err)) })
      }
    },

    async declineRequest({ commit, getters }, { id }) {
      try {
        const args = {
          id,
          action: 'decline',
        }
        const { data } = await this.$axios.$post(
          `/core/v1/contact_requests/${id}/exec/decline`,
          {},
          {
            headers: {
              authorization: `Bearer ${getters.token}`,
            },
          }
        )
        commit('RELOAD_REQUESTS', { args, result: data })
      } catch (err) {
        commit('LOAD_COMPLETE', { errors: Array.from(normalizeErrors(err)) })
      }
    },
  },
  state,
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count_requests: (state) => {
      return (
        state.contact?.attributes.$all_count -
          state.contact?.attributes.$scheduled_count -
          state.contact?.attributes.$draft_count || 0
      )
    },
    requests: (state) =>
      state.requests?.map((r) => ({
        ...r,
        attributes: {
          ...r.attributes,
          $request_status: STATUS_MAPPING[r.attributes.$request_status] || 'new',
        },
      })) || [],
    customizations: (state) =>
      state.customizations?.map((c) => {
        const flow = state.flows?.find((f) => f.id === c.relationships.flow.data.id)
        return {
          customization: c,
          flow,
          image:
            c.meta['pnbx:fill:welcome-image'] ||
            flow.meta['pnbx:fill:welcome-image'] ||
            'cdn://pen-fill/welcome.svg',
        }
      }) || [],
    token: (state) => `co_${state.token}`,
    contact: (state) => state.contact,
    user: (state, getters) => getters.contact?.attributes?.user,

    // Branding
    branding: (state, getters) => state.company?.attributes,

    colors: (state, getters) => getters.branding?.colors,
    favicon: (state, getters) => getters.branding?.favicon,
    homepage: (state, getters) => getters.branding?.links?.homepage,
    hostname: (state, getters) => getters.branding?.hostname,
    icon: (state, getters) => getters.branding?.icon,
    logo: (state, getters) => getters.branding?.logo,
    title: (state, getters) => getters.branding?.name,
    locale: (state, getters) => getters.user?.locale,
  },

  mutations: {
    SET_TOKEN(state, { token }) {
      state.token = token
    },
    LOAD(state) {
      state.loading = true
    },
    LOAD_REQUESTS(state) {
      state.requests = []
    },
    LOAD_COMPLETE(state, { data = null, included = [], errors = [] }) {
      state.loading = false
      state.errors = errors

      const requests = data?.filter?.((item) => item.type === 'contact_requests')
      if (requests?.length) {
        state.requests = requests
      }
      const customizations = data?.filter?.((item) => item.type === 'flow_customizations')
      if (customizations?.length) {
        state.customizations = customizations
      }
      const contact = included?.find((item) => item.type === 'contacts')
      if (contact) {
        state.contact = contact
      }
      const contacts = data?.filter?.((item) => item.type === 'contacts')
      if (contacts?.length) {
        state.contact = contacts[0]
      }
      const flows = included?.filter((item) => item.type === 'flows')
      if (flows.length) {
        state.flows = flows
      }
      const company = included?.find((item) => item.type === 'companies')
      if (company) {
        state.company = company
      }
    },
    RELOAD_REQUESTS(state, payload) {
      const { args, result } = payload
      const currentRequests = state.requests || []

      if (args.action === 'decline') {
        state.requests = currentRequests?.map((r) => (r.id === result.id ? result : r))
      }
      if (args.action === 'cancel') {
        const index = currentRequests.findIndex((i) => i.id === args.id)
        currentRequests.splice(index, 1)
      }
    },
  },
}

const STATUS_MAPPING = {
  archived: 'processed',
  processed: 'processed',
  completed: 'completed',
  declined: 'declined',
  draft: 'new',
  expired: 'expired',
  scheduled: 'new',
  pending: 'new',
  ongoing: 'ongoing',
}
