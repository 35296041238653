export const date = 'Kehtetu kuupäev.'
export const email = 'Kehtetu e-posti aadress.'
export const iban = 'Kehtetu IBAN number.'
export const invalid = 'Kehtetu väärtus.'
export const invalidFileType = 'Kehtetu failitüüp.'
export const maxCount = 'See väli peaks sisaldama maksimaalselt {0} eset.'
export const maxDate = 'Sisestage kuupäev enne {0}.'
export const maxDecimals =
  'See väärtus peab sisaldama maksimaalselt {0} kümnendkohti. Ärge kasutage tuhande eraldajat.'
export const maxFileSize = 'Valige fail, mis on väiksem kui {0}.'
export const maxFilesSize = 'Kõik failid peavad olema väiksemad kui {0}.'
export const maxLength = 'See väärtus peab olema vähem kui {0} tähemärki pikk.'
export const maxValue = 'See väärtus peab olema väiksem või võrdne {0}.'
export const minCount = 'See väli peaks sisaldama vähemalt {0} eset.'
export const minDate = 'Sisestage kuupäev pärast {0}.'
export const minFileSize = 'Valige fail, mis on suurem kui {0}.'
export const minFilesSize = 'Kõik failid peavad olema suuremad kui {0}.'
export const minLength = 'See väärtus peab olema pikem kui {0} tähemärki.'
export const minValue =
  'See väärtus peab olema suurem või võrdne {0}. Ärge kasutage tuhande eraldajat.'
export const pattern = 'See väärtus ei vasta õigele formaadile.'
export const phone = 'Kehtetu telefoninumber.'
export const phoneCountry = 'Kehtetu {0} telefoninumber.'
export const phoneFixedLine = 'Kehtetu lauatelefoninumber.'
export const phoneIntl = 'Veenduge, et alustate oma +xx maakoodiga.'
export const phoneMobile = 'Kehtetu mobiilinumber.'
export const required = 'Väärtus on vajalik.'
export const requiredButton = 'Palun klõpsake sellele nupule.'
export const requiredCard = 'Palun klõpsake {0}.'
export const requiredChoice = 'Palun valige üks.'
export const requiredChoices = 'Valige üks või mitu.'
export const requiredDate = 'Kehtiv kuupäev on vajalik.'
export const requiredDatetime = 'Kehtiv kuupäev ja kellaaeg on vajalikud.'
export const requiredDownload = 'Kõik failid peavad olema alla laaditud, et kinnitada see samm.'
export const requiredFile = 'Fail on vajalik.'
export const requiredFileMultiple = 'Palun esitage vähemalt üks fail.'
export const requiredSignature = 'Palun allkirjastage.'
export const requiredRating = 'Valige väärtus vahemikus 1 kuni 5.'
