import { toNullProtoObject } from '@penbox-io/stdlib'

import * as de from './de.js'
import * as en from './en.js'
import * as fr from './fr.js'
import * as nl from './nl.js'
import * as bg from './bg.js'
import * as fi from './fi.js'
import * as cz from './cz.js'
import * as dk from './dk.js'
import * as ee from './ee.js'
import * as hu from './hu.js'
import * as lv from './lv.js'
import * as lt from './lt.js'
import * as pl from './pl.js'
import * as ro from './ro.js'
import * as sk from './sk.js'
import * as es from './es.js'
import * as se from './se.js'
import * as ua from './ua.js'

export type Messages = { [K in string]?: string }
export type LocalizedMessages = { [K in string]?: Messages }

export const LOCALIZED_MESSAGES: LocalizedMessages = toNullProtoObject({
  de,
  en,
  fr,
  nl,
  bg,
  fi,
  cz,
  dk,
  ee,
  hu,
  lv,
  lt,
  pl,
  ro,
  sk,
  es,
  se,
  ua,
})

export { en as fallbackMessages }
