import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { StringTransformer } from './'
import { findLast, findQuotes } from './utils'

const allowedMethods = [
  'date',
  'number',
  'lowercase',
  'uppercase',
  'capitalize',
  'format-date',
  'weekday',
]

function camelToKebabCase(input: string): string {
  return input.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
}

function getMethod(method: string): string {
  return camelToKebabCase(method)
}

/**
 * Shortcut to evaluate a method without only one parameter.
 *
 * @usage: ```'toto' | method```.
 */
class MethodStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const quotes = findQuotes(expr)

    const pipe = findLast(expr, '|', quotes)

    if (pipe !== -1) {
      const method = getMethod(expr.slice(pipe + 1).trim())

      if (allowedMethods.includes(method)) {
        return true
      }
    }

    return false
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    const quotes = findQuotes(expr)
    const pipe = findLast(expr, '|', quotes)

    const parameters = expr.slice(0, pipe).trim()
    const method = getMethod(expr.slice(pipe + 1).trim())

    if (!method) {
      return this.evalStringExpression(parameters)
    }

    return this.evaluateMethodCall({ [`:${method}`]: `{${parameters}}` })
  }
}

export default new MethodStringTransformer()
