import { createEvaluator } from '@penbox-io/json-expression'
import { ifString, negotiateLocale, normalizeLocale, objectGet } from '@penbox-io/stdlib'
import { extendObject, extractFlowLocales, isObject } from '../util.js'
import I18N, { translateString } from './methods/i18n.js'
import { formatElementTitle, stringifyFormattedValue } from '../format.js'
export const methods = [I18N]

/** @typedef {import('@penbox-io/json-expression').JsonValue} JsonValue */
/** @typedef {import('@penbox-io/json-expression').Value} Value */
/** @typedef {import('@penbox-io/json-expression').Define} Define */

const legacyEvaluator = createEvaluator({ legacy: true, methods })
const strictEvaluator = createEvaluator({ legacy: false, methods })

/**
 * @template T
 * @typedef {import('../../../../src/pen-core/types').CoreEntityOf<T>} CoreEntityOf
 */

/**
 * @typedef {CoreEntityOf<'companies'>} Company
 * @typedef {CoreEntityOf<'flows'>} Flow
 * @typedef {CoreEntityOf<'flow_customizations'>} FlowCustomization
 * @typedef {CoreEntityOf<'requests'>} Request
 * @typedef {CoreEntityOf<'responses'>} Response
 * @typedef {CoreEntityOf<'brandings'>} Branding
 */

/**
 * @typedef {object} FlowScope
 * @property {Flow} RequestScope.$flow
 * @property {Company} [RequestScope.$company]
 * @property {FlowCustomization} [RequestScope.$customization]
 * @property {Request} [RequestScope.$request]
 * @property {Response} [RequestScope.$response]
 * @property {object} [RequestScope.data]
 * @property {object} [RequestScope.user]
 * @property {object} [RequestScope.options]
 */

/**
 * @typedef {object} RequestScope
 * @property {Company} RequestScope.$company
 * @property {Flow} RequestScope.$flow
 * @property {FlowCustomization} RequestScope.$customization
 * @property {Request} RequestScope.$request
 * @property {Response} [RequestScope.$response]
 * @property {object} RequestScope.data
 * @property {object} RequestScope.user
 * @property {object} RequestScope.options
 */

/**
 * @param {JsonValue} expr
 * @param {Define} variables
 * @returns {Value}
 */
const wrapRequestExpression = (expr, variables) => {
  return variables ? { ':define': variables, ':in': expr } : expr
}

/**
 * @param {FlowScope | RequestScope} scope
 * @param {JsonValue} expr
 * @param {{ legacy?: boolean }} [options]
 * @returns {Value}
 * @deprecated use {@link createElementsEvaluator} or {@link createStepsEvaluator} when applicable
 */
export const evalExpression = (scope, expr, options = undefined) => {
  const { variables } = scope.$flow.attributes
  const evaluator = options?.legacy === false ? strictEvaluator : legacyEvaluator
  return evaluator.evaluate(wrapRequestExpression(expr, variables), scope)
}

export const createScope = (entities, { parent = null } = {}) => {
  if (entities?.request) return createRequestScope(entities, { parent })
  if (entities?.flow) return createFlowScope(entities)

  throw new TypeError(`Unable to create scope from entities (no request or flow entity)`)
}

/**
 * Create a "light" scope that only ensures the existence of a "$flow" variable
 *
 * @param {object} entities
 * @param {Flow} entities.flow
 * @param {Company} [entities.company]
 * @param {FlowCustomization} [entities.customization]
 * @param {Request} entities.request
 * @param {Response} [entities.response]
 * @param {{ parent?: object | null }} [options]
 * @returns {FlowScope}
 */
const createFlowScope = (entities, { parent = null } = {}) => {
  if (!isObject(entities.flow?.attributes)) {
    throw new TypeError(`A valid 'flow' entity is required to create a flow scope.`)
  }

  return extendObject(parent, {
    // Globals
    $company: entities.company || undefined,
    $flow: entities.flow,
    $customization: entities.customization || undefined,
    $request: undefined,
    $response: undefined,
    $branding: createBranding(entities.company),
    // i18n
    $locale: entities.flow.attributes.locale ?? 'en',
    $defaultLocale: entities.flow?.attributes?.locale ?? 'en',
    $strings: entities.flow.attributes.strings ?? null,
    // Shorthand
    data: undefined,
    user: undefined,
    options: entities.customization?.attributes.options ?? {},
  })
}

export const negotiateFlowLocale = (flow, locales) => {
  const flowLocales = extractFlowLocales(flow)
  if (!flowLocales) return undefined

  return negotiateLocale(flowLocales, locales)
}

export const _createRequestScope = (
  entities,
  { overrides = undefined, locales = undefined, parent = null } = {}
) => {
  if (!isObject(entities?.request)) {
    throw new TypeError(`A valid 'request' entity is required to create a request scope.`)
  }

  const data = extendObject(entities.request.attributes?.data, entities.response?.attributes?.data)
  const user = extendObject(entities.request.attributes?.user, entities.response?.attributes?.user)
  const options =
    entities.request?.attributes?.options ?? entities.customization?.attributes?.options

  const parentScope = parent === 'legacy' ? data : parent

  const userLocale = ifString(overrides?.user?.locale) ?? ifString(user?.locale)

  const normalizedLocale = entities.flow
    ? negotiateFlowLocale(
        entities.flow,
        // Optimization: avoid creating a new array unless necessary
        userLocale && locales?.length ? [userLocale, ...locales] : userLocale || locales
      )
    : normalizeLocale(userLocale || locales?.[0])

  const scope = extendObject(parentScope, {
    $request: entities.request,
    // Optional
    $company: entities.company || undefined,
    $flow: entities.flow || undefined,
    $customization: entities.customization || undefined,
    $response: entities.response || undefined,
    $responses: entities.request.attributes.$responses || undefined,
    $branding: createBranding(entities.company, entities.branding),
    // i18n,
    $locale: normalizedLocale || entities.flow?.attributes?.locale || 'en',
    $defaultLocale: entities.flow?.attributes?.locale ?? 'en',
    $strings: entities.flow?.attributes?.strings ?? null,
    // Shorthand
    data: data ?? {},
    user: user ?? {},
    options: options ?? {},
    signatures: entities.response?.attributes.$signatures ?? undefined,
    $owner: entities.request.attributes.$owner ?? undefined,
    $creator: entities.request.attributes.$creator ?? undefined,
  })

  return extendObject(scope, overrides, { merge: true })
}

function flatten(input) {
  if (!input) return []
  return Array.isArray(input) ? input.flat(Infinity) : [input]
}

/**
 * @param {object} entities
 * @param {Request} entities.request
 * @param {Flow} entities.flow
 * @param {FlowCustomization} [entities.customization]
 * @param {Company} [entities.company]
 * @param {Response} [entities.response]
 * @param {Branding} [entities.branding]
 * @param {{ parent?: object | null | 'legacy'; locales?: string[]; overrides?: Record<string, any> }} [options]
 * @returns {RequestScope}
 */
export const createRequestScope = (
  entities,
  { overrides = undefined, locales = undefined, parent = null } = {}
) => {
  const scope = _createRequestScope(entities, { overrides, locales, parent })

  const steps = evalExpression(scope, scope.$flow.attributes.steps, { legacy: true })

  // extend the scope with the titles and formatted values of the elements
  for (const step of flatten(steps)) {
    if (step?.elements) {
      for (const element of flatten(step.elements)) {
        if (element?.key && element.key.startsWith('data.')) {
          const parts = element.key.split('.')
          const key = parts.pop()
          const parentPath = parts.join('.')

          const parentObject = parentPath.length === 0 ? scope : objectGet(scope, parentPath)

          if (!parentObject) {
            continue
          }

          parentObject[`${key}__title`] = formatElementTitle(element)
          parentObject[`${key}__formatted`] = stringifyFormattedValue(
            element,
            objectGet(scope, element.key),
            {
              locale: scope.$locale,
              loose: true,
            }
          )
        }
      }
    }
  }

  return scope
}

/**
 * @param {null | Flow} [flow ]
 * @param {null | string} [locale]
 */
export const getFlowName = (flow, locale = null) => {
  if (!flow) return undefined

  const { name, strings, locale: fallbackLocale } = flow.attributes
  return translateString(strings, locale, name, fallbackLocale)
}

/**
 *
 * @param {Company} [company]
 * @param {Branding} [branding]
 * @returns
 */
const createBranding = (company = undefined, branding = undefined) => {
  if (!company && !branding) return undefined
  return (
    branding ?? {
      attributes: {
        slug: 'default',
        logo: company?.attributes?.logo ?? null,
        icon: company?.attributes?.icon ?? null,
        favicon: company?.attributes?.favicon ?? null,
        colors: company?.attributes?.colors ?? null,
        contact: company?.attributes?.contact ?? null,
        links: company?.attributes?.links ?? null,
      },
    }
  )
}
