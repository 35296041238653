import { subScope } from '../scope'
import { JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isArray } from '../util'

type Type = { ':map': [JsonValue, JsonValue] }

/**
 * LEGACY: Please use `:map` instead.
 *
 * Maps each element of an array to a new value.
 * @usage: ```{":map": [array, mapper]}```
 * where:
 * - `array` is an array
 * - `mapper` a json expression that will be evaluated for each element of the array
 *
 * @sub-scope variables
 * - `@item` the current item being processed
 * - `@index` the index of the current item
 * - `@array` the array being processed
 *
 * @example
 * - ```{":map": [[1, 2, 3], {":sum": ["{@item}"", 1]}]}``` returns `[2, 3, 4]`
 */
export default createMethod<Type>({
  name: ':map',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':map') continue
      return false
    }

    if (!isArray(expr[':map'])) return false
    if (expr[':map'].length !== 2) return false

    return true
  },

  evaluate(expr) {
    const input = this.evaluate(expr[':map'][0])
    if (!isArray(input)) return undefined

    const result = Array(input.length)
    for (let i = 0; i < input.length; i++) {
      const ctx = this.subScope({ '@item': input[i], '@index': i, '@array': input })
      result[i] = ctx.evaluate(expr[':map'][1])
    }

    return result
  },

  compile(expr) {
    const inputCompiled = this.createContext(':map', 0).compile(expr[':map'][0])
    const mapperCompiled = this.createContext(':map', 1).compile(expr[':map'][1])

    return (scope: Scope): Value => {
      const input = inputCompiled(scope)
      if (!isArray(input)) return undefined

      const result = Array(input.length)
      for (let i = 0; i < input.length; i++) {
        const childScope = subScope(scope, { '@item': input[i], '@index': i, '@array': input })
        result[i] = mapperCompiled(childScope)
      }
      return result
    }
  },
})
