import { isDefined } from './util'

export const extractQs = (url: any): string | null => {
  if (!url || typeof url !== 'string') return null
  const qsPos = url.indexOf('?')
  return qsPos !== -1 ? url.slice(qsPos) : null
}

export type ParamValue = null | string | number | boolean

const toAssignment = ([name, value]: [string, undefined | ParamValue]): null | string => {
  if (value === undefined) return null
  if (value === null) return encodeURIComponent(name)

  return `${encodeURIComponent(name)}=${encodeURIComponent(String(value))}`
}

export const urlEncode = (
  strings: TemplateStringsArray,
  ...parts: Array<string | Record<string, undefined | ParamValue>>
): string => {
  let output = strings[0]
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i]

    const segment =
      typeof part === 'string'
        ? encodeURIComponent(part)
        : typeof part === 'object' && part !== null
        ? Object.entries(part).map(toAssignment).filter(isDefined).join('&')
        : null

    if (segment === null) throw new Error(`Invalid URL segment: ${String(part)}`)

    output += segment + strings[i + 1]
  }
  return output
}

/** @deprecated use {@link urlEncode} instead */
export const url = urlEncode

export const urlSearchParams = (
  uri: any,
  params?: null | Record<string, null | undefined | number | string | string[]>
): InstanceType<typeof URL> => {
  const url = new URL(String(uri))
  if (params) {
    for (const key in params) {
      const value = params[key]
      if (value == null) url.searchParams.delete(key)
      else if (Array.isArray(value)) for (const v of value) url.searchParams.append(key, v)
      else url.searchParams.set(key, String(value))
    }
  }
  return url
}
