import { camelize } from '@penbox-io/stdlib'

import materialColors from 'vuetify/lib/util/colors'
import { contrastRatio, HexToRGBA } from 'vuetify/lib/util/colorUtils'

const isDarkHex = (color) => contrastRatio(HexToRGBA(color), HexToRGBA('#ffffff')) > 2

/**
 * Returns the hex value of a color from the theme, fallback to material colors
 * @param {string} color color name
 * @param {*} vm
 * @returns
 */
export const getThemeHexColor = (color, vm) => {
  const [colorName, variantName = 'base'] = color.split(' ', 2)
  const variants = vm.$vuetify?.theme.parsedTheme[colorName] || materialColors[camelize(colorName)]
  if (variants) {
    const themeColor = variants[variantName.replace('-', '')] || variants.base
    if (themeColor) return themeColor
  }

  if (colorName === 'black') return '#000000'
  if (colorName === 'white') return '#ffffff'

  return undefined
}

export const getLigthenColor = (hexColor, opacity = 0.5) => {
  const r = parseInt(hexColor.slice(1, 3), 16)
  const g = parseInt(hexColor.slice(3, 5), 16)
  const b = parseInt(hexColor.slice(5, 7), 16)

  if (opacity) {
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

export const isDark = (color, vm = undefined) => {
  if (typeof color !== 'string' || !color) return undefined
  if (color.startsWith('#')) return isDarkHex(color)

  if (vm) {
    const themeColor = getThemeHexColor(color, vm)
    if (themeColor) return isDarkHex(themeColor)
  }

  if (color === 'black') return true
  if (color === 'white') return false

  return undefined
}

const DARKEN_PREFIX = 'darken-'
const LIGHTEN_PREFIX = 'lighten-'

export function extractColorDarkness(color) {
  const variantName = color.split(/\s+/g, 3)[1]

  if (variantName) {
    // Positive if dark
    if (variantName.startsWith(DARKEN_PREFIX)) {
      const amount = Number(variantName.slice(DARKEN_PREFIX.length))
      return amount >= 1 && amount <= 4 ? amount : 0
    }

    // Negative if light
    if (variantName.startsWith(LIGHTEN_PREFIX)) {
      const amount = Number(variantName.slice(LIGHTEN_PREFIX.length))
      return amount >= 1 && amount <= 5 ? -amount : 0
    }
  }

  return 0
}

export function alterColor(color, darkeness = 0) {
  const colorName = color.split(' ', 1)[0]
  if (darkeness >= 1) return `${colorName} ${DARKEN_PREFIX}${Math.min(4, darkeness | 0)}`
  if (darkeness <= 1) return `${colorName} ${LIGHTEN_PREFIX}${Math.min(5, -darkeness | 0)}`
  return colorName
}

export function getDarkerColor(color, amount) {
  const colorDarkness = extractColorDarkness(color)
  const newDarkeness = colorDarkness + amount
  return alterColor(color, newDarkeness)
}

export const blurOnEnter = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault()
    event.target.blur()
  }
}
