export function asURL(value: unknown): URL | undefined {
  try {
    return new URL(value as any)
  } catch {
    return undefined
  }
}

export type UriString<Protocol extends string = string> = `${Protocol}:${string}`

export function isUri<P extends string = string>(
  input: unknown,
  allowedProtocols?: P[]
): input is UriString<P> {
  if (typeof input !== 'string') return false

  // The following code is equivalent to the following regex:
  // return /^[a-z]+:[^\s]+$/.test(input)

  const column = input.indexOf(':')
  if (column <= 0) return false
  if (column === input.length - 1) return false

  for (let i = 0; i < column; i++) {
    const char = input.charCodeAt(i)
    if (char < 97 || char > 122) return false
  }

  if (allowedProtocols) {
    const protocol = input.slice(0, column)
    if (!(allowedProtocols as string[]).includes(protocol)) return false
  }

  for (let i = column + 1; i < input.length; i++) {
    switch (input.charCodeAt(i)) {
      case 9: // tab
      case 10: // line feed
      case 11: // vertical tab
      case 12: // form feed
      case 13: // carriage return
      case 32: // space
        return false
    }
  }

  return true
}

export function ifUri<P extends string = string>(
  input: unknown,
  allowedProtocols?: P[]
): UriString<P> | undefined {
  return isUri(input, allowedProtocols) ? input : undefined
}
