import { findPath } from '../string'
import { MethodCall, MethodSignature, Scope } from '../types'
import { createMethod, isMethodCall, isString } from '../util'

type Type = { ':get': MethodCall | string }

const splitPath = (path: any): undefined | string[] => {
  if (typeof path !== 'string') return undefined

  // Only static path are supported
  if (!path) return undefined
  if (path.includes('[') || path.includes(']')) return undefined
  if (path.includes('{') || path.includes('}')) return undefined
  if (path.includes('"') || path.includes("'")) return undefined

  return path.split('.')
}

/**
 * Get a value from the scope by its path.
 *
 * @usage: ```{ ':get': path }```
 * where:
 * - path: string: the path to the value to get
 *
 * @example
 * scope: ```{ foo: { bar: 'baz' } }```
 * ```{ ':get': 'foo.bar' }``` => 'baz'
 *
 */
export default createMethod<Type>({
  name: ':get',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':get') {
        const value = expr[':get']
        if (value == null) return false
        if (isString(value)) continue
        if (isMethodCall(value)) continue
        return false
      }
      return false
    }

    return true
  },

  evaluate(expr) {
    const parts = splitPath(this.evaluate(expr[':get']))
    if (!parts) return undefined

    return findPath.call(this.scope, parts)
  },

  compile(expr) {
    const getCompiled = this.createContext(':get').compile(expr[':get'])

    // Optimization: pre-compile parts
    if (getCompiled.static) {
      const parts = splitPath(getCompiled.staticValue)
      if (!parts) return () => undefined

      return (scope: Scope) => findPath.call(scope, parts)
    }

    return (scope: Scope) => {
      const parts = splitPath(getCompiled(scope))
      if (!parts) return undefined

      return findPath.call(scope, parts)
    }
  },
})
