import {
  asValidDate,
  ifValidDate,
  ifString,
  toNullProtoObject,
  formatDate,
  toDate,
  ISO_DATE,
} from '@penbox-io/stdlib'

import { type Dict } from '../../common'

const DEFAULT_DATE_PATTERNS: Record<string, undefined | string> = toNullProtoObject({
  en: 'M/D/Y',
  fr: 'D/M/Y',
  nl: 'D/M/Y',
  de: 'D/M/Y',
})

// FR format for compatibility reasons with older forms. New forms are expected
// to provide the pattern if they use an un-supported locale.
const DEFAULT_DATE_PATTERN = 'D/M/Y'

export function parseDatePattern(input: Dict, locale: string) {
  const lang = locale.slice(0, 2)
  return (
    (input.patterns
      ? ifString(input.patterns?.[locale]) || ifString(input.patterns?.[lang])
      : null) || // ??
    ifString(input.pattern) || // the pattern specified
    DEFAULT_DATE_PATTERNS[lang] || // the default lang pattern
    DEFAULT_DATE_PATTERN // the default pattern
  )
}

export type Options = {
  datePattern: string
  min: Date
  max: Date
  weekDays?: boolean
}

export function parseDateOptions(input: Dict, locale: string): Options {
  const min: Date =
    (input.min && toDate(input.min, { stripTime: true })) || new Date('1900-01-01T00:00:00Z')
  const max: Date = (input.max && toDate(input.max, { stripTime: true })) || null

  return {
    datePattern: parseDatePattern(input, locale),
    min,
    max: max && max >= min ? max : twoHundreadYearsLater(min),
    weekDays: input.weekDays === true,
  }
}

export function parseDate(value: unknown): Date | undefined {
  if (!value) return undefined
  switch (typeof value) {
    case 'string':
      if (ISO_DATE.test(value)) return asValidDate(new Date(value))

      return undefined
    case 'object':
      return ifValidDate(value)
    default:
      return undefined
  }
}

export function stringifyDate(
  value: Date,
  locale: string,
  pattern = DEFAULT_DATE_PATTERNS[locale.slice(0, 2)] || DEFAULT_DATE_PATTERN
): string {
  const time = locale ? value.toLocaleTimeString(locale) : value.toTimeString()
  const date = formatDate(value, pattern)

  return `${date} ${time}`
}

export function twoHundreadYearsLater(date: Date) {
  return new Date(`${date.getFullYear() + 200}-12-32T59:59:59.999Z`)
}
