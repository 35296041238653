import { JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isNumber, isString } from '../util'

type Type = { ':date': JsonValue; ':pattern': JsonValue }

/**
 * Parses a date string using a pattern and returns a Date object.
 * @usage: ```{ ':date': string, ':pattern': string }```
 * where:
 * - :date: string: the date string to parse or now for the current date
 * - :pattern: string: the pattern to use for parsing the date string
 *
 * @example
 * ```{ ':date': '2021-01-01', ':pattern': 'yyyy-MM-dd' }``` => new Date(2021, 0, 1)
 */
export default createMethod<Type>({
  name: ':date',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':date') continue
      if (key === ':pattern') continue
      return false
    }
    return true
  },

  evaluate(expr) {
    const arg = this.evaluate(expr[':date'])
    const parser = buildPattern(this.evaluate(expr[':pattern']))

    return parseDate(arg, parser)
  },

  compile(expr) {
    const dateGetter = this.createContext(':date').compile(expr[':date'])
    const patternGetter = this.createContext(':pattern').compile(expr[':pattern'])

    // Performance: pre-build the parser if the pattern is static
    if (patternGetter.static) {
      const parser = buildPattern(patternGetter.staticValue)
      return (scope: Scope) => {
        return parseDate(dateGetter(scope), parser)
      }
    }

    return (scope: Scope) => {
      const parser = buildPattern(patternGetter(scope))
      return parseDate(dateGetter(scope), parser)
    }
  },
})

export function parseDate(arg: Value, parser?: Parser): Date | undefined {
  if (arg === undefined) return undefined
  if (arg === null) return new Date()
  if (arg === 'now') return new Date()
  if (arg === '') return undefined
  if (isNumber(arg)) return asValidDate(arg)
  if (isString(arg)) {
    if (parser) return parser(arg)
    if (/^[+-]?\d+$/.test(arg)) return fromDelta(arg)
    return asValidDate(arg)
  }
  if (arg instanceof Date) return asValidDate(arg)
  return undefined
}

function fromDelta(delta: string) {
  return new Date(Date.now() + Number(delta) * 1e3)
}

function asValidDate(input: Date | number | string): Date | undefined {
  const date = new Date(input)
  return Number.isNaN(date.valueOf()) ? undefined : date
}

type Parser = (value: string) => undefined | Date
const invalidPatternParser: Parser = () => undefined

export function buildPattern(input: unknown): undefined | Parser {
  if (input == null) return undefined
  if (Array.isArray(input) && input.every(isString)) input = input.join('-')
  if (!isString(input)) return invalidPatternParser

  let pattern = ''
  let inUndefinedWidth = false

  // Note "named capture groups" are not widely supported
  // https://caniuse.com/mdn-javascript_regular_expressions_named_capture_groups
  let currentGroup = 0
  const groups = {
    y: undefined as undefined | number,
    m: undefined as undefined | number,
    d: undefined as undefined | number,
  }

  for (let i = 0; i < input.length; i++) {
    const patternChar = input[i]
    switch (patternChar) {
      case 'y':
      case 'm':
      case 'd':
        // Two "y" or "m" or "d" should be delimitted by a separator
        if (inUndefinedWidth) return invalidPatternParser
        inUndefinedWidth = true
      // falls through
      case 'Y':
      case 'M':
      case 'D': {
        const groupName = patternChar.toLowerCase() as 'y' | 'm' | 'd'
        // There can only be one "y/Y" or "m/M" or "d/D" in the pattern
        if (groups[groupName] != null) return invalidPatternParser
        // Register the group number of the current pattern part
        groups[groupName] = ++currentGroup
        pattern += datePatternToRegexpPattern(patternChar)
        break
      }
      case 'x':
        inUndefinedWidth = false
        pattern += '[^\\d]'
        break
      case ' ':
      case '-':
      case '_':
      case '/':
      case ':':
      case '.':
        // Allow any separator to be used in the input, regardless of the one defined in the pattern
        inUndefinedWidth = false
        pattern += '[-_ /:.]'
        break
      case '\\': {
        // Escape character, skip one char from input
        const escapedChar = input[++i]
        if (escapedChar == null) break // we could 'return invalidPatternParser' but let's ignore escaping the end of the pattern

        // pattern is something like 'm\\1d' should not be allowed, but 'm\\xd' should work
        if (!(escapedChar >= '0' && escapedChar <= '9')) {
          inUndefinedWidth = false
        }

        pattern += escapedChar.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
        break
      }
      default:
        // Invalid pattern char
        return invalidPatternParser
    }
  }

  // month and day will default to "01"
  if (groups.y == null) return invalidPatternParser

  const regex = new RegExp(`^${pattern}$`, 'i')
  return (value: string) => {
    if (!isString(value)) return undefined

    const match = value.match(regex)
    if (!match) return undefined

    const year = Number(match[groups.y!])
    if (!(year <= 9999)) return undefined

    const month = groups.m == null ? 1 : Number(match[groups.m])
    if (!(month >= 1 && month <= 12)) return undefined

    const day = groups.d == null ? 1 : Number(match[groups.d])
    if (!(day >= 1 && day <= 31)) return undefined

    const timestamp = Date.UTC(fixYear(year), month - 1, day)
    if (Number.isNaN(timestamp)) return undefined

    return new Date(timestamp)
  }
}

function datePatternToRegexpPattern(patterChar: 'Y' | 'y' | 'M' | 'm' | 'D' | 'd'): string {
  switch (patterChar) {
    case 'Y':
      return '(\\d{4})'
    case 'y':
      return '(\\d{2,4})'
    case 'M':
    case 'D':
      return '(\\d{2})'
    case 'm':
    case 'd':
      return '(\\d{1,2})'
    default:
      throw new TypeError(`Invalid pattern char: ${patterChar}`)
  }
}

function fixYear(year: number): number {
  if (year >= 0 && year < 100) {
    const now = new Date()
    const currentYear = now.getFullYear()
    const currentYearInCentury = currentYear % 100
    const currentCentury = (currentYear - currentYearInCentury) / 100
    // if currentYear is 2035 and year is <=35, then century is 2000.
    // if currentYear is 2035 and year is >36, then century is 1900.
    const century = year <= currentYearInCentury ? currentCentury : currentCentury - 1
    return century * 100 + year
  }
  return year
}
