import { asError, safeWrap, urlSearchParams } from '@penbox-io/stdlib'

const safeJsonParse = safeWrap(JSON.parse)
const setPathReducer = (value) => (o, k, i, a) => (o[k] = i === a.length - 1 ? value : {})

class FlowError extends Error {
  constructor(message, code) {
    super(message)
    this.code = code
  }
}

/** @param {any} err */
const isRedirectError = (err) => err && err.message === 'ERR_REDIRECT'

export default async function webflowPlugin(ctx, inject) {
  if (ctx.route.path === '/wf') {
    try {
      await concludeWebflow(ctx)
    } catch (err) {
      if (isRedirectError(err)) throw err

      return ctx.error(err)
    }
  }

  inject('initiateWebflow', initiateWebflow.bind(ctx.store))
}

/**
 * @this {import('vuex').Store<any>}
 * @param {{ key: string; options: { href: string; mode: string; setup?: any } }} options
 * @param {{ signal?: AbortSignal; setStatus?: (s: string) => void; setPercentage?: (p: number) => void }} options
 */
async function initiateWebflow(
  { key, options: { href, mode, setup = undefined } },
  { signal = undefined, setStatus, setPercentage } = {}
) {
  if (signal?.aborted) throw new FlowError('Aborted', 'aborted')

  const authenticated = this.getters['request/authenticated']

  if (!authenticated) {
    throw new Error(`Unauthenticated`)
  }

  const token = this.getters['request/token']
  const reqId = this.getters['request/requestId']
  const resId = this.getters['request/responseId']

  if (!token || !reqId || !resId) {
    throw new TypeError(`Missing webflow data`)
  }

  const nonce = String(Math.random().toString(36).substr(2, 9))

  const wfParams = {
    'wf:response': resId,
    'wf:token': token,
    'wf:redirect': `${window.location.origin}/wf`,
    'wf:state': JSON.stringify({ reqId, resId, nonce, token, mode, key }),
  }

  const url =
    setup == null ? urlSearchParams(href, wfParams) : await setupUrl(href, wfParams, setup)

  if (signal?.aborted) throw new FlowError('Aborted', 'aborted')

  if (mode === 'redirect' || mode === undefined) {
    return initiateRedirect(url, { nonce, signal })
  }

  if (mode === 'popup') {
    return initiatePopup(url, { nonce, signal })
  }

  throw new TypeError(`Unknown webflow mode "${mode}"`)
}

const setupUrl = async (url, params, setup) => {
  if (typeof fetch === 'undefined') {
    throw Object.assign(new Error('Browser is deprecated'), { code: 'browser-deprecated' }) // Add error code
  }

  const rawResponse = await fetch('/wf', {
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
    method: 'POST',
    body: JSON.stringify({ url, body: { ...(setup?.payload ?? {}), ...params } }),
  })

  const response = await rawResponse.json()

  if (!rawResponse.ok) {
    throw new Error(response.message || 'Webflow setup phase failed')
  }

  if (!response.redirect_url) {
    throw new Error('Webflow setup bad request')
  }

  return new URL(response.redirect_url)
}

/**
 * @param {URL} url
 * @param {object} options
 * @param {string} options.nonce
 * @param {AbortSignal} [options.signal]
 * @returns {Promise<void>}
 */
const initiateRedirect = (url, { nonce, signal = undefined }) => {
  document.cookie = `wf:${nonce}=y; path=/`
  window.location.href = url.toString()

  return new Promise((resolve, reject) => {
    const cleanup = () => {
      signal?.removeEventListener('abort', onAbort)
    }

    const onAbort = () => {
      reject(new FlowError('Aborted', 'aborted'))
      cleanup()
    }

    signal?.addEventListener('abort', onAbort)
    if (signal?.aborted) onAbort()
  }) // Do not fullfill
}

/**
 * @param {URL} url
 * @param {object} options
 * @param {string} options.nonce
 * @param {AbortSignal} [options.signal]
 * @returns {Promise<void>}
 */
const initiatePopup = (url, { nonce, signal = undefined }) => {
  return new Promise((resolve, reject) => {
    let win = window.open(url, '_blank')
    if (!win) return reject(new FlowError('Unable to open popup', 'popup-blocked'))

    /** @type {null | ReturnType<typeof setInterval>} */
    let interval = setInterval(() => {
      if (!win || win.closed) {
        reject(new FlowError('The window was closed', 'user-aborted'))
        cleanup()
      }
    }, 100)

    const onAbort = () => {
      reject(new FlowError('Aborted', 'aborted'))
      cleanup()
    }

    const onMessage = (event) => {
      if (event.source !== win) return
      if (event.origin !== window.location.origin) return
      if (event.data?.['wf:nonce'] !== nonce) return

      const result = event.data['wf:result']

      if (result.status === 'fulfilled') resolve(result.value)
      else reject(new FlowError(result.reason.message, result.reason.code))

      cleanup()
    }
    window.addEventListener('message', onMessage)

    const cleanup = () => {
      window.removeEventListener('message', onMessage)
      signal?.removeEventListener('abort', onAbort)

      if (interval) {
        clearInterval(interval)
        interval = null
      }

      if (win) {
        win.close()
        win = null
      }
    }

    signal?.addEventListener('abort', onAbort)
  })
}

async function concludeWebflow(ctx) {
  const { query } = ctx.route

  const state = safeJsonParse(query['wf:state'])

  if (!state?.nonce || !state?.key) {
    throw Object.assign(new Error(`Invalid state`), { status: 401 })
  }

  const result = query['wf:error']
    ? {
        status: 'rejected',
        reason: {
          code: query['wf:error'],
          message: query['wf:error_description'] || undefined,
        },
      }
    : {
        status: 'fulfilled',
        value: {
          date: new Date(),
          data: safeJsonParse(query['wf:value']) ?? (query['wf:value'] || null),
        },
      }

  if (state.mode === 'redirect') {
    return concludeRedirect(ctx, state, result)
  }

  if (state.mode === 'popup') {
    return concludePopup(ctx, state, result)
  }

  throw Object.assign(new Error(`Invalid mode "${state.mode}"`), { status: 400 })
}

/**
 * @note It is not possible to use relative redirects from client plugins. This
 * function will trigger a new navigation.
 * @see {@link https://nuxtjs.org/docs/2.x/internals-glossary/context#redirect}
 * @param {*} ctx
 * @param {*} param1
 * @param {*} result
 */
async function concludeRedirect(ctx, { token, nonce, key, reqId, resId }, result) {
  try {
    if (document.cookie.includes(`wf:${nonce}=y`)) {
      document.cookie = `wf:${nonce}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
    } else {
      throw new FlowError(`Session expired`, 'session-expired')
    }

    if (result.status === 'fulfilled') {
      const attributes = {}

      key.split('.').reduce(setPathReducer(result.value), attributes)

      await ctx.store.dispatch('request/load', { requestId: reqId, token })
      await ctx.store.dispatch('request/patch', { data: { id: resId, attributes } })
    } else {
      const { code, message = 'Web flow failure' } = result.reason
      throw new FlowError(message, code)
    }

    // Using URL constructor to sanitize the URL
    const redirectUrl = new URL(`/${encodeURIComponent(reqId)}`, window.location.origin)
    ctx.redirect(redirectUrl.toString())
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Unable to conclude redirection flow', err)

    // TODO: find a way to propagate the error to the webflow component instead
    // of throwing here. Something like:

    // ctx.redirect(`${window.location.origin}/${reqId}?wf:key=${key}&wf:error=${err.code || 'webflow-failure'}&wf:error_description=${err.message}`)

    // Or maybe like:

    // localStrage.set(..., errorData)

    const error = /** @type {Error & { status?: any; statusCode?: any }} */ (asError(err))
    const status = error.status ?? error.statusCode ?? (error instanceof FlowError ? 401 : 500)
    throw Object.assign(error, { status })
  }
}

async function concludePopup(ctx, { nonce, key }, result) {
  window.opener.postMessage({ 'wf:result': result, 'wf:nonce': nonce }, window.location.origin)

  try {
    window.close()
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Unable to close self', err)
  }

  return new Promise((resolve, reject) => {}) // Prevent loading of the app
}
