/* eslint-disable  */

import {
  ifInstance,
  ifMatch,
  ifString,
  isDefined,
  isPlainObject,
  parseRegexp,
} from '@penbox-io/stdlib'

export const REFERENCE_FIELDS = Object.freeze([
  //
  'internal_ref',
] as const)

export const PERSONAL_FIELDS = Object.freeze([
  //
  'email',
  'phone',
  'full_name',
  'family_name',
  'given_name',
  'date_of_birth',
  'place_of_birth',
  'gender',
  'locale',
] as const)

export const ADDRESS_FIELDS = Object.freeze([
  //
  'address',
  'address_street',
  'address_number',
  'address_box',
  'address_zip',
  'address_city',
  'address_country',
] as const)

export const COMPANY_FIELDS = Object.freeze([
  //
  'company_name',
  'company_phone',
] as const)

export const JOB_FIELDS = Object.freeze([
  //
  'job_title',
] as const)

export const FISCAL_FIELDS = Object.freeze([
  //
  'iban',
  'vat_number',
  'tax_id',
] as const)

export const ALL_FIELDS = Object.freeze([
  ...REFERENCE_FIELDS,
  ...PERSONAL_FIELDS,
  ...ADDRESS_FIELDS,
  ...COMPANY_FIELDS,
  ...JOB_FIELDS,
  ...FISCAL_FIELDS,
])

export type FieldName = (typeof ALL_FIELDS)[number]

export function isFieldName(value: unknown): value is FieldName {
  if (!value) return false
  return typeof value === 'string' && (ALL_FIELDS as ReadonlyArray<string>).includes(value)
}

export function ifFieldName(value: unknown): FieldName | undefined {
  return isFieldName(value) ? value : undefined
}

// The followign fields are not considered as regular fields as they cannot
// be used in requests.

export const SYSTEM_FIELDS = Object.freeze([
  //
  'accept-language',
  'ip',
  'user-agent',
] as const)

// The following variable contains a subset of FieldName that represents the
// fields that, when provided, will cause requests's has_pii attribute to be
// true.

export const PII_FIELDS = Object.freeze([
  'internal_ref',
  'email',
  'phone',
  'given_name',
  'full_name',
  'family_name',
] as const) satisfies ReadonlyArray<FieldName>

//

export type FieldDefinition = {
  name: FieldName
  required: boolean
  label?: string
  placeholder?: string
} & (
  | {
      name: 'phone'
      country?: string
    }
  | {
      name: 'internal_ref'
      mask?: string
      pattern?: RegExp
    }
  | {
      name: Exclude<FieldName, 'phone' | 'internal_ref'>
    }
)

export const DEFAULT_USER_FIELDS = Object.freeze([
  Object.freeze({ name: 'internal_ref', required: false }),
  Object.freeze({ name: 'given_name', required: false }),
  Object.freeze({ name: 'family_name', required: false }),
  Object.freeze({ name: 'email', required: false }),
  Object.freeze({ name: 'phone', required: false }),
  Object.freeze({ name: 'locale', required: true }),
] as const) satisfies ReadonlyArray<Readonly<FieldDefinition>>

/**
 * @note normalizeUserField(x) must be equal to normalizeUserField(normalizeUserField(x)) for any x
 */
export function normalizeUserField(e: unknown): null | FieldDefinition {
  if (!e) return null
  if (typeof e === 'string') {
    if (!isFieldName(e)) return null
    return { name: e, required: false } // "e" is a non empty string
  }

  if (!isPlainObject(e)) return null

  const name = ifFieldName(e.name)
  if (!name) return null

  return {
    // Global
    required: e.required === true,
    name,
    label: ifString(e.label) || undefined,
    placeholder: ifString(e.placeholder) || undefined,
    // "phone" specific
    country: name === 'phone' ? ifMatch(e.country, /^[A-Z]{2}$/) || 'BE' : undefined,
    // REFERENCE_FIELDS / FISCAL_FIELDS specific
    mask:
      (REFERENCE_FIELDS as ReadonlyArray<string>).includes(name) ||
      (FISCAL_FIELDS as ReadonlyArray<string>).includes(name)
        ? ifString(e.mask) || undefined
        : undefined,
    pattern:
      (REFERENCE_FIELDS as ReadonlyArray<string>).includes(name) ||
      (FISCAL_FIELDS as ReadonlyArray<string>).includes(name)
        ? ifInstance(e.pattern, RegExp) || parseRegexp(e.pattern) || undefined
        : undefined,
  }
}

export function normalizeUserFields(fields: unknown): undefined | FieldDefinition[] {
  return Array.isArray(fields) ? fields.map(normalizeUserField).filter(isDefined) : undefined
}

export function* gerenateUserValues(fields: FieldDefinition[], values: Record<string, unknown>) {
  if (fields?.length) {
    for (const { name } of fields) {
      const { [name]: value = null } = values
      yield [`user.${name}`, value, null] as const
    }
  }
}
