import { createMethodLegacySimple } from '../legacy'
import { dateDeltaRegex, getDateUnitFactor, isArray, isDate, isString, toNumber } from '../util'

const substract = (a: number, b: number): number => a - b

/**
 * Returns the substraction of all items in an array.
 * @usage: ```{":substract": array}```
 * where:
 * - `array` is an array of dates or numbers or date delta strings
 *
 * available delta units:
 * - `y` or `year` or `years` for years
 * - `M` or `month` or `months` for months
 * - `d` or `day` or `days` for days
 * - `h` or `hour` or `hours` for hours
 * - `m` or `minute` or `minutes` for minutes
 * - `s` or `second` or `seconds` for seconds (default)
 *
 * @example
 * - ```{":substract": [1, 2, 3]}``` returns `-4`
 * - ```{":substract": [1, 2, 3, 4]}``` returns `-8`
 * - ```{":substract": [new Date(), '2d', '3d']}``` returns `today - 5 days`
 */
export default createMethodLegacySimple(':substract', function (args): undefined | number | Date {
  const input = this.evaluate(args)
  if (!isArray(input)) return undefined
  if (input.length === 0) return undefined

  const isFirstElementDate = isDate(input[0])

  const values = input.map((v) => {
    // handle date delta strings - transform them to milliseconds
    if (isFirstElementDate && isString(v) && dateDeltaRegex.test(v)) {
      const result = dateDeltaRegex.exec(v)

      if (result) {
        const [_, value, unit] = result
        const factor = getDateUnitFactor(unit)

        return Number(value) * factor
      }
    }

    return toNumber(v)
  })

  if (values.some(isNaN)) return undefined

  const substraction = values.reduce(substract)
  if (isDate(input[0])) return new Date(substraction)

  return substraction
})
