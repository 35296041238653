import { JsonValue, MethodSignature } from '../types'
import { createMethod, isArray } from '../util'

type Type = { ':either': JsonValue }

/**
 * DEPRECATED: Use :some instead.
 * Check if any of the items in the array are truthy.
 *
 * @usage: ```{ ':either': [expr1, expr2, ...] }```
 * where:
 * - expr1, expr2, ...: any: the expressions to evaluate
 *
 * @example
 * ```{ ':either': [true, false] }``` => true
 * ```{ ':either': [false, false] }``` => false
 *
 * @real world example
 * Create an or condition with multiple expressions in a if statement.
 * ```
 * {
 * ":if": {":either": [{":cond" : {"data.count": {"$gt": 4}}}, {":cond" : {"data.count": {"$lt": 10}}}]},
 * ":then": {"ok": true},
 * ":else": {"ok": false}
 * }
 * ```
 *
 * The above example will return true if data.count is greater than 4 or less than 10.
 */
export default createMethod<Type>({
  name: ':either',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':either') continue
      return false
    }

    if (typeof expr[':either'] === 'undefined') return false
    return true
  },

  evaluate(expr) {
    // Optimization: eval only one item at a time
    if (isArray(expr[':either'])) {
      for (const item of expr[':either']) {
        const itemVal = this.evaluate(item)
        if (itemVal) return true
      }
      return false
    }

    const result = this.evaluate(expr[':either'])
    if (!isArray(result)) return undefined

    return result.some(Boolean)
  },
})
