import { createScope, evalExpression } from './engine/index'

/**
 * @type {Record<string, undefined | { dynamic: boolean; default?: any }>}
 */
const META_OPTIONS = {
  // Flows
  'pnbx:flow:summary_keys': { dynamic: false, default: null },
  'pnbx:flow:options': { dynamic: false, default: null },

  // pen-notification (though pen-core)
  'pnbx:notification:invite:disabled': { dynamic: false, default: false },
  'pnbx:notification:invite:manual': { dynamic: false, default: false },

  // pen-app
  'pnbx:app:send:methods': { dynamic: false, default: null /* defined in app */ },
  'pnbx:app:send:mailto': {
    dynamic: true,
    default: {
      ':define': {
        $strings: {
          en: { body: 'Link: $0' },
          fr: { body: 'Lien: $0' },
          // nl: { body: 'Link: $0' },
          // de: { body: 'Link: $0' },
        },
      },
      ':in': {
        subject: '{ user.internal_ref }',
        body: {
          ':i18n': 'body',
          ':values': ['{$request.attributes.link}'],
        },
      },
    },
  },

  // portal
  'pnbx:portal:links': { dynamic: false, default: null },

  // pen-fill
  'pnbx:fill:auto-save': { dynamic: false, default: true },
  'pnbx:fill:multi-completable': { dynamic: false, default: false },
  'pnbx:fill:privacy-policy': { dynamic: false, default: true },
  'pnbx:fill:rate-ux': { dynamic: true, default: true },
  'pnbx:fill:redirect-url': { dynamic: true },
  'pnbx:fill:auto-redirect': { dynamic: true },

  'pnbx:fill:custom-policy': { dynamic: true, default: null },
  'pnbx:fill:declinable': { dynamic: false, default: true },
  'pnbx:fill:help-text': { dynamic: true, default: null },
  'pnbx:fill:help-visible': { dynamic: false, default: true },

  'pnbx:fill:welcome-image': { dynamic: true, default: 'cdn://pen-fill/hello.svg' },
  'pnbx:fill:welcome-title': { dynamic: true },
  'pnbx:fill:welcome-start': { dynamic: true },
  'pnbx:fill:welcome-message': { dynamic: true },

  'pnbx:fill:complete-image': { dynamic: true, default: 'cdn://pen-fill/one_last_thing.svg' },
  'pnbx:fill:complete-title': { dynamic: true },
  'pnbx:fill:complete-message': { dynamic: true },
  'pnbx:fill:complete-send': { dynamic: true },
  'pnbx:fill:complete-modify': { dynamic: true },

  'pnbx:fill:incomplete-image': { dynamic: true },
  'pnbx:fill:incomplete-title': { dynamic: true },
  'pnbx:fill:incomplete-message': { dynamic: true },
  'pnbx:fill:incomplete-action': { dynamic: true },

  'pnbx:fill:ended-image': { dynamic: true, default: 'cdn://pen-fill/ended.svg' },
  'pnbx:fill:ended-message': { dynamic: true },
  'pnbx:fill:ended-title': { dynamic: true },
  'pnbx:fill:ended-restart': { dynamic: true },

  'pnbx:fill:confirmation-text': { dynamic: true, default: null },

  'pnbx:fill:auto-submit': { dynamic: false, default: false },

  'pnbx:powered-by-penbox': { dynamic: false, default: false },
}

const ENTITY_NAMES = ['request', 'customization', 'company', 'flow']

export const metaOption = (entitiesOrScope, metaName) => {
  const metaDefinition = META_OPTIONS[metaName]
  if (!metaDefinition) throw new TypeError(`Unknown meta option: ${metaName}`)

  for (const entityName of ENTITY_NAMES) {
    const entity = entitiesOrScope[entityName] ?? entitiesOrScope[`$${entityName}`]
    if (!entity) continue

    const value = entity?.meta?.[metaName]

    if (value !== undefined && value !== null) {
      if (metaDefinition.dynamic) return evaluateMetaValue(entitiesOrScope, value)
      return value
    }
  }

  const value = metaDefinition.default ?? null
  if (metaDefinition.dynamic) return evaluateMetaValue(entitiesOrScope, value)
  return value
}

const evaluateMetaValue = (entitiesOrScope, value) => {
  if (value === null) return value

  // Optimization: only evaluate is actually needed
  switch (typeof value) {
    case 'string':
      if (!value.includes('{')) return value

    // falls through
    case 'object': {
      const scope = entitiesOrScope.$flow ? entitiesOrScope : createScope(entitiesOrScope)
      return evalExpression(scope, value)
    }

    default:
      return value
  }
}
