import { createMethodLegacyMultiple } from '../legacy'

/**
 * Count the number of items in an array.
 * @usage: ```{ ':count': array, ':where'?: expr, ':unless'?: expr }```
 * where:
 * - array: any[]: the array to count
 * - where?: any: the expression to evaluate for each item
 * - unless?: any: the expression to evaluate for each item
 *
 * @example
 * ```{ ':count': [1, 2, 3] }``` => 3
 * ```{ ':count': [1, 2, 3], ':where': {":cond": {"@item": {"$gt": 1 }}}``` => 2
 * ```{ ':count': [1, 2, 3], :where': {":cond": {"@item": {"$gt": 1 }}}, ":unless": {":cond": {"@item": {"$eq": 3 }}} }``` => 1
 *
 */
export default createMethodLegacyMultiple(
  ':count',
  [':where', ':unless'],
  function ({
    ':count': countExpr,
    ':where': whereExpr = undefined,
    ':unless': unlessExpr = undefined,
  }) {
    const items = this.evaluate(countExpr)
    if (!Array.isArray(items)) return undefined

    // Optimizations
    if (whereExpr === undefined && unlessExpr === undefined) return items.length
    if (unlessExpr === undefined && !whereExpr) return 0
    if (unlessExpr === true) return 0

    let count = 0

    for (let index = 0; index < items.length; index++) {
      const ctx = this.subScope({ '@item': items[index], '@index': index, '@array': items })

      if (whereExpr !== undefined) {
        const where = ctx.evaluate(whereExpr)
        if (!where) continue
      }

      if (unlessExpr !== undefined) {
        const unless = ctx.evaluate(unlessExpr)
        if (unless) continue
      }

      count++
    }

    return count
  }
)
