import { parseNumber as parseFiniteNumber } from '@penbox-io/stdlib'
export { formatNumber } from '@penbox-io/stdlib'
export { extractDecimals } from '@penbox-io/stdlib'

// Not using "parseNumber" as we do not really need to support
export function parseNumber(value: unknown, min?: number, max?: number): number | undefined {
  switch (typeof value) {
    case 'string': {
      const number = Number(value)
      if (Number.isFinite(number)) return number
      // Try "dummy" parsing before trying more expensibe method
      return parseFiniteNumber(value, min, max)
    }
    // falls through
    case 'number': {
      if (!Number.isFinite(value)) return undefined
      const number = value as number | 0
      if (min != null && number < min) return undefined
      if (max != null && number > max) return undefined
      return number
    }
    // falls through
    default:
      return undefined
  }
}
