import { combineComparators, toDateCompare, compareBy, invertComparator } from '@penbox-io/stdlib'

export const flatten = (objs, callbackFn = undefined, thisArg = undefined) => {
  if (objs === null || typeof objs !== 'object') return null
  const array = Array.isArray(objs) ? objs : [objs]
  const flat = array.flat(Infinity)
  return callbackFn ? flat.map(callbackFn, thisArg) : flat
}

export const isObject = (v) => v !== null && typeof v === 'object'

export const hasEnumerableProperty = (obj) => {
  if (obj) for (const k in obj) return true
  return false
}

const hasOwn = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key)

/**
 * @param {object} parent
 * @param {object} properties
 */
export const extendObject = (parent = null, properties = null, { merge = false } = {}) => {
  if (!hasEnumerableProperty(properties)) return parent
  if (parent == null) return properties

  const child = Object.create(parent)

  for (const key in properties) {
    const value =
      merge && isObject(parent[key]) && isObject(properties[key])
        ? extendObject(parent[key], properties[key])
        : properties[key]

    // Optimization: prefer assign to defineProperty()
    if (!(key in parent)) {
      child[key] = value
      continue
    }

    Object.defineProperty(child, key, { value, enumerable: true })
  }

  return child
}

export const isValidChoice = (c) => c.value !== undefined && Boolean(c.label)

export const isUniqueChoice = (choice, index, choices) => {
  for (let i = 0; i < index; i++) {
    if (choices[i].value === choice.value) return false
  }
  return true
}

export const getHelpableText = (v) => (typeof v === 'object' && v !== null ? v.text : v)

const objectPathReducer = (o, k) => (o[k] !== null && typeof o[k] === 'object' ? o[k] : (o[k] = {}))

/**
 * @param {object} root
 * @param {string} key
 * @param {any} value
 * @returns {any}
 */
export const setPath = (root, key, value) => {
  if (!key) return

  const path = key.split('.')
  const last = path.pop()

  const object = path.reduce(objectPathReducer, root)

  object[last] = value
}

export const setValue = (src, { key, value }) => {
  if (!key) return src

  const path = key.split('.')
  const last = path.pop()

  const root = { ...src }
  let obj = root
  for (const k of path) obj = obj[k] = { ...obj[k] }

  // Optimization: avoid creating new object
  if (value !== undefined && obj[last] === value) return src
  if (value === undefined && obj[last] === value && !hasOwn(obj, last)) return src

  if (value === undefined) delete obj[last]
  else obj[last] = value

  return root
}

export const compareResponsesAsc = combineComparators(
  compareBy((x) => x.attributes.completed_at || x.attributes.declined_at, toDateCompare),
  compareBy((x) => x.attributes.$updated_at, toDateCompare)
)

export const compareResponsesDesc = invertComparator(compareResponsesAsc)

export const extractFlowLocales = (flow) => {
  // Fool-proof code in case "flow" is not a valid flow (e.g. when used in builder/studio)
  if (!flow?.attributes) return null

  const { locale, strings, locales } = flow.attributes

  const hasFallbackLocale = typeof locale === 'string' && locale.length > 0

  // v2 locales
  if (locales && Array.isArray(locales)) {
    return locales
  }

  if (strings == null || typeof strings !== 'object') return hasFallbackLocale ? [locale] : []

  const stringsLocales = Object.keys(strings)
  if (hasFallbackLocale && !stringsLocales.includes(locale)) stringsLocales.push(locale)

  return stringsLocales
}
