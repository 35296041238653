import ARRAY from './methods/array'
import ASSIGN from './methods/assign'
import CAPITALIZE from './methods/capitalize'
import CASE from './methods/case'
import CMP from './methods/cmp'
import COALESCE from './methods/coalesce'
import CONCAT from './methods/concat'
import COND from './methods/cond'
import COUNT from './methods/count'
import DATE from './methods/date'
import DEFINE from './methods/define'
import DEFINED from './methods/defined'
import DIFFDATES from './methods/diff-dates'
import DIVIDE from './methods/divide'
import DISTINCT from './methods/distinct'
import EQ from './methods/eq'
import EVAL from './methods/eval'
import EVERY from './methods/every'
import FALSE from './methods/false'
import FILTER from './methods/filter'
import FIND from './methods/find'
import FLATTEN from './methods/flatten'
import FORMAT_DATE from './methods/format-date'
import FORMAT_NUMBER from './methods/format-number'
import GET from './methods/get'
import IF from './methods/if'
import IN from './methods/in'
import INCLUDES from './methods/includes'
import INCREASING from './methods/increasing'
import INTERSECTS from './methods/intersects'
import JOIN from './methods/join'
import LOWERCASE from './methods/lowercase'
import MAP from './methods/map'
import MAP_ARRAY from './methods/map-array'
import MAP_ENTRIES from './methods/map-entries'
import MAX from './methods/max'
import MIN from './methods/min'
import MULTIPLY from './methods/multiply'
import NIN from './methods/nin'
import NOT from './methods/not'
import NULL from './methods/null'
import NUMBER from './methods/number'
import OBJECT_CREATE from './methods/object-create'
import OBJECT_ENTRIES from './methods/object-entries'
import PIPE from './methods/pipe'
import POWER from './methods/power'
import PRODUCT from './methods/product'
import RANGE_ARRAY from './methods/range-array'
import RAW from './methods/raw'
import REDUCE from './methods/reduce'
import REF from './methods/ref'
import SOME from './methods/some'
import SPLIT from './methods/split'
import SUBSTRACT from './methods/substract'
import SUBSTR from './methods/substr'
import SUM from './methods/sum'
import SWITCH_ARRAY from './methods/switch-array'
import TO_FIXED from './methods/to-fixed'
import TRUE from './methods/true'
import UNDEFINED from './methods/undefined'
import UPPERCASE from './methods/uppercase'
import URL from './methods/url'
import WEEKDAY from './methods/weekday'

// Derecated
import EITHER from './methods/either'
import IF_ARRAY from './methods/if-array'
import WITH from './methods/with'

export const methods = new Set([
  //
  ARRAY,
  ASSIGN,
  CAPITALIZE,
  CASE,
  CMP,
  COALESCE,
  CONCAT,
  COND,
  COUNT,
  DATE,
  DEFINE,
  DEFINED,
  DIFFDATES,
  DIVIDE,
  DISTINCT,
  EQ,
  EVAL,
  EVERY,
  FALSE,
  FILTER,
  FIND,
  FLATTEN,
  FORMAT_DATE,
  FORMAT_NUMBER,
  GET,
  IF,
  IN,
  INCLUDES,
  INCREASING,
  INTERSECTS,
  JOIN,
  LOWERCASE,
  MAP_ARRAY,
  MAP_ENTRIES,
  MAP,
  MAX,
  MIN,
  MULTIPLY,
  NIN,
  NOT,
  NULL,
  NUMBER,
  OBJECT_CREATE,
  OBJECT_ENTRIES,
  PIPE,
  POWER,
  PRODUCT,
  RANGE_ARRAY,
  RAW,
  REDUCE,
  REF,
  SOME,
  SPLIT,
  SUBSTRACT,
  SUBSTR,
  SUM,
  SWITCH_ARRAY,
  TO_FIXED,
  TRUE,
  UNDEFINED,
  UPPERCASE,
  URL,
  WEEKDAY,
])

export const legacyMethods = new Set([
  //
  IF_ARRAY,
  EITHER,
  WITH,
])
