import { createEvaluator, JsonValue } from '@penbox-io/json-expression'

import type { Step, Element } from '../json-form'
import { optionsFormBuilder, scenarioElementsBuilder, stepsFormBuilder } from '../json-form'
import type { FlowScope, Pages, RequestScope } from '../types'

import { methods } from './methods'

import { convertLegacyForm } from './legacy.js'

type EvalOptions = { legacy?: boolean; ignoreInlineExpression?: boolean }

export function evalExpression(
  scope: RequestScope | FlowScope,
  expr: JsonValue,
  { legacy = true, ignoreInlineExpression = false }: EvalOptions = {}
) {
  const evaluator = createEvaluator({ legacy, ignoreInlineExpression, methods })
  const variables = scope.$flow?.attributes?.variables ?? null
  const exprWithVariables = variables ? { ':define': variables, ':in': expr } : expr
  return evaluator.evaluate(exprWithVariables, scope)
}

export function evalFlowSteps(
  scope: RequestScope,
  { legacy = true, ignoreInlineExpression = false }: EvalOptions = {}
): Step[] {
  const stepsExpr = scope.$flow?.attributes?.steps
  if (!stepsExpr) return []
  const stepsFixed = legacy ? convertLegacyForm(stepsExpr) : stepsExpr
  const steps = evalExpression(scope, stepsFixed, { legacy, ignoreInlineExpression })
  return stepsFormBuilder(steps, scope)
}

export function evalFlowOptions(scope: FlowScope, { legacy = false }: EvalOptions = {}): Step[] {
  const optionsExpr = scope.$flow?.attributes?.options
  if (!optionsExpr) return []
  const steps = evalExpression(scope, optionsExpr, { legacy })
  return optionsFormBuilder(steps, scope)
}

export function evalPages(scope: RequestScope, { legacy = true }: EvalOptions = {}): Pages | null {
  const pagesExpr = scope.$flow?.attributes?.pages
  if (!pagesExpr) return null

  const pages = evalExpression(scope, pagesExpr, { legacy })

  return pages as Pages
}

export function evalFlowScenario(
  scope: RequestScope,
  { legacy = true }: EvalOptions = {}
): Element[] {
  const scenarioExpr = scope.$flow?.meta?.['pnbx:requests:data']
  if (!scenarioExpr) return []
  const scenarioFixed = legacy ? convertLegacyForm(scenarioExpr) : scenarioExpr
  const scenario = evalExpression(scope, scenarioFixed, { legacy })
  return scenarioElementsBuilder(scenario, scope)
}
