import { type Dict } from '../../common'
import { type Definition } from '../../core'

import {
  Value,
  Options as BooleanOptions,
  options as booleanOptions,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} from '../partials/boolean.js'

type Options = BooleanOptions & {
  inline?: boolean
}

export default {
  requirable: (options: Options) => false,
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  const options: Options = booleanOptions(input, locale)
  if (input.inline === true) options.inline = true
  return options
}
