export const JSON_SCHEMA_V1 = 'pnbx:json-expression:v1' as const

export type WithSchema<V> = { $schema?: typeof JSON_SCHEMA_V1 } & V

export type Scalar = number | string | boolean | null
export type Nested<T> = T | Array<Nested<T>> | { [key: string]: undefined | Nested<T> }
export type JsonValue = Nested<Scalar>

export type Value = Nested<Scalar | Date | undefined>
export type Scope<T extends undefined | null | Record<string, Value> = undefined> =
  T extends undefined ? null | Record<string, Value> : T

export type MethodName = string // `:${string}`
export type MethodCall = WithSchema<{ [name in MethodName]?: Value }>
export type MethodSignature<V> = WithSchema<V>

export type Define<S = Scope> = Array<Define<S>> | { [K in keyof S]?: undefined | JsonValue }
