export const date = 'Neplatný dátum.'
export const email = 'Neplatná e-mailová adresa.'
export const iban = 'Neplatné číslo IBAN.'
export const invalid = 'Neplatná hodnota.'
export const invalidFileType = 'Neplatný typ súboru.'
export const maxCount = 'Toto pole by malo obsahovať najviac {0} položiek.'
export const maxDate = 'Zadajte dátum pred {0}.'
export const maxDecimals =
  'Táto hodnota musí obsahovať najviac {0} desatinných miest. Dávajte pozor, aby ste nepoužili oddeľovač tisícov.'
export const maxFileSize = 'Vyberte súbor menší ako {0}.'
export const maxFilesSize = 'Všetky súbory musia byť menšie ako {0}.'
export const maxLength = 'Táto hodnota musí byť kratšia ako {0} znakov.'
export const maxValue = 'Táto hodnota musí byť menšia alebo rovná {0}.'
export const minCount = 'Toto pole by malo obsahovať najmenej {0} položiek.'
export const minDate = 'Zadajte dátum po {0}.'
export const minFileSize = 'Vyberte súbor väčší ako {0}.'
export const minFilesSize = 'Všetky súbory musia byť väčšie ako {0}.'
export const minLength = 'Táto hodnota musí byť dlhšia ako {0} znakov.'
export const minValue =
  'Táto hodnota musí byť väčšia alebo rovná {0}. Dávajte pozor, aby ste nepoužili oddeľovač tisícov.'
export const pattern = 'Táto hodnota nezodpovedá správnemu formátu.'
export const phone = 'Neplatné telefónne číslo.'
export const phoneCountry = 'Neplatné {0} telefónne číslo.'
export const phoneFixedLine = 'Neplatné číslo pevnej linky.'
export const phoneIntl = 'Uistite sa, že začínate s kódom krajiny +xx.'
export const phoneMobile = 'Neplatné číslo mobilného telefónu.'
export const required = 'Hodnota je povinná.'
export const requiredButton = 'Kliknite prosím na toto tlačidlo.'
export const requiredCard = 'Kliknite prosím na {0}.'
export const requiredChoice = 'Vyberte prosím jednu možnosť.'
export const requiredChoices = 'Vyberte jednu alebo viac možností.'
export const requiredDate = 'Platný dátum je povinný.'
export const requiredDatetime = 'Platný dátum a čas sú povinné.'
export const requiredDownload = 'Všetky súbory musia byť stiahnuté na overenie tohto kroku.'
export const requiredFile = 'Súbor je povinný.'
export const requiredFileMultiple = 'Uveďte prosím aspoň jeden súbor.'
export const requiredSignature = 'Podpíšte sa prosím.'
export const requiredRating = 'Vyberte hodnotu medzi 1 a 5.'
