export const date = 'Недійсна дата.'
export const email = 'Недійсна електронна адреса.'
export const iban = 'Недійсний номер IBAN.'
export const invalid = 'Недійсне значення.'
export const invalidFileType = 'Недійсний тип файлу.'
export const maxCount = 'Це поле повинно містити не більше {0} елементів.'
export const maxDate = 'Введіть дату до {0}.'
export const maxDecimals =
  'Це значення повинно містити не більше {0} десяткових знаків. Переконайтеся, що ви не використовуєте роздільник тисяч.'
export const maxFileSize = 'Виберіть файл менше, ніж {0}.'
export const maxFilesSize = 'Усі файли повинні бути меншими за {0}.'
export const maxLength = 'Це значення повинно бути менше {0} символів.'
export const maxValue = 'Це значення повинно бути менше або дорівнювати {0}.'
export const minCount = 'Це поле повинно містити не менше {0} елементів.'
export const minDate = 'Введіть дату після {0}.'
export const minFileSize = 'Виберіть файл більше, ніж {0}.'
export const minFilesSize = 'Усі файли повинні бути більшими за {0}.'
export const minLength = 'Це значення повинно бути більше {0} символів.'
export const minValue =
  'Це значення повинно бути більше або дорівнювати {0}. Переконайтеся, що ви не використовуєте роздільник тисяч.'
export const pattern = 'Це значення не відповідає правильному формату.'
export const phone = 'Недійсний номер телефону.'
export const phoneCountry = 'Недійсний {0} номер телефону.'
export const phoneFixedLine = 'Недійсний стаціонарний номер.'
export const phoneIntl = 'Переконайтеся, що ви починаєте з вашого коду країни +xx.'
export const phoneMobile = 'Недійсний мобільний номер.'
export const required = 'Потрібно ввести значення.'
export const requiredButton = 'Будь ласка, натисніть цю кнопку.'
export const requiredCard = 'Будь ласка, натисніть {0}.'
export const requiredChoice = 'Будь ласка, виберіть один.'
export const requiredChoices = 'Виберіть один або більше.'
export const requiredDate = 'Потрібна дійсна дата.'
export const requiredDatetime = 'Потрібні дійсні дата та час.'
export const requiredDownload = 'Усі файли повинні бути завантажені для підтвердження цього кроку.'
export const requiredFile = 'Потрібен файл.'
export const requiredFileMultiple = 'Будь ласка, надайте принаймні один файл.'
export const requiredSignature = 'Будь ласка, підпишіться.'
export const requiredRating = 'Виберіть значення від 1 до 5.'
