import { createMethodLegacySimple } from '../legacy'
import { Value } from '../types'
import { isArray } from '../util'

/**
 * Returns true if all the arrays have at least one element in common.
 *
 * @usage: ```{":intersects": [array1, array2, ...arrayN]}```
 * where:
 * - `array1`, `array2`, ... `arrayN` are arrays
 *
 * @example
 * - ```{":intersects": [[1, 2, 3], [2, 3, 4]]}``` returns `true`
 * - ```{":intersects": [[1, 2, 3], [4, 5, 6]]}``` returns `false`
 * - ```{":intersects": [[1, 2, 3], [2, 3, 4], [3, 4, 5]]}``` returns `true`
 */
export default createMethodLegacySimple(':intersects', function (args): undefined | boolean {
  if (!isArray(args)) return undefined
  if (args.length === 0) return false

  let intersection: null | Set<Value> = null

  for (let i = 0; i < args.length; i++) {
    const list = this.evaluate(args[i])
    if (!isArray(list) || list.length === 0) return false

    intersection = new Set(intersection ? list.filter(intersection.has, intersection) : list)

    if (intersection.size === 0) return false
  }

  // Should always be true
  return intersection !== null && intersection.size > 0
})
