import { Config } from './config'
import { SubScope, subScope, subScopeDefine } from './scope'
import { findPath, parsePath } from './string'
import { evaluateStringTransformer } from './string-transformers'
import {
  Define,
  JSON_SCHEMA_V1,
  JsonValue,
  MethodCall,
  MethodSignature,
  Scope,
  Value,
} from './types'
import {
  findMatchingPair,
  isArray,
  isDate,
  isJsonifiable,
  isMethodCallObject,
  isPlainObject,
  jsonify,
  toString,
  unquote,
} from './util'

declare module './config' {
  interface MethodDefinition<T> {
    evaluate: (this: EvaluationContext, expr: MethodSignature<T>) => Value
  }
}

/**
 * @todo Merge EvaluationContext and Evaluator into a single class
 */
export class EvaluationContext<S extends Scope = Scope> {
  constructor(public readonly config: Config, public scope: S) {}

  public subScope<T extends Scope>(props: T): EvaluationContext<SubScope<S, T>> {
    const childScope = (this.scope ? subScope(this.scope, props) : props) as SubScope<S, T>

    // @ts-expect-error S and T might not overlap but T might be "empty"
    if (childScope === this.scope) return this

    return new EvaluationContext(this.config, childScope)
  }

  public subScopeEval<T extends Scope>(
    defineExpr: Define<T> | JsonValue
  ): EvaluationContext<SubScope<S, T>> {
    const varsGetters = isArray(defineExpr)
      ? // @ts-expect-error https://github.com/microsoft/TypeScript/issues/49280
        (defineExpr.flat(Infinity).map(toNewVarsGetter, this) as Array<
          null | ((scope: Scope) => Partial<T>)
        >)
      : [toNewVarsGetter.call(this, defineExpr)]

    return this.subScopeDefine<T>(varsGetters)
  }

  private subScopeDefine<T extends Scope>(
    varsGetters: ReadonlyArray<null | ((scope: Scope) => Partial<T>)>
  ): EvaluationContext<SubScope<S, T>> {
    const childScope = subScopeDefine(this.scope, varsGetters)

    // @ts-expect-error S and T might not overlap but T might be "empty"
    if (childScope === this.scope) return this

    return new EvaluationContext(this.config, childScope)
  }

  evaluate<I>(
    expr: I
  ): I extends unknown
    ? Value
    : I extends any[]
    ? Value[]
    : null | boolean | undefined | number extends I
    ? I
    : Value

  evaluate(expr: unknown): Value {
    switch (typeof expr) {
      case 'number':
        if (!Number.isFinite(expr)) return undefined
        return expr
      case 'boolean':
        return expr
      case 'object':
        if (expr === null) return null
        if (isDate(expr)) return expr
        if (isJsonifiable(expr)) return this.evaluate(expr.toJSON())

        return isArray(expr) ? this.evaluateArray(expr) : this.evaluateObject(expr)
      case 'string':
        return this.evaluateString(expr)
      default:
        return undefined
    }
  }

  evaluateString(expr: string): Value {
    return evalString.call(this, expr)
  }

  evalStringExpression(expr: string): Value {
    return evalStringExpression.call(this, expr)
  }

  evaluateArray(expr: unknown[]): Value[] {
    const result = Array(expr.length)
    for (let i = 0; i < expr.length; i++) {
      result[i] = this.evaluate(expr[i]) ?? null
    }
    return result
  }

  evaluateObject(expr: any): Value {
    if (!isPlainObject(expr)) return undefined
    if (isMethodCallObject(expr)) return this.evaluateMethodCall(expr)

    return this.evaluateHash(expr)
  }

  evaluateMethodCall(expr: MethodCall): Value | undefined {
    const method = this.config.findMethod(expr)
    return method?.evaluate.call(this, expr)
  }

  evaluateHash(expr: { [key: string]: unknown }): Partial<Record<string, Value>> {
    const output: Record<string, Value> = {}

    for (const key in expr) {
      if (key === '$schema' && expr[key] === JSON_SCHEMA_V1) continue

      const newKey = toString(this.evaluateString(key))
      if (newKey === undefined) continue

      const value = this.evaluate(expr[key])
      if (value === undefined) continue

      output[newKey] = value
    }

    return output
  }
}

function pathPartEvaluator(this: EvaluationContext, part: string): undefined | string {
  if (part.startsWith(`'`) || part.startsWith(`"`)) {
    const unquoted = unquote(part)
    if (unquoted === undefined) return undefined

    const result = toString(evalString.call(this, unquoted))
    if (result === undefined) return undefined

    return result
  } else {
    const result = toString(evalStringExpression.call(this, part))
    if (result === undefined) return undefined

    return result
  }
}

/**
 * @param {string} expr A trimmed (!) string
 */
function evalStringExpression(this: EvaluationContext, expr: string): Value {
  if (!expr) return undefined

  if (expr === 'null') return null
  if (expr === 'true') return true
  if (expr === 'false') return false
  if (expr === 'undefined') return undefined
  if (expr === '$today') return new Date()
  if (expr === '$yesterday') return this.evaluateMethodCall({ ':sum': [new Date(), '-1d'] })
  if (expr === '$tomorrow') return this.evaluateMethodCall({ ':sum': [new Date(), '1d'] })

  const firstCharCode = expr.charCodeAt(0)

  if (!this.config.ignoreInlineExpression) {
    const result = evaluateStringTransformer.call(this, expr)

    if (result.found && result.value !== undefined) {
      return result.value
    }
  }

  if (firstCharCode === 39 /* ' */ || firstCharCode === 34 /* " */) {
    return unquote(expr)
  }

  if (
    firstCharCode === 45 /* - */ ||
    firstCharCode === 46 /* . */ ||
    // firstCharCode === 101 /* e */ ||
    (firstCharCode >= 48 && firstCharCode <= 57) /* 0-9 */
  ) {
    const number = Number(expr)
    if (!Number.isNaN(number)) return number
  }

  if (
    firstCharCode === 35 /* # */ || // Legacy
    firstCharCode === 36 /* $ */ ||
    firstCharCode === 64 /* @ */ ||
    firstCharCode === 95 /* _ */ ||
    (firstCharCode >= 65 && firstCharCode <= 90) /* A-Z */ ||
    (firstCharCode >= 97 && firstCharCode <= 122) /* a-z */
  ) {
    // Optimization
    if (expr === '$') return jsonify(this.scope)

    const path = parsePath(expr, pathPartEvaluator.bind(this))
    if (path) {
      const value = findPath.call(this.scope, path[0] === '$' ? path.slice(1) : path)
      return jsonify(value)
    }
  }

  return undefined
}

function evalString(this: EvaluationContext, str: string): Value {
  const parts = []

  let i = 0
  while (i < str.length) {
    const closing = str.indexOf('}', i)
    const opening = str.indexOf('{', i)

    if (closing !== -1 && (opening === -1 || closing < opening)) {
      return undefined
    }

    if (opening === -1) {
      const part = str.slice(i)
      parts.push(part)
      break // EOS
    }

    if (opening > i) {
      const part = str.slice(i, opening)
      parts.push(part)
    }

    const double = str.charAt(opening + 1) === '{'

    // Triple {{{ are not allowed
    if (double && str.charAt(opening + 2) === '{') return undefined
    if (double && this.config.strict) return undefined

    const tagLength = double ? 2 : 1

    const ending = findMatchingPair(str, '{'.repeat(tagLength), '}'.repeat(tagLength), opening, 1)
    if (ending === -1) return undefined

    const expr = str.slice(opening + tagLength, ending)

    const value = evalStringExpression.call(this, expr.trim())
    if (opening === 0 && ending === str.length - tagLength) return value // Return pure expressions

    const sub = toString(value)
    if (sub) parts.push(sub)
    i = ending + tagLength
  }

  return parts.join('')
}

function toNewVarsGetter<T extends JsonValue | Record<string, undefined | JsonValue>>(
  this: EvaluationContext,
  define: T
) {
  // Legacy (1)
  if (!isPlainObject(define)) return null

  // Optimization
  if (Object.keys(define).length === 0) return null

  return (scope: Scope): Record<keyof T, Value> => {
    const ctx = new EvaluationContext(this.config, scope)

    const newVars: Record<string, Value> = {}

    for (const k in define) {
      if (k === '$schema') continue // Reserve for future use

      const compiled = k.startsWith(':')
        ? // Defining methods is not supported (yet). Prevent accessing method
          // defined in parent scopes to support future use of :keyed properties.
          undefined
        : ctx.evaluate(define[k])

      newVars[k] = compiled
    }

    return newVars as Record<keyof T, Value>
  }
}

export class Evaluator {
  public readonly config: Config
  // Optimization: avoid creating one EvaluationContext per evaluate() invocation
  private readonly reUsableContext: EvaluationContext<any>

  constructor(config: Config) {
    // Allow un-necessary "compile" methods to be garbage collected.
    this.config = config.extend({ stripCompile: true })
    this.reUsableContext = new EvaluationContext(this.config, {})
  }

  evaluate<I>(expr: I, scope?: Scope) {
    try {
      this.reUsableContext.scope = scope
      return this.reUsableContext.evaluate<I>(expr)
    } finally {
      this.reUsableContext.scope = undefined
    }
  }

  evaluateHash(expr: Record<string, unknown>, scope?: Scope) {
    try {
      this.reUsableContext.scope = scope
      return this.reUsableContext.evaluateHash(expr)
    } finally {
      this.reUsableContext.scope = undefined
    }
  }

  evaluateMethodCall(expr: MethodCall, scope?: Scope) {
    try {
      this.reUsableContext.scope = scope
      return this.reUsableContext.evaluateMethodCall(expr)
    } finally {
      this.reUsableContext.scope = undefined
    }
  }

  evaluateString(expr: string, scope?: Scope) {
    try {
      this.reUsableContext.scope = scope
      return this.reUsableContext.evaluateString(expr)
    } finally {
      this.reUsableContext.scope = undefined
    }
  }
}
