import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isArray } from '../util'

import { compileIn, computeIn } from './in'

type Type = { ':nin': [JsonValue, JsonValue] }

/**
 * Tests if a value is not in a list.
 *
 * @usage: ```{ ':nin': [value, array] }```
 * where:
 * - value: any: the value to search for
 * - array: array | string: the list of values to search in
 *
 * @example
 * ```{ ':nin': [1, [1, 2, 3]] }``` => false
 * ```{ ':nin': [4, [1, 2, 3]] }``` => true
 */
export default createMethod<Type>({
  name: ':nin',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':nin') continue
      return false
    }

    if (!isArray(expr[':nin'])) return false
    if (expr[':nin'].length !== 2) return false

    return true
  },

  evaluate({ ':nin': [needle, haystack] }): undefined | boolean {
    const result = computeIn.call(this, needle, haystack)
    if (result === undefined) return undefined
    return !result
  },

  compile(expr) {
    const needleGetter = this.createContext(':nin', 0).compile(expr[':nin'][0])
    const haystackGetter = this.createContext(':nin', 1).compile(expr[':nin'][1])

    const compiled = compileIn.call(this, needleGetter, haystackGetter)

    return (scope: Scope) => {
      const result = compiled(scope)
      if (result === undefined) return undefined
      return !result
    }
  },
})
