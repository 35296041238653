import {
  CompiledExpression,
  Compiler,
  ContextDetails,
  createCompiler,
  JsonValue,
  Scope,
  Value,
} from '@penbox-io/json-expression'
import { objectGetArray } from '@penbox-io/stdlib'

import type { FlowEntity } from '../entities/flow.js'
import { legacyAllowed } from '../entities'
import type { Step, Element } from '../json-form'
import { optionsFormBuilder, scenarioElementsBuilder, stepsFormBuilder } from '../json-form'

import { methods } from './methods'

import { convertLegacyForm } from './legacy.js'
import { FlowCustomization } from '../types/entities.js'

export type ObjectPath = ReadonlyArray<string | number>

const mergeDetails = (details?: ContextDetails, path?: ObjectPath): ContextDetails => ({
  path: details?.path && path ? [...details.path, ...path] : details?.path || path || [],
})

type ExpressionTransform = (v: Value) => Value
type ResultTransform<T = any, S = Scope> = (result: Value, scope: S) => T

export class FlowCompiler {
  private readonly compiler: Compiler

  constructor(public readonly strict = false) {
    this.compiler = createCompiler({ legacy: !strict, methods })
  }

  private compileDefs(
    flow: FlowEntity | FlowCustomization,
    path: ObjectPath,
    details?: ContextDetails
  ): (scope: Scope) => Scope {
    const variables = objectGetArray(flow, path)

    // Variable definitions are optional in flow but the compiler will throw
    // when compiling "null" as definition.
    if (!variables) return (scope: Scope) => scope

    return this.compiler.compileDefine(variables as JsonValue, mergeDetails(details, path))
  }

  public compileVariables(flow: FlowEntity | FlowCustomization, details?: ContextDetails) {
    return this.compileDefs(flow, ['attributes', 'variables'], details)
  }

  private compileExpr(
    flow: FlowEntity | FlowCustomization,
    path: ObjectPath,
    exprTransform?: ExpressionTransform,
    details?: ContextDetails
  ) {
    const expr = objectGetArray(flow, path)
    const transformed = exprTransform ? exprTransform(expr) : expr
    return this.compiler.compile(transformed, mergeDetails(details, path))
  }

  protected createCompiler(flow: FlowEntity | FlowCustomization, details?: ContextDetails) {
    const varsCompiled = this.compileVariables(flow, details)

    return (path: ObjectPath, exprTransform?: ExpressionTransform) => {
      // TODO: If path is not attributes.steps, attributes.options or
      // meta.pnbx:requests:data we should expose @steps
      const exprCompiled = this.compileExpr(flow, path, exprTransform, details)

      return (scope: Scope): [Value, Scope] => {
        const subScope = varsCompiled(scope)
        const result = exprCompiled(subScope)

        return [result, subScope] as [Value, Scope]
      }
    }
  }

  protected compileTransform<T, S>(
    flow: FlowEntity | FlowCustomization,
    path: ObjectPath,
    exprTransform: undefined | ExpressionTransform,
    resultTransform: ResultTransform<T, S>,
    details?: ContextDetails
  ): CompiledExpression<T>

  protected compileTransform(
    flow: FlowEntity | FlowCustomization,
    path: ObjectPath,
    exprTransform?: undefined | ExpressionTransform,
    resultTransform?: undefined,
    details?: ContextDetails
  ): CompiledExpression<any>

  protected compileTransform(
    flow: FlowEntity | FlowCustomization,
    path: ObjectPath,
    exprTransform?: undefined | ExpressionTransform,
    resultTransform?: ResultTransform,
    details?: ContextDetails
  ): CompiledExpression {
    const compiled = this.createCompiler(flow, details)(path, exprTransform)
    return (scope: Scope) => {
      const [result, subScope] = compiled(scope)
      return resultTransform ? resultTransform(result, subScope) : result
    }
  }

  compileSteps(flow: FlowEntity, details?: ContextDetails): CompiledExpression<Step[]> {
    return this.compileTransform(
      flow,
      ['attributes', 'steps'],
      !this.strict && legacyAllowed(flow) ? convertLegacyForm : undefined,
      stepsFormBuilder,
      details
    )
  }

  compileOptions(flow: FlowEntity, details?: ContextDetails): CompiledExpression<Step[]> {
    return this.compileTransform(
      flow,
      ['attributes', 'options'],
      !this.strict && legacyAllowed(flow) ? convertLegacyForm : undefined,
      optionsFormBuilder,
      details
    )
  }

  compileScenario(flow: FlowEntity, details?: ContextDetails): CompiledExpression<Element[]> {
    return this.compileTransform(
      flow,
      ['meta', 'pnbx:requests:data'],
      !this.strict && legacyAllowed(flow) ? convertLegacyForm : undefined,
      scenarioElementsBuilder,
      details
    )
  }

  compileExportMapping(flow: FlowEntity, clientId: string, details?: ContextDetails) {
    if (flow.meta?.[`pnbx:connect:export:${clientId}:data`] === undefined) return undefined
    return this.compileTransform(
      flow,
      ['meta', `pnbx:connect:export:${clientId}:data`],
      !this.strict && legacyAllowed(flow) ? convertLegacyForm : undefined,
      undefined,
      details
    )
  }

  compileImportMapping(flow: FlowEntity, clientId: string, details?: ContextDetails) {
    if (flow.meta?.[`pnbx:connect:import:${clientId}:data`] === undefined) return undefined
    return this.compileTransform(
      flow,
      ['meta', `pnbx:connect:import:${clientId}:data`],
      !this.strict && legacyAllowed(flow) ? convertLegacyForm : undefined,
      undefined,
      details
    )
  }

  compileCsvExportColumn(
    flowCustomization: FlowCustomization,
    index: number,
    details?: ContextDetails
  ) {
    return this.compileTransform(
      flowCustomization,
      ['meta', `pnbx:operation:request_export:columns`, index, 'value'],
      undefined,
      undefined,
      details
    )
  }
}
