/**
 * @typedef {object} Entity
 * @property {string} id
 * @property {string} type
 * @property {Record<string, any>} attributes
 * @property {Record<string, { data: null | { type: string; id: string } }>} relationships
 * @property {Record<string, any>} [meta]
 */

const createState = () => ({
  requestId: null,

  loading: false,
  error: null,
  accessLocale: null,
  accessOptions: null,
  accessBranding: null,
  accessInactiveOrArchivedRequest: null,

  access: /** @type {null | Entity} */ (null),
  request: /** @type {null | Entity} */ (null),
  response: /** @type {null | Entity} */ (null),
  responses: /** @type {null | Entity} */ (null),
  flow: /** @type {null | Entity} */ (null),
  company: /** @type {null | Entity} */ (null),
  customization: /** @type {null | Entity} */ (null),
  branding: /** @type {null | Entity} */ (null),

  active: null,
  pending: {},
  pendingFiles: {},
  uploads: {},
})

/**
 * @typedef {ReturnType<typeof createState>} State
 */

export default createState
