import { flatten, isObject, isValidChoice, isUniqueChoice } from './util.js'

const normalizeArrayChoice = function (choice) {
  return !isObject(choice) ? { value: choice, label: choice } : choice
}

/** @this {{ [value: string]: any }} */
const normalizeObjectChoice = function (value) {
  return { value, label: this[value] || value }
}

const normalizeChoices = (choices) => {
  if (Array.isArray(choices)) {
    return choices
      .flat(Infinity)
      .filter(Boolean)
      .map(normalizeArrayChoice)
      .filter(isValidChoice)
      .filter(isUniqueChoice)
  }

  if (isObject(choices)) {
    // no need to filter(isUniqueChoice) (object keys are unique)
    return Object.keys(choices).sort().map(normalizeObjectChoice, choices).filter(isValidChoice)
  }

  return null
}

const normalizeElement = (element) => {
  if (element === null || typeof element !== 'object') return null

  const { id, key, type, required, value, error, default: _d = null, visible, ...options } = element

  if (key && typeof key !== 'string') return null
  if (!type || typeof type !== 'string') return null
  if (!(visible ?? true)) return null

  const newElement = key
    ? { id, type, options, error, key, value, default: _d, required: required ?? false }
    : { id, type, options, error }

  if ('choices' in options) newElement.options.choices = normalizeChoices(options.choices)

  // json-form compatibility
  if (type === 'number') {
    newElement.type = 'text'
    newElement.options.format = 'number'
  }

  return newElement
}

const fixElementKey = (element, index, array) => {
  if (element?.key?.includes?.('.') !== false) return element
  return { ...element, key: `data.${element.key}` }
}

const fixElementId = (element, index, array) => {
  if (element === null || typeof element !== 'object') return null

  if (element.id == null) {
    let count = 0
    for (let i = 0; i < index; i++) {
      if (!array[i]) continue
      if (array[i].key === element.key && array[i].type === element.type) count++
    }
    return { ...element, id: `${element.key}:${element.type}:${count}` }
  } else {
    return element
  }
}

/**
 * @param {any} elements
 */
export const normalizeElements = (elements) => {
  return flatten(elements)?.map(normalizeElement).map(fixElementId).filter(Boolean)
}

const isSubmit = (e) => e?.type === 'submit'
const ensureSubmitable = (elements) =>
  elements.some(isSubmit) || elements[elements.length - 1]?.options.submit_on_change === true
    ? elements // No need to add a submit button
    : elements.concat({ id: '__smart_form_submit_element', type: 'submit', options: {} })

const normalizeStep = (step, stepIdx = undefined) => {
  if (step === null || typeof step !== 'object') return null
  if (!(step.visible ?? true)) return null

  return {
    id: step.id ?? stepIdx,
    label: step.label || null,
    meta: step.meta || undefined,
    enabled: Boolean(step.enabled ?? true),
    elements: ensureSubmitable(
      // Legacy: only flow steps need their key to be fixed
      normalizeElements(step.elements)?.map(fixElementKey) ?? []
    ),
  }
}

/**
 * @param {any} steps
 */
export const normalizeSteps = (steps) => {
  if (!Array.isArray(steps)) return null

  return flatten(steps)?.map(normalizeStep).filter(Boolean)
}
