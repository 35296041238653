const rootGetter =
  (...keys) =>
  (state, getters, rootState, rootGetters) => {
    for (const key of keys) {
      const value = rootGetters[key]
      if (value) return value
    }

    return null
  }

export default {
  title: rootGetter('request/title', 'contact/title', 'hostname/title'),
  colors: rootGetter('request/colors', 'contact/colors', 'hostname/colors'),
  logo: rootGetter('request/logo', 'contact/logo', 'hostname/logo'),
  favicon: rootGetter(
    'request/favicon',
    'request/icon',
    'contact/favicon',
    'contact/icon',
    'hostname/favicon'
  ),
  locale: rootGetter('request/locale', 'contact/locale', 'hostname/locale'),
  locales: rootGetter('request/locales', 'hostname/locales'),
}
