import { MethodCall, MethodSignature } from '../types'
import { createMethod, isPureExpression, isString, toString } from '../util'

type Type = {
  ':capitalize': string | MethodCall
}

// Taken from stdlib instead of importing the lib
const capitalize = (str: string): string =>
  str.toLowerCase().replace(/(?:^|[^\w])\w{1}/g, (match) => match.toUpperCase())

/**
 * Capitalize the first letter of each words in a string.
 * @usage: ```{ ':capitalize': string }```
 * where:
 * - string: string: the string to capitalize
 * @example
 * ```{ ':capitalize': 'hello' }``` => 'Hello'
 * ```{ ':capitalize': '{name}' }``` => 'John' // where name in scope
 */
export default createMethod<Type>({
  name: ':capitalize',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':capitalize') continue
      return false
    }

    const capitalize = expr[':capitalize']
    if (!isPureExpression(capitalize) && !isString(capitalize)) return false

    return true
  },

  evaluate(expr) {
    const str = toString(this.evaluate(expr[':capitalize']))
    if (!str) return str
    return capitalize(str)
  },
})
