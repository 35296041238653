import { isPureExpression } from './../util'
import { subScope } from '../scope'
import { JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isArray } from '../util'
import { asIterableExpression } from '../compilation'

type Type = {
  ':find': JsonValue
  ':where'?: JsonValue
  ':else'?: JsonValue
}

/**
 * Find the first truthy value in an array. Optionally filter by a condition. If no truthy value is found, return the `:else` value.
 *
 * @usage: ```{ ':find': array, ':where': condition, ':else': value }```
 * where:
 * - array: any[] | any: the array to search
 * - condition: any: the condition to filter by
 * - value: any: the value to return if no truthy value is found
 *
 * @sub-scope variables
 * - @index: number: the current index of the array
 * - @item: any: the current item in the array
 * - @position: number: the current position in the array (index + 1)
 *
 * @example
 * ```{ ':find': [null, false, '', 0, 1] }``` => 1
 * ```{ ':find': [null, false, '', 0, 1], ':where': { ':cmp': '{ @item }', ':eq': 0 } }``` => 0
 *
 */
export default createMethod<Type>({
  name: ':find',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue

      if (key === ':where') continue
      if (key === ':else') continue
      if (key === ':find') {
        if (isArray(expr[':find'])) continue
        if (isPureExpression(expr[':find'])) continue
        return false
      }

      return false
    }

    if (expr[':find'] === undefined) return false

    return true
  },

  evaluate(expr): undefined | Value {
    const array = this.evaluate(expr[':find'])
    if (isArray(array)) {
      if (expr[':where'] === undefined) {
        const first = array.find(Boolean)
        if (first !== undefined) return first
      } else {
        // Optimization: Create a re-usable child context
        const ctx = this.subScope({
          '@item': undefined as Value,
          '@index': -1,
          '@position': 0,
        })

        for (let i = 0; i < array.length; i++) {
          ctx.scope['@item'] = array[i]
          ctx.scope['@index'] = i
          ctx.scope['@position'] = i + 1

          const whereResult = ctx.evaluate(expr[':where'])
          if (whereResult) return array[i]
        }
      }
    }

    return this.evaluate(expr[':else'])
  },

  compile(expr) {
    const array$ = this.createContext(':find').compile(expr[':find'])
    const else$ = this.createContext(':else').compile(expr[':else'])

    const iterable$ = asIterableExpression(array$)

    // No "where" clause, use truthiness
    if (expr[':where'] === undefined) {
      return (scope: Scope) => {
        const iterable = iterable$(scope)
        if (iterable) for (const item of iterable) if (item) return item
        return else$(scope)
      }
    }

    const where$ = this.createContext(':where').compile(expr[':where'])
    if (where$.static) {
      const truthy = Boolean(where$.staticValue)
      if (!truthy) return else$ // No need to loop if all items are falsy

      return (scope: Scope) => {
        const iterable = iterable$(scope)
        if (iterable) for (const item of iterable) return item
        return else$(scope)
      }
    }

    return (scope: Scope) => {
      const iterable = iterable$(scope)
      if (iterable) {
        let index = -1

        // Optimization: Create a re-usable child scope instead of one per iteration
        const childScope = subScope(scope, {
          '@item': undefined as Value,
          '@index': index,
          '@position': index + 1,
        })

        for (const item of iterable) {
          index++

          childScope['@item'] = item
          childScope['@index'] = index
          childScope['@position'] = index + 1

          if (where$(childScope)) return item
        }
      }

      return else$(scope)
    }
  },
})
