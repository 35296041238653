import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isString } from '../util'

type Type = { ':split': JsonValue; ':separator': JsonValue }

const defaultSeparator = () => ','

/**
 * Splits a string into an array of substrings.
 * @usage: ```{":split": string, ":separator": string}```
 * where:
 * - `string` is a string to split
 * - `separator` is a string to split by
 *
 * @example
 * - ```{":split": "a,b,c", ":separator": ","}``` returns `["a", "b", "c"]`
 */
export default createMethod<Type>({
  name: ':split',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':split' && typeof expr[key] !== 'undefined') continue
      if (key === ':separator' && typeof expr[key] !== 'undefined') continue
      return false
    }

    return true
  },

  evaluate(expr) {
    const subject = this.evaluate(expr[':split'])
    if (!isString(subject)) return undefined

    const separator = ':separator' in expr ? this.evaluate(expr[':separator']) : defaultSeparator()
    if (!isString(separator)) return undefined

    return subject.split(separator)
  },

  compile(expr) {
    const subjectCompiled = this.createContext(':split').compile(expr[':split'])

    const sepCompiled =
      ':separator' in expr
        ? this.createContext(':separator').compile(expr[':separator'])
        : defaultSeparator

    return (scope: Scope) => {
      const subject = subjectCompiled(scope)
      if (!isString(subject)) return undefined

      const separator = sepCompiled(scope)
      if (!isString(separator)) return undefined

      return subject.split(separator)
    }
  },
})
