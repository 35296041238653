export const HEX_COLOR = /^#[a-f0-9]{3}(?:[a-f0-9]{3})?$/i
export const SLUG = /^[a-z0-9]([a-z0-9]|-[a-z0-9])+$/

export const UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
export const UUID_V4 = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export const LOCALE_REGEXP = /^(?<language>[a-z]{2})(?:[-_](?<territory>[A-Z]{2}))?$/
export const MOBILE_PHONE = /^(00|\+)\d{6,15}$/

export const ISO_DATE =
  /^(?:[-+]\d{2})?(?:\d{4}(?!\d{2}\b))(?:(-?)(?:(?:0[1-9]|1[0-2])(?:\1(?:[12]\d|0[1-9]|3[01]))?|W(?:[0-4]\d|5[0-2])(?:-?[1-7])?|(?:00[1-9]|0[1-9]\d|[12]\d{2}|3(?:[0-5]\d|6[1-6])))(?![T]$|[T][\d]+Z$)(?:[T\s](?:(?:(?:[01]\d|2[0-3])(?:(:?)[0-5]\d)?|24:?00)(?:[.,]\d+(?!:))?)(?:\2[0-5]\d(?:[.,]\d+)?)?(?:[Z]|(?:[+-])(?:[01]\d|2[0-3])(?::?[0-5]\d)?)?)?)?$/
export const INTL_PHONE_NUMBER =
  /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(\d{1,14})$/

export const NON_ASCII_CHARS = /[^\x20-\x7E]/g

// Because UTF (used by JS) is an extension of ISO-8859-1, we can use simple char code ranges
export const NON_IEC_8859_1_CHARS = /[^\x20-\x7E\xA0-\xFF]/g

// ISO-8859-15 changes 8 chars of ISO-8859-1
export const NON_IEC_8859_15_CHARS =
  /[^\x20-\x7E\xA0-\xA3€\xA5Š\xA7š\xA9-\xB3Ž\xB5-\xB7ž\xB9-\xBBŒœŸ\xBF-\xFF]/g

export const NON_GSM7_CHARS = /[^a-zA-Z0-9 &@#?!()<>'_.,:;+\-*/%=àåÅèéÉìòù£$€ÇØøÆæß¥"\n]/g // eslint-disable-line no-control-regex

export const REG_EXP_STRING = /^\/(.+)\/([gimuy]+)?$/

export const parseRegexp = (str: unknown): RegExp | null => {
  if (typeof str !== 'string') return null
  try {
    const [, pattern = `^${str}$`, flags] = str.match(REG_EXP_STRING) ?? []
    return new RegExp(pattern, flags)
  } catch {
    return null
  }
}

export const SED_RE = /^s\/((?:[^/]|\\\/)+)\/((?:[^/]|\\\/)*)\/([a-z]*)$/
export const compileSed = (exp: string): ((a: any) => any) | null => {
  const sed = SED_RE.exec(exp)
  if (!sed) return null
  const re = new RegExp(sed[1], sed[3])
  const replaceValue = sed[2].replace(/\\\//g, '/')
  return (v = null) => {
    switch (typeof v) {
      case 'string':
      case 'number':
      case 'bigint':
      case 'boolean':
        return String(v).replace(re, replaceValue)
      default:
        return v
    }
  }
}
