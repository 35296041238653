import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { toDateOrValue } from '../util'
import { StringTransformer } from './'
import { findNext, findQuotes } from './utils'

/**
 * Evaluate comparison between two values.
 *
 * @usage: ```expr == expr2```. Available operators are `==`, `!=`.
 */
class EqualityCompareStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const quotes = findQuotes(expr)

    const equal = findNext(expr, 0, ['!', '='], quotes)

    return equal !== -1
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    const quotes = findQuotes(expr)
    const equal = findNext(expr, 0, ['!', '='], quotes)

    const operator = expr.charAt(equal) + expr.charAt(equal + 1)

    const left = expr.slice(0, equal).trim()
    const right = expr.slice(equal + operator.length).trim()

    const evaledLeft = toDateOrValue(this.evalStringExpression(left))
    const evaledRight = toDateOrValue(this.evalStringExpression(right))

    if (operator === '==') {
      return this.evaluateMethodCall({ ':cmp': evaledLeft, ':eq': evaledRight })
    } else {
      return this.evaluateMethodCall({ ':cmp': evaledLeft, ':neq': evaledRight })
    }
  }
}

export default new EqualityCompareStringTransformer()
