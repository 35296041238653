import { ContextDetails, Scope, Value } from '@penbox-io/json-expression'
import LRU from 'lru-cache'

import type { FlowEntity } from '../entities/flow.js'

import { FlowCompiler, ObjectPath } from './compiler.js'

export class FlowCompilerCached extends FlowCompiler implements FlowCompiler {
  private readonly cache = new LRU<string, (path: ObjectPath) => (scope: Scope) => [Value, Scope]>({
    max: 20,
  })

  protected createCompiler(
    flow: FlowEntity,
    details?: ContextDetails
  ): (path: ObjectPath) => (scope: Scope) => [Value, Scope] {
    const key = getFlowKey(flow)
    if (key && this.cache.has(key)) {
      return this.cache.get(key)!
    } else {
      const val = super.createCompiler(flow, details)

      const pathCache = new LRU<string, (scope: Scope) => [Value, Scope]>({ max: 5 })
      const cached = (path: ObjectPath) => {
        const pathKey = JSON.stringify(path)
        if (pathCache.has(pathKey)) {
          return pathCache.get(pathKey)!
        } else {
          const compiled = val(path)
          pathCache.set(pathKey, compiled)
          return compiled
        }
      }

      if (key) this.cache.set(key, cached)
      return cached
    }
  }
}

function getFlowKey(flow: FlowEntity): null | string {
  const ts = flow?.attributes?.$updated_at
  if (!ts || !flow.id) return null
  return `${flow.id}:${ts}`
}
