import { objectGet } from '@penbox-io/stdlib'

/**
 * @typedef {import('./engine').RequestScope} RequestScope
 */

export {
  createRequestScope,
  evalExpression,
  getFlowName,
  methods,
  negotiateFlowLocale,
} from './engine/index.js'
// TODO: Move in another module
export {
  formatElementTitle,
  formatElementValue,
  stringifyElementValue,
  stringifyFormattedValue,
} from './format.js'
export { convertLegacyForm } from './legacy.js'
export { normalizeElements, normalizeSteps } from './normalize.js'
export { metaOption } from './options.js'
export {
  compareResponsesAsc,
  compareResponsesDesc,
  extractFlowLocales,
  getHelpableText,
  setPath,
  setValue,
} from './util.js'

/** @this {RequestScope} */
export function populateElement(element) {
  if (element.value === undefined && element.key && typeof element.key === 'string') {
    const loadedValue = objectGet(this, element.key)
    const value = loadedValue === undefined ? element.default : loadedValue
    return { ...element, value }
  } else {
    return element
  }
}

/** @this {RequestScope} */
export function populateStep(step) {
  return { ...step, elements: step.elements.map(populateElement, this) }
}
