import { findMatchingQuote } from '../util'

export function findQuotes(str: string): Array<[number, number]> {
  let quotes: Array<[number, number]> = []
  quotes = quotes.concat(findPairs(str, "'"))
  quotes = quotes.concat(findPairs(str, '"'))

  return quotes
}

// Find the next occurence of a char not contained in a tuple
export function findNext(
  str: string,
  start: number,
  char: string | string[],
  tuples: Array<[number, number]>
) {
  let i = start
  char = Array.isArray(char) ? char : [char]
  while (i < str.length) {
    if (char.includes(str[i]) && !isInTuples(i, tuples)) return i
    i++
  }
  return -1
}

export function findPairs(str: string, character: string) {
  const pairs: Array<[number, number]> = []
  let i = 0
  while (i < str.length) {
    // Find the next quote
    const quote = str.indexOf(character, i)
    if (quote === -1) break
    // Let's find the matching quote
    const next = findMatchingQuote(str, quote)
    if (next === -1) return pairs // Unterminated quote
    pairs.push([quote, next])
    i = next + 1
  }
  return pairs
}

// Find the last occurence of a char not contained in a tuple
export function findLast(str: string, char: string | string[], tuples: Array<[number, number]>) {
  let i = str.length - 1
  char = Array.isArray(char) ? char : [char]
  while (i >= 0) {
    if (char.includes(str[i]) && !isInTuples(i, tuples)) return i
    i--
  }
  return -1
}

function isInTuples(value: number, tuples: Array<[number, number]>) {
  for (const tuple of tuples) {
    if (tuple[0] <= value && tuple[1] >= value) return true
  }
  return false
}
