if (!window.EventTarget) {
  class EventTargetPolyfill {
    constructor() {
      this.listeners = Object.create(null)
    }

    addEventListener(type, callback) {
      this.listeners[type] = this.listeners[type] || []
      this.listeners[type].push(callback)
    }

    removeEventListener(type, callback) {
      const stack = this.listeners[type]
      if (!stack) return

      for (let i = 0, l = stack.length; i < l; i++) {
        if (stack[i] === callback) {
          stack.splice(i, 1)
          return
        }
      }
    }

    dispatchEvent(event) {
      const stack = this.listeners[event.type]
      if (!stack) return true

      for (const listener of stack.slice()) listener.call(this, event)

      return !event.defaultPrevented
    }
  }

  window.EventTarget = EventTargetPolyfill
}
