import { createMethodLegacyMultiple } from '../legacy'
import { isArray } from '../util'

/**
 * Concatenates multiple arrays into a single array. If an item is not an array, it will be wrapped in an array.
 * If you are looking to concatenate strings, use the `:join` method.
 * If you are looking to flatten an array, use the `:flatten` method.
 *
 * @usage: ```{ ':concat': [array1, array2, ...] }```
 * where:
 * - array1, array2, ...: any: the arrays to concatenate
 *
 * @example
 * ```{ ":concat": [[1, 2], [3, 4]] }``` => [1, 2, 3, 4]
 * ```{ ":concat": [1, 2, [3, 4]] }``` => [1, 2, 3, 4]
 * ```{ ":concat": [1, 2, 3, 4] }``` => [1, 2, 3, 4]
 * ```{ ":concat": [[1, 2], 3, 4] }``` => [1, 2, 3, 4]
 * ```{ ":concat": [1, 2, [3, [4]]] }``` => [1, 2, 3, [4]] // if your looking for a flat array, use the ':flatten' method
 *
 *
 */
export default createMethodLegacyMultiple(':concat', [], function ({ ':concat': concatExpr }) {
  if (!isArray(concatExpr)) return undefined

  const array = []

  for (let i = 0; i < concatExpr.length; i++) {
    const item = this.evaluate(concatExpr[i])
    if (item === undefined) continue

    if (isArray(item)) array.push(...item)
    else array.push(item)
  }

  return array
})
