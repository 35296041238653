import { createMethodLegacySimple } from '../legacy'
import { dateDeltaRegex, getDateUnitFactor, isArray, isDate, isString, toNumber } from '../util'

const add = (a: number, b: number): number => a + b

/**
 * Returns the sum of all items in an array.
 * @usage: ```{":sum": array}```
 * where:
 * - `array` is an array of numbers or date or date delta strings
 *
 * available delta units:
 * - `y` or `year` or `years` for years
 * - `M` or `month` or `months` for months
 * - `d` or `day` or `days` for days
 * - `h` or `hour` or `hours` for hours
 * - `m` or `minute` or `minutes` for minutes
 * - `s` or `second` or `seconds` for seconds (default)
 *
 * @example
 * - ```{":sum": [1, 2, 3]}``` returns `6`
 * - ```{":sum": [1, 2, 3, 4]}``` returns `10`
 * - ```{":sum": ["{$today}", '2d', '3d', '12h']}``` returns `today + 5 days and 12 hours`
 * - ```{":sum": ["{$today}", '2d', '-12h']}``` returns `today + 1.5 days`
 */
export default createMethodLegacySimple(':sum', function (args): undefined | number | Date {
  const input = this.evaluate(args)

  if (!isArray(input)) return undefined
  if (input.length === 0) return 0

  const isFirstElementDate = isDate(input[0])

  const values = input.map((v) => {
    // handle date delta strings - transform them to milliseconds
    if (isFirstElementDate && isString(v) && dateDeltaRegex.test(v)) {
      const result = dateDeltaRegex.exec(v)

      if (result) {
        const [_, value, unit] = result
        const factor = getDateUnitFactor(unit)

        return Number(value) * factor
      }
    }

    return toNumber(v)
  })

  if (values.some(isNaN)) return undefined

  const sum = values.reduce(add, 0)
  if (isDate(input[0])) return new Date(sum)

  return sum
})
