import { createMethodLegacyMultiple } from '../legacy'
import { Value } from '../types'
import { isArray, isString, toString } from '../util'

/**
 * Joins the elements of an array into a string using a separator (default is a comma).
 *
 * @usage: ```{":join": array, ":separator": string}```
 * where:
 * - `array` is an array
 * - `string` is a string separator
 *
 * @example
 * - ```{"join": [1, 2, 3], ":separator": "-"}``` returns `"1-2-3"`
 * - ```{"join": [1, 2, 3]}``` returns `"1,2,3"`
 */
export default createMethodLegacyMultiple(':join', [':separator'], function (expr): Value {
  const { ':join': joinExpr, ':separator': separatorExpr = ',' } = expr

  const separator = this.evaluate(separatorExpr)
  if (!isString(separator)) return undefined

  const array = this.evaluate(joinExpr)
  if (!isArray(array)) return undefined

  return array.map(toString).filter(Boolean).join(separator)
})
