import type { Step, Element } from './engine.js'

export function fixInvalidElements<E extends Element[]>(elements: E): E {
  // Optimization: we are mutating the elements
  for (const element of elements) {
    if (element.type === 'card') {
      if (!element.options.label && !element.options.content && !element.options.actions?.length) {
        element.options.label = element.title || undefined
        element.title = null
      }
    } else if (element.type === 'checkbox') {
      if (element.title && !element.options.label) {
        element.options.label = element.title || undefined
        element.title = null
      }
    }
  }

  return elements
}

export function fixInvalidSteps<S extends Step[]>(steps: S): S {
  // Optimization: we are mutating the steps
  for (const step of steps) {
    step.elements = fixInvalidElements(step.elements)
  }

  return steps
}
