import { isArray } from '@penbox-io/stdlib'

import { type Dict, type FormHelpable, ifFormHelpable, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import {
  type Choice,
  type Value as ItemValue,
  findChoice,
  normalizeChoices,
  stringifyChoice,
} from '../utils/choice.js'
import { buildError, type FormError } from '../utils/error.js'
import { parseNumber } from '../utils/number.js'

export type Value = ItemValue | ItemValue[]
export type Options = {
  label?: FormHelpable
  choices: Choice[]
  min?: number
  max?: number
  display?: 'horizontal' | 'vertical'
  cards?: boolean
  submitOnChange?: boolean
}
export type Element = GenericElement<Options, Value>

export default {
  options,
  requirable,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

export function options(input: Dict, locale: string): Options {
  const choices = input.choices ? normalizeChoices(input.choices, locale, input) : []

  const min = input.min == null ? undefined : parseNumber(input.min, 0, choices.length)
  const max = input.max == null ? undefined : parseNumber(input.max, min || 0, choices.length)

  return {
    label: ifFormHelpable(input.label),
    choices,
    min,
    max,
    display: input.display,
    cards: input.cards,
    submitOnChange: input.submit_on_change === true,
  }
}

export function requirable({ choices }: Options): boolean {
  return choices.length > 0
}

export function parse(options: Options, locale: string, input: unknown | Value): null | Value {
  const max = options.max

  if (max !== 1) {
    if (!input || !isArray(input) || input.length === 0) return null
    if (!options.choices.length) return null

    if (allInChoices(input, options.choices)) return input

    const parsed = options.choices
      .filter((choice) => input.includes(choice.value))
      .map((choice) => choice.value)

    if (parsed.length === 0) return null

    return parsed
  } else {
    return input as ItemValue
  }
}

export function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (value === null) {
    if (required) return buildError('requiredChoices', locale)
    return null
  }

  const min = options.min
  const max = options.max

  if (Array.isArray(value)) {
    if (min != null && value?.length < min) {
      return buildError('minCount', locale, [min])
    }
    if (max != null && value?.length > max) {
      return buildError('maxCount', locale, [max])
    }
  }

  return null
}

export function normalize(element: Element): undefined | Value {
  const { value } = element
  if (value === undefined || value === null) return undefined

  if (Array.isArray(value)) {
    const normalizedValue = element.options.choices
      .filter((choice) => value.includes(choice.value))
      .map((c) => c.value)
    return normalizedValue.length ? normalizedValue : undefined
  } else {
    return (
      element.options.choices.filter((choice) => choice.value === value).map((c) => c.value)[0] ??
      undefined
    )
  }
}

export function stringify(element: Element): undefined | string {
  const { value } = element
  if (!value) return undefined

  if (Array.isArray(value)) {
    return element.options.choices
      .filter((choice) => value.includes(choice.value))
      .map(stringifyChoice)
      .join(', ')
  } else {
    return (
      element.options.choices.filter((choice) => choice.value === value).map(stringifyChoice)[0] ||
      undefined
    )
  }
}

function allInChoices(value: any[], choices: Choice[]): boolean {
  // Optimization: no need to check
  if (!choices.length) return false

  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      // TODO: Check unique ?
      if (!findChoice(value[i], choices)) return false
    }
    return true
  }
  return true
}

export function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.options.label || element.title)
}
