import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { StringTransformer } from './'
import { findLast, findQuotes } from './utils'
import { isDate, isNumber, toDateOrValue } from '../util'

/**
 * Handle the "+" operator.
 *
 * If numbers: sum them
 * If strings: concatenate them
 * If date and number: add the number of days to the date
 *
 * @usage: ```expr + expr2```.
 */
class PlusStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const quotes = findQuotes(expr)

    const operator = findLast(expr, '+', quotes)

    return operator !== -1
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    const quotes = findQuotes(expr)
    const operator = findLast(expr, '+', quotes)

    const left = expr.slice(0, operator).trim()
    const right = expr.slice(operator + 1).trim()

    const evaledLeft = toDateOrValue(this.evalStringExpression(left))
    const evaledRight = toDateOrValue(this.evalStringExpression(right))

    // add 1 day to a date
    if (isDate(evaledLeft) && isNumber(evaledRight)) {
      return this.evaluateMethodCall({ ':sum': [evaledLeft, `${evaledRight}d`] })
    }

    // handle the other way around
    if (isNumber(evaledLeft) && isDate(evaledRight)) {
      return this.evaluateMethodCall({ ':sum': [evaledRight, `${evaledLeft}d`] })
    }

    const sum = this.evaluateMethodCall({ ':sum': [evaledLeft, evaledRight] })

    if (sum !== undefined) {
      return sum
    }

    return this.evaluateMethodCall({ ':join': [evaledLeft, evaledRight], ':separator': '' })
  }
}

export default new PlusStringTransformer()
