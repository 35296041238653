import { en as vuetifyStrings } from '@penbox-io/vuetify-rules'
import merge from 'deepmerge'

export default merge(vuetifyStrings, {
  title: 'Welcome',
  errors: {
    'aborted': 'Action aborted',
    'http-401': 'You must be authenticated to perform this action',
    'http-403': 'You are not allowed to access this resource',
    'http-404': 'The resource could not be found',
    'http-409': 'Another version of the same resource was updloaded',
    'http-413': 'The uploaded file is too large',
    'http-422': 'Unknown file type',
    'http-429': 'You have reached the limit of attempts. Please try again later.',
    'http-4xx': 'The data provided is invalid',
    'http-502': 'Invalid response from remote server',
    'http-503': 'Service unavailable',
    'http-5xx': 'Internal server error',
    'http-error': 'HTTP error',
    'http-network': 'Network error',
    'request-access-rate-limit':
      'This access has been temporarily deactivated following too many unsuccessful attempts. Please try again later.',
    'request-access-subject-invalid': 'The information provided does not match the one we have',
    'staticAttributes': 'These answers cannot be modified',
    'undefined': 'Unknown error',
    'browser-deprecated': 'A modern browser is required',
    'signature-not-finished': 'The signature is not ready yet.\nPlease try again in a few seconds.',
  },
})
