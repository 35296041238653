import { type Dict, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'

type Value = 0 | 1 | 2 | 3 | 4 | 5
type Options = null
type Element = GenericElement<Options, Value>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return null
}

function parse(options: Options, locale: string, input: unknown): null | Value {
  if (input == null) return null
  if (typeof input !== 'number') return null
  if (input !== (input | 0)) return null
  if (input < 0 || input > 5) return null

  return input as Value
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  if (value === null) {
    if (required) return buildError('requiredRating', locale)
    return null
  }

  return null
}

function normalize(element: Element): undefined | Value {
  return element.value ?? undefined
}

function stringify(element: Element): undefined | string {
  const value = normalize(element)
  if (value == null) return undefined

  return Array.from(Array(5), stringifyArrayPosition, value).join('')
}

function stringifyArrayPosition(this: Value, _: any, i: number) {
  return i < this ? '★' : '☆'
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.title)
}
