import { Config } from './config'
import { JSON_SCHEMA_V1 } from './constants'
import { Evaluator } from './evaluation'
import { compileDefine } from './methods/define'
import { SubScope, subScopeDefine } from './scope'
import { findPath } from './string'
import { Define, JsonValue, MethodCall, MethodSignature, Scope, Value } from './types'
import {
  extractDefinedKeys,
  getDeep,
  isArray,
  isDate,
  isIterable,
  isJsonifiable,
  isMethodCall,
  isMethodCallObject,
  isPlainObject,
  isStatic,
  isString,
  jsonify,
  toString,
} from './util'

export interface DynamicCompiledExpression<R = Value> {
  (scope: Scope): R
  static?: false
}

export interface StaticCompiledExpression<R = Value> {
  (scope?: Scope): R
  static: true
  staticValue: R
}

export interface IterableCompiledExpression<R = Value> extends DynamicCompiledExpression<R[]> {
  iterator: (scope: Scope) => Generator<Value, void, void>
}

export type InferedCompilationResult<I> = I extends any[]
  ? IterableCompiledExpression<Value>
  : undefined | null | boolean | number extends I
  ? CompiledExpression<I>
  : CompiledExpression<Value>

export type CompiledExpression<R = Value> =
  | DynamicCompiledExpression<R>
  | StaticCompiledExpression<R>
  | (R extends Array<infer I> ? IterableCompiledExpression<I> : never)

export type CompilationResult<R = Value> = undefined | CompiledExpression<R>

declare module './config' {
  interface MethodDefinition<T> {
    compile?: (this: CompilationContext, expr: MethodSignature<T>) => CompilationResult
  }
}

export class CompilationError extends TypeError {
  readonly type = 'CompilationError'

  constructor(
    message: string,
    public readonly config: Config,
    public readonly path: ReadonlyArray<string | number>,
    public readonly expr: any
  ) {
    super(message)
  }

  static fromCompilationContext(cc: CompilationContext, expr: any) {
    const message = this.buildMessage(cc, expr)
    return new this(message, cc.config, cc.path, expr)
  }

  static buildMessage(cc: CompilationContext, expr: any) {
    if (isMethodCall(expr)) {
      const method = cc.config.findMethod(expr)
      return method
        ? `Invalid use of method: ${method.name}`
        : `Unknown method: "${extractDefinedKeys(expr).join('" "')}"`
    }

    if (isString(expr)) {
      return `Invalid string expression: ${JSON.stringify(expr)}`
    }

    if (typeof expr === 'number') {
      return `Invalid number expression: ${expr} (only finite numbers are supported)`
    }

    return `Invalid expression: ${JSON.stringify(expr)}`
  }
}

const asStaticExpression = <R extends Value>(getter: () => R): StaticCompiledExpression<R> =>
  Object.defineProperties(getter as unknown, {
    static: { value: true },
    staticValue: { get: getter },
  }) as StaticCompiledExpression<R>

const compileStatic = <R extends Value>(expr: R): CompiledExpression<R> => {
  if (isDate(expr)) {
    const timestamp = expr.valueOf()
    return asStaticExpression(() => new Date(timestamp) as R)
  }

  // TODO: deep copy all cases (not only date)
  return asStaticExpression(() => expr)
}

export interface ContextDetails {
  path: ReadonlyArray<string | number>
}

export class Compiler {
  constructor(public readonly config: Config) {}

  // Lazy instantiation
  get defaultContext() {
    const value = new CompilationContext(this.config)

    Object.defineProperty(this, 'defaultContext', {
      value,
      writable: false,
      configurable: true,
      enumerable: true,
    })

    return value
  }

  compile<I>(expr: I, details?: ContextDetails): InferedCompilationResult<I> {
    const cc = details?.path?.length
      ? new CompilationContext(this.config, details)
      : this.defaultContext

    return cc.compile(expr)
  }

  compileDefine<T extends Scope = Scope>(
    defineExpr: Define<T> | JsonValue,
    details?: ContextDetails
  ) {
    const cc = details?.path?.length
      ? new CompilationContext(this.config, details)
      : this.defaultContext

    const varsGetters = compileDefine.call(cc, defineExpr)
    if (!varsGetters) throw CompilationError.fromCompilationContext(cc, defineExpr)

    return <S extends Scope>(parent: S): SubScope<S, T> => {
      return subScopeDefine(parent, varsGetters) as SubScope<S, T>
    }
  }
}

export class CompilationContext {
  public readonly config: Config
  private readonly parent?: CompilationContext

  constructor(
    parent: Config | CompilationContext,
    public readonly details: ContextDetails = { path: [] }
  ) {
    if (parent instanceof CompilationContext) {
      this.config = parent.config
      this.parent = parent
    } else {
      this.config = parent
      this.parent = undefined
    }
  }

  get path(): ReadonlyArray<string | number> {
    const { path } = this.details
    return this.parent?.path.concat(path) ?? path
  }

  // Lazy instantiation
  get evaluator() {
    // TODO: Un comment next line once:
    // - "compileString" does not rely on "evaluator" anymore
    // - methods can be defined using ":define"

    // if (this.config.strict) throw new Error('use of :eval is not allowed in strict mode')

    const value = new Evaluator(this.config)

    Object.defineProperty(this, 'evaluator', {
      value,
      writable: false,
      configurable: true,
      enumerable: true,
    })

    return value
  }

  /**
   * @deprecated Prefer using {@link createContext}(...path).{@link compile}(value).
   */
  compileProperty<I, KK extends ReadonlyArray<string | number>>(expr: I, ...path: KK) {
    const child = this.createContext(...path)
    return child.compile(getDeep(expr, ...path))
  }

  createContext(...path: ReadonlyArray<string | number>) {
    if (!path.length) return this
    return new CompilationContext(this, { path })
  }

  // Some compilation results type can be inferred from the input type
  compile<I>(expr: I): InferedCompilationResult<I>

  compile(expr: unknown): CompiledExpression {
    switch (typeof expr) {
      case 'undefined':
        return () => undefined
      case 'number':
        if (!Number.isFinite(expr)) {
          if (this.config.strict) break
          return compileStatic(undefined)
        }
        return compileStatic(expr)

      case 'boolean':
        return compileStatic(expr)

      case 'string': {
        if (isStatic(expr)) return compileStatic(expr)
        const result = this.compileString(expr)
        if (typeof result === 'undefined') break
        return result
      }

      case 'object': {
        if (expr === null) return () => null
        if (isDate(expr)) return compileStatic(expr)
        if (isJsonifiable(expr)) return this.compile(expr.toJSON() as JsonValue)

        // Optimization
        if (isStatic(expr)) return compileStatic(expr as JsonValue)

        const result = isArray(expr) ? this.compileArray(expr) : this.compileObject(expr)

        if (typeof result === 'undefined') break

        return result
      }

      // case 'bigint': // Not supported in JSON
      // case 'undefined':
      // case 'function':
      // case 'symbol':
      default:
        break
    }

    if (this.config.ignoreCompileErrors) {
      return asStaticExpression(() => undefined)
    }

    throw CompilationError.fromCompilationContext(this, expr)
  }

  public asRuntimeEval(expr: MethodCall | JsonValue) {
    const { evaluator } = this

    // Optimization
    if (typeof expr === 'string') {
      return (scope: Scope) => evaluator.evaluateString(expr, scope)
    }

    // Optimization
    if (isPlainObject(expr)) {
      if (isMethodCallObject(expr)) {
        return (scope: Scope) => evaluator.evaluateMethodCall(expr, scope)
      } else {
        return (scope: Scope) => evaluator.evaluateHash(expr, scope)
      }
    }

    return (scope: Scope) => evaluator.evaluate(expr, scope)
  }

  private compileString(expr: string): CompilationResult {
    if (/^{\s*\$\s*}$/.test(expr)) {
      return (scope: Scope) => jsonify(scope)
    }

    const matches = expr.match(/^\{\s*(?:\$\.)?([a-z_$]\w+(?:\.\w+)*)\s*\}$/)
    if (matches) {
      const parts = matches[1].split('.')

      if (parts.length > 1 || !['$today', '$yesterday', '$tomorrow'].includes(parts[0])) {
        return (scope: Scope) => {
          const value = findPath.call(scope, parts)
          return jsonify(value)
        }
      }
    }

    // TODO: Actually build
    return this.asRuntimeEval(expr)
  }

  private compileArray(expr: JsonValue[]): IterableCompiledExpression<Value> {
    const { length } = expr

    const compiledItems: CompiledExpression[] = Array(length)
    for (let i = 0; i < length; i++) {
      compiledItems[i] = this.createContext(i).compile(expr[i])
    }

    const getter = (scope: Scope) => {
      const result: Value[] = Array(length)
      for (let i = 0; i < length; i++) {
        result[i] = compiledItems[i](scope) ?? null
      }
      return result
    }

    function* iterator(scope: Scope): Generator<Value, void, unknown> {
      for (let i = 0; i < length; i++) {
        yield compiledItems[i](scope) ?? null
      }
    }

    getter.iterator = iterator

    return getter
  }

  private compileObject(expr: any): CompilationResult {
    if (!isPlainObject(expr)) return undefined
    if (isMethodCallObject(expr)) return this.compileMethodCall(expr)

    return this.compileHash(expr)
  }

  private compileMethodCall(expr: MethodCall): CompilationResult {
    const method = this.config.findMethod(expr)
    if (!method) return undefined

    if (method.compile != null) return method.compile.call(this, expr)

    // Fall back to evaluation if compilation is not available
    return this.asRuntimeEval(expr)
  }

  private compileHash(expr: { [key: string]: JsonValue }): CompilationResult {
    const entries: Array<[CompiledExpression, CompiledExpression]> = []

    for (const key in expr) {
      if (key === '$schema' && expr[key] === JSON_SCHEMA_V1) continue

      const keyGetter = this.compile(key)
      if (!keyGetter) {
        if (this.config.strict) return undefined
        else continue
      }

      const valueGetter = this.createContext(key).compile(expr[key])
      if (!valueGetter) {
        if (this.config.strict) return undefined
        else continue
      }

      entries.push([keyGetter, valueGetter])
    }

    return (scope: Scope): Value => {
      const result: { [key: string]: Value } = {}
      for (const [keyGetter, valueGetter] of entries) {
        const key = toString(keyGetter(scope))
        if (key === undefined) continue

        const value = valueGetter(scope)
        if (value === undefined) continue

        result[key] = value
      }
      return result
    }
  }
}

export const asIterableExpression = <
  T extends CompiledExpression | IterableCompiledExpression<Value>
>(
  compiledExpr: T
): ((scope: Scope) => undefined | Iterable<Value>) => {
  const iterableGetter = 'iterator' in compiledExpr ? compiledExpr.iterator : compiledExpr

  return (scope: Scope) => {
    const iterable = iterableGetter(scope)
    if (!isIterable(iterable)) return undefined
    return iterable
  }
}
