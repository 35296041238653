import { CompiledExpression } from '../compilation'
import { subScopeDefine } from '../scope'
import { Define, JsonValue, MethodSignature, Scope, Value } from '../types'
import { createMethod, isArray } from '../util'
import { compileDefine, evalDefine } from './define'

type Type = { ':with': JsonValue[] }

/**
 * LEGACY: This method is deprecated. Use `:define` instead.
 * Defines a series of variables and evaluates an expression.
 * @usage: ```{":with": [define1, define2, ..., inExpr]}```
 * where:
 * - `define1`, `define2`, ... are define expressions
 * - `inExpr` is the expression to evaluate
 *
 * @example
 * - ```{":with": [{"a": 1}, {"b": 2}, "{a + b}"]}``` returns `3`
 *
 */
export default createMethod<Type>({
  name: ':with',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':with') continue
      return false // Error: Unknown key
    }

    if (!isArray(expr[':with'])) return false
    if (expr[':with'].length === 0) return false

    return true
  },

  evaluate(expr) {
    const { ':with': withExpr } = expr

    if (withExpr.length === 0) return undefined

    // Optimization
    if (withExpr.length <= 1) return this.evaluate(withExpr[0])

    // Not using .pop() because we can't alter "expr"
    const inExpr = withExpr[withExpr.length - 1]
    const defineExpr = withExpr.slice(0, withExpr.length - 1) as Define

    return evalDefine.call(this, defineExpr, inExpr)
  },

  compile(expr) {
    const lastItemIdx = expr[':with'].length - 1
    if (lastItemIdx < 0) return undefined

    const inCompiled = this.createContext(':with', lastItemIdx).compile(expr[':with'][lastItemIdx])

    const varsGetters: Array<CompiledExpression<Record<string, Value>>> = []
    for (let i = 0; i < lastItemIdx; i++) {
      const context = this.createContext(':with', i)
      const varsGetter = compileDefine.call(context, expr[':with'][i])
      if (!varsGetter) return undefined

      varsGetters.push(...varsGetter)
    }

    // Optimization
    if (inCompiled.static) return inCompiled

    // Optimization
    if (varsGetters.length === 0) return inCompiled

    return (parent: Scope): Value => {
      const childScope = subScopeDefine(parent, varsGetters)
      return inCompiled(childScope)
    }
  },
})
