import { JsonValue, MethodSignature, Scope } from '../types'
import { createMethod, isDate } from '../util'
import { parseDate } from './date'

type Type = { ':weekday': JsonValue }

/**
 * Parses a date string using a pattern and returns the day of the week
 * @usage: ```{ ':weekday': string }```
 * where:
 * - :date: string: the date string to parse or now for the current date
 *
 * @example
 * ```{ ':date': '2024-07-10' }``` => 3
 */
export default createMethod<Type>({
  name: ':weekday',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':weekday') continue
      return false
    }
    return true
  },

  evaluate(expr) {
    const arg = this.evaluate(expr[':weekday'])
    const date = parseDate(arg)
    return getISODay(date)
  },

  compile(expr) {
    const dateGetter = this.createContext(':date').compile(expr[':weekday'])

    return (scope: Scope) => {
      const date = parseDate(dateGetter(scope))
      return getISODay(date)
    }
  },
})

function getISODay(date: any) {
  if (!isDate(date)) return undefined
  const day = date.getDay()
  return day === 0 ? 7 : day // ISO day of the week. Sunday is 7
}
