import { MethodCall, MethodSignature } from '../types'
import { createMethod, isNumber, isPureExpression, isString, toNumber, toString } from '../util'

type Type = {
  ':number': string | MethodCall
}

/**
 * Converts a value to a number.
 *
 * Limitations: do not support locale-specific formatting. For example numbers with commas will be parsed as NaN.
 *
 * @usage: ```{":number": value}```
 * where:
 * - `value` is a string, number or a json expression that will be evaluated to a number
 *
 * @example
 * - ```{":number": "123"}``` returns `123`
 * - ```{":number": "a"}``` returns `NaN`
 */
export default createMethod<Type>({
  name: ':number',

  test(expr): expr is MethodSignature<Type> {
    for (const key in expr) {
      if (key === '$schema') continue
      if (key === ':number') continue
      return false
    }

    const input = expr[':number']
    if (!isPureExpression(input) && !isString(input) && !isNumber(input)) return false

    return true
  },

  evaluate(expr) {
    return toNumber(this.evaluate(expr[':number']))
  },
})
