import { createMethodLegacyMultiple } from '../legacy'
import { Value } from '../types'
import { isArray } from '../util'

/**
 * Check if all elements in the array are distinct.
 *
 * @usage: ```{ ':distinct': array }```
 * where:
 * - array: array: the array to check if all elements are distinct
 *
 * @example
 * ```{ ':distinct': [1, 2, 3] }``` => true
 * ```{ ':distinct': [1, 2, 1] }``` => false
 */
export default createMethodLegacyMultiple(
  ':distinct',
  [],
  function ({ ':distinct': distinctExpr }): undefined | boolean {
    // Optimization: evaluate only required elements
    if (isArray(distinctExpr)) {
      const visited: Value[] = []

      for (let i = 0; i < distinctExpr.length; i++) {
        const current = this.evaluate(distinctExpr[i])
        if (visited.includes(current)) return false
        visited.push(current)
      }

      return true
    }

    const result = this.evaluate(distinctExpr)

    if (!isArray(result)) return undefined

    return result.every(isLastItem)
  }
)

const isLastItem = (v: Value, i: number, a: Value[]) => i === a.length - 1 || !a.includes(v, i + 1)
