import { asArray, humanFileSize, ifString, isArray, renameBasename } from '@penbox-io/stdlib'

import {
  type Dict,
  type FormHelpable,
  ifFormHelpable,
  stringifyHelpable,
  isDict,
} from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { type FormError, buildError } from '../utils/error.js'

import { parseNumber } from '../utils/number.js'
import { getExt } from '../utils/file.js'

type FileValue = {
  name: string
  size?: number
  type?: string
} & {
  // Note, files will typically contain other implementation specific fields.
  // For example, in the browsers, a file migh be an instance of File.
  [key: string]: unknown
}

type Value<O extends Options = Options> = O extends { multiple: true }
  ? FileValue[]
  : O extends { multiple: false }
  ? FileValue
  : never

type Options = {
  label?: FormHelpable
  customName?: string
  minSize: number
  maxSize?: number
  hint?: string
  accept?: string
  extract?: any
} & (
  | {
      multiple: false
    }
  | {
      multiple: true
      min: number
      max?: number
    }
)
type Element<O extends Options = Options> = GenericElement<O, Value<O>>

export default {
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

/**
 * @deprecated use value.name instead. This function can be removed after sept 2023.
 * When removing this function, also remove the renaming in stringifyFileInternal.
 */
export function stringifyFile(element: Element, file: FileValue): string {
  return stringifyFileInternal.call(element, file)
}

function options(input: Dict, locale: string): Options {
  const multiple = input.multiple === true

  const min = !multiple || input.min == null ? 0 : parseNumber(input.min, 0) || 0
  const max = !multiple || input.max == null ? undefined : parseNumber(input.max, min)

  const minSize = input.minSize == null ? 0 : parseNumber(input.minSize, 0) || 0
  const maxSize = input.maxSize == null ? undefined : parseNumber(input.maxSize, minSize)

  return {
    label: ifFormHelpable(input.label),
    hint: ifString(input.hint),
    accept: ifString(input.accept)?.trim(),
    customName: ifString(input.custom_name),
    minSize,
    maxSize,
    multiple,
    min,
    max,
    extract: input.extract,
  }
}

function parse<O extends Options>(options: O, locale: string, input: unknown): Value<O> | null
function parse(options: Options, locale: string, input: unknown): Value | null {
  if (!input) return null

  if (options.multiple) {
    const files = asArray(input).filter(isFile)
    return files.length ? files : null
  } else {
    if (isFile(input)) return input
    if (isArray(input)) return input.find(isFile) || null
    return null
  }
}

function isFile(value: unknown): value is FileValue {
  if (!isDict(value)) {
    return false
  }

  if (typeof value.name !== 'string') {
    return false
  }

  if (value.type !== undefined) {
    if (typeof value.type !== 'string') return false
  }

  // size must be a Natural number

  if (value.size !== undefined) {
    if (typeof value.size !== 'number') return false
    if (value.size !== (value.size | 0) || !(value.size >= 0)) {
      return false
    }
  }

  return true
}

function validate<O extends Options = Options>(
  options: O,
  locale: string,
  value: null | Value<O>,
  required: boolean
): null | FormError {
  if (value === null) {
    if (required) {
      return buildError(options.multiple ? 'requiredFileMultiple' : 'requiredFile', locale)
    }
    return null
  }

  if (options.multiple !== Array.isArray(value)) {
    throw new Error('Invalid value for file element')
  }

  if (options.multiple) {
    const files = value as FileValue[]

    for (let i = 0; i < files.length; i++) {
      const error = validateFile(files[i], options, locale)
      if (error) return error
    }

    const min = options.min
    if (min != null && files.length < min) {
      return buildError('minCount', locale, [min])
    }

    const max = options.max
    if (max != null && files.length > max) {
      return buildError('maxCount', locale, [max])
    }

    return null
  } else {
    return validateFile(value as FileValue, options, locale)
  }
}

function validateFile(value: FileValue, options: Options, locale: string): null | FormError {
  if (value.size != null) {
    const { maxSize } = options
    if (maxSize != null && maxSize > 0) {
      if (value.size > maxSize) {
        return buildError('maxFileSize', locale, [humanFileSize(maxSize)])
      }
    }

    const { minSize } = options
    if (minSize != null && minSize > 0) {
      if (value.size < minSize) {
        return buildError('minFileSize', locale, [humanFileSize(minSize)])
      }
    }
  }

  if (value.type != null) {
    const { accept } = options
    if (accept != null && !checkAccept(accept, value)) {
      return buildError('invalidFileType', locale)
    }
  }

  return null
}

function normalize<O extends Options>(element: Element<O>): undefined | Value<O>
function normalize(element: Element): undefined | FileValue | FileValue[] {
  return element.value ?? undefined
}

function stringify(element: Element): undefined | string {
  const value = normalize(element)
  if (value === undefined) return undefined

  if (element.options.multiple) {
    return (value as FileValue[]).map(stringifyFileInternal, element).join(', ')
  } else {
    return stringifyFileInternal.call(element, value as FileValue)
  }
}

export function stringifyFileInternal(this: Element, value: FileValue): string {
  // Legacy: file name should be adapted upload upload and, hence, should not
  // need renaming here. However, we keep this for backward compatibility as
  // this was not always the case. This can be removed after sept 2023.
  const { customName } = this.options
  if (customName) return renameBasename(value.name, customName)

  return value.name
}

function stringifyTitle(element: Element): undefined | string {
  return (
    stringifyHelpable(element.options.label || element.title) ||
    element.options.customName ||
    undefined
  )
}

const checkAccept = (accept: string, file: FileValue) => {
  if (!accept) return true

  let prev = 0
  do {
    const next = accept.indexOf(',', prev)
    const acceptPart = accept.slice(prev, next < 0 ? undefined : next).trim()

    if (acceptPart.startsWith('.')) {
      if (acceptPart === getExt(file.name)) return true
    } else if (acceptPart.includes('/')) {
      // TODO (?): is there a better way to check the mime type ?
      if (file.type != null && checkAcceptType(acceptPart, file.type)) return true
    } else {
      // Invalid values are ignored
    }

    prev = next + 1
  } while (prev !== 0)

  return false
}

function createChecker(accept: string) {
  const regexp = new RegExp(
    `^(${accept.replace(/[-/\\^$+?.()|[\]{}]/g, '\\$&').replace('*', '.+')})$`
  )
  return (type: string) => regexp.test(type)
}

function cachedTypeCheckers(maxCacheEntries = 100) {
  // (very) basic LRU cache implementation
  const cache: Record<string, undefined | ((type: string) => boolean)> = Object.create(null)
  return (accept: string, type: string) => {
    const keys = Object.keys(cache)
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    if (keys.length > maxCacheEntries) delete cache[keys[0]!]

    const checker = cache[accept] || (cache[accept] = createChecker(accept))
    return checker(type)
  }
}

// Static cache for the least recently used accept type checkers
const checkAcceptType = cachedTypeCheckers()
