// @hack: enforce import order (because nuxt unshifts plugins...)
import './event-target-polyfill.client.js'

if (!window.AbortController) {
  const SECRET = {}

  class AbortSignalPolyfill extends EventTarget {
    constructor(secret) {
      if (secret !== SECRET) throw new TypeError('Illegal constructor.')

      super()

      this._reason = undefined
      this._onabort = undefined
    }

    get aborted() {
      return this._reason !== undefined
    }

    get reason() {
      return this._reason
    }

    get onabort() {
      return this._onabort
    }

    set onabort(callback) {
      if (this._onabort) this.removeEventListener('abort', this._onabort)
      if (callback) this.addEventListener('abort', callback)
      this._onabort = callback
    }

    throwIfAborted() {
      if (this.aborted) throw this._reason
    }

    toString() {
      return '[object AbortSignal]'
    }
  }

  class AbortControllerPolyfill {
    // Some polyfills put this on the AC class, not global
    static AbortSignal = AbortSignalPolyfill

    constructor() {
      this._setAborted = undefined
      this._signal = new AbortSignalPolyfill(SECRET)
    }

    get signal() {
      return this._signal
    }

    abort(reason = new DOMException('signal is aborted without reason', 'AbortError')) {
      if (this._signal.aborted) return

      let event
      try {
        event = new Event('abort')
      } catch (e) {
        if (typeof document === 'undefined') {
          event = {
            type: 'abort',
            bubbles: false,
            cancelable: false,
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (!document.createEvent) {
            // For Internet Explorer 8:
            event = /** @type {any} */ (document).createEventObject()
            event.type = 'abort'
          } else {
            // For Internet Explorer 11:
            event = document.createEvent('Event')
            event.initEvent('abort', false, false)
          }
        }
      }

      this._signal._reason = reason

      this.signal.dispatchEvent(event)
    }

    toString() {
      return '[object AbortController]'
    }
  }

  window.AbortSignal = AbortSignalPolyfill
  window.AbortController = AbortControllerPolyfill
}
