import { ifString } from '@penbox-io/stdlib'
import { Dict, isDict } from './dict'

export type Helpable = {
  text: string
  help?: null | string
}

export type FormHelpable = Helpable | string

export function isHelpableDict(input: Dict): input is Helpable {
  if (!input.text || typeof input.text !== 'string') return false
  if (input.help != null && typeof input.help !== 'string') return false

  return true
}

export function isFormHelpable(input: unknown): input is FormHelpable {
  if (!input) return false

  switch (typeof input) {
    case 'string':
      return true
    case 'object':
      return isHelpableDict(input)
    default:
      return false
  }
}

export function ifFormHelpable(input: unknown): undefined | FormHelpable {
  if (!input) return undefined

  switch (typeof input) {
    case 'string':
      return input
    case 'object':
      return isHelpableDict(input) ? input : undefined
    default:
      return undefined
  }
}

export function isHelpable(input: unknown): input is Helpable {
  return isDict(input) && isHelpableDict(input)
}

export function ifHelpable(input: unknown): undefined | Helpable {
  return isHelpable(input) ? input : undefined
}

export function stringifyHelpable(input: unknown): string | undefined {
  if (!input) return undefined

  switch (typeof input) {
    case 'string':
      return input
    case 'object':
      return ifString((input as Dict).text) || undefined
    default:
      return undefined
  }
}
