import { localStorage } from '@penbox-io/browser-storage'
import { safeWrap, parseFilename, renameBasename } from '@penbox-io/stdlib'

export const byId = (id) => (s) => s.id === id
export const byType = (type) => (s) => s.type === type

const memorizeKey = ({ id }) => `memorized:${id}`

// safeWrap because localStorage not always available
export const getMemorized = safeWrap((entity) =>
  JSON.parse(localStorage.getItem(memorizeKey(entity)))
)

export const setMemorized = safeWrap((entity, values) => {
  localStorage.setItem(
    memorizeKey(entity),
    JSON.stringify(Object.assign({}, getMemorized(entity), values))
  )
})

/** @param {{ type?: string; name?: string }} file */
const isImage = (file) => {
  if (file.type) return file.type.toLowerCase().startsWith('image/')
  // type not set (this can sometimes happen, e.g. while drag and drop)
  else if (file.name && file.name.includes('.')) {
    switch (file.name.split('.').pop().toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'heic':
      case 'tiff':
      case 'bmp':
        return true
      default:
        return false
    }
  }

  return undefined
}

/**
 * @param {File} file
 * @returns {Promise<{ name: string; blob: Blob }>}
 */
export const compressFile = async (file, element) => {
  if (!(file instanceof File)) throw new Error('Unable to decode file')

  if (file.size > 1024 * 1024 && isImage(file)) {
    try {
      // Lazy load 'browser-imgmanip'
      const { resize } = await import('@penbox-io/browser-imgmanip')

      const type = 'image/jpeg'
      const blob = await resize(file, {
        type,
        quality: 0.95,
        maxWidth: 1500,
        maxHeight: 1500,
      })

      if (!(blob instanceof Blob)) throw new Error('Unable to resize image')

      if (blob.size < 20 * 1024) {
        // Heuristic allowing to detect "black" images
        throw new Error('Compression ratio suspiciously low, dropping resized image.')
      }

      return {
        name: `${parseFilename(element?.options?.customName || file.name)[1] || 'image'}.jpg`,
        blob,
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Unable to resize image', err)
    }
  }

  return {
    name: renameBasename(file.name, element?.options?.customName),
    blob: file,
  }
}

export const getValue = (attrs, key) => {
  const [attr, prop] = key.split('.', 2)
  return attrs[attr]?.[prop]
}

/**
 * When an axios request made with 'responseType: blob' fails, do parse the
 * err.response.data as JSON if the content-type is application/json.
 *
 * @param {unknown} err
 */
export async function parseAxiosErrorBlobResponse(err) {
  if (!(err?.response?.data instanceof Blob)) throw err
  if (!/^application\/(?:.+\+)?json$/.test(err.response.headers['content-type'])) throw err

  err.response.data = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      try {
        resolve(JSON.parse(reader.result))
      } catch (err) {
        reject(err)
      }
    }
    reader.onerror = () => reject(reader.error)
    reader.readAsText(err.response.data)
  })

  throw err
}

export function storeAuthInSession(requestId, requestAccessId, token) {
  if (sessionStorage) {
    sessionStorage.setItem(`auth_${requestId}`, JSON.stringify({ token, requestAccessId }))
  }
}

export function getAuthFromSession(requestId) {
  if (sessionStorage) {
    const value = sessionStorage.getItem(`auth_${requestId}`)

    if (value) {
      return JSON.parse(value)
    }
  }

  return undefined
}

export function clearAuthInSession(requestId) {
  if (sessionStorage) {
    sessionStorage.removeItem(`auth_${requestId}`)
  }
}
