import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { toDateOrValue } from '../util'
import { StringTransformer } from './'
import { findNext, findQuotes } from './utils'

const operatorsMap = new Map([
  ['<', ':lt'],
  ['>', ':gt'],
  ['<=', ':le'],
  ['>=', ':ge'],
])

/**
 * Evaluate greather or lower between two values.
 *
 * @usage: ```expr < expr2```. Available operators are `<`, `>`, `<=`, `>=`.
 */
class GreatherLowerCompareStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const quotes = findQuotes(expr)

    const comparison = findNext(expr, 0, ['>', '<'], quotes)

    return comparison !== -1
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    const quotes = findQuotes(expr)
    const comparison = findNext(expr, 0, ['>', '<'], quotes)

    const nextChar = expr.charAt(comparison + 1)

    // handle >= and <=
    const compareOperator = expr.charAt(comparison) + (nextChar === '=' ? '=' : '')

    const left = expr.slice(0, comparison).trim()
    const right = expr.slice(comparison + compareOperator.length).trim()

    const evaledLeft = toDateOrValue(this.evalStringExpression(left))
    const evaledRight = toDateOrValue(this.evalStringExpression(right))

    const operator = operatorsMap.get(compareOperator)
    if (!operator) {
      return
    }

    return this.evaluateMethodCall({ ':cmp': evaledLeft, [`${operator}`]: evaledRight })
  }
}

export default new GreatherLowerCompareStringTransformer()
