import { ifString } from '@penbox-io/stdlib'

import {
  type Dict,
  type FormError,
  type FormHelpable,
  ifFormHelpable,
  stringifyHelpable,
} from '../../common'
import { type Definition, type GenericElement } from '../../core'

type Value = unknown[]
type Options = {
  label?: FormHelpable
  content?: string
}
type Element = GenericElement<Options, Value>

export default {
  requirable: (options: Options) => false,
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    label: ifFormHelpable(input.label),
    content: ifString(input.content) || undefined,
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  return null // paragraph are not supposed to have a "value" (yet?)
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  return null
}

function normalize(element: Element): undefined | Value {
  return undefined
}

function stringify(element: Element): undefined | string {
  return undefined
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.title || element.options.label)
}
