<script>
import { parseUrl } from '@penbox-io/pnbx-url'
import { VImg } from 'vuetify/lib/components/VImg'

export default VImg.extend({
  computed: {
    normalisedSrc() {
      const src = VImg.options.computed.normalisedSrc.call(this)
      if (!src) return src
      for (const key in src) {
        if (typeof src[key] === 'string') {
          src[key] = parseUrl(src[key])
        }
      }
      return src
    },
  },
})
</script>
