import { ArrayItem } from './array'
import { Camelize, camelize } from './string'
import { Dict, isView } from './util'

export type Forbidden<T, U extends string | number | symbol> = Omit<T, U> & { [K in U]?: never }
export type Override<A, B> = Omit<A, keyof B> & B
export type Optional<A, K extends keyof A> = Omit<A, K> & { [V in K]?: A[V] }
export type Mandatory<A, K extends keyof A> = A extends null | undefined
  ? never
  : Omit<A, K> & {
      [V in K]-?: A[V] extends null | undefined ? never : A[V]
    }

const { hasOwnProperty, valueOf, toString } = Object.prototype

export function ifOwnProperty<O>(obj: O, key: keyof NonNullable<O>): O[keyof O] | undefined {
  if (typeof obj !== 'object' || obj === null) return undefined
  if (key === '__esModule') return undefined
  // @ts-expect-error
  if (hasOwnProperty.call(obj, key)) return (obj as NonNullable<O>)[key]
  return undefined
}

export function objectMap<
  A extends any[],
  F extends (this: any, value: ArrayItem<A>, key: number, obj: A) => any
>(arr: A, fn: F, thisArg?: ThisParameterType<F>): Array<ReturnType<F>>

export function objectMap<
  O extends Record<string, any>,
  F extends <K extends keyof O>(this: any, value: O[K], key: K, obj: O) => any
>(
  obj: O,
  fn: F,
  thisArg?: ThisParameterType<F>
): {
  [K in keyof O]: F extends (value: O[K], key: K, obj: O) => infer R ? R : ReturnType<F>
}

export function objectMap<
  O extends Record<string, any> | any[],
  F extends (this: any, value: any, key: any, obj: any) => any
>(obj: O, fn: F, thisArg?: ThisParameterType<F>) {
  if (Array.isArray(obj)) {
    return obj.map(fn, thisArg)
  } else {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, fn.call(thisArg, v, k, obj)]))
  }
}

export type CamelizeKeys<T> = {
  [K in keyof T as Camelize<string & K>]: T[K]
}

export const camelizeKeys = <T extends Record<string, any>>(obj: T): CamelizeKeys<T> =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [camelize(k), v])) as CamelizeKeys<T>

export function isObjectLike(sub: unknown): boolean {
  return typeof sub === 'object' && sub !== null
}

export function getTag(sub: unknown) {
  if (sub == null) {
    return sub === undefined ? '[object Undefined]' : '[object Null]'
  }
  return toString.call(sub)
}

// https://github.com/lodash/lodash/blob/master/isPlainObject.js
export const isPlainObject = (sub: any): sub is Dict<unknown> => {
  if (!isObjectLike(sub) || getTag(sub) !== '[object Object]') {
    return false
  }
  if (Object.getPrototypeOf(sub) === null) {
    return true
  }
  let proto = sub
  do {
    proto = Object.getPrototypeOf(proto)
  } while (Object.getPrototypeOf(proto) !== null)
  return Object.getPrototypeOf(sub) === proto
}

export const hasOwn = <T extends Record<PropertyKey, unknown>, K extends PropertyKey>(
  obj: T,
  key: K
): obj is T & Record<K, T[K]> => hasOwnProperty.call(obj, key)

export const pick = <T extends Dict<unknown>, K extends keyof T & string>(
  obj: T,
  keys: K[]
): Pick<T, K> => {
  const result = {} as Pick<T, K>
  for (const key of keys) if (hasOwn(obj, key)) result[key] = obj[key]
  return result as Pick<T, K>
}

/**
 * @deprecated use {@link pick} instead
 */
export const striped = pick

export const omit = <T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> => {
  const result: Partial<T> = {}
  for (const key in obj) if (!keys.includes(key as any)) result[key] = obj[key]
  return result as Omit<T, K>
}

export const toNullProtoObject = <T>(o: T): NonNullable<T> => Object.assign(Object.create(null), o)

const DEEP_EQUAL_DEFAULTS = {
  jsonify: false,
}

export type DeepEqualOptions = Partial<typeof DEEP_EQUAL_DEFAULTS>

/**
 * @see {@link https://github.com/epoberezkin/fast-deep-equal}
 */
export const deepEqual = (
  a: any,
  b: any,
  options: DeepEqualOptions = DEEP_EQUAL_DEFAULTS
): boolean => {
  if (options.jsonify) {
    if (a && typeof a.toJSON === 'function') a = a.toJSON()
    if (b && typeof b.toJSON === 'function') b = b.toJSON()
  }

  if (a === b) return true

  if (a == null || b == null || typeof a !== 'object' || typeof b !== 'object') {
    // Either a or b is not an object

    // eslint-disable-next-line no-self-compare
    if (a !== a && b !== b) return true // Both a and b are NaN

    // Optimization: No need to check for strict equality again (initial check before)
    // return a === b
    return false
  }

  // a and b are both distinct objects at this point

  if (Array.isArray(a)) {
    if (!Array.isArray(b)) return false

    const { length } = a
    if (length !== b.length) return false
    for (let i = length; i-- !== 0; ) if (!deepEqual(a[i], b[i], options)) return false
    return true
  }

  if (isView(a)) {
    if (!isView(b)) return false

    // Compare the bytes only
    const aView = new Int8Array(a.buffer)
    const bView = new Int8Array(b.buffer)

    const { length } = aView
    if (length !== bView.length) return false
    for (let i = length; i-- !== 0; ) if (aView[i] !== bView[i]) return false
    return true
  }

  // if (a instanceof Map) {
  //   if (!(b instanceof Map)) return false
  //   if (a.size !== b.size) return false
  //   for (const k of a.keys()) if (!b.has(k)) return false
  //   for (const [k, v] of a.entries()) if (!deepEqual(v, b.get(k))) return false
  //   return true
  // }

  // if (a instanceof Set) {
  //   if (!(b instanceof Set)) return false
  //   if (a.size !== b.size) return false
  //   for (const v of a.values()) if (!b.has(v)) return false
  //   return true
  // }

  if ((a.constructor ?? Object) !== (b.constructor ?? Object)) return false

  // a and b are either both plain/null-proto objects or both instances of the same class

  if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags
  if (a.constructor === Date) {
    const ta = a.getTime()
    const tb = b.getTime()
    return ta === tb || (Number.isNaN(ta) && Number.isNaN(tb))
  }
  if (a.constructor != null && b.constructor != null) {
    if (a.valueOf !== valueOf || b.valueOf !== valueOf) return a.valueOf() === b.valueOf()
    if (a.toString !== toString || b.toString !== toString) return a.toString() === b.toString()
  }

  const keys = Object.keys(a)
  const length = keys.length
  if (length !== Object.keys(b).length) return false

  for (let i = length; i-- !== 0; ) {
    if (!hasOwnProperty.call(b, keys[i])) return false
  }

  for (let i = length; i-- !== 0; ) {
    const key = keys[i]
    if (!deepEqual(a[key], b[key], options)) return false
  }

  return true
}

const DEEP_EXTENDS_DEFAULTS = {
  strict: false,
}

export type DeepExtendsOptions = Partial<typeof DEEP_EXTENDS_DEFAULTS>

export const deepExtends = function <R>(
  value: any,
  reference: R,
  options: DeepExtendsOptions = DEEP_EXTENDS_DEFAULTS
): value is R {
  if (Array.isArray(reference) && Array.isArray(value)) {
    if (value.length < reference.length) return false

    for (let i = reference.length; i >= 0; i--) {
      if (!deepExtends(value[i], reference[i], options)) return false
    }

    return true
  }

  if (isPlainObject(reference) && isPlainObject(value)) {
    for (const key of Object.keys(reference) as Array<Extract<keyof R, string>>) {
      // in "non-strict" mode, a missing property is considered to extend an undefined property
      if (options.strict && !(key in value)) return false
      if (!deepExtends(value[key], reference[key], options)) return false
    }

    return true
  }

  if (reference instanceof Date && value instanceof Date) {
    return reference.getTime() === value.getTime()
  }

  if (value === reference) {
    return true
  }

  // eslint-disable-next-line no-self-compare
  return value !== value && reference !== reference
}

const DEEP_MERGE_DEFAULTS = {
  nullish: false,
  jsonify: false,
  itemKey: undefined as undefined | ((item: any) => any),
}

export type DeepMergeOptions = Partial<typeof DEEP_MERGE_DEFAULTS>

/**
 * Recursively merge two values, returning the source if both values are
 * identical (in a deepEqual sense).
 */
export const deepMerge = (
  src: any,
  dst: any,
  options: DeepMergeOptions = DEEP_MERGE_DEFAULTS
): typeof dst => {
  if (src === dst) return src
  if (src == null && dst == null && options.nullish) return src

  if (dst == null || typeof dst !== 'object') return dst
  if (src == null || typeof src !== 'object') return dst

  const srcIsArray = Array.isArray(src)
  const dstIsArray = Array.isArray(dst)

  if (srcIsArray !== dstIsArray) return dst
  if (srcIsArray && dstIsArray) {
    let rv: typeof dst | typeof src = src
    const copy: typeof dst = []

    const { length } = dst
    if (src.length !== length) rv = copy

    let srcKeys

    const { itemKey } = options
    for (let i = 0; i < length; i++) {
      const dstItem = dst[i]
      const srcItem = src[i]

      // Allow objects & functions to move inside arrays
      if (itemKey) {
        try {
          if (isKeyable(dstItem)) {
            const dstKey = itemKey(dstItem)
            if (dstKey != null) {
              srcKeys ??= src.map(toKeyable, itemKey)
              if (srcKeys[i] !== dstKey) {
                const idx = srcKeys.indexOf(dstKey)
                if (idx !== -1) {
                  // A matching item was found at another index in src
                  const copyItem = deepMerge(src[idx], dstItem, options)
                  copy[i] = copyItem
                  if (copyItem !== srcItem) rv = copy
                  continue
                }
              }
            }
          }
        } catch (err) {
          // Do not let this optimization break the merge
          console.warn(`Error while finding keyed item:`, err)
        }
      }

      const copyItem = deepMerge(srcItem, dstItem, options)
      copy[i] = copyItem
      if (copyItem !== srcItem) rv = copy
    }

    return rv
  }

  const srcProto = Object.getPrototypeOf(src) || Object.prototype
  const dstProto = Object.getPrototypeOf(dst) || Object.prototype

  if (srcProto !== dstProto) return dst
  if (srcProto === Object.prototype && dstProto === Object.prototype) {
    let rv: typeof dst | typeof src = src
    const copy: typeof dst = {}

    for (const key in dst) {
      copy[key] = deepMerge(src[key], dst[key], options)

      // TODO: account for nullish here ?
      if (copy[key] !== src[key]) rv = copy
    }

    for (const key in src) {
      if (!hasOwnProperty.call(copy, key)) {
        copy[key] = src[key]
        rv = copy
      }
    }

    return rv
  }

  return deepEqual(src, dst, options) ? src : dst
}

const IMMUTABLE_UPDATE_DEFAULTS = {
  jsonify: false,
  nullish: false,
  itemKey: undefined as undefined | ((item: any) => any),
}

export type ImmutableUpdateOptions = Partial<typeof IMMUTABLE_UPDATE_DEFAULTS>

const isKeyable = (item: any): boolean => {
  switch (typeof item) {
    case 'function':
      return true
    case 'object': {
      if (item === null) return false
      const proto = Object.getPrototypeOf(item)
      return proto === Object.prototype || proto === null
    }
    default:
      return false
  }
}

function toKeyable(this: (item: any) => any, item: any): any {
  return isKeyable(item) ? this(item) : undefined
}

/**
 * Recursively compare two values, returning the source if both values are
 * identical (in a deepEqual sense), or a copy otherwise.
 */
export function immutableUpdate(
  src: any,
  dst: any,
  options: ImmutableUpdateOptions = IMMUTABLE_UPDATE_DEFAULTS
): typeof dst {
  if (src === dst) return src
  if (src == null && dst == null && options.nullish) return src

  if (dst == null || typeof dst !== 'object') return dst
  if (src == null || typeof src !== 'object') return dst

  const srcIsArray = Array.isArray(src)
  const dstIsArray = Array.isArray(dst)

  if (srcIsArray !== dstIsArray) return dst
  if (srcIsArray && dstIsArray) {
    let rv: any[] = src
    const copy: any[] = []

    const { length } = dst
    if (src.length !== length) rv = copy

    let srcKeys

    const { itemKey } = options
    for (let i = 0; i < length; i++) {
      const dstItem = dst[i]
      const srcItem = src[i]

      // Allow objects & functions to move inside arrays
      if (itemKey) {
        try {
          if (isKeyable(dstItem)) {
            const dstKey = itemKey(dstItem)
            if (dstKey != null) {
              srcKeys ??= src.map(toKeyable, itemKey)
              if (srcKeys[i] !== dstKey) {
                const idx = srcKeys.indexOf(dstKey)
                if (idx !== -1) {
                  // A matching dstItem was found at another index in src
                  const copyItem = immutableUpdate(src[idx], dstItem, options)
                  copy[i] = copyItem
                  if (copyItem !== srcItem) rv = copy
                  continue
                }
              }
            }
          }
        } catch (err) {
          // Do not let this optimization break the merge
          console.warn(`Error while finding keyed item:`, err)
        }
      }

      const copyItem = immutableUpdate(srcItem, dstItem, options)
      copy[i] = copyItem
      if (copyItem !== srcItem) rv = copy
    }

    return rv
  }

  const srcProto = Object.getPrototypeOf(src) || Object.prototype
  const dstProto = Object.getPrototypeOf(dst) || Object.prototype

  if (srcProto !== dstProto) return dst
  if (srcProto === Object.prototype && dstProto === Object.prototype) {
    let rv: typeof dst | typeof src = src
    const copy: typeof dst = {}

    for (const key in dst) {
      copy[key] = immutableUpdate(src[key], dst[key], options)

      if (copy[key] !== src[key]) rv = copy
    }

    if (rv === src) {
      // Search for keys in src that were not present in dst
      for (const key in src) {
        // no need to use hasOwn because the proto is either null or Object.prototype
        if (!(key in copy)) {
          rv = copy
          break // no need to keep serching for differences
        }
      }
    }

    return rv
  }

  return deepEqual(src, dst, options) ? src : dst
}

export function immutableSet<R>(
  src: R,
  key: string | string[],
  value: any
): R extends Dict<unknown> ? R : Dict<unknown>
export function immutableSet(src: unknown, key: string | string[], value: any): Dict<unknown> {
  const srcIsObject = isPlainObject(src)
  if (!key?.length) return srcIsObject ? src : {}

  const root: Dict<unknown> = srcIsObject ? { ...src } : {}

  const path = Array.isArray(key) ? key : key.split('.')

  // Create a deep copy of the path, up to (but not including) the last element
  let cur = root
  for (let i = 0; i < path.length - 1; i++) {
    const key: any = path[i]
    if (key === '__proto__') return srcIsObject ? src : root

    const item = cur[key]
    const copy = isPlainObject(item) ? { ...item } : {}
    cur = cur[key] = copy
  }

  const last = path[path.length - 1]
  if (last === '__proto__') return srcIsObject ? src : root

  // Optimization: avoid creating new object
  if (value !== undefined && cur[last] === value) {
    return srcIsObject ? src : root
  }
  if (value === undefined && cur[last] === value && !hasOwn(cur, last)) {
    return srcIsObject ? src : root
  }

  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  if (value === undefined) delete cur[last]
  else cur[last] = value

  return root
}

const OBJECT_HANDLER = {
  set: (obj: Record<string, any>, key: string, value: any) => {
    if (key in obj) {
      // make sure not to be affected by prototypal inheritance
      Object.defineProperty(obj, key, {
        configurable: true,
        enumerable: true,
        writable: true,
        value,
      })
    } else {
      obj[key] = value
    }
  },
  delete: (obj: Record<string, any>, key: string) => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete obj[key]
  },
}

export function deepSet(
  src: Dict<unknown>,
  key: string | string[],
  value: any,
  handler = OBJECT_HANDLER
): void {
  if (!isPlainObject(src)) throw new TypeError('src must be a plain object')
  if (!key?.length) return

  const path = Array.isArray(key) ? key : key.split('.')
  if (path.includes('__proto__')) return

  const { length } = path
  const last = path[length - 1]

  let cur = src
  for (let i = 0; i < length - 1; i++) {
    const key = path[i]

    if (!hasOwn(cur, key) || !isPlainObject(cur[key])) {
      if (value === undefined) return

      const newObject: Dict<unknown> = {}
      let subCur = newObject

      // Only call handler.set when setting into a pre-existing object
      for (let j = i + 1; j < length - 1; j++) {
        subCur = subCur[path[j]] = {}
      }

      subCur[last] = value
      handler.set(cur, key, newObject)

      return
    }

    cur = cur[key] as Dict<unknown>
  }

  if (value === undefined) {
    handler.delete(cur, last)
  } else {
    handler.set(cur, last, value)
  }
}

export function objectGet(
  object: any,
  path: string
): Record<string, any> | any[] | null | string | number | boolean | bigint | undefined {
  let current: any = object
  let currentType = typeof current // Optimization

  // Optimization: Inline the implementation of split(path, '.')
  let prev = 0
  do {
    const next = path.indexOf('.', prev)
    const key = next === -1 ? (prev === 0 ? path : path.slice(prev)) : path.substring(prev, next)

    switch (currentType) {
      case 'object':
        if (current === null) return undefined

        // Only __proto__ needs to be protected since other properties inherited
        // from Object.prototype are functions, which will never be returned by
        // this function.
        if (key === '__proto__') return undefined

        if (Array.isArray(current)) {
          const { length } = current

          // Allow reading an array's length (if length is the last part of the path)
          if (key === 'length') {
            return next === -1 ? length : undefined
          } else {
            const index = Number(key) % length
            if (Number.isNaN(index)) return undefined

            current = current[index < 0 ? length + index : index]
            currentType = typeof current
          }

          break
        }

        if (!(key in current)) return undefined

        current = current[key]
        currentType = typeof current

        break

      case 'string':
        // Allow reading a string's length (if length is the last part of the path)
        if (key === 'length' && next === -1) return current.length

      // falls through
      default:
        return undefined
    }

    prev = next + 1
  } while (prev !== 0)

  switch (currentType) {
    case 'object':
    case 'string':
    case 'number':
    case 'boolean':
    case 'bigint':
      return current
    default:
      return undefined
  }
}

export type DeepIdx<T, KS extends readonly any[]> = KS extends readonly [infer K, ...infer KK]
  ? K extends keyof T
    ? DeepIdx<T[K], KK>
    : undefined
  : T

export function objectGetArray<O, KK extends ReadonlyArray<string | number>>(
  obj: O,
  path: KK
): DeepIdx<O, KK>
export function objectGetArray(obj: any, path: ReadonlyArray<string | number>) {
  let current: any = obj
  let currentType = typeof current // Optimization

  if (path.length > 0) {
    let i = 0
    do {
      const key = path[i++]

      switch (currentType) {
        case 'object':
          if (current === null) return undefined

          // Only __proto__ needs to be protected since other properties inherited
          // from Object.prototype are functions, which will never be returned by
          // this function.
          if (key === '__proto__') return undefined

          if (Array.isArray(current)) {
            const { length } = current

            // Allow reading an array's length (if length is the last part of the path)
            if (key === 'length') {
              return i === path.length ? length : undefined
            } else {
              const index = Number(key) % length
              if (Number.isNaN(index)) return undefined

              current = current[index < 0 ? length + index : index]
              currentType = typeof current
            }

            break
          }

          if (!(key in current)) return undefined

          current = current[key]
          currentType = typeof current

          break

        case 'string':
          // Allow reading a string's length (if length is the last part of the path)
          if (key === 'length' && i === path.length) return current.length

        // falls through
        default:
          return undefined
      }
    } while (i < path.length)
  }

  switch (currentType) {
    case 'object':
    case 'string':
    case 'number':
    case 'boolean':
    case 'bigint':
      return current
    default:
      return undefined
  }
}

export function jsonifyStringObject(o: unknown): unknown {
  switch (typeof o) {
    case 'undefined':
    case 'boolean':
    case 'number':
    case 'bigint':
      return o
    case 'string':
      switch (o) {
        case 'undefined':
          return undefined
        case 'null':
          return null
        case 'true':
        case 'false':
          return o === 'true'
        case String(Number(o)):
          return Number(o)
        default:
          return o
      }
    case 'object':
      if (o === null) return null
      else if (Array.isArray(o)) return o.map(jsonifyStringObject)
      else {
        const result = {}
        // @ts-expect-error
        for (const k in o) result[k] = jsonifyStringObject(o[k])
        return result
      }
    default:
      return undefined
  }
}
