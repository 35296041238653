import { ifMatch, ifString } from '@penbox-io/stdlib'

import { type Dict, type FormError, stringifyHelpable } from '../../common'
import { type Definition, type GenericElement } from '../../core'

import { parseNumber } from '../utils/number.js'

type Value = unknown[]
type Options = {
  src?: `https://${string}` | `//${string}`
  alt?: string
  width: string
  height: 'auto' | number
  justify: 'start' | 'end' | 'left' | 'right' | 'center'
}
type Element = GenericElement<Options, Value>

export default {
  requirable: (options: Options) => false,
  options,
  parse,
  validate,
  normalize,
  stringify,
  stringifyTitle,
} satisfies Definition<Options, Value>

function options(input: Dict, locale: string): Options {
  return {
    src: ifMatch(input.src, /^(?:https:)?\/\//) as Options['src'],
    alt: ifString(input.alt),
    width: ifString(input.width) || '100%',
    height: input.height === 'auto' ? 'auto' : parseNumber(input.height, 1, 1000) || 200,
    justify: parseJustify(input.justify),
  }
}

function parseJustify(value: unknown) {
  switch (value) {
    case 'start': // RTL dependent
    case 'end': // RTL dependent
    case 'left':
    case 'right':
      return value
    default:
      return 'center'
  }
}

function parse(options: Options, locale: string, value: unknown): null | Value {
  return null // images are not supposed to have a "value" (yet?)
}

function validate(
  options: Options,
  locale: string,
  value: null | Value,
  required: boolean
): null | FormError {
  return null
}

function normalize(element: Element): undefined | Value {
  return undefined
}

function stringify(element: Element): undefined | string {
  return undefined
}

function stringifyTitle(element: Element): undefined | string {
  return stringifyHelpable(element.title) || element.options.alt
}
