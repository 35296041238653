import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import evalStringTransformer from './evalStringTransformer'
import notStringTransformer from './notStringTransformer'
import parenthesisStringTransformer from './parenthesisStringTransformer'
import ifStringTransformer from './ifStringTransformer'
import greatherLowerCompareStringTransformer from './greatherLowerCompareStringTransformer'
import equalityCompareStringTransformer from './equalityCompareStringTransformer'
import plusStringTransformer from './plusStringTransformer'
import minusStringTransformer from './minusStringTransformer'
import multiplyStringTransformer from './multiplyStringTransformer'
import divideStringTransformer from './divideStringTransformer'
import methodStringTransformer from './methodStringTransformer'

// order in the array is important
const stringTransformers = [
  evalStringTransformer,
  notStringTransformer,
  parenthesisStringTransformer,
  ifStringTransformer,
  greatherLowerCompareStringTransformer,
  equalityCompareStringTransformer,
  plusStringTransformer,
  minusStringTransformer,
  multiplyStringTransformer,
  divideStringTransformer,
  methodStringTransformer,
]

export interface StringTransformer {
  test: (expr: any) => boolean
  evaluate: (this: EvaluationContext, expr: string) => Value
}

function findStringTransformer(expr: any): StringTransformer | undefined {
  return stringTransformers.find((transformer) => transformer.test(expr))
}

export function evaluateStringTransformer(
  this: EvaluationContext,
  expr: string
): { found: boolean; value: Value } {
  const transformer = findStringTransformer(expr)

  if (!transformer) {
    return { found: false, value: expr }
  }

  return { found: true, value: transformer.evaluate.call(this, expr) }
}
